<div style="display: grid; grid-template-columns: 1fr auto;">
    <h2>Data change timeline</h2>
    <div>
        <mat-radio-group name="axns" [(ngModel)]="selected">
            <mat-radio-button value="quote" style="padding-right: 1em;">Quote</mat-radio-button>
            <mat-radio-button value="proposal" style="padding-right: 1em;">Proposal</mat-radio-button>
            <mat-radio-button value="eproposal" style="padding-right: 1em;" *ngIf="policy?.endProduct">EProposal</mat-radio-button>
        </mat-radio-group>
    </div>
</div>

<mat-dialog-content class="mat-typography">
    <div class="timeline">
        <ng-template [ngIf]="timelines[selected]" [ngIfElse]="qload">
            <ul>
                <li *ngFor="let tl of timelines[selected]"><span [style.background]="tl.other ? 'transparent' : auto"></span>
                    <div class="title">{{tl.author}}</div>
                    <div class="content">
                        <div class="info">
                            <div *ngIf="tl.changed.length==0">No changes detected</div>
                            <ng-container *ngTemplateOutlet="diff; context: {changed: tl.changed, count: tl.count}" ></ng-container>
                            <!-- <div *ngFor="let chg of tl.changed | slice:0:tl.count" [ngClass]="chg.op=='*' ? 'changed' : (chg.op=='+' ? 'added' : 'removed') ">
                                <div class="chpart" [title]="chg.name">{{chg.label}}</div>
                                <div class="chpart" [title]="chg.nv" style="color: #080;">{{chg.nv}}</div>
                                <div class="chpart" [title]="chg.ov" style="color: #800;">{{chg.ov}}</div>
                            </div> -->
                        </div>
                        <div style="font-size: 0.9em; text-align: right; cursor: pointer; padding: 0px 1em .5em 0em; " *ngIf="tl.changed.length > tl.count" (click)="tl.count = tl.changed.length">More ...</div>
                        <div style="font-size: 0.9em; text-align: right; cursor: pointer; padding: 0px 1em .5em 0em;" *ngIf="tl.changed.length > 3 && tl.count >= tl.changed.length" (click)="tl.count =3" >Less ...</div>
                    <!-- <div class="type"></div> -->
                    </div> <span class="number"><span>{{tl.u_ts_disp}}</span><span></span></span>
                </li>
            </ul>
        </ng-template>
        <ng-template #qload>
            <div style="display: grid; grid-template-columns: 1fr; grid-template-rows: 1fr auto 1fr; align-items: bottom; height: 350px;">
                <div></div>
                <button mat-button (click)="_load_timeline(selected)">Load data history</button>
                <div></div>
            </div>
            
        </ng-template>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
</mat-dialog-actions>

<ng-template #ditem let-chg="chg">
    <div [ngClass]="chg.op=='*' ? 'changed' : (chg.op=='+' ? 'added' : 'removed') ">
        <div class="chpart" [title]="chg.name">{{chg.label}}</div>
        <div class="chpart" [title]="chg.nv" style="color: #080;">{{chg.nv}}</div>
        <div class="chpart" [title]="chg.ov" style="color: #800;">{{chg.ov}}</div>
    </div>
</ng-template>

<ng-template #diff let-changed="changed" let-count="count">
    <ng-template ngFor let-chg [ngForOf]="changed | slice:0:count">
        <ng-template [ngIf]="chg.op == '[]'" [ngIfElse]="reg">
            <div style="padding: 0em 1em 1em 1em;">
                {{chg.label}}
                <table class="achanges">
                    <tr>
                        <th></th>
                        <th *ngFor="let key of chg.keys">{{key}}</th>
                    </tr>
                    <tr *ngFor="let row of chg.changes">
                        <td>{{row[chg.keys?.[0]]?.idx}}</td>
                        <td *ngFor="let key of chg.keys" [title]="row[key].ov">{{row[key].nv}}</td>
                    </tr>
                </table>
            </div>
            
        </ng-template>
        <ng-template #reg>
            <ng-container *ngTemplateOutlet="ditem; context: {chg}" ></ng-container>
        </ng-template>
    </ng-template>


    <!-- <div *ngFor="let chg of tl.changed | slice:0:tl.count" [ngClass]="chg.op=='*' ? 'changed' : (chg.op=='+' ? 'added' : 'removed') ">
        <ng-template [ngIf]="chg.op == '[]'" [ngIfElse]="reg">
            Array ...
        </ng-template>
        <ng-template #reg>
            <div class="chpart" [title]="chg.name">{{chg.op}} {{chg.label}}</div>
            <div class="chpart" [title]="chg.nv" style="color: #080;">{{chg.nv}}</div>
            <div class="chpart" [title]="chg.ov" style="color: #800;">{{chg.ov}}</div>
        </ng-template>
    </div> -->
</ng-template>
