import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Policy, IPolicy, insapi, IProduct } from 'insapi';
import { IField, animShowHide, animShowHideOpacity } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { Subscription } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { PreferencesService } from '../../../lib/services/preferences.service';

@Component({
    selector: 'i-combo-policy',
    templateUrl: './combo-policy.component.html',
    styleUrls: ['./combo-policy.component.scss']
})
export class ComboPolicyComponent implements OnInit {

    @Output() onChange = new EventEmitter();
    @Output() onAction = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    policy!: Policy;

    _products: any = {};    // all allowed products
    products: any[] = [];
    subproducts: any[] = [];
    subscription: Subscription | undefined = undefined;
    psubscription: Subscription | undefined = undefined;
    selected: any = null;
    clones: any[] = [];
    constructor(protected preferences: PreferencesService) { }

    ngOnInit(): void {
        this.subscription = insapi.profileSubject.subscribe((profile) => {
            for (let prod of insapi.profile?.products||[]) this._products[prod.product_id] = prod;
        });
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = undefined;
    }

    async _policy_changed() {
        let combo = (this.policy?.policy?.data?.combo || []);
        let pdata = (this.policy?.policy?.proposal_id ? this.policy?.policy?.proposal?.data : this.policy?.policy?.quote.data) ?? {};

        this.subproducts = [];
        for (let sub of this.policy.product?.data.combo||[]) {
            let spol = {product_id: sub.product_id, policy_id: '', selected: false};
            for (let cprod of combo) if (cprod.product_id == sub.product_id) {spol = cprod; break;}
            if (pdata.subpolicies?.[spol.product_id]) spol = {...pdata.subpolicies?.[spol.product_id], ...spol}

            this.subproducts.push({...sub, ...(spol||{}), product: this._products[sub.product_id]||null, policy_id: spol?.policy_id || null, selected: spol?.selected});
        }
        
        let pids = combo.map((x: any) => x.policy_id).filter(Boolean);

        if (this.policy.product?.combo_type === 'qms') {
            await this._update_clones();            
        } else {
            if (combo.length > 0) {
                let flts = {policy_id: '^'+pids.join(','), "quote.status": 'ignore', fmt: 'csv'};
                let ret = await insapi.quotes(undefined, 'payment.total_amount premium,policy_no', 100, flts);
                for (let r of ret) {
                    for (let sub of combo) {
                        if (sub.policy_id == r.policy_id) {
                            sub.premium = Number(r.premium || '0.00').toFixed(2);
                            sub.policy_no = r.policy_no || '';
                            if (sub.policy_no === 'null') sub.policy_no = '';
                            break;
                        }
                    }
                }
            }
        }
    }

    async _update_clones() {
        let combo = (this.policy?.policy?.data?.combo || []);
        let selected = combo.find((x: any) => x.selected);
        if (!selected) return;

        this.clones = await insapi.__xget("/api/v1/quote/clones/" + selected.policy_id+'?data=1') || [];
        this.selected = selected.product_id;
        for (let clone of this.clones) {
            let cprod = await insapi.productFromId(clone.product_id);
            clone.html = await this.preferences.render('combo', cprod?.product_name||'', clone.data);
        }
    }

    async cloneQuote(quote: any) {
        await insapi.cloneQuote(quote.quote_id, true, true, "");
        await this._update_clones();
    }

    async savePolicy() {
        this.onAction.emit({field_name: 'save_continue'});
    }

    async addToPackage(productId: string) {
        if (!productId) return;
        this.onAction.emit({field_name: 'add_to_package', product_id: productId});
    }

    async removeFromPackage(productId: string) {
        if (!productId) return;
        await this.policy.removeSubProduct(productId);
    }

    async goToPolicy(policyId: string) {
        if (!policyId) return;
        this.onAction.emit({field_name: 'load_policy', policy_id: policyId});
    }
    async downloadPolicy(policyId: string) {
        this.policy?.__redirect_to(insapi.server + '/api/v1/policy/data/' + encodeURIComponent(policyId) +
        '/policy.pdf/policy.pdf?download=1&token=' + encodeURIComponent(insapi.getToken()));

        // window.location.href = insapi.server + '/api/v1/policy/data/' + encodeURIComponent(policyId) +
        //     '/policy.pdf/policy.pdf?download=1&token=' + encodeURIComponent(insapi.getToken());
    }

    async subProdChange(ev: MatRadioChange) {
        // console.log('change:', ev.value, this.selected);
        this.onAction.emit({field_name: 'add_to_package', product_id: ev.value});
    }
}
