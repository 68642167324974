import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'reassign-policy',
	template: `
	<div>
		<div style="font-size: 1.2em; padding-bottom: 1em;">Select your reportee</div>
        <user-selector [(ngModel)]="userName"></user-selector>
		<textarea matInput placeholder="Message" [(ngModel)]="reason"></textarea>
	</div>
	<div class="message-footer">
        <button mat-button ibutton (click)="onAction('assign')">Assign</button>
		<button mat-button ibutton (click)="onAction('close')">Close</button>
	</div>

	`,
	styles: [
        `.message-footer {}`
	]
})
export class ReassignPolicyComponent {
    userName!: string;
	reason!: string;
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<ReassignPolicyComponent>) {
            
    }
    async onAction(action: any) {
        this.dialogRef.close({action, user: this.userName, reason: this.reason});
    }
}
