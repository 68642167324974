import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreferencesService } from '../services/preferences.service';
import { Policy, insapi, is_valid_date, parse_date } from 'insapi';

@Component({
    selector: 'csv-import',
    templateUrl: './csv-import.component.html',
    styleUrls: ['./csv-import.component.scss']
})
export class CsvImportComponent implements OnInit{

    parsed: string[][] = [];
    errors: string[][] = [];
    status: boolean[] = [];
    errCount: number = 0;
    showErrors: boolean = false;
    headers = ['col1', 'col2'];
    names = ['col1', 'col2'];
    accept: string = ".csv,.xlsx";
    
    policy: Policy | null = null;
    // jdata: {[key: string]: string | number | any[]} | null = null;
    jdata: {[key: string]: any} | null = null;
    jcolumns: {[key: string]: string[]} = {};
    actions: string[] = ['append', 'replace'];

    selcaption: string = 'Select CSV file';
    selmessage: string = 'First line of file must have headers';

    // data is expected to have 
    //  headers : string array of column headers
    //  validations: array of objects describing validations on each cell value (pass empty object if none needed)
    //
    constructor(public dialogRef: MatDialogRef<CsvImportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public preferences: PreferencesService) {
            this.headers = data.headers || [];
            this.names = data.names;
            this.policy = data.policy || null;
            this.actions = data.actions || ['append', 'replace'];
            this.accept = data.accept || ".csv,.xlsx";

            if (preferences.vendor?.strings?.csv_select_label)
                this.selcaption = preferences.vendor?.strings?.csv_select_label;
            if (preferences.vendor?.strings?.csv_select_message)
                this.selmessage = preferences.vendor?.strings?.csv_select_message;
            if (data.selcaption) this.selcaption = data.selcaption;
            if (data.selmessage) this.selmessage = data.selmessage;
    }

    ngOnInit() {
        this.dialogRef.updateSize('80%', '60%');
    }

    ngAfterViewInit() {
        if (!this.preferences.vendor?.testmode?.enable)
            document.getElementById('import-file')?.click();
    }

    async xlsxToJson(files: FileList) {
        const formData = new FormData();
        formData.append('name', files[0].name);
        formData.append('file', files[0], files[0].name);
        let ret = null;
        if (this.policy) {
            formData.append('product_id', this.policy?.product?.product_id ?? '');
            ret = await insapi.__xpost('/api/v1/quote/attach/fillin', formData);
            if (!ret) return insapi.showMessage("Invalid file, could not parse as xlsx", 1);
            else {
                let common: any = {};
                let jdata: any = {common};
                for (let key in ret) {
                    if (ret[key] instanceof Array) jdata[key] = ret[key];
                    else common[key] = ret[key];
                }
                this.jdata = jdata;
                for (let key in jdata) {
                    if (key != 'common' && jdata[key].length > 0) this.jcolumns[key] = Object.keys(jdata[key][0]).filter(x => x != 'uid' && x != 'puid');
                }
                
            }
        } else {
            ret = await insapi.__xpost('/api/v1/master/attach/xlsx', formData);
            if (ret.sheets[0]) this.processParsed(ret[ret.sheets[0]]);
            else insapi.showMessage('Parsing failed, could not find sheet names', 0);
        }
    }

    onFileSelected(files: FileList | null) {
        if (!files || files.length == 0 || !files[0]) return;
        if (files[0].name.endsWith('.xlsx')) {
            this.xlsxToJson(files);
        } else if (files[0].name.endsWith('.csv')) {
            const reader = new FileReader();
            reader.onload = () => this.csvToJson(reader.result);
            reader.readAsText(files[0]);
        } else {
            insapi.showMessage('File extension not supported '+files[0].name?.split('.')?.pop(), 1);
        }
    }

    line_to_array(str: string) {
        let sep = ',';
        let esc = '\\';
        let q = '';
        let acc = '';
        let ret = [];
        for (let i=0; i<str.length; i++) {
            if (str[i] == sep) {
                ret.push(acc.trim());
                acc = '';
            } else if (str[i] == esc) { // consume escaped character
                acc += str[i+1];
                i ++;
            } else if (str[i] == '"' || str[i] == "'") {
                if (q) {q = ''; for(; i<str.length-1; i++) if (str[i+1]==sep) break;} // skip trailing white spaces after end "
                else q = str[i]; //todo: assert acc is empty here
            } else {
                acc += str[i];
            }
        }
        ret.push(acc.trim());
        return ret;
    }

    _validate() {
        let errors = [];

        this.status = [];
        this.errCount = 0;
        for (let row of this.parsed) {
            let erow = [];
            let state = false;
            for (let i=0; i<row.length; i++) {
                let err = '';
                if (this.data.validations[i]?.visibility == false) continue;
                if (this.data.validations[i]?.date && row[i] ) {
                    // let formatted = parse_date(row[i], "YYYY-MM-DD", this.data.validations[i].date);
                    let formatted = is_valid_date(row[i], "YYYY-MM-DD", this.data.validations[i].date)
                    // let formatted = is_valid_date(row[i], "YYYY-MM-DD")
                    if (!formatted) {
                        err = row[i] + ' invalid date';
                        row[i] = '';
                    } else row[i] = formatted;
                }
                if (this.data.validations[i]?.options && row[i]) {
                    const index = this.data.validations[i].options.findIndex((element:string) => element?.toLowerCase() === row[i]?.toLowerCase());
                    if (index >= 0 && index < this.data.validations[i].options.length)
                        row[i] = this.data.validations[i].options[index];
                    else {
                        err = row[i] + ' not allowed';
                        state = true;
                    }
                }
                if (this.data.validations[i]?.mandatory && !row[i]) {
                    err = 'mandatory';
                    state = true;
                }

                erow.push(err);
            }
            errors.push(erow);
            this.status.push(state);
            if (state) this.errCount ++;
        }
        this.errors = errors;
        console.log('errors: ', this.errors, this.data.validations)
    }

    processParsed(lines: any[][]) {
        let headers = lines.shift();
        if (!headers) {insapi.showMessage("missing header line", 1); return; }

        headers = headers.map(x => x.toLowerCase());
        let idxs = headers.map(x => {
            let idx = this.headers.indexOf(x);
            if (idx < 0) idx = this.names?.indexOf(x);
            return idx;
        });

        let hmap: number[] = [];
        for (let i=0; i<this.headers.length; i++) hmap.push(idxs.indexOf(i));

        // this.headers.map(x => headers ? headers.indexOf(x) : -1);
        this.parsed = lines.map(r => hmap.map((x) => x >= 0 ? r[x] : ''));
        this._validate();
    }

    csvToJson(txt: string | ArrayBuffer | null) {
        if (!txt) return;
        if (!this.headers || this.headers.length == 0) return;

        if (txt instanceof ArrayBuffer) txt = Buffer.from(txt).toString('utf8');
        
        let lines = txt.split('\n').map(x => x.trim()).filter(Boolean).map(x => this.line_to_array(x));
        this.processParsed(lines);
    }

    onClose() {
        this.dialogRef.close({});
    }

    onAction(axn: string) {
        this.dialogRef.close({mode: axn, data: this.parsed, jdata: this.jdata, errors: this.errors});
    }
}
