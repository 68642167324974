import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { http, tm } from './httpng';
import { insapi, IProfile } from './insapi';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class InsapiService {

	wsConnection: WebSocketSubject<any> | null = null;
	recent: any[] = [];
	subjects: {[key: string]:Subject<any>|BehaviorSubject<any>} = {};
	offline: any[] = [];
	proxyUsers: any = {};

	constructor(private httpClient: HttpClient) {
		http.setHttpClient(httpClient, false);
		this.subjects['notify'] = new Subject<any>();
		this.subjects['logs'] = new Subject<any>();
		// this.subjects['cart'] = new BehaviorSubject<any>(null);
	}

	setStorage(mode: string) {
		tm.store = mode;
	}

	async setEncryptionOn() {
		await http.setHttpClient(this.httpClient, true);
	}

	async loadProfile(): Promise<IProfile | null> {
		await insapi.loadUser();
		return insapi.profile;
	}

	wsURL() {
		const url = document.createElement('a');
		url.setAttribute('href', window.location.href);
		// if( url.hostname == 'localhost' || url.hostname == '127.0.0.1' )
		// 	return profile ? profile.istats.ws : 'ws://127.0.0.1:8009';
		return (url.protocol == 'https:'?'wss://':'ws://')+ url.hostname + (+url.port > 0 ? (':' + url.port) : '')+'/ws';
	}

	_process_message(msg: any) {
		// console.log('ws-message:', msg);
		if (msg.type == 'recent') {
			for (let m of msg.data) this._process_message(m);
		} else {
			if (this.subjects[msg.type]) this.subjects[msg.type].next(msg);
			// else console.log('ws-message:', msg.type);
		}
	}

	wsConnect() {
		if (!this.wsConnection/* || this.wsConnection.closed*/) {
			console.log('InsapiService: connect', this.wsURL());
			this.wsConnection = webSocket(this.wsURL());
			this.wsConnection.subscribe((message: string) => {
				this._process_message(message);
			}, (error: Error) => {
				const { message } = error
				// console.log('ws-error:', message, error);
			}, () => {
				console.log('ws: completed, unsubscribing');
				this.wsConnection?.unsubscribe();
				this.wsConnection = null;
			});
		}

		// authenticate ourselves (everytime profile is changed)
		//
		this.recent = [];
		// console.log('token: ', insapi.getToken());
		this.wsConnection.next({type: 'token', token: insapi.getToken()});
		while (this.offline.length > 0) {
			this.wsConnection.next(this.offline.shift());
		}
	}

	influx(ctx: string, qry: string) {
		if (this.wsConnection) {
			console.log('ws:', ctx, qry);
			this.wsConnection.next({type: 'influx', q: qry, ctx: ctx});
		} else {
			console.log('ws: not-connected, pushing to offline...');
			for (let i=0; i<this.offline.length; i++)
				if (this.offline[i].q == qry && this.offline[i].ctx == ctx) return;
			if (this.offline.length > 100) this.offline.shift();
			this.offline.push({type: 'influx', q: qry, ctx: ctx});
		}
	}

	ngOnDestroy() {
		console.log('InsapiService: destroy');
		if (this.wsConnection) {
			this.wsConnection.unsubscribe();
			this.wsConnection.complete();
			this.wsConnection = null;
		}
		this.subjects = {};
	}

	subscribe(type: string, cb: (msg: any)=>any): Subscription {
		if (!this.subjects[type]) this.subjects[type] = new Subject<any>();
		return this.subjects[type].subscribe(cb);
	}

	update(type: string, obj: any) {
		this.subjects[type]?.next(obj);
	}

	async updateCart(subId: string | null = null) {
		insapi.cartSubject.next(null);
	}

	async getProxyUsers(of: string) {
		if (!this.proxyUsers[of]) {
			try {
				this.proxyUsers[of] = await insapi.proxyUserList() || [];
			} catch (e) {
				this.proxyUsers[of] = [];
			}
		}
		return this.proxyUsers[of];
	}

}
