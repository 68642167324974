import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { IField } from '../../form.interface';


@Component({
    selector: 'i-readonly',
    template: `
    <div class="ireadonly" [attr.data-iid]="field.field_name">
      <div class="ireadonly-label" >{{field.label}}</div>
      <div class="ireadonly-text">{{data[field.field_name]}}</div>
    </div>
  `,
    styles: [
    ]
})
export class ReadonlyComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    data: any = {};
    constructor() { }

    ngOnInit(): void {
    }

}
