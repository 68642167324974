import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { insapi, IProduct, IProfile, flattenJSON, is_obj } from 'insapi';
import { environment } from './../../../environments/environment';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PreferencesService } from 'ins-form';
import moment from 'moment';

@Component({
    selector: 'app-renewable-list',
    templateUrl: './renewable-list.component.html',
    styleUrls: ['./renewable-list.component.scss']
})
export class RenewableListComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    
    profile: IProfile | null = null;
    dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    product?: IProduct | null;
    data: any[] = [];
    filters: {[key: string]: string} = {};
    listDef: any = null;
    form: any = {groups: [{fields: []}], layout: {"cls": "fg-wrapper"}};
    columns: string[] = [];

    subscription: Subscription | null = null;
    rsubscription: Subscription | null = null;
    constructor(private route: ActivatedRoute, private router: Router,
        public dialog: MatDialog,
        protected preferences: PreferencesService) {
            this.__init_def();
        }

    ngOnInit(): void {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {
            this.profile = profile;
            if (!this.rsubscription && profile) {
                this.rsubscription = this.route.paramMap.subscribe((params: ParamMap) => this.__load(params.get('product_id')));
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    @HostListener('window:resize', ['$event']) onResize(ev: any) {
        this._set_layout_width();
    }
    _set_layout_width() {
        let iw = window.innerWidth;
        this.form.layout.width = (+this.form.layout.maxWidth > iw) ? '100%' : (this.form.layout.maxWidth || 860) + 'px';
    }

    __init_def() {
        let listdef = 
            environment.vendor['renew-list']?.[this.product?.product_group_id||''] || 
            environment.vendor['renew-list']?.[this.product?.product_group_name||''] || 
            environment.vendor['renew-list']?.['all-groups'];

        if (listdef) this.listDef = listdef;
        for (let col of listdef.columns) {
            col.pipe_args = null;
            if (!col.pipe && col.type == 'currency') col.pipe = CurrencyPipe;
            if (!col.pipe && col.type == 'decimal') {col.pipe = DecimalPipe; col.pipe_args = '1.0-2'}
        }

    }
    async applyFilter(ev: any) {
        if (ev.target?.value)
            this.dataSource.filter = ev.target.value.trim().toLowerCase();
        else
            this.dataSource.filter = "";
    }

    async _select_product() {
        let options = this.profile?.products.map(x => {return {name: x.product_name, value: x.product_id}});
        this.form.groups[0].fields = [{field_name: 'product_id', type: 'lookup', options}];
        this.form = JSON.parse(JSON.stringify(this.form));
    }

    __fix_row(row: any, columns: any[]) {
        for (let col of columns) {
            if (col.name.startsWith('data.')) {
                row[col.name] = row.data[col.name.substring(5)];
            }
        }
    }

    async __load(productId: string | null) {
        if (!productId) return this._select_product();
        this.product = await insapi.productFromId(productId);
        if (!this.product || !this.product?.product_group_id) return;
        this.columns = this.listDef.columns.filter((x: any) => x.type != 'hidden').map((x: any) => x.disp);

        let url = "/api/v1/renewable/list";
        url += "?fields=" + encodeURIComponent(this.columns.join(','));
        url += "&fmt=csv";
        let ret = await insapi.__xget(url) || [];
        let dates = this.listDef.columns.filter((x: any) => x.type == 'datetime');
        this.data = ret;
        console.log('ret:', this.data);
        for (let row of this.data) {
            let actions = [{name: 'Renew', icon: 'content_copy'}];
            for (let date of dates)
                if (row[date.disp]) row[date.disp] = moment.utc(row[date.disp]).local().format(this.preferences.dateFormat);
            this.__fix_row(row, this.listDef.columns);
            row.actions = actions;
        }

        if (this.dataSource) this.dataSource.data = this.data;
        if (this.listDef.layout) this.form.layout = this.listDef.layout;
        if (this.form.layout && !this.form.layout.maxWidth) this.form.layout.maxWidth = 860;
        this._set_layout_width();
    }
    async onFilterAction(ev: any) {
        if (this.filters.product_id) this.__load(this.filters.product_id);
        else if (this.product) this.__load(this.product.product_id);
    }

    async onFilterChange(ev: any) {
        if (ev.min && ev.max) {
            let max = moment(ev.max).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            this.filters['u_ts'] = '[]'+ev.min+','+max;
            this.filters['period'] = ev.min+','+ev.max;
        }
    }

    async rowClicked(row: any) {
        this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {renew_id: row.renewable_id}});
    }


    async menuClicked(action: any, row: any) {
        console.log('action:', action, row);
        if (action.name === 'Renew') {
            console.log('renewable_id:', row.renewable_id);
            this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {renew_id: row.renewable_id}});
        }
    }
}
