import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router, Route, NavigationStart } from "@angular/router";
import { insapi, IPolicy, IWorkflow, Policy, NameValue, InsapiService, deepMerge, _clone, ExcelForm, IProfile, IEndorsement } from 'insapi';
import { CdkStepper } from '@angular/cdk/stepper';
import { MatDialog } from '@angular/material/dialog';
import { FlowComponent, PreferencesService } from 'ins-form';
import { environment } from './../../../environments/environment';
import { CurrencyPipe } from '@angular/common';
import { GenericMessageComponent, PartPaymentComponent } from 'ins-form';
import { Subscription } from 'rxjs';
import { SettingsService } from '../../services/settings.service';
import { trace } from '../../services/tracer';


@Component({
    selector: 'app-policy',
    templateUrl: './policy.component' + environment.brand + '.html',
    styleUrls: ['./policy.component' + environment.brand + '.scss']
})
export class PolicyComponent extends FlowComponent implements OnInit {
    
    
    premiumDisp: any = {field_name: 'premium_value', pipe: environment.vendor?.policy?.premium?.premiumFormat || ['currency'], style: {'font-size': '1.6em', color: 'var(--header-fg-hilite)'}};
    premiumData: any = {premium_value: 0, message: ''};

    
    rsubscription: Subscription | null = null;
    psubscription: Subscription | undefined = undefined;
    ssubscription: Subscription | undefined = undefined;
    
    chatType: number = 0;
    inMyGroup: boolean = false;
    referralMessage: string = '';
    endorsements: boolean = false;
    upgrade: boolean = false;

    referranceDisp: any = {field_name: 'quote_id', label:"Quote ID"}
    showMinibar: boolean = true;
    constructor(protected activatedRoute: ActivatedRoute,
        protected settings: SettingsService,
        protected insapiService: InsapiService,
        protected preferences: PreferencesService,
        protected router: Router,
        public dialog: MatDialog) {
        super(insapiService, preferences, router, activatedRoute, dialog);

        this.rsubscription = this.activatedRoute.queryParams.subscribe(params => {
            // console.log('params:', params);
            this.endorsements = params.endorsements ? true : false;
            if (params.product_id) this.newPolicy(params.product_id,params);
            else if (params.renew_id) this.renewPolicy(params.renew_id);
            else this.loadPolicy(params.policy_id);
        });
        
        if (environment.vendor.premiumAtNavBar) {
            this.premiumDisp = null;
        }

        if (environment.vendor?.policy?.hideMinibar) this.showMinibar = false;

        if (environment.vendor?.policy?.referral?.hidepremium || environment.vendor?.policy?.referral?.hidepremium === undefined) {
            this.referralMessage = environment.vendor?.policy?.referral?.premiumMessage || 'Referral case';
        } else {
            this.referralMessage = '';
        }
    }

    ngOnInit(): void {
        
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        insapi.changeFunc = null;
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
        if (this.ssubscription) this.ssubscription.unsubscribe();
        this.ssubscription = undefined;
        this.preferences._update_premium(null, false, null);
    }

    async _init(policy: Policy) {
        if (!policy.policy) return;

        // if we have already initialized the workflow, lets not repeat it again
        //
        if (this.workflow && this.workflow.wf_id == policy.policy.wf_id) {
            this._update_stage_status();
            this._move_to_inprogress();
            return;
        }

        this.workflow = await policy.workflow();
        if (!this.workflow) return;
        this.workflow.layout = deepMerge(environment.vendor.layout.workflow||{}, this.workflow.layout);
        try {await this._prepare_excel_forms(policy);}catch(e){console.log(e)}

        if (this.psubscription) this.psubscription.unsubscribe();
        // this.psubscription = this.policy?.policySubject.subscribe((policy: IPolicy | null) => {
        this.psubscription = this.policy?.changeSubject?.subscribe((policy: IPolicy | IEndorsement | null) => {
            if (policy?.prd_endorsement_id) return; // change is not on policy
            if (this.policy?.policy) {
                let cur = this.policy.policy.stage_status.filter(x => x.status == 'inprogress').map(x => x.stage);
                this.chatType = 0;

                if (this.nstpType && this.policy.policy[this.nstpType] && this.policy.policy[this.nstpType].nstp_enabled=='Yes') {
                    this.chatType = 1;
                }
                if (this.policy.policy.inspect?.need_inspetion && cur.indexOf('inspect') >= 0) {
                    this.chatType = 2;
                }

                this.inMyGroup = false;
                if (this.policy.policy.assigned_to?.startsWith('G0')) {
                    let grpName = insapi.groupName(this.policy?.policy?.assigned_to);
                    if (grpName != this.policy?.policy?.assigned_to) {
                        if (this.profile?.groups?.indexOf(this.policy?.policy?.assigned_to)>=0)
                            this.inMyGroup = true;
                    }
                }
            }

            this._update_list_options();    //-rr Dec-13-2022 moved from change func
            this.premiumData = this.preferences._update_premium(policy, this.profile?.is_underwriter?true:false, this.policy);
            if (this.policy?.policy?.cart_id) this.insapiService.updateCart();

            this.upgrade = false;
            if (!this.policy?.policy?.proposal_no && this.policy?.policy?.proposal_id) {
                if (+this.policy?.product?.proposal_form_js_version > +this.policy?.policy?.proposal?.data.proposal_form_js_version) {
                    if (this.profile && this.profile.privileges.indexOf("Upgrade Proposal") >= 0) this.upgrade = true;
                }
            }

            //to display referrance Id Quote_id/PolicyId
            if (environment.vendor?.policy?.referanceDisplay) {
                if (environment.vendor?.policy?.referanceDisplay[this.policy?.policy?.quote.data.product_group_name] ) {
                    this.referranceDisp.field_name = environment.vendor?.policy?.referanceDisplay[this.policy?.policy?.quote.data.product_group_name].field_name;
                    this.referranceDisp.label = environment.vendor?.policy?.referanceDisplay[this.policy?.policy?.quote.data.product_group_name].label;
                }
                else {
                    this.referranceDisp.field_name = environment.vendor?.policy?.referanceDisplay?.field_name;
                    this.referranceDisp.label = environment.vendor?.policy?.referanceDisplay?.label;
                }
            }
            // console.log('js-version:', this.policy?.policy?.quote?.data.proposal_form_js_version, this.policy?.product?.proposal_form_js_version)
            this.__update_router();
        });

        if (this.ssubscription) this.ssubscription.unsubscribe();
        this.ssubscription = this.policy?.stateSubject.subscribe((policy: IPolicy | IEndorsement | null) => {
            if (policy) {
                this.__move_to_inprogress();
                this._update_stage_status();
            }
        });


        return;
    }

    async renewPolicy(renewlId: string) {
        let policy: Policy | null = await Policy.__renew(renewlId);
        if (!policy) return;
        await this._init(policy);
        let changed = await policy.__premium(policy.policy?.quote.data, true);
        if (changed) this.fgs.forEach(x => x._changed(changed as NameValue));
    }

    async newPolicy(productId: string, params: any) {
        let policy: Policy = new Policy(this.options);
        await policy.init(productId/*, {wf_id: '21'}*/);
        
        //For prefilling values from custom angular app
        if (policy.policy && policy.policy.quote && !policy.policy.policy_id && !params.quote_id && !params.policy_id)
           policy.policy.quote.data = {...policy.policy.quote.data,...params};

        await this._init(policy);

        let changed = await policy.__premium(policy.policy?.quote.data, true);
        if (changed) this.fgs.forEach(x => x._changed(changed as NameValue));
    }


    async loadPolicy(policyId: string) {
        await this.insapiService.loadProfile();
        let policy = new Policy(this.options);
        await policy.__load(policyId, this.endorsements);
        await this._init(policy);
    }

    submit() {
    }


    async __partPayment(ev: any) {
        if (!this.policy) return;
        let data = {
            caption: 'Payment', 
            description: 'You may choose to pay in full or make part payment',
            amount: (this.policy.policy?.payment.total_amount - this.policy.policy?.payment.total_recvd)
        };
        let ref = this.dialog.open(PartPaymentComponent, {data});
        ref.afterClosed().subscribe(async (action) => {
            if (isNaN(action?.amount) || action?.amount <= 0) return;
            if (!await this.policy?.__payCash(action?.amount, ev.sub_action)) {
                this.__reload();
            } else {
                this._move_to_inprogress();
                this._update_stage_status();
            }
        });

    }

    async onAction(ev: any) {
        if (ev.field_name === 'add_to_cart' ) {
            if (await this.policy?.__addToCart(ev.sub_action||'')) {
                await this._redirectTo(ev, this.policy?.policy?.proposal ? this.policy?.policy?.proposal.data : this.policy?.policy?.quote.data);
                this.insapiService.updateCart(ev.sub_action||'');
                await this.addToCart();
            }
        } else if (ev.field_name === 'collect_cash') {
            if (this.policy?.product?.product_name && this.preferences.vendor?.payment?.[this.policy?.product?.product_name]?.partPayment) {
                this.__partPayment(ev);
            } else {
                super.onAction(ev);
            }

        } else {
            super.onAction(ev);
        }
    }
    
    async addToCart() {
        // if (await this.policy?.__addToCart()) {
            let data = {
                caption: 'CART', 
                description: 'Added ' + (this.policy?.product?.product_name||'') + ' product to cart. Use Cart button at top right to checkout',
                actions: [{name: 'continue', disp: 'Continue Shopping'}, {name: 'close', disp: 'Close'}]
            };
            let ref = this.dialog.open(GenericMessageComponent, {data});
            ref.afterClosed().subscribe((action) => {
                if (action.name == 'continue') this.router.navigate(['/'], {});
            });
        // }
    }


    __copy_event_data(data: any, ev: any) {
        if (ev instanceof Array && ev[0]?.field_name) {
            for (let fld of ev) data[fld.field_name] = fld.value;
        } else {
            if (ev && !ev.field_name) deepMerge(data, ev);
        }
    }

    async onChange(ev: any) {
        if (!this.policy?.policy) return;
        if (ev?.field_name && ev?.ignore_change) return;
        if (ev?.field_name) this.fgs.forEach(x => x._resetError({[ev.field_name]:''}));

        let changed: any = undefined;
        if (this.policy.policy.proposal_id) {
            this.__copy_event_data(this.policy.policy.proposal.data, ev);
            changed = await this.policy.__premium(this.policy.policy.proposal.data, false, undefined, this.preferences.vendor?.policy?.blockCalc);
        } else {
            this.__copy_event_data(this.policy.policy.quote?.data, ev);
            // if (ev && !ev.field_name) deepMerge(this.policy.policy.quote?.data, ev);
            changed = await this.policy?.__premium(this.policy.policy.quote?.data, true, undefined, this.preferences.vendor?.policy?.blockCalc);
        }

        // array of inputs changed (in suplimentary widgets) that needs to be updated in main widgets
        //
        if (ev instanceof Array) {
            if (!changed) changed = {};
            for (let key in ev) if (!changed[ev[key].field_name]) changed[ev[key].field_name] = ev[key].value;
        }
        this.fgs.forEach(x => x._changed(changed)); // update all controls that have changed with new values

        this._handle_errors(ev.field_name);
        trace._print();
    }

    async takeOver() {
        if (this.policy && this.policy.policy) {
            await insapi.acquireQuote(this.policy.policy.quote_id);
            this.policy.load(this.policy.policy.policy_id);
        }
    }
    async upgradeVersion() {
        if (!this.policy?.policy?.proposal_id) return;
        if(confirm("Switcing to new version is not reversible, would you like to continue")) {
            await insapi.__xpost('/api/v1/proposal/upgrade', {proposal_id: this.policy?.policy?.proposal_id});
            this.policy.load(this.policy.policy.policy_id);
        }
    }
}
