<div class="choice-container" *ngIf="multi && form">
    <div class="choice-caption">
        <div>{{multi.description}}</div>
    </div>

    <table class="multi-table">
        <tr>
            <th (click)="addChoice()"></th>
            <th *ngFor="let choice of data.choices; let i=index;" [class.selected]="choice.selected">
                <div class="option">Option {{i+1}}</div>
                <div *ngIf="multi.highlite" class="highlite" (click)="setSelected(choice)">
                    <span *ngIf="!policy.referral || premium_field != multi.highlite.param_name || (profile && profile.is_underwriter)">
                        {{choice[multi.highlite.param_name] | generic2: multi.highlite.pipe }}
                    </span>
                    <span *ngIf="policy.referral && premium_field == multi.highlite.param_name && (!profile || !profile.is_underwriter)"> -- </span>
                </div>
            </th>
            <th [attr.rowspan]="parameters.length+multi.outputs.length+2" style="vertical-align: middle;" (click)="addChoice()">
                <div *ngIf="!readonly && data.choices.length < multi.max" style="cursor: pointer;"><mat-icon fontSet="material-icons-outlined" >add</mat-icon> Add option</div>
            </th>
        </tr>

        <tr *ngFor="let param of parameters">
            <td style="text-align: right;">{{param.param_label}}</td>
            <td *ngFor="let choice of data.choices; let i=index;" [class.selected]="choice.selected">
                <ng-template [ngIf]="readonly">
                    {{choice[param.param_name]}}
                </ng-template>
                <ng-template [ngIf]="!readonly">
                    <ng-template [ngIf]="visible[i]?.[param.param_name]" [ngIfElse]="invisble">
                        <ng-template [ngIf]="param.type == 'number'">
                            <input class="multi-input" [title]="param.param_label||''" type="text" [(ngModel)]="choice[param.param_name]" (change)="changeValue(choice, param, choice[param.param_name])">
                        </ng-template>
                        <ng-template [ngIf]="param.type == 'checkbox'">
                            <mat-icon fontSet="material-icons-outlined" *ngIf="choice[param.param_name]==param.param_values[0]" (click)="changeValue(choice, param, param.param_values[1])" style="color: #0A0;">{{yesNo[choice[param.param_name]]}}</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" *ngIf="choice[param.param_name]!=param.param_values[0]" (click)="changeValue(choice, param, param.param_values[0])" style="color: #A00;">{{yesNo[choice[param.param_name]]}}</mat-icon>
                        </ng-template>
                        <ng-template [ngIf]="param.type == 'options'">
                            <select [title]="param.param_label||''" [(ngModel)]="choice[param.param_name]" (change)="changeValue(choice, param, choice[param.param_name])">
                                <option *ngFor="let value of param.param_values" [value]="value">{{value}}</option>
                            </select>
                        </ng-template>
                    </ng-template>
                    <ng-template #invisble>
                        -+-
                    </ng-template>

                </ng-template>
            </td>
        </tr>
        <tr>
            <td></td>
            <td *ngFor="let choice of data.choices; let i=index;" [class.selected]="choice.selected"></td>
        </tr>

        <tr *ngFor="let output of multi.outputs">
            <td>{{output.param_label}}</td>
            <td *ngFor="let choice of data.choices; let i=index;" [class.selected]="choice.selected" (click)="setSelected(choice)">
                <mat-icon fontSet="material-icons-outlined" *ngIf="choice.selected && choice.param_name == multi.highlite">done</mat-icon>
                <span *ngIf="!policy.referral || premium_field != output.param_name || (profile && profile.is_underwriter)">{{choice[output.param_name] | generic2: output.pipe }}</span>
                <span *ngIf="policy.referral && premium_field == output.param_name && (!profile || !profile.is_underwriter)"> -- </span>
            </td>
        </tr>

        <tr>
            <td></td>
            <td *ngFor="let choice of data.choices; let i=index;" [class.selected]="choice.selected"></td>
        </tr>

    </table>
</div>