import { Component, OnInit, ViewChildren, ElementRef, Input, QueryList, SimpleChange } from '@angular/core';
import { ZulipService } from '../../services/zulip.service';
import { Router } from "@angular/router";
import { insapi, InsapiService, http } from 'insapi';
import { Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
    animations: [trigger('inOutAnimation', [
            transition(':enter', [style({ width: 0, opacity: 0 }), animate('.25s ease-out', style({ width: 128, opacity: 1 }))])
            ]
        )]
})
export class ActivityComponent implements OnInit {
    @ViewChildren('zontainer') zontainer!: QueryList<ElementRef>;
    @ViewChildren("zsearch") zsearch!: QueryList<ElementRef>;
    constructor(private insapiService: InsapiService, 
        private router: Router,
        protected zulip: ZulipService
        ) {}

    activities: any[] = [];
    show: boolean = true;
    subscription: Subscription | null = null;
    zsubscription: Subscription | null = null;
    ssubscription: Subscription | null = null;
    msg: string = '';

    attach: boolean = false;
    files: any[] = [];
    narrow: boolean = false;
    search: string = '';

    @Input() doctypes: {document_type: string; desc: string, attached: boolean}[] = [];
    @Input() mode: number = 1;
    @Input() streamName: string = '';
    
    ngOnInit(): void {
        this._init();
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // console.log('chaning to stream:', this.streamName);
        // if (changes['streamName']) this.zulip.select_stream(this.streamName || -1);
    }

    async _init() {
        this.subscription = this.insapiService.subscribe('notify', (msg: any) => this.activities.unshift(msg));
        console.log('doctypes', this.doctypes)
    }

    showAttachment() {
        this.attach = !this.attach;
    }

    attachFile(ev: any, dtype: any) {
        if (dtype) dtype.attached = true;
        this.files.push({document_type: dtype?.document_type || 'others', desc: dtype?.desc || 'Others', file: ev.target.files[0]});
    }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
        if (this.zsubscription) this.zsubscription.unsubscribe();
        if (this.ssubscription) this.ssubscription.unsubscribe();
        this.subscription = null;
        this.zulip.__stop_objserver();
    }

    ngAfterViewInit() {
        this.zulip.select_stream(this.streamName ? this.streamName : -1);
        if (this.zontainer) {
            if (this.zontainer.first) this.zulip.__start_objserver(this.zontainer.first);
            this.zsubscription = this.zontainer.changes.subscribe((comps: QueryList<ElementRef>) => this.zulip.__start_objserver(comps.first));
        }
        if (this.zsearch) {
            this.ssubscription = this.zsearch.changes.subscribe((comps: QueryList<ElementRef>) => {
                console.log('focus:', comps.first?.nativeElement);
                comps.first?.nativeElement?.focus();
            });
        }
    }

    blockClick(ev: any) {
        ev.stopPropagation();
    }

    onClick(id: string, mod: string) {
        if (mod == 'policy') {
            this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: id}});
        }
    }

    async __handle_attachment_downloads(ev: any) {
        if (!ev?.target) return false;
        let link = ev.target.href;
        if (!link) {
            let refs = ev.target.getElementsByTagName('a');
            if (refs.length>0) link = refs[0].href;
        }
        console.log('link:', link)
        if (!link || link.indexOf('/document/data') < 0) return false;
        link += '&token=' + encodeURIComponent(insapi.getToken());
        window.location.href = await http.__encrypt_url(link, true);
        return true;
    }

    async messageClicked(ev: any, msg: any) {
        if (await this.__handle_attachment_downloads(ev)) return;
        if (!msg) return;
        let stream = this.zulip.streams[msg.stream_id];
        if (stream) {
            console.log('viewing ... ', msg.id);
            this.zulip.viewing = msg.id;
            if (stream.name.startsWith('P0')) {
                this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: stream.name}});
            }
        }
    }

    async selectStream(streamId: number) {
        await this.zulip.select_stream(streamId);
        // setTimeout(() => {if (this.zontainer) this.zulip.__start_objserver(this.zontainer);}, 150);   
    }
    async send(streamId: number) {
        console.log('send to ...', streamId);
        if (!this.msg && this.files.length == 0) return;
        await this.zulip.post(streamId, this.msg, this.files);
        this.files = [];
        for (let type of this.doctypes) type.attached = false;
        this.attach = false;
        this.msg = '';
    }

    debounce: any = null;
    async searchChanged() {
        if (this.debounce) clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
            this.zulip.__load_messages(this.search);
        }, 700);
    }

    async clearSearch() {
        this.narrow = false;
        if (this.debounce) clearTimeout(this.debounce);
    }
}
