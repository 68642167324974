import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { insapi, InsapiService } from 'insapi';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { PreferencesService } from '../../lib/services/preferences.service';

@Component({
    selector: 'icart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

    @Output() close = new EventEmitter();
    cartSubIds: any[] = [];
    idx: number = 0;
    cart: any = null;
    taxKeys: string[] = [];
    promoCode: string = '';
    subscription: Subscription | null = null;
    saved: any[] = [];
    constructor(private insapiService: InsapiService, 
        private preferences: PreferencesService,
        private router: Router) { }

    ngOnInit(): void {
        this.cartSubIds = this.preferences?.vendor?.cart?.subids || [{"name": "Default", "value": ""}];
        this.subscription = insapi.cartSubject.subscribe((val) => this._cart_changed(val));
    }
    
    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    prevCart() {
        if (this.idx <= 0) return;
        this.idx --;
        this._cart_changed(null);
    }

    nextCart() {
        if (this.idx >= this.cartSubIds.length) return;
        this.idx ++;
        this._cart_changed(null);
    }

    __get_current_cart() {
        if (this.idx < 0 || this.idx >= this.cartSubIds.length) return null;

        
        let subId = this.cartSubIds[this.idx].value;
        for (let cart of insapi.carts) {
            if (cart.sub_id == subId) return cart;
        }
        return null;
    }

    _cart_changed(cart: any) {
        // locate the cart thats currently being displayed (in case of multi-cart)
        //
        this.cart = this.__get_current_cart();
        
        let saved: any = {};
        for (let cart of insapi.carts) {
            for (let item of cart.saved) saved[item.policy_id] = item;
        }
        this.saved = Object.values(saved);
        console.log('saved:', this.saved)
        if (!this.cart) return;

        

        let net = 0;
        let total = 0;
        let tax: any = {};
        for (let item of this.cart.items) {
            total += item.premium;
            net += item.amount;
            let td = JSON.parse(item.tax_details);
            for (let tp of td.tax_parts) {
                let name = tp.name + ' @' + tp.rate + '%';
                if (!tax[name]) tax[name] = 0;
                tax[name] += tp.value;
            }
        }
        this.cart.total = total;
        this.cart.tax = tax;
        this.cart.net = net;
        this.taxKeys = Object.keys(tax);
    }

    goto(item: any) {
        this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: item.policy_id}});
        this.close.emit();
    }
    async remove(item: any) {
        let ret = await insapi._cart_to_saved(item.policy_id);
        let cart = this.__get_current_cart();
        // await insapi._cart(cart?.sub_id);
        await this._reload_all();
    }

    async moveToCart(item: any) {
        let cart = this.__get_current_cart();
        let subId: string = cart?.sub_id || '';
        if (!cart) subId = this.cartSubIds[this.idx].value || '';
        let ret = await insapi._cart_from_saved(item.policy_id, subId);
        // await insapi._cart(cart?.sub_id);
        await this._reload_all();
    }
    async removeFromSaved(item: any) {
        let ret = await insapi._cart_to_saved(item.policy_id);
        // let cart = this.__get_current_cart();
        // await insapi._cart(cart?.sub_id);
        await this._reload_all();
    }

    async checkout() {
        if (!this.cart) return;
        let ret = await insapi._cart_checkout(this.cart.cart_id);
        if (ret) {
            this.router.navigate(['/order'], { state: {asd:1}, queryParams: {order_id: ret.order_id}});
            await insapi.reload_carts()
            this._cart_changed(null);
            this.close.emit();
        }
    }

    async checkoutOnline() {
        if (!this.cart || !insapi.profile?.email) return;
        let url = (this.preferences.vendor?.pubbase || '') + "/payment/" + encodeURIComponent(this.cart.cart_id);
        url += "?entity_id=" + encodeURIComponent(insapi.profile.email);
        window.open(url, '_blank');
    }

    async _reload_all() {
        for (let sub of this.cartSubIds) {
            await insapi._cart(sub.value);
        }
        // this._cart_changed(null);
        this.insapiService.updateCart();
    }

    @HostListener('window:focus') onFocus() {
        insapi.reload_carts();
    }

}
