import { Component, OnInit, Output, EventEmitter, ViewChildren, ViewChild, QueryList, ElementRef, SimpleChange } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { insapi, Policy, luid, excelDateToDate, _saveAs } from 'insapi';
import { CsvImportComponent } from './../../../lib/csv-import/csv-import.component';
import { FieldGroupComponent } from '../../field-group/field-group.component';
import { IField } from '../../form.interface';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { DatePipe } from '../../../lib/pipes/idate.pipe';
import { link } from 'fs';
import { PreferencesService } from '../../../lib/services/preferences.service';
import moment from 'moment';
import { GenericMessageComponent } from '../../../common/generic-message.component';

let pipes: {[key: string]: any} = {
    date: DatePipe,
    currency: CurrencyPipe,
    number: DecimalPipe
};

interface GridGroup {
    key: string;
    values: ({[key: string]: string} /* | GridGroup*/)[];
    level: number;
}

interface GridSubLink {
    key: string;
    rows: {[key: string]: any}[];
    field: IField;
}

interface GridLink {
    // key: string;
    row: ({[key: string]: string});
    links: {[name: string]: GridSubLink};
    collapse: boolean;
    ukey: string;
}

@Component({
    selector: 'grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0' })),
          state('expanded', style({ height: '*' })),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],
})
export class GridComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {this.dataSource.sort = value;}
    @ViewChildren('fgs') fgs!: QueryList<FieldGroupComponent>;
    data!: any;
    field: IField = {field_name: '', type: ''};
    control!: FormControl;
    group!: FormGroup;
    policy!: Policy;
    readonly: boolean = false;
    @Output() onChange = new EventEmitter();
    
    edit: any = {
        linkidx: 0,
        index: -1,
        data: {}
    };
    // maxRow: number = 0;
    // headers: string[] = [];

    filter: string = '';
    pageStart: number = 0;
    pageEnd: number = 0;
    pageSize: number = 5;

    psubscription: Subscription | undefined = undefined;

    formWidth: string = "";
    groupdef: any = {
        keys: [],
        leadcolumns: [],
        headers: []
    };
    groups: GridGroup[] | null = null;
    master: GridLink[] | null = null;
    linkdef: {[key: string]: any} = {};

    constructor(public dialog: MatDialog, private elRef: ElementRef, private preferences: PreferencesService) { }

    columns: string[] = [];
    allheaders: {[key: string]: any}[] = [];
    aheaders: {[key: string]: any}[] = [];
    dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    tmplcols: {[key: number]: string} = {};
    arrcols: {[key: number]: string} = {};
    actionName: string = '';
    collapse: boolean | undefined = false;

    csv: {headers: string[], names: string[], validations: any[]} = {
        names: [],
        headers: [],
        validations: []
    }
    rowdec: {ro: boolean, hilite: string, error: string}[] = [];

    ngOnInit(): void {
        this.tmplcols = {};
        this.aheaders = [];
        if (!this.data[this.field.field_name]) this.data[this.field.field_name] = [];
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    ngAfterViewInit() {
        // sort accessor works only if the display name matches the property
        this.dataSource.sortingDataAccessor = (item: any, property) => item[property.toLowerCase()];
        if (this.paginator) {
            this.paginator._intl.itemsPerPageLabel = 'Page size';
            this.dataSource.paginator = this.paginator;
        }
    }

    trackMaster(index: number, item: any) {
        return item.row.uid;
    }
    trackLink(index: number, item: any) {
        return item.uid || item.name;
    }

    _link_table(link: any, master: GridLink[]) {
        let cols = Object.keys(link.keys);

        let lkeys: any = {};
        if (!this.data[link.field.field_name]) this.data[link.field.field_name] = [];
        for (let l=0; l< this.data[link.field.field_name].length; l++) {
            let lrow = this.data[link.field.field_name][l];
            lrow.index = l;
            let lkey = cols.map(x => lrow[link.keys[x]]).join('~');
            if (!lkeys[lkey]) lkeys[lkey] = [];
            lkeys[lkey].push(lrow);
        }

        for (let m of master) {
            let key = cols.map(x => m.row[x]).join('~');
            m.links[link.field.field_name] = {rows: lkeys[key] || [], field: link.field, key: key};
            delete lkeys[key];
        }

        let orphans = [];
        for (let key in lkeys) {
            if (lkeys[key].length > 0)  orphans.push({key, rows: lkeys[key]});
        }
        return orphans;
    }

    _field_type(type: any, valid: any) {
        if (type || !valid || valid.status == 1) return type;
        let fmt = valid.format?.trim();
        if (fmt) {
            let parts = fmt.split(':');
            let pname = parts.shift();
            type = [pipes[pname], ...parts];
        }
        return type;
    }

    _setup_links() {
        if (!this.field.grid) return;
        if (!(this.field?.grid?.links instanceof Array) || this.field?.grid?.links.length == 0) return;

        if (!this.field.grid.unique) this.field.grid.unique = [];

        let remeber: {[key: string]: boolean} = {};
        if (this.master) for (let m of this.master) remeber[m.row.uid] = m.collapse;

        // remember the last edited/added index and ...
        if (this.field.field_name=='loc_si') console.log('edit:', this.edit)

        // if there was a row that was being added and the round-trip to the server didnot know
        // add it back
        if (this.edit.index >= 0 && this.edit.linkidx==-1 && this.data[this.field.field_name].length <= this.edit.index) {
            // add the last added entry to the array
            let orow: any = this.master?.[this.edit.index].row;
            if (orow) {
                let found = false;
                for (let row of this.data[this.field.field_name]) {
                    if (row.uid == orow.uid) {found = true; break;}
                }
                if (!found) {
                    this.data[this.field.field_name].push(orow);
                    this.control?.markAsDirty();
                }
            }
        }


        // let remeber: {[key: string]: boolean} = {};
        // if (this.field.grid.unique && this.field.grid.unique.length > 0 && this.master) {
        //     for (let m of this.master) remeber[m.ukey] = m.collapse;
        // }
        
        let master: GridLink[] = [];
        for (let row of this.data[this.field.field_name]) {
            let ukey = this.field.grid.unique.map((x: string) => row[x]).join('~');
            master.push({row: row, links: {}, collapse: remeber[row.uid] ?? true, ukey});
        }

        let valids = [this.policy.product?.premium_calc_validations || {}, this.policy.product?.premium_calc_output_validations || {}];
        for (let i=0; i<this.field?.grid?.links.length; i++) {
            let link = this.field?.grid?.links[i];
            if (!link.field) {
                console.log('**** invalid grid setup, could not find linked array fields', this.field.field_name, link.name)
                continue;
            }

            if (!link.field.grid.render_as) link.field.grid.render_as = 'grid';


            if (!link.field.group.layout) link.field.group.layout = structuredClone(this.preferences.vendor?.widgets?.grid?.editor?.layout || {});
            if (!link.field.group.layout.cls) link.field.group.layout.cls = 'sub-grid-wrapper';

            let linkcols = Object.values(link.keys);
            for (let fld of link.field.group.fields) {
                if (linkcols.indexOf(fld.field_name.substring(0, fld.field_name.length-5))>=0) fld.type = 'hidden';
            }

            link.editor_style = this.preferences?.vendor?.layout?.widgets?.grid?.styles?.[link.name+'-editor'] || {};
            let lkeys = Object.fromEntries(Object.entries(link.keys).map(([k, v]) => [v, k]));
            let names = [];
            for (let j=0; j<link.field.names.length; j++) {
                let fname = link.field.names[j];
                if (lkeys[fname]) continue;
                let v = valids[0][fname+'_tmpl'] || valids[1][fname+'_tmpl'];
                if (v?.status == 1) v = null;
                
                let type = this._field_type(this.field.types[i], v);
                if (v && v.ifFunc && !v.ifFunc(this.data, insapi.profile||{}, this.policy)) continue;
                let style = this.preferences?.vendor?.layout?.widgets?.grid?.styles?.[fname] || {};
                names.push({name: fname, disp: link.field.tmpl.data[0][j] || fname, types: type, style});
            }
            let orphans = this._link_table(link, master);
            this.linkdef[link.field.field_name] = {
                keys: link.keys,
                index: i,
                action: link.field.grid?.action_name || 'Entry',
                names,
                orphans,
                max: link.max || 0
            };
        }

        this.master = master;
        if (this.aheaders[0].disp != '' && this.aheaders[0].name != '') {
            let style: any = {position: 'sticky', width: "32px", "min-width": "32px", "max-width": "32px"};
            let scol = this.aheaders.find(x => x.style?.position == 'sticky');
            // console.log('stick...', scol, this.aheaders)
            if (scol) {style.position = "sticky"; style.left = "0"; style.overflow = "hidden";}
            this.aheaders.unshift({name: '', disp: '', style});
        }

        this.field.editor_style = this.preferences?.vendor?.layout?.widgets?.grid?.styles?.[this.field.field_name+'-editor'] || {};
    }

    _setup_grid() {
        if (!this.field || !this.field.grid) return;
        if (!this.field.grid?.render_as)this.field.grid.render_as = 'grid';

        if (this.field?.grid?.groupby) {
            this.groupdef.keys = this.field?.grid?.groupby.keys || [];
            this.groupdef.leadcolumns = this.field?.grid?.groupby.leads || [];
        }

        if (this.groupdef.keys.length == 0 || this.groupdef.keys[0].length == 0) {
            this.groups = null;
            return;
        }

        let groups: {[key: string]: GridGroup} = {};
        for (let row of this.data[this.field.field_name]) {
            let key = this.groupdef.keys[0].map((x: string) => row[x]).join(' - ');
            if (!groups[key]) {
                let keydesc = key + ' - ' + this.groupdef.leadcolumns.map((x: string) => row[x]).join(' - ');
                groups[key] = {key: keydesc, values: [], level: 1};
            }
            groups[key].values.push(row);
        }
        this.groups = Object.values(groups);
        this.groupdef.headers = []; // [...this.aheaders];
        for (let ah of this.aheaders) {
            let skip = false;
            for (let key of this.groupdef.keys[0])
                if (ah.name == key) {skip = true; break;}
            if (!skip) this.groupdef.headers.push(ah);
        }

    }

    _input_col_indices() {
        if (Object.keys(this.tmplcols).length != 0) return;
        // console.log('grid array: ', this.field.items);

        let items = this.field.items;
        this.tmplcols = {};
        this.arrcols = {};
        for (let i=0; i<items.length; i++) {
            if (items[i].id) {
                let parts = (''+items[i].id).split('_');
                if (parts.length == 3) {
                    let name = items[i].field_name;
                    if (name.endsWith('_tmpl')) name = name.substring(0, name.length-5);
                    this.tmplcols[+parts[2] - this.field.tmpl.cs] = items[i].field_name;
                    this.arrcols[+parts[2] - this.field.tmpl.cs] = name;
                }
            } else {
                this.tmplcols[i] = items[i].field_name;
                this.arrcols[i] = items[i].field_name.substring(0, items[i].field_name.length-5);
            }
        }
        // console.log('inscols: '+this.field.field_name, Object.values(this.tmplcols), Object.values(this.arrcols));
    }


    __get_validations() {
        if (this.policy?.endProduct) {
            return {...(this.policy.endProduct?.premium_calc_validations || {}), ...(this.policy.endProduct?.premium_calc_output_validations || {})};
        } else {
            return {...(this.policy.product?.premium_calc_validations || {}), ...(this.policy.product?.premium_calc_output_validations || {})};
        }
    }

    __fix_grid_headers() {
        let valid = this.__get_validations();
        
        if (this.allheaders.length == 0) {
            let hdrs: {[key: string]: string|number|object}[] = [];

            // try and get the names from fields llst
            if (!this.field.names || this.field.names.length == 0) {
                this.field.names = this.field.group.fields.map((x: any) => x.field_name.endsWith('_tmpl') ? x.field_name.substring(0, x.field_name.length-5) : x.field_name);
            }

            if (!this.field.types || this.field.types.length==0) {
                this.field.types = this.field.group.fields.map((x: any) => x.type);
            }

            if (!this.field.labels || this.field.labels.length==0) {
                this.field.labels = this.field.group.fields.map((x: any) => x.label);
            }

            for (let i=0; i<this.field.names.length; i++) {
                let type = this._field_type(this.field.types[i], valid[this.field.names[i]+'_tmpl']);
                let style = this.preferences?.vendor?.layout?.widgets?.grid?.styles?.[this.field.names[i]] || {};
                hdrs.push({
                    disp: this.field.labels?.[i] || this.field.tmpl?.data[0][i] || '', 
                    name: this.field.names[i],
                    col: i + (this.field.range?.cs||0),
                    rs: this.field.range?.rs || 0,
                    sh: this.field.range?.sheet || 0,
                    types: type,
                    style
                });
            }
            this.allheaders = hdrs;
        }

        this.aheaders = [];
        for (let hdr of this.allheaders) {
            let v = valid[hdr.name+'_tmpl'];
            if (v && v.ifFunc) {
                if (!v.ifFunc(this.data, insapi.profile||{}, this.policy)) continue;
            }
            this.aheaders.push(hdr);
        }
        this.columns = this.aheaders.map(x => ''+x.name);
    }

    // add temp index and unique id to array elements
    __fix_row_data() {
        for (let i=0; i<this.data[this.field.field_name].length; i++) {
            this.data[this.field.field_name][i].index = i;      // do not depend on this, as this may get changed
            if (!this.data[this.field.field_name][i].uid)       // this is expected to be unique with in this array
                this.data[this.field.field_name][i].uid = luid(6);
            
            if (this.rowdec.length <= i) this.rowdec.push({ro: false, hilite: '', error: ''});
            this.rowdec[i].ro = this.field.grid.rrfunc(this.data, insapi.profile, this.policy, this.data[this.field.field_name][i]);
            this.rowdec[i].hilite = this.field.grid.rhfunc(this.data, insapi.profile, this.policy, this.data[this.field.field_name][i]);
            this.rowdec[i].error = '';
        }

        for (let err of this.policy.errors||[]) {
            if (typeof err === 'string' || (err as any).arr !== this.field.field_name) continue;
            let e: any = err as any;
            if (e.arridx >= 0 && e.arridx < this.rowdec.length) this.rowdec[e.arridx].error += e.msg + '\n';
        }
    }

    _cellids_to_array() {
        this._input_col_indices();

        if (!this.field.grid.max) this.field.grid.max = this.field.rows?.length || 10;

        // for older, id based products, the array should be populated from 
        // cell-ids if not already part of array
        //
        if (!this.data[this.field.field_name] || this.data[this.field.field_name].length == 0) {
            if (this.field.range) {
                let arr = this.field.range;
                let res: any[] = [];
                for (let r=arr.rs; r<=arr.re; r++) {
                    let row: {[key: string]: string} = {};
                    let valid = false;
                    for (let c=arr.cs; c<=arr.ce; c++) {
                        if (this.arrcols[c-arr.cs]) {
                            let cid = arr.sheet + '_' + r + '_' + c;
                            row[this.arrcols[c-arr.cs]] = this.data[cid] ?? '';
                            valid = valid || (row[this.arrcols[c-arr.cs]]!='');
                        }
                    }
                    if (valid) res.push(row);
                }
                this.data[this.field.field_name] = res;
            }
        } else {
            // console.log('_cellids_to_array: data:', this.data[this.field.field_name]);
        }
        
        
        if (!(this.data[this.field.field_name] instanceof Array)) {
            console.log('************* Error ************** grid array expected, found', this.field.field_name, this.data[this.field.field_name]);
            this.data[this.field.field_name] = [];
        }

        if (!this.field.grid.rrfunc) {
            console.log('************* Error ************** could not find rrfunc', this.field.field_name, this.field.grid);
        }

        this.__fix_row_data();
        
        this.__fix_grid_headers();

        this.dataSource.data = this.data[this.field.field_name];


        if (this.paginator) this.dataSource.paginator = this.paginator;
        if (!this.field.group.layout) this.field.group.layout = structuredClone(this.preferences.vendor?.widgets?.grid?.editor?.layout || {});
        if (!this.field.group.layout.cls) this.field.group.layout.cls = 'grid-wrapper';

        if (!this.field.group.name) this.field.group.name = 'fg-' + this.field.field_name;

        this._setup_grid();
        this._setup_links();
    }

    _policy_changed() {
        this.actionName = this.field.grid?.action_name || 'Entry';
        if (!this.field.grid) this.field.grid = {render_as: 'grid'};

        if (this.field.grid.render_as === 'addon') return;

        this._cellids_to_array();

        let valids = this.__get_validations();
        this.csv = {names:[], headers: [], validations: []};
        for (let i=0; i<this.field.group.fields.length; i++) {
            let fld = this.field.group.fields[i];
            if (fld.type === 'label') continue;
            
            let name = fld.field_name;
            if (name.endsWith('_tmpl')) name = name.substring(0, name.length-5);

            let valid = valids[name+'_tmpl'];


            this.csv.names.push(name);
            this.csv.headers.push(fld.label);   // or use name
            if (fld.type === 'lookup' && fld.options) this.csv.validations.push({options: fld.options.map((x: any) => x.value), visibility: fld.if});
            else if (fld.type == 'date') this.csv.validations.push({date: this.preferences.dateFormat, visibility: fld.if});
            else if (valid?.mandatory == true) this.csv.validations.push({mandatory: true, visibility: fld.if});
            else this.csv.validations.push({});
        }
        setTimeout(() =>  this.__compute_form_width(), 100);
        return;
    }

    _clear_empty(name: string='') {
        let found = false;
        let rows = this.data[name ||this.field.field_name] || [];
        let linkcols = Object.values(this.linkdef[name]?.keys || []) || [];
        linkcols.push('index', 'uid', 'puid');
        for (let i=rows.length-1; i>=0; i--) {
            let valid = false;
            for (let c in rows[i]/*this.arrcols*/) {
                if (linkcols.indexOf(c)>=0) continue;
                if (rows[i][c]) { console.log('no-cancel:', c); valid=true; break;}
            }
            if (!valid) {
                rows.splice(i, 1);
                found = true;
            }
        }
        for (let i=0; i<rows.length; i++) rows[i].index = i;
        return found;
    }


    editCancel(name: string='') {
        this.edit.linkidx = -1;
        this.edit.index = -1;

        if (this._clear_empty(name) && this.master) {
            this._policy_changed();
        }   
    }

    deleteRow() {
        if (this.edit.index < 0) return;
        if (this.edit.index >= 0 && this.edit.index < this.data[this.field.field_name].length) {
            this.data[this.field.field_name].splice(this.edit.index, 1);
        }
        this.edit.linkidx = -1;
        this.edit.index = -1;
        // this._policy_changed();
        this.onChange.emit(this.field);
    }

    action(ev: any) {console.log('grid: onaction');}
    changed(ev: any) {
        // this.onChange.emit(this.field);
        this.fgs.forEach(x => {x._updateDependants(); x._changed();});
    }
    
    _error_str(errors?: string[]) {
        if (!errors) return '';
        errors = errors.map((x, i) => x ? this.csv.names[i] + ':' + x : '').filter(x => x);
        return errors.join('; ');
    }
    _merge_imported(mode: string, recs: string[], errors?: string[][]) {
        if (mode == undefined || !recs) return;

        let skipped = 0;
        // reuse the uid if there is a unique field specified
        let ukeys: any = {};
        if (this.field.grid?.unique?.length > 0) {
            for (let row of this.data[this.field.field_name]) {
                let ukey = this.field.grid.unique.map((x: string) => row[x]).join('~');
                ukeys[ukey] = row.uid;
            }
        }

        if (mode == 'replace') this.data[this.field.field_name] = [];
        for (let i=0; i<recs.length; i++) {
            let rec = recs[i];
            let row: any = {err: this._error_str(errors?.[i])};
            for (let i=0; i<this.csv.names.length; i++) row[this.csv.names[i]] = rec[i];

            let ukey = this.field.grid.unique?.map((x: string) => row[x]).join('~');
            if (ukeys[ukey]) row.uid = ukeys[ukey];
            
            if (this.data[this.field.field_name].length <= this.field.grid.max)
                this.data[this.field.field_name].push(row);
            else {
                skipped ++;
            }
        }

        if (skipped > 0) insapi.showMessage(skipped + " records ignored, maximum allowed " + this.field.grid.max, 0);

        this._clear_empty();
        console.log('_merge', this.data[this.field.field_name].length);
        this._policy_changed();
        this.onChange.emit(this.field);
        return;
    }

    // _saveAs(data: string, fname: string, type: string) {
    //     const a = document.createElement('a');
    //     const url = window.URL.createObjectURL(new Blob([data], { type: type || 'text/plain' }));
    //     a.href = url;
    //     a.download = fname;
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    //     a.remove();        
    // }

    __clear() {
        this.edit.linkidx = -1;
        this.edit.index = -1;
        for (let i=0; i<this.field?.grid?.links.length; i++) {
            let link = this.field?.grid?.links[i];
            if (!link) continue;
            this.data[link.name] = [];
            console.log('link:', link)
        }
        for (let row of this.field.rows) {
            for (let col of row) delete this.data[col]; // = '';
        }
        this._merge_imported('replace', [], []);
    }

    clear() {
        let data = {
            caption: 'Remove All', 
            description: 'This action will remove all data in ' + (this.field.field_name) + ' list and any data thats linked to it.',
            actions: [{name: 'removeall', disp: 'Clear data'}, {name: 'cancel', disp: 'Cancel'}]
        };
        console.log('clear:', data)
        let ref = this.dialog.open(GenericMessageComponent, {data});
        ref.afterClosed().subscribe((action) => {
            if (action.name == 'removeall') this.__clear();
        });
    }

    export() {
        let cols = Object.values(this.arrcols);
        let csv: string = cols.join(',');
        let dates = this.field?.group?.fields.filter((x: any) => x.type == 'date').map((x:any) => x?.field_name.substring(0,x?.field_name?.length-5)) ;
        for (let row of this.data[this.field.field_name]) {
            csv += '\n"' + cols.map(x => {
                if (dates?.includes(x) && row[x])
                    return moment.utc(excelDateToDate(row[x])).local().format(this.preferences.dateFormat);
                return typeof row[x] == 'string' ? row[x].replace(/[\"\r\n]/g, "'"): row[x]
            }).join('","') + '"';
        }
        _saveAs(csv, this.field.label + '.csv', 'text/csv');
    }

    import() {
        let data: any = {
            caption: this.field.label || this.field.field_name, 
            names: this.csv.names,
            headers: this.csv.headers,
            labels: [],
            validations: this.csv.validations
        };
        const dialogRef = this.dialog.open(CsvImportComponent, {data});
        dialogRef.afterClosed().subscribe(res => this._merge_imported(res?.mode, res?.data, res?.errors));
    }

    __compute_form_width() {
        let formElem = this.elRef.nativeElement.closest('.field-group');
        if (formElem && formElem.clientWidth > 0) {
            let em = parseFloat(getComputedStyle(this.elRef.nativeElement).fontSize);
            // console.log('em-size:', em, formElem.clientWidth, formElem.clientWidth*.95)
            this.formWidth = Math.floor((formElem.clientWidth - 6*em)) + 'px';
            // this.formWidth = Math.floor((formElem.clientWidth-em) *.95) + 'px';
        }

    }

    _edit_row(element: any) {
        console.log('edit:', element.index, this.rowdec[element.index]?.ro);
        if (this.readonly || this.rowdec[element.index]?.ro) return;
        this.__compute_form_width();
        this.edit.data = {...this.data}; // to allow widgets access to quote/proposal data
        this.edit.linkidx = -1;
        this.edit.index = element.index;
        for (let f of this.field.group?.fields){
            f.has_history = this.policy.__update_row_history(f.field_name, element.index) ? true : false;
        }  
        let arrrow = this.data[this.field.field_name][element.index];
        if (!arrrow) return;
        
        for (let c in this.tmplcols) {
            this.edit.data[this.tmplcols[c]] = arrrow[this.arrcols[c]]||'';
        }
        console.log('edit:', this.edit, this.data[this.field.field_name].length);
    }

    _edit_linked_row(row: any, link: any, lname: string, pindex: number) {
        if (this.readonly) return;
        if (this.rowdec[pindex]?.ro) return;    // parent row is not editable

        this.__compute_form_width();
        this.edit.data = {...this.data};
        this.edit.linkidx = this.linkdef[lname].index;
        this.edit.index =  row.index;
        let arr = this.data[lname][row.index];
        for (let c of link.field.names) {
            this.edit.data[c+'_tmpl'] = arr[c]||'';
        }
    }

    __init_defaults(arr: any, names: string[]) {
        // let valids = [this.policy.product?.premium_calc_validations || {}, this.policy.product?.premium_calc_output_validations || {}];
        let valids = [this.__get_validations()];
        for (let c of names) {
            let valid = null;
            for (let v of valids) {
                if (v[c]) {valid = v[c]; break;}
            }
            if (valid?.deffunc && valid?.status != 1)
                arr[c] = valid.deffunc(this.data, insapi.profile, this.policy);
            else
                arr[c] = this.policy.defaults[c] || this.policy.defaults[c+'_tmpl'] || '';
        }

    }

    _add_new_link_row(par: any, lname: string) {
        if (this.readonly) return;
        if (this.rowdec[par.row?.index]?.ro) return;    // parent row is not editable

        console.log('link-names:', par, this.linkdef);
        if (!this.data[lname]) this.data[lname] = [];
        if (this.linkdef[lname].max > 0 && par.links[lname].rows.length >= this.linkdef[lname].max) {
            return;
        }

        let larr = par.links[lname];
        
        let lrow: any = {index: this.data[lname].length, uid: luid(6), puid: par.uid};
        this.__init_defaults(lrow, larr.field.names);
        // for (let c of larr.field.names) {
        //     lrow[c] = this.policy.defaults[c] || this.policy.defaults[c+'_tmpl'] || '';
        // }
        // constants
        console.log('link-keys:', this.linkdef[lname].keys);
        let keys = this.linkdef[lname].keys||{};
        for (let key in keys) lrow[keys[key]] = par.row[key];

        this.data[lname].push(lrow);
        console.log('l:', lname, this.data[lname], this.edit);
        this._edit_linked_row(lrow, larr, lname, par.row.index);
        this._setup_links();
    }

    _add_new_row(grp: GridGroup | undefined = undefined) {
        if (this.readonly) return;
        if (this.data[this.field.field_name].length >= this.field.grid.max) return;

        let row: any = {index: this.data[this.field.field_name].length, uid: luid(6)};
        this.__init_defaults(row, Object.values(this.arrcols));

        this.collapse = false;
        this.collapseAll(null);

        // for (let c in this.arrcols) {
        //     let col = this.arrcols[c];
        //     row[this.arrcols[c]] = this.policy.defaults[col] || this.policy.defaults[col+'_tmpl'] || '';
        // }

        let readonly: any = {};
        if (grp && grp.values.length > 0) {
            for (let key of this.groupdef.keys) {
                row[key] = grp.values[0][key];
                readonly[key] = true;
            }
        }

        this.data[this.field.field_name].push(row);
        this._edit_row(row);
        this._setup_grid();
        this._setup_links();
        if (this.paginator) {
            this.paginator.pageIndex = this.paginator.length;
            // this.paginator.length = this.data[this.field.field_name].length;
        } else {
            setTimeout(() => {
                if (this.paginator) this.paginator.pageIndex = this.paginator.length;
            }, 300);
        }
        this.dataSource.data = this.data[this.field.field_name];
        this.__fix_row_data();
    }

    __check_unique_key_master(data: any, index: number) {
        if (!this.master) return true;
        if (!this.field.grid.unique || this.field.grid.unique.length == 0) return true;

        let ukey = this.field.grid.unique.map((x: string) => data[x+'_tmpl']).join('~');
        for (let i=0; i<this.master.length; i++) {
            if (index == i) continue;
            if (this.master[i].ukey == ukey) {
                insapi.showMessage('Duplicate key found for ' + (ukey || '[empty]'), 0);
                return false;
            }
        }
        return true;
    }

    _save_row(element: any) {
        if (this.edit.index < 0 || this.readonly) return;
        
        if (!this.__check_unique_key_master(this.edit.data, this.edit.index)) return;

        let empty = true;
        for (let c in this.arrcols) {
            let key = this.tmplcols[c];
            if (key == 'index' || key == 'uid') continue;
            if (this.edit.data[key] || this.edit.data[key]===0) {empty = false; break}
        }
        if (empty) return;


        let arr = this.data[this.field.field_name][element.index];
        for (let c in this.arrcols) {
            arr[this.arrcols[c]] = this.edit.data[this.tmplcols[c]] || '';
        }
        this.edit.linkidx = -1;
        this.edit.index = -1;
        this._clear_empty();
        // this._policy_changed();
        this.onChange.emit(this.field);
        this.control?.markAsDirty();

        for (let mas of this.master || []) {
            if (mas.row.uid == element.uid) {
                this.collapseMaster(null, mas);
                break;
            }
        }
    }

    __check_unique_key_links(link: any, data: any) {
        if (!link.field.grid.unique || link.field.grid.unique.length == 0) return true;

        let ukey = link.field.grid.unique.map((x: string) => data[x]).join('~');
        console.log('luniq:', ukey, 'from:',data);
        for (let i=0; i<this.data[link.name].length; i++) {
            let row = this.data[link.name][i];
            if (data.uid == row.uuid) continue;
            let rkey = link.field.grid.unique.map((x: string) => row[x]).join('~');
            if (rkey == ukey) {
                insapi.showMessage('Duplicate key found for ' + (ukey || '[empty]'), 0);
                return false;
            }
        }
        return true;
    }


    _save_linked_row(link: any, lrow: any) {
        if (this.edit.index < 0 || this.readonly) return;
        // if (!this.__check_unique_key_links(link, lrow)) return;

        let empty = true;
        let arr = this.data[link.name][lrow.index];
        let lkeys = Object.values(link.keys);
        for (let c of link.field.names) {
            arr[c] = this.edit.data[c+'_tmpl'] || '';
            if (empty && c != 'index' && c != 'uid' && c != 'puid' && lkeys.indexOf(c) < 0) {
                if (arr[c] || arr[c] === 0) empty = false;
            }
        }
        if (empty) return;

        this.edit.linkidx = -1;
        this.edit.index = -1;
        this._clear_empty(link.name);
        console.log('save-linked:', link.name, this.data[link.name])
        this.onChange.emit(link.field);
        this.control?.markAsDirty();
    }

    deleteLinkedRow(link: any, lrow: any) {
        if (this.edit.index < 0) return;
        if (lrow.index >= 0 && lrow.index < this.data[link.name].length) {
            this.data[link.name].splice(lrow.index, 1);
        }
        this.edit.linkidx = -1;
        this.edit.index = -1;
        this.onChange.emit(link.field);
    }

    collapseAll(ev: any) {
        this.collapse = !this.collapse;
        if (this.master)
            for (let m of this.master) m.collapse = this.collapse;
        console.log('colaal', this.collapse);
        ev?.stopPropagation();
    }

    collapseMaster(ev: any, master: any) {
        ev?.stopPropagation();
        if (!this.master) return;
        let cur = master.collapse;
        for (let m of this.master) m.collapse = true;
        master.collapse = !cur;

        /*
        
        this.collapse = master.collapse;
        for (let m of this.master) 
            if (m.collapse != master.collapse) {
                this.collapse = undefined;
                return;
            }
        */
    }

    __apply_filter() {
        this.dataSource.filter = this.filter || '';
    }
    
    async downloadTemplate() {
        console.log('download ' + this.field.field_name, this.field.grid?.template_id);
        await this.policy.downloadProductAttachment('upload-template', 'template-' + this.field.field_name);
    }
}
