<div *ngIf="field.grid && field.grid.render_as == 'addon'">
    <ng-template [ngIf]="!field.link_parent">
        <addon-grid [field]="field" [policy]="policy" [data]="data" (onChange)="onChange.emit(field)"></addon-grid>
    </ng-template>
    <ng-template [ngIf]="field.link_parent">
        Skip linked grids
    </ng-template>
</div>
<div class="igrid" *ngIf="master && field.grid.render_as == 'grid'">
    <table class="gtable master-table">
        <tr>
            <th *ngFor="let col of aheaders; let i=index;" [ngStyle]="col.style">
                <ng-template [ngIf]="i==0">
                    <mat-icon *ngIf="collapse === true" fontSet="material-icons-outlined" class="grid-collapse-icon" style='color: #228;' (click)="collapseAll($event);">unfold_more</mat-icon>
                    <mat-icon *ngIf="collapse === false" fontSet="material-icons-outlined" class="grid-collapse-icon" style='color: #228;' (click)="collapseAll($event)">unfold_less</mat-icon>
                    <mat-icon *ngIf="collapse === undefined" fontSet="material-icons-outlined" class="grid-collapse-icon" style='color: #228;' (click)="collapseAll($event)">expand_content</mat-icon>
                </ng-template>
                {{col.disp | titlecase}}
            </th>
        </tr>
        <ng-template ngFor let-master let-i="index" [ngForOf]="master" >
            <ng-template [ngIf]="master.row.index === edit.index && edit.linkidx === -1">
                <tr>
                    <td [attr.colspan]="aheaders.length" >
                        <div class="add-row-form" [style.max-width]="formWidth ? formWidth : '100%'">
                        <field-group #fgs
                            [fieldGroup]="field.group" 
                            [data]="edit.data"
                            [policy]="policy"
                            [readonly]="readonly"
                            [layout]="field.group.layout"
                            (onAction)="action($event)"
                            (onChange)="changed($event)">
                        </field-group>
                        <div style="padding-left: 1em;">
                            <button mat-button ibutton (click)="editCancel()" color="primary" [attr.data-iid]="field.field_name+'-cancel-edit'">Cancel Edit</button>
                            <button mat-button ibutton (click)="_save_row(master.row)" color="primary" [attr.data-iid]="field.field_name+'-save'">Save</button>
                            <button mat-button ibutton (click)="deleteRow()"color="warn" [attr.data-iid]="field.field_name+'-remove'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'">Remove row</button>
                        </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template [ngIf]="master.row.index !== edit.index || edit.linkidx !== -1">
                <tr [class]="'row-hilite-' + rowdec[+master.row.index]?.hilite" [class.row-error]="rowdec[+master.row.index]?.error" [title]="rowdec[+master.row.index]?.error">
                    <td *ngFor="let col of aheaders; let mi=index;" (click)="_edit_row(master.row)" [ngStyle]="col.style">
                        <ng-template [ngIf]="mi==0">
                            <mat-icon *ngIf="master.collapse" fontSet="material-icons-outlined" class="grid-collapse-icon" style='color: #A00;' (click)="collapseMaster($event, master)">unfold_more</mat-icon>
                            <mat-icon *ngIf="!master.collapse" fontSet="material-icons-outlined" class="grid-collapse-icon" style='color: #A00;' (click)="collapseMaster($event, master)">unfold_less</mat-icon>
                        </ng-template>
                        <ng-template [ngIf]="mi>0">
                            {{master.row[col.name] | generic2: col.types}}
                        </ng-template>
                    </td>
                </tr>
                <ng-template [ngIf]="!master.collapse">
                <tr>
                    <td [attr.colspan]="aheaders.length">
                        <div *ngFor="let l of field.grid.links; trackBy: trackLink" class="sub-grid-container">
                            <ng-template [ngIf]="!linkdef[l.name]">
                                Could not find linked array definition for {{l.name}}
                            </ng-template>
                            <ng-template [ngIf]="linkdef[l.name] && l.field.grid.render_as === 'addon'">
                                <div class="addon-form" [style.max-width]="formWidth ? formWidth : '100%'">
                                    <addon-grid [field]="l.field" [parent]="master.row" [pkeys]="linkdef[l.name].keys" [policy]="policy" [data]="data"  (onChange)="onChange.emit(field)"></addon-grid>
                                </div>
                            </ng-template>
                            <ng-template [ngIf]="linkdef[l.name] && l.field.grid.render_as === 'grid'">
                                <div class="sub-grid-caption">{{linkdef[l.name]?.action}} - <small>{{master.links[l.name]?.key}}</small></div>
                                <table class="sub-table">
                                    <tr>
                                        <th *ngFor="let item of linkdef[l.name]?.names" [ngStyle]="item.style">{{item.disp | titlecase}}</th>
                                    </tr>
                                    <ng-template ngFor let-lrow let-li="index" [ngForOf]="master.links[l.name].rows" >
                                        <ng-template [ngIf]="lrow.index === edit.index && edit.linkidx === linkdef[l.name].index">
                                            <tr>
                                                <td [attr.colspan]="l.field.names.length">
                                                    <div class="add-row-form" [style.max-width]="formWidth ? formWidth : '100%'">
                                                    <field-group #fgs
                                                        [fieldGroup]="l.field.group" 
                                                        [data]="edit.data"
                                                        [policy]="policy"
                                                        [readonly]="readonly"
                                                        [layout]="l.field.group.layout"
                                                        (onAction)="action($event)"
                                                        (onChange)="changed($event)">
                                                    </field-group>
                                                    <div style="padding-left: 1em;" *ngIf="!readonly">
                                                        <button mat-button ibutton (click)="editCancel(l.name)" color="primary">Cancel Edit</button>
                                                        <button mat-button ibutton (click)="_save_linked_row(l, lrow)" color="primary" >Save</button>
                                                        <button mat-button ibutton (click)="deleteLinkedRow(l, lrow)"color="warn" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'" >Remove row</button>
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template [ngIf]="linkdef[l.name].index !== edit.index || edit.linkidx !== linkdef[l.name].index">
                                            <tr>
                                                <td *ngFor="let item of linkdef[l.name].names;" (click)="_edit_linked_row(lrow, master.links[l.name], l.name, +master.row.index)"  [ngStyle]="item.style">{{lrow[item.name] | generic2: item.types}}</td>
                                            </tr>
                                        </ng-template>
                                    </ng-template>
                                </table>
                                <button mat-button ibutton (click)="_add_new_link_row(master, l.name)" *ngIf="!readonly && (!linkdef[l.name].max || linkdef[l.name].max > master.links[l.name].rows.length) " style="color: var(--button-color-primary);" >Add {{linkdef[l.name].action}}</button>
                            </ng-template>
                        </div>
                    </td>
                </tr>
                </ng-template>
            </ng-template>
        </ng-template>
    </table>
    <div style="width: 100%;"  *ngIf="!readonly && data[field.field_name] && (edit.index==-1 || edit.index>=data[field.field_name].length)">
        <div *ngIf="data[field.field_name].length == 0" style="padding: 1.5em; text-align: center; color: var(--grid-paginator-fg);">No records added yet</div>
        <ng-template [ngIf]="field.grid?.disable_addrow != 'Yes'">
        <button mat-button ibutton (click)="_add_new_row()" style="color: var(--button-color-primary);" *ngIf="!readonly && (data[field.field_name] && data[field.field_name].length<this.field.grid.max && (!field.grid || !field.grid.addentry_limit || data[field.field_name].length<field.grid.addentry_limit))" [attr.data-iid]="field.field_name+'-add'">Add {{actionName}}</button>
        </ng-template>
        <button mat-button ibutton (click)="import()" style="color: var(--button-color-primary);" [attr.data-iid]="field.field_name+'-upload'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Upload</button>
        <button mat-button ibutton (click)="export()" style="color: var(--button-color-primary);"[attr.data-iid]="field.field_name+'-download'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Download</button>
        <button mat-button ibutton (click)="clear()" style="color: var(--button-color-warn);" [attr.data-iid]="field.field_name+'remove-all'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'">Remove all</button>
        <button mat-button ibutton (click)="downloadTemplate()" style="color: var(--button-color-warn);" *ngIf="field.grid?.template_id">Download template</button>
    </div>

    <div *ngFor="let item of linkdef | keyvalue">
        <div class="orphan" *ngIf="linkdef[item.key].orphans && linkdef[item.key].orphans.length > 0">
            Following {{item.key}} data are not linked with the current master
            <div *ngFor="let orph of linkdef[item.key].orphans" class="orphan-item">
                <div>{{orph.key}} - {{orph.rows.length}} record(s)</div>
            </div>
        </div>
    </div>
</div>

<div class="igrid" *ngIf="groups && field.grid?.render_as == 'grid'">
    <table class="gtable">
        <tr>
            <th *ngFor="let col of groupdef.headers; let i=index;" [style.padding-left]="i==0?'2em':'0px'">{{col.disp}}</th>
        </tr>
        <ng-template ngFor let-grp [ngForOf]="groups">
            <tr><td class="gtable-key" [attr.colspan]="groupdef.headers.length">{{grp.key}}</td></tr>
            <tr *ngFor="let val of grp.values;">
                <ng-template [ngIf]="val.index === edit.index">
                    <td [attr.colspan]="groupdef.headers.length">
                    <field-group #fgs
                        [fieldGroup]="field.group" 
                        [data]="edit.data"
                        [policy]="policy"
                        [readonly]="readonly"
                        [layout]="field.group.layout"
                        (onAction)="action($event)"
                        (onChange)="changed($event)">
                    </field-group>
                    <div style="padding-left: 1em;">
                        <button mat-button ibutton (click)="editCancel()" color="primary" [attr.data-iid]="field.field_name+'-cancel-edit'">Cancel Edit</button>
                        <button mat-button ibutton (click)="_save_row(val)" color="primary"[attr.data-iid]="field.field_name+'-save'">Save</button>
                        <button mat-button ibutton (click)="deleteRow()"color="warn" [attr.data-iid]="field.field_name+'-remove'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'" >Remove row</button>
                    </div>
                    </td>
                </ng-template>
                <ng-template [ngIf]="val.index !== edit.index">
                    <td *ngFor="let col of groupdef.headers; let i=index;" [style.padding-left]="i==0?'2em':'0px'" 
                        (click)="_edit_row(val)">{{val[col.name] | generic2: col.types}}
                    </td>
                </ng-template>
            </tr>
            <tr>
                <td class="gtable-sub-footer" style="text-align: right;" [attr.colspan]="groupdef.headers.length" (click)="_add_new_row(grp)">Add</td>
            </tr>
        </ng-template>
    </table>
    <div style="width: 100%;"  *ngIf="!readonly && data[field.field_name] && (edit.index==-1 || edit.index>=data[field.field_name].length)">
        <div *ngIf="data[field.field_name].length == 0" style="padding: 1.5em; text-align: center; color: var(--grid-paginator-fg);">No records added yet</div>
        <ng-template [ngIf]="field.grid?.disable_addrow != 'Yes'">
        <button mat-button ibutton (click)="_add_new_row()" style="color: var(--button-color-primary);" *ngIf="(data[field.field_name] && data[field.field_name].length<this.field.grid.max && (!field.grid || !field.grid.addentry_limit || data[field.field_name].length<field.grid.addentry_limit))" [attr.data-iid]="field.field_name+'-add'">Add {{actionName}}</button>
        </ng-template>
        <button mat-button ibutton (click)="import()" style="color: var(--button-color-primary);" [attr.data-iid]="field.field_name+'-upload'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'" >Upload</button>
        <button mat-button ibutton (click)="export()" style="color: var(--button-color-primary);"[attr.data-iid]="field.field_name+'-download'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Download</button>
        <button mat-button ibutton (click)="clear()" style="color: var(--button-color-warn);" [attr.data-iid]="field.field_name+'remove-all'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'">Remove all</button>
        <button mat-button ibutton (click)="downloadTemplate()" style="color: var(--button-color-warn);" *ngIf="field.grid?.template_id">Download template</button>
    </div>

</div>

<div class="igrid" *ngIf="!groups && !master && field.grid?.render_as == 'grid'">
    <div class="grid-caption">
        <div>
            {{field.label}} <small style="color: #888;">Maximum of {{(field.grid?.addentry_limit>0 && field.grid?.addentry_limit < this.field.grid.max)?field.grid?.addentry_limit:this.field.grid.max}} records can be added</small>
        </div>
        <div *ngIf="this.field.filter">
            <input class="grid-filter" type="text" placeholder="Filter" [(ngModel)]="filter" (input)="__apply_filter()">
        </div>
    </div>

    <div class="grid-horzontal">
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows >
            <ng-container matColumnDef="{{column.name}}" *ngFor="let column of aheaders">
              <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.name ? column.name : null" [disabled]="column.name?false:true"> {{column.disp | capitalize}} </th>
              <td mat-cell *matCellDef="let element; let i=index;" (click)="_edit_row(element)" [attr.data-iid]="field.field_name+'-'+column.name+'-'+i">{{
                  (element[column.name]===undefined ? 
                    data['f_'+column.sh+'_'+(column.rs+element.index)+'_'+column.col] || data[column.sh+'_'+(column.rs+element.index)+'_'+column.col] :
                    element[column.name])
                  | generic2: column.types
                }}</td>
            </ng-container>
        
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element; let i=index;" [attr.colspan]="columns.length" >
                <div class="add-row-form" *ngIf="!readonly && element.index == edit.index" [style.max-width]="formWidth ? formWidth : '100%'">
                    <div class="grid-row-num" >{{element.index+1}}</div>
                    <field-group #fgs
                        [fieldGroup]="field.group" 
                        [data]="edit.data"
                        [policy]="policy"
                        [readonly]="readonly"
                        [layout]="field.group.layout"
                        (onAction)="action($event)"
                        (onChange)="changed($event)">
                    </field-group>
                    <div style="padding-left: 1em;">
                        <button mat-button ibutton (click)="editCancel()" color="primary" [attr.data-iid]="field.field_name+'-cancel-edit'">Cancel Edit</button>
                        <button mat-button ibutton (click)="_save_row(element)" color="primary"[attr.data-iid]="field.field_name+'-save'">Save</button>
                        <button mat-button ibutton (click)="deleteRow()" color="warn" [attr.data-iid]="field.field_name+'-remove'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'">Remove row</button>
                    </div>
                </div>
            </td>
            </ng-container>

            <ng-container matColumnDef="btnfooter" *ngIf="data[field.field_name]">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="columns.length">
                    <div style="width: 100%;"  *ngIf="!readonly && (edit.index==-1 || edit.index >= data[field.field_name].length)">
                        <div *ngIf="data[field.field_name].length == 0" style="padding: 1.5em; text-align: center; color: var(--grid-paginator-fg);">No records added yet</div>
                        <ng-template [ngIf]="field.grid?.disable_addrow != 'Yes'">
                        <button mat-button ibutton (click)="_add_new_row()" style="color: var(--button-color-primary);" *ngIf="!readonly && (data[field.field_name] && data[field.field_name].length<this.field.grid.max && (!field.grid || !field.grid.addentry_limit || data[field.field_name].length<field.grid.addentry_limit))" [attr.data-iid]="field.field_name+'-add'">Add {{actionName}}</button>
                        </ng-template>
                        <button mat-button ibutton (click)="import()" style="color: var(--button-color-primary);" [attr.data-iid]="field.field_name+'-upload'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Upload</button>
                        <button mat-button ibutton (click)="export()" style="color: var(--button-color-primary);"[attr.data-iid]="field.field_name+'-download'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Download</button>
                        <button mat-button ibutton (click)="clear()" style="color: var(--button-color-warn);" [attr.data-iid]="field.field_name+'remove-all'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'">Remove all</button>
                        <button mat-button ibutton (click)="downloadTemplate()" style="color: var(--button-color-warn);" *ngIf="field.grid?.template_id">Download template</button>
                    </div>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let element; columns: columns;" class="normal-row"
              [class.error-row]="element.err?true:false"
              [class.example-expanded-row]="element.index==edit.index" 
              [class]="'row-hilite-' + rowdec[+element.index]?.hilite"
              [class.row-error]="rowdec[+element.index]?.error"
              [title]="rowdec[+element.index]?.error"
              ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="detail-row"
                [class.detail-row-edit]="row.index == edit.index"></tr>
            <tr mat-footer-row *matFooterRowDef="['btnfooter']"></tr>
        </table>
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons *ngIf="data[field.field_name].length>=pageSize"></mat-paginator>
    </div>

    <div class="grid-vertical">
        <div class="grid-row" *ngFor="let row of data[field.field_name]; let i=index">
            <ng-template [ngIf]="edit.index==i">
                <div class="add-row-form" [style.max-width]="formWidth ? formWidth : '100%'">
                    <div class="grid-row-num" >{{row.index+1}}</div>
                    <field-group #fgs
                        [fieldGroup]="field.group" 
                        [data]="edit.data"
                        [policy]="policy"
                        [readonly]="readonly"
                        [layout]="field.group.layout"
                        (onAction)="action($event)"
                        (onChange)="changed($event)">
                    </field-group>
                    <div style="padding-left: 1em;">
                        <mat-icon fontSet="material-icons-outlined" class="grid-edit-icon" style='color: #A00;' (click)="editCancel()" [attr.data-iid]="field.field_name+'-exit'">exit_to_app</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" class="grid-edit-icon" style='color: #0A0;' (click)="_save_row(row)" [attr.data-iid]="field.field_name+'-save'">save</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" class="grid-edit-icon" style='color: #D00;' (click)="deleteRow()" [attr.data-iid]="field.field_name+'-delete'">delete_sweep</mat-icon>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="edit.index!=i">
                <div class="mobile-row-container">
                    <div class="mobile-row-num">{{row.index+1}}</div>
                    <div class="mobile-row-details" (click)="_edit_row(row)">
                        <div class="mobile-row-item" *ngFor="let column of aheaders; let c=index">
                            <div class="mobile-row-header">{{column.disp}}</div>
                            <div class="mobile-row-text">
                                {{
                                    row[column.name] ||
                                    data['f_'+column.sh+'_'+(column.rs+row.index)+'_'+column.col] || data[column.sh+'_'+(column.rs+row.index)+'_'+column.col] | 
                                    generic2: column.types
                                }}</div>
                        </div>
                    </div>
                </div>
                
            </ng-template>
        </div>
        <div *ngIf="!readonly && data[field.field_name] && (edit.index==-1 || edit.index >= data[field.field_name].length)">
            <ng-template [ngIf]="field.grid?.disable_addrow != 'Yes'">
            <button mat-button ibutton (click)="_add_new_row()" *ngIf="!readonly && data[field.field_name].length<this.field.grid.max" [attr.data-iid]="field.field_name+'-add'">Add {{actionName}}</button>
            </ng-template>
            <button mat-button ibutton (click)="import()" style="color: var(--button-color-primary);" [attr.data-iid]="field.field_name+'-upload'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Upload</button>
            <button mat-button ibutton (click)="export()" style="color: var(--button-color-primary);"[attr.data-iid]="field.field_name+'-download'" *ngIf="!readonly && field.grid?.disable_upload != 'Yes'">Download</button>
            <button mat-button ibutton (click)="clear()" style="color: var(--button-color-warn);" [attr.data-iid]="field.field_name+'remove-all'" *ngIf="!readonly && field.grid?.disable_removeall != 'Yes'">Remove all</button>
            <button mat-button ibutton (click)="downloadTemplate()" style="color: var(--button-color-warn);" *ngIf="field.grid?.template_id">Download template</button>
        </div>

    </div>

</div>
