import { Injectable, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { insapi, IProfile } from 'insapi';
import moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class ZulipService {
    streamName: string = '';
    disabled: boolean = false;
    profile: IProfile | null = null;
    lastseen: number = -1;
    viewing: string = '';

    objserver: IntersectionObserver | null = null;
    rendered: {[key: number|string]: boolean} = {};
    unread: Map<number, boolean> = new Map();
    unread_count: number = 0;

    messages: any[] = [];
    narrow: any[] = [];
    msgmap: {[key: string]: any} = {};
    streams: any = {};
    stream: any = null;

    psubscription: Subscription | null = null;
    queue_id: string | null = null;
    last_event_id: number = -1;
    zuser: string = '';
    inloop: boolean = false;

    readmarkers: number[] = [];

    containerElem: HTMLElement | null = null;
    constructor(private sanitizer: DomSanitizer) {
        this.lastseen =  +(localStorage.getItem('zls') || -1);
        this.last_event_id =  +(localStorage.getItem('zeid') || -1);
        console.log('loaded lastseen:', this.lastseen, 'evid:', this.last_event_id);
        this.psubscription = insapi.profileSubject.subscribe((profile: any) => {
            this.profile = profile;
            if (!this.profile) {
                this.messages = [];
                this.streams = {};
                this.stream = null;
            }
            this.__reregister(profile)
        });
    }
	ngOnDestroy() {
		if (this.psubscription) this.psubscription.unsubscribe();
		this.psubscription = null;
    }
    
	__set_local_store(queueid: string, evid: string, user: string|null) {
		localStorage.setItem('zqid', queueid || '');
		localStorage.setItem('zusr', user || '');
		if (user !== null) localStorage.setItem('zeid', evid || '');
	}

    async attach(stream: any, doc: any) {
        if (!stream) return;
        const formData = new FormData();
        formData.append('document_id', stream.name);
        formData.append('document_type', doc.document_type);
        formData.append('name', doc.file.name);
        formData.append('document_desc', doc.desc || '');
        formData.append('file', doc.file, doc.file.name);
        try {
            let ret = await insapi.xpost('/api/v1/document/attach', formData);
            if (!ret || ret.length == 0) return;
            for (let det of ret[0].document?.details) {
                if (det.document_type == doc.document_type && det.name == doc.file.name) {
                    let url = window.location.hostname + '/api/v1/document/data/' +
                        encodeURIComponent(det.document_id) +
                        '/' + encodeURIComponent(det.document_type) +
                        '/' + encodeURIComponent(det.name) + '?download=1';
                    await insapi.xpost('/api/v1/zulip/message', {stream_id: stream.stream_id, msg: '🔗 attached: [' + doc.file.name+']('+url+')'});
                    return;
                }
            }
        } catch (e: any) {
            await insapi.xpost('/api/v1/zulip/message', {stream_id: stream.stream_id, msg: 'attach failed: ' + e.message || e});
        }
        return;
    }

    async post(streamId: number, msg: string, docs: any[]) {
        let stream = this.streams[streamId];
        if (stream) {
            for (let doc of docs) {
                await this.attach(stream, doc);
            }
        }
        return await insapi.xpost('/api/v1/zulip/message', {stream_id: streamId, msg});
    }

    async select_stream(streamId: number | string) {
        if (isNaN(+streamId)) {
            this.streamName = streamId+'';
            for (let stream in this.streams) if (this.streams[stream].name == streamId) {streamId = stream; break}
            if (this.streamName == streamId) return;    // could not find
        }
        if (streamId < 0) this.stream = null;
        else if (this.streams[streamId]) {
            this.stream = this.streams[streamId];
            this.__scroll_to_stream_unread();
        }
    }

    async __get_new_queue() {
        this.__set_local_store('', '', '');                         // clear queue and last seen ids
        let data = {
            // event_types: JSON.stringify(["messages", "subscription", "update_message_flags"]),
            client_capabilities: JSON.stringify({bulk_message_deletion: true, 
                notification_settings_null: false})
        };
        let ret = await insapi.xpost('/api/v1/zulip/register', data);
        if (ret.result == 'success') {
            this.queue_id = ret.queue_id;
            this.last_event_id = ret.last_event_id;
            this.__set_local_store(this.queue_id||'', this.last_event_id+'', insapi.profile?.email||'');
            console.log('__get_new_queue: ', this.queue_id, this.last_event_id);
            return await this.__zulip_init();
        } else {
            console.log('__get_new_queue: failed', ret);
        }

    }

    async __reregister(profile: IProfile) {
        if (!profile?.privileges || profile.privileges.indexOf('Modify Zulips') < 0) {
            if (profile) console.log('zulip: no privilege', profile?.privileges);
            this.disabled = true;
            return;
        }
        this.disabled = false;
        let queueid = localStorage.getItem('zqid') || '';
        let zuser = localStorage.getItem('zusr') || '';
        
        if (queueid && zuser == profile?.email) {
            this.queue_id = queueid;
            this.zuser = zuser;
            return this.__zulip_init();
        } else if (zuser != profile?.email) {
            this.messages = [];
            this.msgmap = {};
            this.streams = {};
            this.stream = null;
            this.last_event_id = -1;
        }

        console.log('**register for zulip events:');
        await this.__get_new_queue();
    }

    async __load_subscriptions() {
		try {
			let ret = await insapi.xget("/api/v1/zulip/subscriptions");
			if (ret.result === 'success') {
				this.__handle_zulip_events({type: 'subscriptions', subscriptions: ret.subscriptions});
			} else {
                console.log('zulip: failed to load subscriptions', ret);
            }
		} catch (e) {
            console.log(e);
		}
    }

    async __load_messages(search?: string) {
		try{
			let lastseen = localStorage.getItem('zls');
            let narrow = [{"operator": "search", "operand": search}];
			let url = "/api/v1/zulip/messages?anchor=" + (lastseen || 'first_unread') + (search ? '&narrow=' + JSON.stringify(narrow) : '');
			let ret = await insapi.xget(url);
            if (search) this.narrow = [];
			if (ret?.result === 'success') this.__handle_zulip_events({type: 'messages', messages: ret.messages}, search ? true : false);
            else console.log('zulip: loading messages failed', ret);
            if (this.streamName && this.stream == null) this.select_stream(this.streamName);
		} catch (e: any) {
			console.log('ev-error:', e?.code, e);
		}
    }

	async __zulip_init() {
        await this.__load_subscriptions();
        await this.__load_messages();
		this.__zulip_event_loop();
	}

	async wait(secs: number) {
		return new Promise((resolve, reject) => setTimeout(() => resolve(1), secs*1000));
	}

    async __call_with_backoff(url: string, depth: number = 0): Promise<any> {
		try {
			return await insapi.xget(url);
		} catch (e) {
			await this.wait(2 ** depth * 10);
			if (depth < 10) depth = depth + 1;
			return this.__call_with_backoff(url, depth);
		}
	}

	async __check_events() {
        let url = '/api/v1/zulip/events?queue_id=' + encodeURIComponent(this.queue_id||'')+'&dont_block=false';
		let curl = url + '&last_event_id=' + encodeURIComponent(this.last_event_id);
		let ret = await this.__call_with_backoff(curl);
		if (ret?.result === 'success' && ret.events?.length > 0) {
			let max = this.last_event_id;
			try {
				for (let i=0; i<ret.events.length; i++) {
					if (max < +ret.events[i].id) max = +ret.events[i].id;
                    if (ret.events[i].type == 'heartbeat') continue;
					this.__handle_zulip_events(ret.events[i]);
				}
			} catch (e) {}

			if (max != this.last_event_id) {
				this.last_event_id = max;
				localStorage.setItem('zeid', ''+this.last_event_id);
			}

			setTimeout(() => this.__check_events(), 100);    // loop forever
		} else {
            console.log('zerror:', ret, 'cooling off 15 secs');
            if (ret.code === 'BAD_EVENT_QUEUE_ID' || ret.code == 'BAD_REQUEST') {
                console.log('creating new queu');
                await this.__get_new_queue();
            }
            
			setTimeout(() => this.__check_events(), 15*1000);
		}
	}

	async __zulip_event_loop() {
		if (this.inloop) return; // prevent repeated calls from creating more loops
		this.inloop = true;
		this.__check_events();
	}

    __stop_objserver() {
        if (this.objserver) this.objserver.disconnect();
        this.objserver = null;
    }

    __scroll_to_stream_unread() {
        if (!this.stream) return;
        console.log('looking for stream unread:', this.stream.name, this.stream.messages.length);
        let keys = this.stream.unread.keys();

        let min = Number.MAX_SAFE_INTEGER;
        for (let id of keys) {
            if (min > +id) min = +id;
        }
        if (min != Number.MAX_SAFE_INTEGER) {
            console.log('mocing to smallest unread: ', min);
            this.__scroll_to_message('msg'+min);
        } else if (this.stream.messages.length > 0) {
            console.log('mocing to last message: ', this.stream.messages[this.stream.messages.length-1].id);
            this.__scroll_to_message('msg'+this.stream.messages[this.stream.messages.length-1].id);
        } else {
            // console.log('stream messages:', this.stream.messages.length);
        }
    }

    __start_objserver(elem: ElementRef) {
        if (!elem) return;
        if (this.objserver) this.objserver.disconnect();
        console.log('***__start_objserver:', this.viewing, this.stream);
        this.containerElem = elem.nativeElement;
        this.objserver = new IntersectionObserver((e: any, o: any) => this.__handle_visible(e, o), {root: elem.nativeElement, threshold: 1.0});
        this.__observe_all_elems();

        if (this.stream) {
            this.__scroll_to_stream_unread();
        } else if (this.viewing != '') {
            console.log('mocing to last clicked: ', this.viewing);
            setTimeout(() => {
                this.__scroll_to_message('msg'+this.viewing);
                this.viewing = '';
            }, 300);
            
        } else {
            console.log('zulip: no scroll')
        }
    }

    __handle_visible(entries: any[], observer: IntersectionObserver) {
        for (let ent of entries) {
            let id = ent.target.id.substring(3);
            if (ent.isIntersecting && this.msgmap[id]) {   // being seen
                if (!this.msgmap[id].flags) this.msgmap[id].flags = [];
                if (this.msgmap[id].flags.indexOf('read') < 0) this.readmarkers.push(+id);
            }
            if (ent.isIntersecting) {
                if (+id > this.lastseen) this.lastseen = +id;
            }
        }
        this.unread_count = this.unread.size;
        if (this.readmarkers.length > 0) this.__debounce_mark_read();
    }

    mark_read_debouncer: any = null;
    __debounce_mark_read() {
        if (this.mark_read_debouncer) clearTimeout(this.mark_read_debouncer);
        this.mark_read_debouncer = setTimeout(() => {
            localStorage.setItem('zls', '' + this.lastseen);

            let messages = this.readmarkers;
            this.readmarkers = [];
            try {insapi.xpost('/api/v1/zulip/mark', {flag: 'read', messages});}catch(e){}

            for (let id of messages) {
                this.unread.delete(id);
                let stream = this.streams[this.msgmap[id]?.stream_id];
                if (stream) stream.unread.delete(+id);
            }
        }, 500);
    }

    _time(time: number) {
        let m = moment(new Date(time*1000));
        let diff = moment().diff(m);
        // console.log(m.format('YYYY-MM-DD HH:mm:ss'), diff/1000);
        if (diff < 12*60*60*1000) {
            return m.format("HH:mm");
        }
        if (diff < 7*24*60*60*1000) {
            return m.format("ddd HH:mm");
        }
        return m.format("");
    }

    __handle_zulip_events(ev: any, narrow: boolean=false) {
        if (!ev) return;
        try {
            if (ev.type == 'messages') this.__handle_zulip_messages(ev.messages, narrow);
            else if (ev.type == 'message') this.__handle_zulip_messages([ev.message], narrow);
            else if (ev.type == 'update_message_flags') {
                for (let id of ev.messages) this.msgmap[id]?.flags.push(ev.flag);
            } else if (ev.type == 'subscriptions') {
                console.log('found subscriptions:', ev.subscriptions.length)
                for (let sub of ev.subscriptions) {
                    if (!this.streams[sub.stream_id]) {
                        if (!sub.messages) sub.messages = [];
                        if (!sub.unread) sub.unread = new Map();
                        this.streams[sub.stream_id] = sub;
                    }
                }
            } else {
                console.log('unhandled event type:', ev);
            }
        } catch (e) {
            console.log(e);
        }
    }

    __handle_zulip_messages(msg: any, narrow: boolean = false) {
        if (!(msg instanceof Array)) msg = [msg];
        let added: number[] = [];

        for (let m of msg) {
            if (!m) continue;
            if (!m.flags) m.flags = [];
            if (m.type == 'stream') {
                let last = this.messages[this.messages.length-1] || null;
                if (this.rendered[m.id]) continue;

                let time = this._time(m.timestamp);
                let me = m.sender_email == this.profile?.email;
                let html =  "";
                if (last?.sender_id != m.sender_id || last?.stream_id != m.stream_id) {
                    html =  "<div class='z-hdr'><span>" + m.sender_full_name + "["+m.id+"]</span>";
                    html += "<span style='text-align: center;'>"+(this.streams[m.stream_id]?.description || this.streams[m.stream_id]?.name || m.display_recipient)+"</span>";
                    html += "<span style='text-align: right;'>"+time+"</span></div>";
                }

                m.content = m.content.replace(/\<a href/gi, '<a onclick="return false;" href');
                m.content = m.content.replace(/\:link\:/gi, '<span class="material-symbols-outlined">attach_file</span>');

                html += "<div class='z-content"+(me?'-me':'')+"'>" + m.content + "</div>";
                html += "<div id='msg"+m.id+"' style='padding-bottom: 4px;'> </div>";   // scroll anchor element
                
                m.html = this.sanitizer.bypassSecurityTrustHtml(html);

                this.msgmap[m.id] = m;
                let read = m.flags.indexOf('read') >= 0 ? true : false;

                if (narrow) {
                    this.narrow.push(m);
                } else {
                    this.messages.push(m);
                    if ( read && m.id > this.lastseen) this.lastseen = m.id;
    
                    let stream = this.streams[m.stream_id];
                    if (stream) {
                        stream.messages.push(m);
                        stream.summary = (m.sender_full_name + ' : ' + m.content).substring(0, 80);
                        stream.stime = time;

                        if (read) stream.unread.delete(m.id);
                        else stream.unread.set(m.id);
                    }
                }
                if (read) {
                    this.unread.delete(m.id);
                } else {
                    this.unread.set(m.id, true);
                }        

                added.push(m.id);
            }
        }
        
        if (added.length > 0) {
            setTimeout(() => this.__observe_added_elems(added), 300);
        }
        this.unread_count = this.unread.size;
        console.log('zls:', this.lastseen, this.unread_count)
    }

    __scroll_to_message(id: string) {
        let elem = document.getElementById(id);
        if (elem) {
            console.log('scrolling to:', id, elem);
            elem.scrollIntoView({block: "nearest", inline: "nearest"});
            if (this.containerElem) this.containerElem.scrollBy(0, 30); // some offset adjustment
        } else {
            console.log('cannot scrolling to:', id, ' not visible yet');
        }
    }

    __observe_messages(messages: any[]) {
        for (let m of messages) {
            if (this.msgmap[m.id]) {
                if (this.msgmap[m.id].flags.indexOf('read') < 0) {
                    let elem = document.getElementById('msg'+m.id);
                    if (elem) this.objserver?.observe(elem);
                }
            }
        }
    }

    __observe_all_elems() {
        if (this.stream) this.__observe_messages(this.stream.messages);
        else this.__observe_messages(this.messages);
    }

    __observe_added_elems(added: number[]) {        
        for (let id of added) {
            if (this.msgmap[id]) {
                if (this.msgmap[id].flags.indexOf('read') < 0) {
                    let elem = document.getElementById('msg'+id);
                    if (elem) this.objserver?.observe(elem);
                }
            }
        }
        this.__scroll_to_message('msg'+this.lastseen);
    }

}
