import { Component, HostBinding, Input, OnDestroy, Optional, Inject, ElementRef, ChangeDetectorRef, ViewChild, SimpleChange } from '@angular/core';
import { AbstractControl, ValidationErrors, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatFormFieldControl, MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';

// to make it compatible with mat-form-field
// https://material.angular.io/guide/creating-a-custom-form-field-control

@Component({
    selector: 'masked-input',
    host: {'(pointerdown)': 'pointerDown($event)'},
    templateUrl: './masked-input.component.html',
    styleUrls: ['./masked-input.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: MaskedInputComponent}
    ]
})
export class MaskedInputComponent implements MatFormFieldControl<string|number>, ControlValueAccessor, OnDestroy, Validator  {
    @ViewChild('mi') mi!: ElementRef;
    stateChanges = new Subject<void>();
    unfvalue: string | number = '';
    fmtvalue: string | number = '';
    _disabled: boolean = false;
    _placeholder!: string;
    _required = false;
    focused = false;
    touched = false;

    onChange = (value: string | number) => {};          // the function we need to call when there is a change in value detected
    onTouched = (value: string | number) => {};         // the function we need to call when the control is touched
    onValidatorChanged = (value: string | number) => {};

    registerOnChange = (onChange: any) => this.onChange = onChange;
    registerOnTouched = (onTouched: any) => this.onTouched = onTouched;
    setDisabledState = (disabled: boolean) => this._disabled = disabled;
    registerOnValidatorChange = (onValidatorChanged: any) => this.onValidatorChanged = onValidatorChanged;
    
    focusIn(ev: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }
    focusOut(ev: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(ev.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched(1);
            this.stateChanges.next();
        }
    }
    pointerDown(ev: any) {/*this.touched = true; this.onTouched(ev);*/}
    
    static nextId = 0;
    @HostBinding() id = `mskinp-${MaskedInputComponent.nextId++}`;
    
    @Input()
    get placeholder() {return this._placeholder;}
    set placeholder(plh: string) {this._placeholder = plh; this.stateChanges.next();}

    get empty() {return this.unfvalue ? false : true}
    @HostBinding('class.floating') get shouldLabelFloat() {return this.focused || !this.empty;}

    @Input()
    get required() {return this._required;}
    set required(req) {this._required = coerceBooleanProperty(req);this.stateChanges.next();}
    

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {this._disabled = coerceBooleanProperty(value); this.stateChanges.next();}

    get errorState(): boolean {
        if (this.ngControl?.invalid && this.ngControl?.control?.touched) return true;
        return !this.unfvalue && this.touched;
    }

    @Input('aria-describedby') userAriaDescribedBy!: string;
    setDescribedByIds(ids: string[]) {
        this._elementRef.nativeElement?.setAttribute('aria-describedby', ids.join(' '));
        // const controlElement = this._elementRef.nativeElement .querySelector('.example-tel-input-container')!;
        // controlElement?.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick(event: MouseEvent) {
        this.mi?.nativeElement.focus();
    }

    controlType = "masked-input";
    constructor(public ngControl: NgControl, 
        private _elementRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField) {
        if (this.ngControl) this.ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['symbol']) this._update_value(this.fmtvalue);
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    _update_value(value: string|number) {
        let [fmt, unf, skipped] = this.mask_reverse(value);
        this.unfvalue = unf;
        // this handler's change detection runs after we make the change and would not reconize it unless we force it to run now
        if (skipped) this.cdr.detectChanges();
        this.fmtvalue = fmt;
    }

    writeValue(value: string | number) {
        console.log('writeValue:', value)
        this._update_value(value);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // if (this.ngControl?.invalid) return { internal: true };
        return null;    // {errorDesc: {control.value}};
    }

    changed() {
        this._update_value(this.fmtvalue);
        this.onChange(+this.unfvalue);
    }

    get value() {return this.unfvalue;}
    set value(val: string | number) {this.writeValue(val);this.stateChanges.next();}
    
    separator: string = ',';
    pattern: number[] = [3,2,2,2,2,2,2,2,2];
    format(val: string | number) {
        if (!val) return ["", ""];
        if (typeof val !== 'string') val = '' + val;
        
        let parts = val.split('.');
        val = parts[0];

        let pat = this.pattern[0];
        let ret = '';
        let unf = '';
        for (let i=val.length-1, p=0; i>=0; i--) {
            if (val[i] < '0' || val[i] > '9') continue; // skip non numbers
            if (pat == 0) {
                ret = ',' + ret;
                pat = this.pattern[++p];
            }
            ret = val[i] + ret;
            unf = val[i] + unf;
            --pat;
        }

        if (parts.length > 1) {ret = ret + '.' + parts[1]; unf = unf + '.' + parts[1];}
        return [(ret.length>0 ? this.symbol:'') + ret, unf, false];
    }

    @Input() mask = "ddd,dd,dd,dd,dd,dd,dd";
    @Input() symbol: string = '$ ';
    @Input() decimal: number = 2;
    mask_reverse(val: string | number) {
        if (!val) return ['', '', 0];

        if (typeof val !== 'string') val = '' + val;
        let parts = val.split('.');
        val = parts[0];

        let vi = val.length - 1;
        let ret = '';
        let unf = '';
        let pmask = '';
        let skipped = 0;
        for (let i=0; i<this.mask.length && vi >= 0; i++) {
            if (this.mask[i] == 'd') {
                while (vi >= 0 && (val[vi] < '0' || val[vi] > '9')) {skipped = 1; vi --; }
                if (vi < 0) break;
                
                ret = val[vi] + pmask + ret;
                unf = val[vi] + unf;
                pmask = '';
                vi --;
            } else {
                if (pmask) ret = pmask + ret;   // will this happen? two or more masks together?
                pmask = this.mask[i];
            }
        }
        if (parts.length > 1 && this.decimal>0) {
            let dpart = parts[1].slice(0,this.decimal);
            ret = ret + '.' + dpart;
            unf = unf + '.' + dpart;
        }
        return [(ret.length>0 ? this.symbol:'') + ret, unf, skipped];
    }

}
