import { Inject, Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SettingsService {
    title: string = environment.vendor.title || 'Insillion';
    company: string = environment.vendor.company || '';
    dateFormat: string = environment.vendor.dateFormat || 'DD-MM-YYYY';

    // premiumData: any = {premium_value: 0};
    // premiumSubject = new BehaviorSubject<number|string>(0);
    constructor() {}
    public load() {
        
    }
    // setPremium(premium: number|string) {
    //     this.premiumSubject.next(premium);
    // }
}
