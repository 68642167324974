import moment from 'moment';

var achars =     [
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '!', '@', '#', '$', '%', '^', '&', '-', '(', ')', '[', ']', '_', '/', '=','\\',
];
export function luid(len: number) {
    let ui8 = crypto.getRandomValues(new Uint8Array(len));
    let ret = '';
    for (let i=0; i<ui8.length; i++) ret += achars[ui8[i]%achars.length];
    return ret;
}

export function suid(len: number) {
    let ui8 = crypto.getRandomValues(new Uint8Array(len));
    let ret = '';
    for (let i=0; i<ui8.length; i++) ret += achars[ui8[i]%62];
    return ret;
}

export function is_obj(o: any) {
    return null !== o && typeof o === 'object' && 
        Object.getPrototypeOf(o).isPrototypeOf(Object);
}

/**
 * @ignore
 */
export function is_num(num: string | number) {
    if (typeof num === 'number') return !isNaN(num);
    if (typeof num === 'string') return !isNaN(parseFloat(num)) && isFinite(num as any);
    return false;
}

/**
 * Convert givent date input to Excel formated date.
 * @param dt Must be Date|number|number in string format.
 * @returns Excel date number
 */
export function dateToExcel(dt: any){
    if( !dt )return null;
    if( is_num(dt) ){
        if( +dt > 2958446 )dt = new Date(dt); //unix timestamp
        else return dt;
    }
    if( !(dt instanceof Date) && !moment.isMoment(dt) )dt = new Date(dt);

    var ret = 25569.0 + ((dt.getTime() - (dt.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
    return +ret.toString().substr(0,20);
}

/**
 * Convert Excel date number to Date object. Additionaly converts a string formated date.
 * @param dt number|number in string format|date in string format
 * @param tz Timezone
 * @param dateFormat [YYYY-MM-DD] input date format (optional)
 */
export function excelDateToDate(dt: string | Date, tz?: string, dateFormat?: string){
    if (dt instanceof Date) return moment(dt);
    // console.log('e2d:', dt, dateFormat);
    if (!dt) return null;
    
    if (is_num(dt)) {
        var d1900 = new Date(1900, 0, 1);
        if (+dt > 2958446) return moment( +dt );
        const d: number = +dt|0;
        const h: number = (+dt - d)*24;
        const m: number = (h - (h|0))*60;
        const s: number = (m - (m|0))*60;

        var dd: Date;
        if (d>60) dd = new Date(d1900.getTime() + (d - 2) * 86400000);
        else dd = new Date(d1900.getTime() + (d - 1) * 86400000);

        dd.setHours( h|0 );
        dd.setMinutes( m|0 );
        dd.setSeconds( Math.floor(s+0.5)|0 );
        // if( tz )return moment(dd).tz(tz);
        return moment(dd);
    }

    try{
        // if a custom format is specified try it first
        if (dateFormat) {
            let mdt = moment(dt, dateFormat, true);
            if (mdt.isValid()) return mdt;
        }

        let isoFormat = 'YYYY-MM-DD';
        if (dt.length > 10) isoFormat = 'YYYY-MM-DD HH:mm:ss';

        // check if its in universal format
        let mdt = moment(dt, isoFormat);
        if (mdt.isValid()) return mdt;

        // just shake the tree and see what falls
        return moment( new Date(dt) );
    }catch(e){
        console.log(e);
        return null;
    }
}

export function timeFormat(value: any){
    let dt = null;
    if (typeof value === 'string') {
        dt = moment(dt, 'YYYY-MM-DD');
        if( !dt.isValid() ) {
            dt = moment( new Date(value) );
        }
    } else {
        dt = moment( value );
    }
    if (dt.isValid()) return dt.format('YYYY-MM-DD HH:mm:ss');
    return 'invalid';
}

/**
 * Convert JSON object into flat JSON path/value object.
 * @param data 
 */
export function flattenJSON(data: any) {
    var result: any = {};
    const recurse = (cur: any, prop: any) => {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
             for(var i=0, l=cur.length; i<l; i++)
                 recurse(cur[i], prop ? prop+"."+i : ""+i);
            if (l == 0)
                result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return result;
}

const powersB = [
    {key: 'P', value: Math.pow(10, 15)},
    {key: 'T', value: Math.pow(10, 12)},
    {key: 'G', value: Math.pow(10, 9)},
    {key: 'M', value: Math.pow(10, 6)},
    {key: 'K', value: 1000}
];
const powersK = [
    {key: 'Q', value: Math.pow(10, 15)},
    {key: 'T', value: Math.pow(10, 12)},
    {key: 'B', value: Math.pow(10, 9)},
    {key: 'M', value: Math.pow(10, 6)},
    {key: 'K', value: 1000}
];
const powersT = [
    {key: 'w', value: 7*24*60*60*1000},
    {key: 'd', value: 24*60*60*1000},
    {key: 'h', value: 60*60*1000},
    {key: 'm', value: 60*1000},
    {key: 's', value: 1000}
];
const powersL = [
    {key: 'Cr', value: Math.pow(10, 7)},
    {key: 'L', value: Math.pow(10, 5)},
    {key: 'K', value: 1000}
];
const powersX: {[key: string]: any} = {'L': powersL, 'T': powersT, 'K': powersK, 'B': powersB};


export function numberFormat(value: any, fmt: string = 'K', decimal: number = 0){
    if (isNaN(value)) return null; // will only work value is a number
    if (value === null) return null;
    if (value === 0) return '0';
    let abs = Math.round(Math.abs(value));
    const rounder = Math.pow(10, 1);
    const isNegative = value < 0; // will also work for Negetive numbers
    let key = '';
    const powers: {key: string, value: number}[] = powersX[fmt] || powersK;
    for (let i = 0; i < powers.length; i++) {
        let reduced = abs / powers[i].value;
        reduced = Math.round(reduced * rounder) / rounder;
        if (reduced >= 1) {
            abs = reduced;
            key = powers[i].key;
            break;
        }
    }
    return (isNegative ? '-' : '') + (decimal > 0 ? abs.toFixed(decimal) : abs) + key;
}

export function momentOf(value: string, fmt: string = 'YYYY-MM-DDTHH:mm:ss') {
    return moment(value, fmt);
}

export function _capitalize(name: string) {
    if (!name) return '';
    return name.split('_').filter(Boolean).map(x => x[0].toUpperCase() + x.slice(1)).join(' ');
}

export function isObject(v: any) {
    return (v && typeof v === 'object' && !Array.isArray(v));
}

export function deepMerge(targ: any, ...src: any): any {
    if (src.length === 0) return targ;
    const source = src.shift();
    if (!isObject(targ) || !isObject(source)) return targ;
    for (const key in source) {
        if (isObject(source[key])) {
            if (!targ[key] || !isObject(targ[key])) targ[key] = deepMerge({}, source[key]);
            else deepMerge(targ[key], source[key]);
        } else {
            targ[key] = source[key];
        }        
    }
    return deepMerge(targ, ...src);
}

function _clone_array(arr: any[]): any[] {
    let dst = [];
    for (let i=0; i<arr.length; i++) {
        if (arr[i] instanceof Array) dst.push(_clone_array(arr[i]));
        else if (isObject(arr[i])) dst.push(_clone(arr[i]));
        else dst.push(arr[i]);
    }
    return dst;
}

export function _clone(src: any): any {
    if (!isObject(src)) return src;
    let dst: any = {};
    for (const key in src) {
        if (src[key] instanceof Array) dst[key] = _clone_array(src[key]);
        else if (isObject(src[key])) dst[key] = _clone(src[key]);
        else dst[key] = src[key];
    }
    return dst;
}

export function intersects(a1: any[], a2: any[]): any {
    if (!(a1 instanceof Array) || !(a2 instanceof Array)) return false;
    for (let i=0; i<a1.length; i++) {
        for (let j=0; j<a2.length; j++)
            if (a1[i] == a2[j]) return true;
    }
    return false;
}

function _date_with_time_format(str: string, dtfmt: string) {
    return str.indexOf(':') > 0 ? dtfmt + ' HH:mm:ss' : dtfmt;
}

function _guess_date_format(str: string) {
    if (is_num(str)) return 'DD-MM-YYYY';
    if (typeof str != 'string') return 'DD-MM-YYYY';
    str = str.replace(/\//g, '-');
    let parts = str.split('-');
    if (parts.length < 3) return 'DD-MM-YYYY';

    if (/^[a-zA-Z]+$/.test(parts[0])) {
        if (parts[0].length === 3) {
            return _date_with_time_format(str, parts[1].length == 2 ? 'MMM-DD-YYYY' : 'MMM-YYYY-DD');
        } else {
            return _date_with_time_format(str, parts[1].length == 2 ? 'MMMM-DD-YYYY' : 'MMMM-YYYY-DD');
        }
    }

    if (/^[a-zA-Z]+$/.test(parts[1])) {
        if (parts[1].length === 3) {
            return _date_with_time_format(str, parts[0].length == 2 ? 'DD-MMM-YYYY' : 'YYYY-MMM-DD');
        } else {
            return _date_with_time_format(str, parts[0].length == 2 ? 'DD-MMMM-YYYY' : 'YYYY-MMMM-DD');
        }
    }

    if (parts[0].length > 2) {
        return str.indexOf(':')>0 ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
    } else {
        return str.indexOf(':')>0 ? 'DD-MM-YYYY HH:mm:ss' : 'DD-MM-YYYY';
    }
}

export function is_valid_date(str: string, outfmt: string='', infmt: string='') {
    let mdt = parse_date(str, outfmt, infmt);
    return mdt?.format(outfmt||'DD/MM/YYYY') || null;
}

export function parse_date(str: string, outfmt: string='', infmt: string='') {
    let mdt = outfmt ? moment(str, infmt || outfmt) : null;
    if (mdt && !mdt.isValid()) {
        mdt = moment(str, _guess_date_format(str));
    }
    if (mdt && mdt.isValid()) return  mdt;
    return null;
}


export function _saveAs(data: string, fname: string, type: string) {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([data], { type: type || 'text/plain' }));
    a.href = url;
    a.download = fname;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();        
}

function __get_data(name: string, data: any) {
    if (name.indexOf('.') < 0) return data[name];
    let parts = name.split('.').map((x: any) => isNaN(x) ? x : '['+x+']');
    let expr = parts.join('?.');
    let f = new Function('data', 'return data.' + expr);
    return f(data);
}
function __set_data(name: string, data: any, value: any) {
    if (name.indexOf('.') < 0) {
        data[name] = value;
        return;
    }
    let parts = name.split('.');
    let obj = data;
    for (let i=0; i<parts.length-1; i++) {
        if (!obj[parts[i]]) obj[parts[i]] = {};
        obj = obj[parts[i]];
    }
    obj[parts[parts.length-1]] = value;
}

export function __fix_getter_setter(field: any, devMode=false) {
    if (!field || !field.field_name) return;
    if (field.getdata || field.setdata) return;
    if (field.field_name.indexOf('-') >= 0) {console.log('*** invalid field name, remove -', field.field_name);} // invalid name
    let parts = field.field_name.split('.');
    /* if (parts.length == 1) {
        if (field.empty_as_null) {
            field.getdata = function (data: any) {return data[this.field_name] === null ? '' : data[this.field_name];};
            field.setdata = function (data: any, value: any) {data[this.field_name] = value === '' ? null : value;}
        } else {
            field.getdata = function (data: any) {return data[this.field_name]};
            field.setdata = function (data: any, value: any) {data[this.field_name] = value;}
        }
    } else {*/


    
    try {
        let expr = parts.map((x: any) => isNaN(x) ? x : '['+x+']').join('?.');
        if (field.empty_as_null) expr = 'return data.' + expr + ' ?? ""';
        else expr = 'return data.' + expr;

        if (devMode) expr = 'try {' + expr + '} catch (e) {console.log("field-setter: ' + field.field_name+ '", e); return null;}';
        field.getdata = new Function('data', expr);

        // if (field.empty_as_null)
        //     field.getdata = new Function('data', 'return data.' + expr + ' ?? ""');
        // else {
        //     field.getdata = new Function('data', 'return data.' + expr);
        // }
    } catch (e) {
        console.log('**Error: fix field name', field.field_name, field);
        field.getdata = new Function('data', 'return data["' + field.field_name + '"]');
        field.setdata = new Function('data', 'value', 'data["' + field.field_name + '"]=value');
        return;
    }

    // if this field is part of choices (multi-quote), that data should also get updated
    // everytime we change this value
    //
    let stmt = (field.empty_as_null ? "value = value === '' ? null : value;\n" : "") + `
        if (data.choices?.[0]?.['`+field.field_name+`'] !== undefined) {
            for (let choice of data.choices) if (choice.selected) choice['`+field.field_name+`'] = value;
        }
    `;
    let prefix = 'data';
    for (let i=0; i<parts.length-1; i++) {
        let part = parts[i]
        prefix += is_num(part) ? ('[' + part  + ']') : ('.' + part);
        stmt += 'if (!' + prefix + ') ' + prefix + ' = {};\n';
    }
    let part = parts[parts.length-1];

    // temp: remove after enforcing naming convention
    if (parts.length == 1 && part.indexOf('-') >= 0) {
        stmt += "data['"+part+"'] = value";
    } else {
        stmt += prefix + (is_num(part) ? ('[' + part  + ']') : ('.' + part)) + ' = value;';
    }
    // console.log('setter:', field.field_name, stmt);
    try {
        field.setdata = new Function('data', 'value', stmt);
    } catch (e) {
        console.log('**Error: fix field name', field.field_name);
        console.log(stmt, e);
    }
}

export function __eval_func(expr: string, name?: string) {
    if (expr[0] === '{') {
        return new Function('data', 'profile', 'policy', expr.substring(1, expr.length-1) );
    } else {
        expr = expr.replace(/this\.mod\.data/g, 'data');
        expr = expr.replace(/this\.mod/g, 'policy');
        expr = expr.replace(/this\.data/g, 'data');
        let _expr = "try{return ("+expr+");}catch(e){/*console.log('if func: " + (name || '') + "', e.message);*/ return false;}";
        return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
    }
}

import { Policy } from '../lib/inspolicy';
import { IWorkflow, insapi, IProduct, IEndProduct } from '../lib/insapi';

export class ExcelForm {

    pipes: {[key: string]: any} = {};
    nstpType: string = '';
    benefit: any = null;
    condMandatory: {[key: string]: string} = {};
    label: any = {prefix: {mandatory: "* ", conditional: "+ "}, style: {mandatory: "", conditional: ""}};
    devMode: boolean = false;

    constructor(pipes: {[key: string]: any}, devMode = false) {
        this.pipes = pipes;
        this.devMode = devMode;
    }

    _fix_label(str: string) {
        if (this.label.capitalize == 'uppercase') return (str||'').toUpperCase();
        if (this.label.capitalize == 'camelcase') return _capitalize(str);
        return (str||'');
    }

    _dict(name: string, policy: Policy) {
        if (policy.product?.dictionary?.inputs[name]) return policy.product?.dictionary.inputs[name].label;
        return '';
    }

    _label(name: string, label: string, policy: Policy) {
        if (policy.product?.dictionary?.inputs[name]) label = policy.product?.dictionary.inputs[name].label;
        if (!label) label = this._fix_label(name);
        return label;
    }


    _fix_field_validation(/*valid: any, field: any*/valids: any[], field: any) {
        let valid = null;
        let name = field.field_name;
        let aname = 'arr_'+field.field_name;
        for (let v of valids) {
            if (v[name]) {valid = v[name]; break;}
            if (v[aname]) {valid = v[aname]; break;}
        }

        if (!valid || valid.status == 1) return;
        // visiblity condition from validation
        if (valid.if) {
            if (!field.if) field.if = valid.if;
        }

        if (valid.defaultif && valid.default) {
            if (!field.defaultif) {
                field.defaultif = valid.defaultif;
                field.default = valid.default;
            }
        }

        if (!field.title) field.title = undefined;
        if (!field.title_format && valid.title_format) {
            if (valid.title_format instanceof Array)
                field.title_format = valid.title_format;
            else if (typeof valid.title_format === 'string') 
                field.title_format = valid.title_format;
        }
        
        if (field.title_format && typeof field.title_format === 'string')
            field.title_format = valid.title_format.split(':').map((x: string) => x.trim());


        field.props = { ...(field.props||{}), ...(valid.props||{})};

        field.labelclass = '';
        if (valid.mandatory) {
            // mandatory grids are conditional even if mentioned as boolean true
            if (typeof valid.mandatory === 'string' || field.type == 'grid') {
                field.label = (this.label?.prefix?.conditional||'') + field.label;
                field.labelclass = (this.label?.class?.conditional||'');
                if (valid.mandatory == 'true')  //-rr 2023-Jul-19 conditional mandatory should not be treated as mandatory always
                    field.validate = (field.validate ? (field.validate + "|") : "") + "mandatory";
                this.condMandatory[field.field_name] = field.field_name;
            } else {
                field.label = (this.label?.prefix?.mandatory||'') + field.label;
                field.labelclass = (this.label?.class?.mandatory||'');
                field.validate = (field.validate ? (field.validate + "|") : "") + "mandatory";
            }
        }

        if (valid.subtext) {
            if (valid.subtext.formatter) {
                valid.subtext.pipe = valid.subtext.formatter.split(':');
            }
        }
        if (valid.mask) {
            field.mask = valid.mask;
        }

        if (valid.auto_init) field.auto_init = true;

        // source defined in validations has higher precendence than what is defined in workflow
        if (valid.source) field.source = {...valid.source};
        if (valid.options) field.options = [...valid.options];
        
        if (valid.type == 'lookup' || 
            valid.type == 'autocomplete' || 
            valid.type == 'checkbox' || 
            valid.type == 'fetch') field.type = valid.type;

        if (valid.multi) field.multi = parseInt(valid.multi);
        if (valid.multi_maxlimit) field.multi_maxlimit = parseInt(valid.multi_maxlimit);
        if (valid.grid) field.grid = valid.grid;
        if (valid.address) field.address = valid.address;
        if (valid.checkbox) field.checkbox = valid.checkbox;

        if (valid.filter) field.filter = valid.filter;
        if (valid.empty_as_null) field.empty_as_null = true;

        // rest of the validation properties can be copied over to fields
        let ignored: {[key: string]: number} = {'if': 1, type: 1, source: 1, options:1, mandatory:1};
        for (let key in valid) {
            if (!ignored[key]) {
                field[key] = valid[key];
            }
        }        
    }

    _prepare_grid(fld: any, policy: Policy, grids: number) {
        if (fld.type != 'grid' /*|| !fld.tmpl || !fld.range*/) return;
        
        fld.span = grids;
        fld.layout = {
            columns: new Array((fld.range ? (fld.range.ce - fld.range.cs + 1) : fld.items.length)).fill('1fr').join(' '),
        };

        if (!fld.names) fld.names = fld.items.map((x: any) => x.field_name.substring(0, x.field_name.length-5));

        let fmap = fld.items.reduce((a: any, x: any) => {a[x.field_name] = x; return a;}, {});
        fld.types = fld.names.map((x: string) => this.pipes[fmap[x + '_tmpl']?.type] || '');
        fld.labels = fld.names.map((x: string) => this._dict(x+'_tmpl', policy) || fmap[x+'_tmpl']?.label || '');

        // fld.types = [];
        // for (let name of fld.names) {
        //     let items = fld.items.filter((x: any) => x.field_name == name+'_tmpl');
        //     fld.types.push(this.pipes[items[0]?.type] || '');
        // }
        // fld.labels = []
        // for (let name of fld.names) fld.labels.push(this._dict(name+'_tmpl', policy) || fmap[name+'_tmpl']?.label || '');


        fld.rows = [];
        if (fld.range) {
            for (let rs=fld.range.rs; rs<=fld.range.re; rs++) {
                let cols = [];
                for (let cs=fld.range.cs; cs<=fld.range.ce; cs++) cols.push(fld.range.sheet+'_'+rs+'_'+cs);
                fld.rows.push(cols);
            }
        }
        fld.group = {fields: fld.items};

        // fld.group = {fields: fld.items, layout: {
        //     // cls: 'fg-wrapper', 
        //     grids: fld.items.length > 3 ? 12 : 8,
        //     style: {"max-width": '99%', "min-width": "99%"}
        // }};
    }


    _excel_cell_to_field(fld: any, policy: Policy, data: any, grids?: number) {
        let field: any = {field_name: fld.name, type: 'string', label: this._label(fld.name, fld.label, policy), ex: true};

        if (fld.t == 'grid') {
            field.type = 'grid';
            field.tmpl = fld.tmpl;
            field.range = fld.range;
            let items = [];
            for (let item of fld.items) {
                items.push(this._excel_cell_to_field(item, policy, data, grids));
            }
            field.items = items;
            field.names = fld.names;
            // field.labels = [];
            // for (let name of fld.names) field.labels.push(this._dict(name+'_tmpl', policy));
            this._prepare_grid(field, policy, grids ?? fld.span ?? 12);
        }

        __fix_getter_setter(field);
        
        let val = field.getdata(data);
        if (typeof val === 'string' && val.startsWith('parseDate(')) { val = +val.substring(10, val.length-1);}

        if (fld.id) field.id = fld.id;

        if (fld.t == 'label') field.type = 'label';

        if (fld.t == 'n') {
            field.validate = (field.validate ? (field.validate + "|") : "") + "number";
        }
        
        if (fld.t == 'l' && fld.options) {
            if (typeof fld.i == 'number') {
                // $any cast in lookup.component does not work with number string mixup
                fld.i = '' + fld.i;
                // let ret = fld.options.filter((x:any) => +x != x);
                // if (ret.length > 0) fld.i = '' + fld.i;
                // else fld.options = fld.options.map((x: any) => +x);
            }
            field.type = 'lookup';
            field.options = fld.options;
        }

        if (fld.i && !policy.policy?.policy_id && !data[fld.name]) {
            __set_data(fld.name, data, fld.i);
            // data[fld.name] = fld.i;
        }

        if (fld.t == 'd') {
            field.type = 'date';
            __set_data(field.field_name, data, excelDateToDate(val)?.toDate() || '');
            // data[field.field_name] = excelDateToDate(val)?.toDate() || '';
        }
        return field;
    }

    _is_page_allowed(product: any, name: string) {
        let priv = product.data?.privileges?.view?.sheets;
        if (!priv || !priv[name]) return true;
        if (!insapi.profile) {
            // console.log('sheet view priv: profile not found');
            return false; // profile not found
        }
        for (let grp of priv[name]) {
            if (insapi.profile.groups.indexOf(grp) >= 0) return true;
        }
        // console.log('sheet view priv: user does not belong to ', priv[name], 'users', insapi.profile.groups);
        return false;
    }

    __fix_validation_funcs(name: string, valid: any) {
        valid.if = valid.if?.trim();
        if (!valid.if) valid.ifFunc = () => true;
        if (!valid.ifFunc) {
            let _expr = "try{return ("+valid.if+");}catch(e){/*console.log('if func: " + (name) + "', e.message);*/ return false;}";
            valid.ifFunc = new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
        }
        if (valid.default && typeof valid.default === 'string') {
            valid.default = valid.default.trim();
            if (valid.default[0] === '{') {
                valid.deffunc = new Function('data', 'profile', 'policy', valid.default);
            }
        }

    }

    _fix_product_output_validations(product: IProduct | IEndProduct | null) {
        for (let v in product?.premium_calc_output_validations||{}) {
            this.__fix_validation_funcs(v, product?.premium_calc_output_validations[v]);
        }
        for (let v in product?.premium_calc_validations||{}) {
            this.__fix_validation_funcs(v, product?.premium_calc_validations[v]);
        }
        for (let v in product?.proposal_form_validations||{}) {
            this.__fix_validation_funcs(v, product?.proposal_form_validations[v]);
        }
        for (let v in product?.proposal_form_output_validations||{}) {
            this.__fix_validation_funcs(v, product?.proposal_form_output_validations[v]);
        }
        
    }

    __get_page_type(policy: Policy, stage: any) {
        let type = 'proposal_form';
        let data = policy.policy?.proposal?.data || policy.policy?.quote?.data;

        if (stage.module.name !== 'proposal' && stage.module.name !== 'pnstp') {
            type = 'premium_calc';
            data = policy.policy?.quote?.data;
        }

        if (stage.module.name == 'eproposal') data = policy.endorsement?.eproposal?.data;
        if (!data && policy.endorsement?.endorsement_id) data = policy.endorsement?.eproposal?.data;

        if (stage.module.name == 'qnstp' || stage.module.name == 'pnstp' || stage.module.name == 'enstp') {
            type = 'nstp_form';
        }
        if (!data) console.log('Could not find data for stage', stage.module.name, policy);
        return {type, data};
    }

    __look_for_grids_in_pages(field: any, pages: any[], stage: any, policy: Policy, data: any) {
        if (!pages) return false;

        for (let page of pages || []) {
            for (let grp of page.groups) {
                for (let fld of grp.fields) {
                    if (fld.name == field.field_name) {
                        field.type = 'grid';
                        field.tmpl = fld.tmpl;
                        console.log('fld.tmpl:', fld.tmpl)
                        field.range = fld.range;
                        let items = [];
                        for (let item of fld.items) {
                            items.push(this._excel_cell_to_field(item, policy, data, stage.form?.layout?.grids));
                        }
                        field.items = items;
                        field.names = fld.names;
                        field.labels = [];
                        for (let name of fld.names) field.labels.push(this._dict(name+'_tmpl', policy));
            
                        if (isNaN(+field.span)) field.span = '';
                        this._prepare_grid(field, policy, field.span || stage.form?.layout?.grids||8);
                        return true;
                    }
                }
            }
        }
        return false;
    }


    _fix_wflow_grid_from_pages(field: any, policy: Policy, curstage: any) {
        let {type, data} = this.__get_page_type(policy, curstage);
        let pages = policy.product?.pages?.[type];
        if (this.__look_for_grids_in_pages(field, pages, curstage, policy, data)) return field;

        // grid not found in current stage, lets try and locate it in others
        if (type == 'proposal_form') {
            pages = policy.product?.pages?.['premium_calc'];
        } else if (type === 'premium_calc') {
            pages = policy.product?.pages?.['proposal_form'];
        } else if (type === 'nstp_form') {
            pages = policy.product?.pages?.['premium_calc'];
        }
        if (this.__look_for_grids_in_pages(field, pages, curstage, policy, data)) return field;

        // field.type = 'readonly'
        if (!field.items) {console.log(field.field_name, '*********** grid missing items'); return field;}
        field.t = field.type;
        field.name = field.field_name;
        field = this._excel_cell_to_field(field, policy, {}, field.span);
        field.ex = false;   // this is not from excel
        return field;
    }

    _excel_to_pages_and_groups(policy: Policy, curstage: any, valids: any[]) {
        let type = 'proposal_form';
        // let data = policy.policy?.proposal?.data || policy.policy?.quote?.data;
        let data = policy.policy?.proposal_id ? policy.policy?.proposal?.data : policy.policy?.quote?.data;
        if (curstage.module.name !== 'proposal' && curstage.module.name !== 'pnstp') {
            type = 'premium_calc';
            data = policy.policy?.quote?.data;
        }

        if (curstage.module.name == 'qnstp' || curstage.module.name == 'pnstp') {
            type = 'nstp_form';
        }

        // let ivalid = policy.product ? (policy.product[type+'_validations'] || {}) : {};
        // let ovalid = policy.product ? (policy.product[type+'_output_validations'] || {}) : {};
        // console.log('ivalid:', ivalid, ovalid);
        let stages = [];
        let pages = policy.product?.pages?.[type];
        if (!pages) {console.log('incomplete meta data, please regenerate forms after upgraing - ' + type); return [];}
        for (let page of pages) {
            if (policy.product && !this._is_page_allowed(policy.product, page.name)) continue;
            let stage: any = {name: this._fix_label(page.name), form: {layout: {}}};
            let groups = [];
            for (let grp of page.groups) {
                // let gvalid = ivalid[grp.name] || ivalid['grp_'+grp.name] || ovalid[grp.name] || ovalid['grp_'+grp.name] || [];
                let gvalid = valids[0][grp.name] || valids[0]['grp_'+grp.name];
                if (!gvalid && valids.length > 0) gvalid = valids[1][grp.name] || valids[1]['grp_'+grp.name] || [];

                if (gvalid.status == 1) gvalid = null;

                grp.if = gvalid?.if || null;

                let nfields = grp.fields.map((x: any) => this._excel_cell_to_field(x, policy, data, curstage.form?.layout?.grids));
                for (let field of nfields) {
                    if (!field.ifFunc) field.ifFunc = () => true;
                    if (field.type == 'grid') {
                        for (let sfield of field.items) this._fix_field_validation(valids, sfield);
                    }

                    this._fix_field_validation(valids, field);
                }

                // if the current stage has a group with the same name, lets add these fields to it
                //
                for (let g of curstage.form.groups) {
                    if (g.name === grp.name || g.name == 'grp_' + grp.name) {
                        if (g.name.startsWith('grp_')) g.name = g.name.substring(4);
                        g.fields.splice(g.fields.length, 0, ...nfields);
                        nfields = [];
                        break;
                    }
                }

                if (nfields.length > 0) {
                    groups.push({name: this._dict('grp_'+grp.name || grp.name, policy) || this._fix_label(grp.name), fields: nfields, if: grp.if});
                }
            }

            if (groups.length > 0) {
                stage.module = curstage.module;
                stage.form.groups = groups;
                stage.form.layout = curstage.form.layout;
                stages.push(stage);
            }

        }
        // console.log('stages:', type, JSON.parse(JSON.stringify(stages)));
        return stages;
    }


    __merge_group(stages: any[], group: any, si: number, modname: string) {
        let gname = group.name.toLowerCase();

        for (let stage of stages) {
            if (stage.module?.name != modname) continue;
            for (let i=0; i<stage.form.groups.length; i++) {
                let grp = stage.form.groups[i];
                let name = (grp.name)?grp.name.toLowerCase():grp.name;

                if (name == gname || 'grp_' + name == gname) {
                    grp.fields.push(...group.fields);
                    return;
                }
            }
        }
        stages[si].form.groups.push(group); // add the group to the current stage
    }

    __find_by_name(stages: any, obj: any) {
        for (let i=0; i<stages.length; i++)
            if (stages[i].name == obj.name && stages[i].module?.name == obj.module?.name) return i;
        return -1;
    }

    __add_to_curr_stage(stages: any[], si: number, gidx: number, fidx: number, nstages: any[], modname: string) {
        if (nstages.length == 0) return;

        let nstage = nstages.shift();           // first stage gets merged with cur stage at 'at'
        let ngroup = nstage.form.groups.shift();// first group's field replaces the html field at fidx
        if (ngroup) stages[si].form.groups[gidx].fields.splice(fidx, 1, ...ngroup.fields);

        // rest of stages and groups need to be added to corresponding ones
        for (let grp of nstage.form.groups) {
            this.__merge_group(stages, grp, si, modname);    // find a matching group in stages and merge or add as a new group
        }

        let letfout: any[] = [];
        for (let nstage of nstages) {
            let idx = this.__find_by_name(stages, nstage);
            if (idx == -1) {
                stages.splice(si+1, 0, nstage);
                si ++;
            } else {
                letfout.push({si: idx, stage: nstage});
            }
        }

        for (let lo of letfout) {
            for (let grp of lo.stage.form.groups) {
                this.__merge_group(stages, grp, lo.si, modname);
            }
        }

    }

    __mark_unallowed_readonly(allowed: any, stage: any, policy: Policy) {
        if (!allowed) return;
        let completed = policy?.statuses[stage?.module.name] == 'completed' ?? false;

        for (let g=0; g<stage.form.groups.length; g++) {
            let grp = stage.form.groups[g];
            for (let f of grp.fields) {
                if (f.type === 'button') continue;
                let ed = allowed[f.field_name];
                if (f.type === 'grid') {
                    if (!f.group) {console.log('********* __mark_unallowed_readonly: f.group is empty'); continue;}
                    // console.log('allowed:', f.field_name, ed)
                    if (ed instanceof Array) {
                        for (let fld of f.group.fields) {
                            let name = fld.field_name.endsWith('_tmpl') ? fld.field_name.substring(0, fld.field_name.length-5) : fld.field_name;
                            if (ed.indexOf(name) < 0) fld.readonly = true;
                        }
                        f.grid.disable_addrow = 'Yes';
                        f.grid.disable_upload = 'Yes';
                        f.grid.disable_removeall = 'Yes';
                    } else if (!ed) {   // just the entire array is read-only
                        f.readonly = true;
                        for (let fld of f.group.fields) if (!fld.readonly) fld.readonly = true;
                    } else {
                        for (let fld of f.group.fields) if (!fld.readonly) fld.readonly = completed || false;
                    }
                } else {
                    let parts = f.field_name.split('.').filter(Boolean);
                    if (parts.length > 1) {
                        if (!allowed[parts[0]]?.includes(parts[1])) f.readonly = true;
                        // leave the rest as is
                    } else {
                        if (!ed) f.readonly = true;
                    }
                    // if (parts.length > 1) f.readonly = !allowed[parts[0]]?.includes(parts[1]);
                    // else f.readonly = ed ? false : true;
                }
            }
        }
    }

    async __mark_endorsement_fields_editable(endProduct: IEndProduct, stage: any, policy: Policy) {
        this.__mark_unallowed_readonly(endProduct.end_field_list?.allowed, stage, policy);
    }
    
    async __mark_proposal_fields_editable(product: IProduct, stage: any, policy: Policy) {
        if (insapi.profile?.is_underwriter && product.data.proposal_uw_field_list?.allowed )
            return this.__mark_unallowed_readonly(product.data.proposal_uw_field_list?.allowed, stage, policy);
        // else
        this.__mark_unallowed_readonly(product.data.proposal_field_list?.allowed, stage, policy);
    }

    async __mark_quote_fields_editable(product: IProduct, stage: any, policy: Policy) {
        if (insapi.profile?.is_underwriter && product.data.quote_uw_field_list?.allowed )
            return this.__mark_unallowed_readonly(product.data.quote_uw_field_list?.allowed, stage, policy);
        this.__mark_unallowed_readonly(product.data.quote_field_list?.allowed, stage, policy);
    }

    __fix_addon_heirarchy(tree: any) {
        let ret = false;
        for (let ukey in tree) {
            if (!tree[ukey].parent || tree[ukey].fixed) continue;
            // console.log('uk:', ukey, tree[ukey].parent)
            let parent = tree[tree[ukey].parent];
            if (parent) {
                parent.children.push(tree[ukey]);
                tree[ukey].fixed = true;
                ret = true;
            } else {
                console.log('** addon: parent not found', tree[ukey].parent, 'skipping');
            }
        }
        return ret;
    }


    __group_addons(aodef: any) {
        // console.log('proc-addon:', aodef);
        let aogroups: any = {};
        let tree: any = {};

        aodef.max_params = 0;
        for (let ao of aodef.addons) {
            if (!ao.params || typeof ao.params === 'string') ao.params = JSON.parse(ao.params || '[]');
            for (let param of ao.params) param.orig_name = param.field_name;
            if (aodef.max_params < ao.params.length) aodef.max_params = ao.params.length;
            tree[ao.ukey] = {...ao, children: []};
        }

        // console.log('tree:', aodef.addons)
        if (this.__fix_addon_heirarchy(tree))
            this.__fix_addon_heirarchy(tree);

        for (let ukey in tree) {
            let ao = tree[ukey];
            if (ao.parent) continue;
            if (!aogroups[ao.group_name]) aogroups[ao.group_name] = {group_name: ao.group_name, addons: []};
            aogroups[ao.group_name].addons.push(ao);
        }
        return {aogroups, tree};
    }

    __fix_addon_aarays(product: IProduct, grids: any) {
        if (!product.addons) return;

        let addons = product.addons; // JSON.parse(JSON.stringify(product.addons));
        for (let ao of addons) {
            if (!ao.opted_name) ao.opted_name = 'opted';
            if (grids[ao.array_name]) {
                let {aogroups, tree} = this.__group_addons(ao);
                grids[ao.array_name].addon_def = ao;
                grids[ao.array_name].addon_groups = Object.values(aogroups);
                grids[ao.array_name].addon_map = tree;
                grids[ao.array_name].addon_names = Object.values(tree).reduce((a: any, x: any) => {a[x.name]=x; return a;}, {});
                grids[ao.array_name].fldlist = [];
                grids[ao.array_name].fldmap = {};
                grids[ao.array_name].fldgrp = {fields: grids[ao.array_name].fldlist, layout: {cls: 'addon-formgroup'}};

                // console.log('ag:', ao.array_name, grids[ao.array_name].addon_groups)
                for (let fld of grids[ao.array_name].group.fields) {
                    if (fld.field_name == ao.addon_name + '_tmpl') {
                        // add on name should become a look with fixed list of addon-names
                        fld.type = 'lookup';
                        fld.options = ao.addons.map((x: any) => ({name: x.desc, value: x.name}));
                        // console.log('++ found addon-name field', fld.field_name, fld.options)
                        // console.log('ao:', ao);
                    }
                }
            }
        }

    }

    async _prepare_excel_forms(policy: Policy, workflow: IWorkflow, options?: any) {
        let skip = options?.uw ? ['document'] : ['document', 'nstp', 'qnstp', 'pnstp'];
        let stages = [];
        this._fix_product_output_validations(policy.product);
        if (policy.endProduct) this._fix_product_output_validations(policy.endProduct);

        let wstages = JSON.parse(JSON.stringify(workflow?.wf_script.stages));
        this.nstpType = '';
        for (let stage of wstages) {
            if (!this.nstpType && 
                ['nstp', 'qnstp', 'pnstp'].indexOf(stage?.module.name) >= 0 && 
                policy.statuses[stage?.module.name] != 'completed')
                this.nstpType = stage?.module.name;
                
            if (skip.includes(stage?.module.name) || !stage.form.groups) continue;

            if(!stage.ifFunc) {
                stage.ifFunc= new Function('policy', 'profile', "return " + (stage?.if || "true"));
            }
            if (!stage.ifFunc(policy,insapi.profile||{})) continue;

            if (stage.module_name === 'iagree') {
                continue;
            }

            // stage = _clone(stage);
            stage.form.layout = deepMerge({cls: 'fg-wrapper', grids: workflow?.layout?.grids || 8, maxWidth: 860,
                style: {}}, stage.form.layout);
            stages.push(stage);
        }

        // let grids: {[key: string]: any} = {};
        let stagegrids: {[key: string]: {[key: string]: any}} = {};
        //console.log('stages:', JSON.parse(JSON.stringify(stages)));
        for (let i=0; i<stages.length; i++) {
            let stage = stages[i];
            let {type, data} = this.__get_page_type(policy, stage);
            
            let valids = [];
            if (policy.endorsement && policy.endProduct) {
                valids.push(policy.endProduct.premium_calc_validations || {});
                valids.push(policy.endProduct.premium_calc_output_validations|| {});
            } else if (policy.product) {
                valids.push(policy.product[type+'_validations'] || {});
                valids.push(policy.product[type+'_output_validations'] || {});
            }

            for (let g=0; g<stage.form.groups.length; g++) {
                let group = stage.form.groups[g];
                for (let f=0; f<group.fields.length; f++) {
                    if (Object.keys(group.fields[f]).length <= 0) continue; //to hanle empty groups (without fields)
                    if (group.fields[f].field_name[0] >= '0' && group.fields[f].field_name[0] <= '9'){
                        if (group.fields[f].type != 'button')
                            policy.input_cell_ids[group.fields[f].field_name] = 1; // for no name excels
                    }

                    if (group.fields[f].type == 'grid') {
                        if (!stagegrids[stage.name]) stagegrids[stage.name] = {};
                        stagegrids[stage.name][group.fields[f].field_name] = group.fields[f];
                        // grids[group.fields[f].field_name] = group.fields[f];
                    }
                    if (group.fields[f].type == 'html') {
                        let nstages = this._excel_to_pages_and_groups(policy, stage, valids);
                        this.__add_to_curr_stage(stages, i, g, f, nstages, stage.module?.name);
                        for (let s of nstages) {
                            for (let g of s.form.groups) {
                                for (let ft of g.fields) {
                                    if (ft.type == 'grid') {
                                        if (!stagegrids[stage.name]) stagegrids[stage.name] = {};
                                        stagegrids[stage.name][ft.field_name] = ft;
                                        // grids[ft.field_name] = ft;
                                    }
                                }
                            }
                        }
                    } else if (!group.fields[f].ex) {       // fix validations for workflow fields alone
                        if (group.fields[f].type == 'grid') {
                            group.fields[f] = this._fix_wflow_grid_from_pages(group.fields[f], policy, stage);
                            if (!group.fields[f].items) group.fields[f].items = [];
                            for (let itm of group.fields[f].items) {
                                this._fix_field_validation(valids, itm);
                            }
                        }
                        this._fix_field_validation(valids, group.fields[f]);
                    }
                }
            }
        }

        for (let i=0; i<stages.length; i++) {
            let stage = stages[i];
            for (let g=0; g<stage.form.groups.length; g++) {
                let group = stage.form.groups[g];
                for (let f=0; f<group.fields.length; f++) {
                    __fix_getter_setter(group.fields[f]);
                    
                    if (group.fields[f].type == 'grid') {
                        if (!stagegrids[stage.name]) stagegrids[stage.name] = {};
                        stagegrids[stage.name][group.fields[f].field_name] = group.fields[f];
                        // grids[group.fields[f].field_name] = group.fields[f];
                        for (let f1 of group.fields[f]?.group?.fields||[]) __fix_getter_setter(f1);
                    }
                }
            }
        }


        for (let sname in stagegrids) {
            let grids = stagegrids[sname]
            for (let name in grids) {
                this.__fix_grid_row_funcs(grids[name]);
                if (!(grids[name].grid?.links instanceof Array)) continue;
                for (let link of grids[name].grid.links) {
                    if (grids[link.name]) {
                        link.field = grids[link.name];
                        grids[link.name].link_parent = name;
                        // console.log('++ linked:', link.name, 'with', name);
                    } else {
                        console.log('-- could not find array with name:', link.name);
                    }
                }
            }
            if (policy.product)
                this.__fix_addon_aarays(policy.product, grids);
        }

        // move actions
        let done: any = {};
        for (let i=0; i<stages.length; i++) {
            let stage = stages[i];
            let actions = [];
            let saveactions = [];

            if (stage.module?.name && !done[stage.module?.name]) {
                if (stage.module?.name == 'quote' && policy.product) this.__mark_quote_fields_editable(policy.product, stage, policy);
                if (stage.module?.name == 'proposal' && policy.product) this.__mark_proposal_fields_editable(policy.product, stage, policy);
                if (stage.module?.name == 'eproposal' && policy.endProduct) this.__mark_endorsement_fields_editable(policy.endProduct, stage, policy);
                //done[stage.module?.name] = true; // removed. when quote has more than 1 sheets, fields are not readonly in other sheets.
            }

            for (let g=0; g<stage.form.groups.length; g++) {
                let group = stage.form.groups[g];
                let fields = [];
                for (let i=0; i<group.fields.length; i++) {
                    let fld = group.fields[i];
                    if (!fld.ifFunc) fld.ifFunc = () => true;
                    if (fld.props && typeof fld.props == 'string') fld[i].props = JSON.parse(fld.props);

                    if (fld.field_name == 'add_to_cart') {
                        fld.options = options?.vendor?.cart?.subids || null;
                    }

                    if (fld.type == 'button' && !fld.in_place) actions.push(fld);
                    else if (fld.type == 'benefits') this.benefit = fld;
                    else if (fld.type != 'html') fields.push(fld);

                    if (fld.field_name == 'save_continue' || fld.field_name == 'save' || fld.field_name == 'save_finalize') {
                        saveactions.push(fld);
                    }
                }
                group.fields = fields;
            }
            if (actions.length > 0) {
                // find last stage with same mod name
                let si = -1;
                for (let s=0; s<stages.length; s++) {
                    if (stages[s].module?.name == stage.module.name) {
                        if (!stages[s].no_save && saveactions.length > 0) stages[s].actions = {fields: [{field_name: 'actions', type: 'buttons', buttons: saveactions, span : 12}]};
                        si = s;
                    }
                }
                if (si >= 0)
                    stages[si].actions = {fields: [{field_name: 'actions', type: 'buttons', buttons: actions, span : 12}]};
            }
        }

        // console.log('stages:', JSON.parse(JSON.stringify(stages)));
        return stages;
    }


    __fix_expr(expr: string, name: string) {
        expr = expr.trim()
        let _expr = "try{return (" + expr + ");}catch(e){console.log('row-readonly func: " + (name) + "', e.message); return false;}";
        if (expr[0] == '{') _expr = expr.substring(1, expr.length-1);
        console.log('expr:', name, expr, 'n:', _expr);
        return _expr;
    }

    __fix_grid_row_funcs(field: any) {
        if (!field.grid) field.grid = {render_as: 'grid'};  // default
        if (!field.grid.rrfunc) {
            if (field.grid.row_readonly) {
                field.grid.rrfunc = new Function('data', 'profile', 'policy', 'row', this.__fix_expr(field.grid.row_readonly, field.field_name));
            } else {
                field.grid.rrfunc = () => false;
            }
        }
        if (!field.grid.rhfunc) {
            if (field.grid.row_highlite) {
                field.grid.rhfunc = new Function('data', 'profile', 'policy', 'row', this.__fix_expr(field.grid.row_highlite, field.field_name));
            } else {
                field.grid.rhfunc = () => '';
            }
        }

    }

}