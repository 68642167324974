<div class="div-curved">
    <div class="svg-curved">
        <svg viewBox="0 0 500 150" style="height: 100%; width: 100%;" preserveAspectRatio="none">
          <linearGradient id="gradient"><stop offset="0%" class="curve-stop-0" /><stop offset="100%" class="curve-stop-100"/></linearGradient>
          <path d="M-0.85,137.65 C135.72,178.12 336.06,-110.03 500.84,85.35 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: url(#gradient);"></path>
        </svg>
    </div>
</div>

<div class="filter-container" style="position: relative; z-index: 10; margin-top: -2.5em;" *ngIf="listDef">
    <div>
        <field-group #fgs
            [fieldGroup]="form.groups[0]" 
            [data]="filters"
            [layout]="form.layout"
            (onAction)="onFilterAction($event)"
            (onChange)="onFilterChange($event)">
        </field-group>
    </div>
    <div>
        <button mat-button ibutton (click)="onFilterAction()" *ngIf="product">Apply Filters</button>
        <button mat-button ibutton (click)="onFilterAction()" *ngIf="!product">Select Product</button>
        <!-- <button mat-button ibutton (click)="changeProduct()" *ngIf="product">Change Product</button> -->
    </div>
</div>
<div class="list-container" [style.max-width]="form.layout.width" [style.min-width]="form.layout.width">
    <div class="header">
        <div>
            <h3 *ngIf="product">{{product.product_name}}</h3>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Filter string" #input>
            </mat-form-field>
        </div>
    </div>
    <mat-table [dataSource]="dataSource" matSort >
        <ng-container *ngFor="let col of listDef.columns; let i = index" [matColumnDef]="col.disp">
            <ng-template [ngIf]="col.type==='action'">
            <mat-header-cell *matHeaderCellDef mat-sort-header  style="max-width: 48px;"> {{col.disp | capitalize}} </mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="col" style="max-width: 48px;">
                <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="{row: row}" aria-label="">
                <mat-icon>more_vert</mat-icon>
                </button>                          
            </mat-cell>
            </ng-template>
            <ng-template [ngIf]="col.type!=='action'">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{col.disp | capitalize}}</mat-header-cell>
            <mat-cell *matCellDef="let row" [attr.data-label]="col.disp" (click)="rowClicked(row)">
                <div style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" [title]="row[col.disp] | generic2: col.title_format ">{{row[col.name] | generic2: col.pipe :col.pipe_args}}</div>
            </mat-cell>
            </ng-template>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columns;"></mat-row>
        <mat-menu #actionMenu>
            <ng-template matMenuContent let-row="row">
                <button mat-menu-item *ngFor="let act of row.actions" (click)="menuClicked(act, row)">
                    <mat-icon>{{act.icon}}</mat-icon>{{act.name}}
                </button>
            </ng-template>
        </mat-menu>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>