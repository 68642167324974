import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

    transform(str: string, args?: any): any {
        if (!str || typeof str !== 'string') return str;
        return str.replace(/_/g, ' ').split(' ').map(x => (x[0]||'').toUpperCase() + x.slice(1)).join(' ');
    }
}