import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-busy-spinner',
    template: `Processing ...`,
    styles: []
})
export class BusySpinnerComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<BusySpinnerComponent>) { }

    ngOnInit(): void {
    }

}
