import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { insapi, Policy } from 'insapi';
import { IField } from './../../form.interface';

@Component({
    selector: 'lib-goal-seek',
    templateUrl: './goal-seek.component.html',
    styleUrls: ['./goal-seek.component.css']
})
export class GoalSeekComponent implements OnInit {

    @Output() onAction = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    data!: any;
    control!: FormControl;

    policy!: Policy;
    paramValue: string = '';
    constructor() { }

    ngOnInit(): void {
    }

    async seek() {
        if (this.field.input && !this.paramValue) return;
        if (!this.field.input && this.field.paramvalue) {
            let ifFunc = this._evalFunc(this.field.paramvalue, null);
            this.paramValue = ifFunc(this.data, insapi.profile||{}, this.policy||{});
            console.log("this.paramValue",this.paramValue);
        }

        if (!this.field.goal?.targets || this.field.goal.targets.length<=0) return;
        if (isNaN(+this.paramValue)) return;
        this.field.goal.targets[0].value = +this.paramValue;
        this.onAction.emit(this.field);
    }

    _evalFunc(expr: string, field: IField | null) {
        expr = expr.replace(/this\.mod\.data/g, 'data');
        expr = expr.replace(/this\.mod/g, 'policy');
        expr = expr.replace(/this\.data/g, 'data');
        let _expr = "try{return ("+expr+");}catch(e){/*console.log('if func: " + (field?field.field_name:'') + "', e.message);*/ return false;}";
        // console.log('expr: ', _expr);
        return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
    }

}
