import { Component, OnInit, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { IField } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { insapi, Policy, IPolicy } from 'insapi';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { Subscription } from 'rxjs';
import { FieldGroupComponent } from '../../field-group/field-group.component';

@Component({
    selector: 'lib-coinsurance',
    templateUrl: './coinsurance.component.html',
    styleUrls: ['./coinsurance.component.scss']
})
export class CoinsuranceComponent implements OnInit {
    @ViewChildren('fgs') fgs!: QueryList<FieldGroupComponent>;
    data!: any;
    field!: IField;
    policy!: Policy;
    readonly: boolean = false;
    control!: FormControl;
    @Output() onChange = new EventEmitter();

    types: {name: string, value: string}[] = [];

    coinsgroups: any[] = [];
    coinsurers: any[] = [];
    names: any[] = [];
    insfield: IField | null = null;
    typefield: IField | null = null;
    fmap: {[key: string]: any} = {};
    edit: {index: number, data: any} = {index: -2, data: {}};
    errors: string[] = [];
    total: number = 0;

    psubscription: Subscription | undefined = undefined;
    constructor(private preferences: PreferencesService) { }

    ngOnInit(): void {
        this.__init();
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        this._policy_changed();

    }
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }


    async __init() {
        this.types = this.preferences.vendor?.coinsurance?.types;
        this.coinsgroups = JSON.parse(JSON.stringify(this.preferences.vendor?.coinsurance?.groups));
        if (!this.data.coinsurance) {
            this.data.coinsurance = {type: 'Direct'};
            this.control.setValue(this.data[this.field.field_name]);
        }
        if (!this.data.coinsurers) this.data.coinsurers = [];

        let names: any = [];
        for (let grp of this.coinsgroups) {
            for (let fld of grp.fields) {
                this.fmap[fld.field_name] = fld;
                names.push({name: fld.field_name, disp: fld.label});
                if (fld.formatter) fld.pipe = fld.formatter.split(':');
                if (fld.field_name == 'insurer') this.insfield = fld;
                if (fld.field_name == 'type') {
                    this.typefield = fld;
                    fld.options = this.preferences.vendor?.coinsurance?.types.filter((x: any) => x.value != 'Direct');
                }
            }
        }
        this.coinsurers = await insapi.xget('/api/v1/rater/lookup/insurers') || [];
        if (this.insfield) {
            this.insfield.options = this.coinsurers.map((x: any) => ({name: x.insurer, value: x.insurer}));
            // this.fgs.forEach(x => x._updateDependants());
        }
        console.log('coinsurers:', this.coinsurers);
        this.names = names;
        this.cancel();
    }
    
    _policy_changed() {
        if (!this.data.coinsurance) this.data.coinsurance = {type: 'Direct'};
        if (!this.data.coinsurers) this.data.coinsurers = [];
    }

    typeChanged() {
        let self = this.coinsurers.find((x: any) => x.insurer == this.preferences.vendor?.coinsurance?.self);
        if (!self) return;

        let added = this.data.coinsurers.find((x: any) => x.insurer == this.preferences.vendor?.coinsurance?.self);
        if (!added) this.data.coinsurers.push({type: this.data.coinsurance.type, ...self, share: 0});
        else added.type = this.data.coinsurance.type;

        // if leader, mark everybody else as member
        if (this.data.coinsurance.type == 'Leader') {
            for (let coin of this.data.coinsurers)
                if (coin.insurer != self.insurer) coin.type = 'Member';
        }
    }

    __validate() {
        // ensure no insurer is repeated twice
        let errors = [];
        let inses: any = {};
        let total: number = 0;
        let leaders: string[] = [];
        for (let ins of this.data.coinsurers) {
            let key = ins.insurer; // ins.insurer+'~'+ins.branch;
            if (inses[key]) errors.push("Insurer " + ins.insurer + " added more than once");
            inses[key] = true;
            if (ins.type=='Leader') leaders.push(ins.insurer);
            if (+ins.share > 0) total += +ins.share;
            ins.sum_insured = (this.data.sum_insured||0) * (ins.share/100);
            ins.premium_value = (this.data.premium_value||0) * (ins.share/100);
            if (ins.insurer == this.preferences.vendor?.coinsurance?.self) {
                if (ins.type != this.data.coinsurance.type) errors.push("Self " + ins.insurer + " should be " + this.data.coinsurance.type);
            }
        }
        if (leaders.length > 1) errors.push("More than one leader found");
        if (leaders.length == 0) errors.push("Please choose a leader");
        if (total < 100) errors.push("Balance to be allocated " + (100-total));
        if (total > 100) errors.push("Over allocated " + (total-100));
        this.total = total;
        this.errors = errors;
        console.log('__validate: errors:', total, this.errors);
    }

    async editCoin(idx: number) {
        if (idx >= this.data.coinsurers.length) return;
        if (this.insfield) this.insfield.readonly = true;
        this.edit.index = idx;
        this.edit.data = {...this.data.coinsurers[idx]};
        this.fgs.forEach(x => x.form.patchValue(this.edit.data));
    }

    async removeCoin() {
        if (this.edit.index >= this.data.coinsurers.length) return;
        this.data.coinsurers.splice(this.edit.index, 1);
        this.fgs.forEach(x => x.form.patchValue(this.edit.data));
        this.onChange.emit(this.field);
        this.edit.index = -2;
        this.__validate();
    }

    async changed(ev: any) {
        if (ev && ev.field_name === 'insurer') {
            if (this.edit.data.insurer) {
                let ins = this.coinsurers.find((x: any) => x.insurer == this.edit.data.insurer);
                if (ins) {
                    this.edit.data.address = ins.address;
                    this.edit.data.branch = ins.branch;
                    this.edit.data.insurer_code = ins.insurer_code;
                    this.fgs.forEach(x => x.form.patchValue(this.edit.data));
                }
            }
        }
        console.log('changed:', ev, this.edit)
    }

    async update() {
        if (!this.edit.data.insurer) {
            this.errors = ['Insurer cannot be empty'];
            return;
        }
        if (!this.edit.data.type) {
            this.errors = ['Type cannot be empty'];
        }

        if (this.edit.index >= 0 && this.edit.index < this.data.coinsurers.length)
            this.data.coinsurers[this.edit.index] = {...this.edit.data};
        else {
            let ci = this.data.coinsurers.find((x: any) => x.insurer == this.edit.data.insurer && x.branch == this.edit.data.branch);
            if (ci) {
                ci.address = this.edit.data.address;
                ci.share = this.edit.data.share;
            } else {
                this.data.coinsurers.push({...this.edit.data});
            }
        }
        this.__validate();
        this.cancel();
        this.onChange.emit(this.field);
    }

    async addNew() {
        this.edit.index = -1;
        let leader = this.data.coinsurers.find((x: any) => x.type == 'Leader');
        this.edit.data = {type: leader ? 'Member' : 'Leader', share: 0};
        if (this.typefield) {
            if (leader) this.typefield.options = this.preferences.vendor?.coinsurance?.types.filter((x: any) => x.value == 'Member');
            else this.typefield.options = this.preferences.vendor?.coinsurance?.types.filter((x: any) => x.value != 'Direct');
        }
        this.fgs.forEach(x => x.form.patchValue(this.edit.data));
    }

    cancel() {
        if (this.insfield) this.insfield.readonly = false;
        this.edit.index = -2;
        let leader = this.data.coinsurers.find((x: any) => x.type == 'Leader');
        this.edit.data = {type: leader ? 'Member' : 'Leader'};
    }

}
