import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';

@Component({
    selector: 'i-button',
    template: `
    <div style="color: var(--button-color-primary);" [formGroup]="group">
        <button type="submit" ibutton cls="flat" mat-button (click)="onAction.emit(field)" [disabled]="readonly"
            [attr.data-iid]="field.field_name">{{field.label}}</button>
    </div>
  `,
    styles: [
    ]
})
export class ButtonComponent implements OnInit {

    @Output() onAction = new EventEmitter<any>();
    field!: IField;
    group!: FormGroup;
    readonly!: boolean;
    constructor() { }

    ngOnInit(): void {
    }
}
