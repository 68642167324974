<div class="page-container-s">
    <role-selector [(ngModel)]="roleName" (optionSelected)="roleChanged()"></role-selector>
</div>
<div class="page-container-m"  *ngIf="roleName">
    <mat-tab-group dynamicHeight mat-align-tabs="center">
        <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">face</mat-icon>
              Users
            </ng-template>
            <div class="user-list grid-3">
                <div *ngFor="let user of roleUsers">
                    <div><mat-icon class="tab-icon-delete" (click)="removeUser(user)">close</mat-icon></div>
                    <div>{{user}}</div>
                </div>
                <div *ngIf="roleUsers.length==0">No user has been mapped yet!</div>
            </div>
            <div class="add-user-list">
                <div>
                    <user-selector [(ngModel)]="userName" (optionSelected)="userChanged()"></user-selector>
                </div>
                <div>
                    <button mat-button ibutton (click)="addUser()">Add</button>
                </div>
            </div>
        </mat-tab>
        
        <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">manage_accounts</mat-icon>
              Privileges
            </ng-template>
            <div class="sub-caption" style="color: #6758c9">View</div>
            <div class="user-list grid-3"  style="color: #6758c9">
                <div *ngFor="let p of rolePrivileges['GET']">
                    <div><mat-icon class="tab-icon-delete" (click)="removePriv(p.priv_name)">close</mat-icon></div>
                    <div>{{p.disp_name}}</div>
                </div>
                <div *ngIf="rolePrivileges['GET'].length==0">No privilege has been mapped yet!</div>
            </div>
            <div class="sub-caption" style="color: #c95867">Modify</div>
            <div class="user-list grid-3">
                <div *ngFor="let p of rolePrivileges['POST']" style="color: #c95867">
                    <div><mat-icon class="tab-icon-delete" (click)="removePriv(p.priv_name)">close</mat-icon></div>
                    <div>{{p.disp_name}}</div>
                </div>
                <div *ngIf="rolePrivileges['POST'].length==0">No privilege has been mapped yet!</div>
            </div>
            <div class="sub-caption">Remove</div>
            <div class="user-list grid-3">
                <div *ngFor="let p of rolePrivileges['DELETE']">
                    <div><mat-icon class="tab-icon-delete" (click)="removePriv(p.priv_name)">close</mat-icon></div>
                    <div>{{p.disp_name}}</div>
                </div>
                <div *ngIf="rolePrivileges['DELETE'].length==0">No privilege has been mapped yet!</div>
            </div>

            <div class="add-user-list">
                <div>
                    <option-selector [(ngModel)]="privName" [options]="privNames" (optionSelected)="privChanged()"></option-selector>
                </div>
                <div>
                    <button mat-button ibutton (click)="addPrivilege()" [disabled]='!privName || !privileges[privName]'>Add</button>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</div>
<br>