import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { Policy } from "insapi";

export function ssValidator(name: string, policy: Policy): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!policy.errMap?.[name] || (!control?.dirty && !control?.touched)) return null;
        return {ssValidator: {value: control.value, msg: policy.errMap[name].msg}};
        // if (!control?.dirty || !control?.touched) return null;
        // console.log('ss:', name, control?.touched, control?.dirty);
        // if (control && policy && policy.errMap) {
        //     if (policy.errMap[name]) console.log('ss-validate:', name, policy.errMap[name]);
        //     return policy.errMap[name] ? { ssValidator: { value: control.value, msg: policy.errMap[name].msg } } : null;
        // }
        // return null;
    };
}

export function numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control?.dirty || !control?.touched) return null;
        return (isNaN(control.value)) ? { ssValidator: { value: control.value, msg: "valid number expected" } } : null;
    }
}