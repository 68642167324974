<div *ngIf="policy && policy.policy && !policy.policy.policy_no">
    Policy not issued yet!
</div>
<div *ngIf="policy && policy.policy && policy.policy.policy_no && policy.product" style="padding: 0.5em 1em 2em 1em;">
    <div style="font-size: 1.3em; text-align: center; padding: 0.5em 0.5em;">
        {{policy.product.product_name}}
    </div>
    <div class="grid-2" style="gap: 1em; padding-top: 1em;">
        <div>
            <div style="color: #888;">Policy NO:</div><div style="font-size: 1.2em;">{{policy.policy.policy_no}}</div>
        </div>
        <div>
            <div style="color: #888;">Validity</div>
            <div>{{startDate}} till {{endDate}}</div>
        </div>
    </div>
    <div class="grid-2" style="gap: 1em; padding-top: 1em;">
        <div>
            <div style="color: #888;">Policy holder</div><div style="font-size: 1.2em;">{{holder||'-'}}</div>
        </div>
        <div>
            <div style="color: #888;">Issued on</div>
            <div>{{issueDate}}</div>
        </div>
    </div>


</div>
