import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';
import { IEndorsement, IPolicy, Policy, insapi } from 'insapi';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-file',
    template: `
    <div style="padding-left: 1em;">
    <div *ngIf="!ready">
        Save the quote to upload the document
    </div>
    <div *ngIf="detail && ready">
        <div style="font-size: .9em; color: var(--field-fg-label)">{{field.label}}</div>
        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{detail.document_description}}</div>
        <div>{{field.document_type}} {{detail.data.name}}</div>
        <div style="font-size: 0.8em"><span style="text-decoration: underline; cursor: pointer;" (click)="removeDocument()" >Remove</span></div>
    </div>
    <div *ngIf="!detail && ready">
        <div class="file-upload" *ngIf="!readonly" [title]="field.title">
            <div>{{field.label}}</div>
            <div>{{field.document_desc}}</div>
            <input type="file" [accept]="extns" (change)="onUpload($event)" [attr.data-iid]="field.field_name"/>
        </div>
    </div>
    </div>
`,
    styles: [``]
})
export class FileComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    readonly: Boolean = false;
    policy!: Policy;
    @Output() onAction = new EventEmitter<any>();

    ready: boolean = false;
    detail: any = null;
    psubscription: Subscription | undefined = undefined;
    extns: string = '';
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe((policy) => {
            this.__update_status(policy);
        });
        
        if (!this.extns && this.field?.props?.extns)
            this.extns = this.field?.props?.extns;
    }
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    __update_status(policy: IPolicy | IEndorsement | null) {
        if (!policy) return;
        this.ready = true; // policy.document_id ? true : false;
        this.detail = null;
        for (let det of policy.document?.details||[]) {
            if (det.document_type === this.field.document_type) {
                this.detail = det;
                det.document_desc = det.document_desc || det.document_description || this.field.document_desc;
                if (det.document_extns) this.extns = det.document_extns;
                break;
            }
        }

        if (!this.detail || this.detail.document_desc) return;
        for (let doc of policy?.policy.documents||[]) {
            if (this.detail.document_type == doc.document_type) {
                this.detail.document_desc = doc.document_desc || doc.document_description;
                break;
            }
        }
        
    }

    async removeDocument() {
        if (this.detail) {
            await this.policy?.__deleteDocument(this.detail.document_details_id);
        }
    }
    onUpload = async (ev: any) => {
        if (this.field?.props?.maximum_size) {
            let allowedsize = +this.field?.props?.maximum_size;
            console.log("files", this.field, ev.target.files[0]?.size, allowedsize);
            if (ev.target.files[0]?.size > allowedsize*1024*1024) {
                insapi.showMessage('File should be smaller than '+allowedsize+' MB', 1);
                if (ev && ev.target && ev.target.value) ev.target.value = null;
                ev.target.files = null; 
                return;
            }
        }
        await this.policy?.__uploadDocument(this.field.document_type, this.field.document_desc, ev.target.files[0]);
        this.__update_status(this.policy.policy);
        if (ev && ev.target && ev.target.value) ev.target.value = null;
        ev.target.files = null;
    }
}
