import { Component, OnInit, EventEmitter } from '@angular/core';
import { IField } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { IEndorsement, IPolicy, Policy } from 'insapi';

@Component({
    selector: 'app-policy-meta-data',
    templateUrl: './policy-meta-data.component.html',
    styleUrls: ['./policy-meta-data.component.scss']
})
export class PolicyMetaDataComponent implements OnInit {

    onAction = new EventEmitter<any>();
    field!: IField;
    group!: FormGroup;
    policy: any = {};

    name: string = '';
    value: string = '';
    add: boolean = false;
    psubscription: any;
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe((policy: IPolicy | IEndorsement | null) => {
            this.policy = policy;
        });
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }
    
    addMeta() {
        if (!this.name) return;
        this.onAction.emit({...this.field, meta: {[this.name]: this.value}});
    }
}
