import { Component, OnInit, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { insapi, Policy, IPolicy, is_valid_date } from 'insapi';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { IField } from '../../form.interface';
import { FieldGroupComponent } from '../../field-group/field-group.component';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
    selector: 'customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
    @ViewChildren('fgs') fgs!: QueryList<FieldGroupComponent>;
    data!: any;
    field: IField = {field_name: '', type: ''};
    policy!: Policy;
    readonly: boolean = false;
    control!: FormControl;
    @Output() onChange = new EventEmitter();

    fgroups: any = [];

    rex: {[key: string]: RegExp[]} = {};
    timer: any = null;
    
    id: string = '';
    label: string = "Customer Unique ID";
    alllabel: string = '';
    idtype: string = '';

    params: {name: string, label: string, visible: boolean, value: any, type: string, options?: {name: string, value: string}[] }[] = [];
    fields: {field_name: string, label: string, type: string, mandatory?: boolean} [] = [];
    fmap: {[key: string]: any} = {};
    // names: string[] = [];
    // labels: string[] = [];

    matched: {[key: string]: string}[] = [];
    customer: {[key: string]: string} = {};
    psubscription: Subscription | undefined = undefined;
    validId: boolean = false;

    custdef: any = {};
    // new changes 
    extcusturl:any;
    // end 
    constructor(private preferences: PreferencesService) { }

    ngOnInit(): void {
        const customer_object = JSON.parse(JSON.stringify(this.preferences.vendor?.customer || {}));
        this.extcusturl = customer_object.extcustapi.url;
        console.log("extrnl api",this.extcusturl)
        this.__init();
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        this._policy_changed();
        // if (this.policy) {
        //     this.psubscription = this.policy.policySubject.subscribe((policy: IPolicy|null) => {
        //         // if (!policy) return;
        //         this._policy_changed();
        //     });
        //     this._policy_changed();
        // }
    }
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    __init() {
        // key type to parameters map
        this.__fix_field_groups();
        this.__make_field_names();
        let params: any = {};
        let labels = [];
        this.custdef = JSON.parse(JSON.stringify(this.preferences.vendor?.customer || {}));
        // merge LoB specific overrides if found
        let lob = this.custdef.lob?.[this.policy.product?.product_group_name];
        if (lob?.groups) this.custdef.groups = lob?.groups;
        if (lob?.idtype) this.custdef.groups = lob?.idtype;

        for (let key in this.custdef.idtype||{}) {
            labels.push(key.toUpperCase());
            let idtype = this.custdef.idtype[key];
            for (let name of idtype.lookup) {
                let type = this.fmap[name]?.type || 'string';
                params[name] = {name: name, label: name, visible: false, value: "", type: type, options: this.fmap[name]?.options||[]};
            }
        }
        this.params = Object.values(params);
        // console.log('params:', this.params, 'labels:', labels);

        // id field label
        this.label = labels.join('/');
        this.alllabel = this.label;

    }

    __fix_field_groups() {
        if (this.fgroups.length != 0) return; // already fixed
        this.fgroups = JSON.parse(JSON.stringify(this.preferences.vendor?.customer?.groups));   // make a copy
        // fix layout issues with field groups
        for (let grp of this.fgroups) {
            if (!grp.layout) grp.layout = {};
            if (!grp.layout.caption) grp.layout.caption = {style: {"font-size": "1.1em", "padding-left": "1em"}, description: {}};
        }
    }

    __make_field_names() {
        if (this.fields.length !== 0) return;
        let fields = [];
        for (let grp of this.fgroups) {
            for (let fld of grp.fields) {
                if (fld.default) this.customer[fld.field_name] = fld.default;
                if (fld.mandatory) {
                    fld.validate = 'mandatory';
                    fld.label = this.preferences.vendor?.label?.prefix?.mandatory + fld.label;
                }
                fields.push(fld);
            }

        }
        this.fields = fields;
        this.fmap = this.fields.reduce((a: any, x: any) => {a[x.field_name]=x; return a;}, {});

        for (let param of this.params) param.label = this.getFieldLabel(param.name);
        this.label = this.getFieldLabel('id');
        if (this.label == 'id') this.label = this.alllabel;
    }

    _policy_changed() {
        
        if (!this.data[this.field.field_name]) {
            this.data[this.field.field_name] = this.fields.reduce((a: any, x: any) => {a[x.field_name] = ""; return a;}, {});
            this.control.setValue(this.data[this.field.field_name]);
        }

        for (let param of this.params) {
            if (param.type == 'date' && this.data[this.field.field_name][param.name] ) {
                try{this.data[this.field.field_name][param.name+'_dt'] = moment(this.data[this.field.field_name][param.name]).format(this.preferences.vendor?.dateFormat || 'YYYY-MM-DD');}catch(e){}
            }
        }
    }


    changed(ev: any) {this.fgs.forEach(x => x._updateDependants());}

    _field_type(name: string) {
        let fields = this.preferences.vendor?.customer?.group.fields || [];
        for (let fld of fields) {
            if (name == fld.field_name) return fld.type;
        }
        return 'string';
    }


    __make_regex(name: string, res: string[]) {
        if (this.rex[name]) return this.rex[name];
        this.rex[name] = [];
        for (let re of res) {
            this.rex[name].push(new RegExp(re));
        }
        return this.rex[name];
    }

    _check_id_type() {
        if (!this.id) return;
        this.idtype = '';
        for (let key in this.preferences.vendor?.customer?.idtype||{}) {
            let idtype = this.preferences.vendor?.customer.idtype[key];
            this.__make_regex(key, idtype.validre);
            for (let re of this.rex[key]||[]) {
                if (this.id.match(re)) {
                    this.idtype = key;
                    for (let param of this.params) {
                        param.visible = idtype.lookup?.includes(param.name);
                    }
                    this.label = key.toUpperCase();
                    return key;
                }
            }
        }
        for (let param of this.params) param.visible = false;
        this.label = this.alllabel;
        console.log('no match found:', this.id);
        return '';
    }

    onKeyup(ev: any) {
        this.validId = false;
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {this._check_id_type()}, 300);
    }

    async fetchCustomer() {
        if (!this.idtype) {
            return insapi.showMessage('Input does not match any recognized ID', 0);
        }
        let params = {[this.idtype]: this.id};
        this.customer[this.idtype] = this.id;

        for (let param of this.params) {
            if (param.visible) {
                if (param.value instanceof Date) params[param.name] = moment(param.value).format('YYYY-MM-DD');
                else params[param.name] = param.value;
            }
            if (!param.value && param.visible) {
                return insapi.showMessage('Provide valid value for ' + param.name, 0);
            }
            this.customer[param.name] = param.value;
        }
        if (this.extcusturl !== '') {
            let ret = await insapi.__xget(this.extcusturl+'?mobile_no='+params.mobile_no+'&first_name='+params.first_name);
            this.matched = ret || [];
        }
        else{
        let ret = await insapi.xget('/api/v1/customer/lookup?' + (new URLSearchParams(params)).toString());
        this.matched = ret || [];
        }
        
        for (let param of this.params) {
            if (param.type == 'date') {
                console.log('fixing: ', param.name)
                for (let match of this.matched) {
                    try {match[param.name+'_dt'] = moment(match[param.name]).format(this.preferences.vendor?.dateFormat || 'YYYY-MM-DD');} catch (e) {}
                    console.log('fixied: ', match[param.name], match[param.name+'_dt'])
                }
            }
        }

        this.validId = true;
        console.log('matched:', this.matched);
        console.log('fetch:', this.field.field_name, this.data[this.field.field_name]);
    }

    _reset_inputs() {
        this.label = this.alllabel;
        this.id = '';
        this.idtype = '';
        this.validId = false;
        for (let param of this.params) {param.visible = false; param.value = '';}
        this.fgs.forEach(x => {x.form.reset()});
    }

    async addNew(update: boolean = false) {
        // let data = this.data[this.field.field_name] || {};
        let data = this.customer || {};
        data[this.idtype] = this.id;
        if (!update) delete data.customerx_id;
        for (let param of this.params) { //Conversion for idTypes
            if (param.type == 'date') data[param.name] = is_valid_date(data[param.name], "YYYY-MM-DD") || '';
        }

        for (let grp of this.fgroups) { //Conversion for fields
            for (let fld of grp.fields) {
                if (fld.type === 'date') data[fld.field_name] = is_valid_date(data[fld.field_name], "YYYY-MM-DD") || '';
            }
        }

        // let ret = await insapi.xpost("/api/v1/customer/customerx", data);
        let ret = await insapi.__xpost("/api/v1/customer/customerx", data);
        ret.data.customerx_id = ret.customerx_id;
        await this.selectCustomer(ret.data);
        this._reset_inputs();
    }

    async selectCustomer(row: any) {
        if (!row || !row.customerx_id) return;
        if (!this.data[this.field.field_name]) this.data[this.field.field_name] = {};
        this.data[this.field.field_name].customerx_id = row.customerx_id;
        this.customer.customerx_id = row.customerx_id;
        for (let fld of this.fields) {
            this.data[this.field.field_name][fld.field_name] = row[fld.field_name];
            this.customer[fld.field_name] = row[fld.field_name];
        }
        this.__send_change_notification();

        // no idea why this is needed
        this.fgs.forEach(x => x.form.patchValue(this.customer));
    }

    __send_change_notification() {
        let obj = this.data[this.field.field_name];
        let changed = [];//Object.keys(obj).map(x => ({field_name: 'customer.' + x, value: obj[x]}));
        changed.push({field_name: this.field.field_name, value: obj});
        this.onChange.emit(changed);
    }

    getFieldLabel(field_name: string): string {
        let field = this.fields.find((ele: any) => ele.field_name.toLowerCase() === field_name.toLowerCase());
        return (field && field.label) ?? field_name;
    }

}
