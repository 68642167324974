import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'gen-message',
	template: `
	<div class="message">
		<div style="padding: 0em 1.2em 1.1em 0em; text-align: left; font-size: 1.6em; letter-spacing: .07em;">{{data.caption}}</div>
		<div>{{data.description}}</div>
		<div>
		</div>
	</div>
	<div class="message-footer">
        <div *ngFor="let act of data.actions" class="message-btn">
            <button mat-button ibutton (click)="onAction(act)">{{act.disp}}</button>
        </div>
	</div>

	`,
	styles: [
        `.message {}`,
		`.message-btn {display: inline-block; margin-right: 1em;}`, 
        `.message-footer {padding: 2em 0em 0em 0em;}`,
		`button:focus {outline:0; letter-spacing: normal; transition: .5s}`
	]
})
export class GenericMessageComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<GenericMessageComponent>) {
            
    }
    async onAction(action: any) {
        this.dialogRef.close(action);
    }
}
