<div *ngIf="policy && data[field.field_name]" class="company">
    <ng-template [ngIf]="!readonly">
        <div class="caption">{{entityName}} <span *ngIf="flags.edit && !flags.search" (click)="flags.search=true"><mat-icon>search</mat-icon></span> </div>
        <div *ngIf="flags.search" class="comp-search">
            <div>
                <mat-form-field style="max-width: 100%;">
                    <mat-label>Search</mat-label>
                    <input #fminput type="text" matInput  placeholder="field.label" [(ngModel)]="compName" [matAutocomplete]="autofmt" (keyup)=onKeydown($event)>
                    <mat-icon matSuffix>search</mat-icon>
                    <mat-autocomplete #autofmt="matAutocomplete" (optionSelected)="companySelected($event)" [attr.data-iid]="'ac-'+field.field_name">
                        <mat-option *ngFor="let comp of filteredCompanies" [value]="comp.name">{{comp.name}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div *ngIf="flags.canSave">
                <button mat-button (click)="addNew()">Add new {{entityName}}</button>
            </div>
        </div>
    </ng-template>
    <ng-template [ngIf]="data[field.field_name]?.name">
        <div *ngIf="!flags.edit" style="padding-left: 1em;">
            <div (click)="editMode()" style="cursor: pointer; font-size: 1.2em;">{{data[field.field_name]?.name}}  <mat-icon style="font-size: .9em;" *ngIf="flags.canEdit">edit</mat-icon></div>
            <div>{{data[field.field_name]?.address}}</div>
            <div>{{data[field.field_name]?.state}}, {{data[field.field_name]?.city}}</div>
            <div>{{data[field.field_name]?.email}}</div>
        </div>
        <div *ngIf="flags.edit" style="padding-left: 1em;">
            <ng-container *ngTemplateOutlet="defedit; context: {}" ></ng-container>
        </div>
    </ng-template>
</div>

<ng-template #defedit>
    <div class="grid-4 comp-form">
        <div>
            <mat-form-field style="width: 100%;">
                <mat-label>{{entityName}} Name</mat-label>
                <input type="text" matInput  [placeholder]="entityName + ' Name'" [readonly]="!flags.edit"
                    [(ngModel)]="data[field.field_name].name">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field style="width: 100%;">
                <mat-label>ID</mat-label>
                <input type="text" matInput  placeholder="ID" [readonly]="!flags.edit"
                    [(ngModel)]="data[field.field_name].company_id">
            </mat-form-field>
        </div>
        <ng-template ngFor let-p let-i="index" [ngForOf]="fields" >
            <div *ngIf="p!='company_id' && p!='name'">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{p|titlecase}}</mat-label>
                    <input type="text" matInput  [placeholder]="p" [(ngModel)]="data[field.field_name][p]" (change)="paramChanged(p)">
                </mat-form-field>
            </div>
        </ng-template>
    </div>
    <div *ngIf="flags.canSave">
        <button mat-flat-button (click)="addUpdate()">Add/Update {{entityName}}</button> 
        <button mat-flat-button (click)="closeEdit()">Close</button>
        <button mat-flat-button (click)="addNew()">Clear</button>
    </div>
</ng-template>
