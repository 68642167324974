import { Pipe, PipeTransform } from '@angular/core';
import { numberFormat } from 'insapi';

const tens = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen ']
const tees = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
const descs = [' ', 'hundred ', 'thousand ', 'lac ', 'crore '];

function words_asian(n: number) {
    let num = '0000000000000' + Math.round(+n);
    num = num.substring(num.length - 12);
    let str = '';
    let v = num[10] + num[11];
    if (+v > 0) str = (tens[+v] || (tees[+num[10]] + ' ' + tens[+num[11]])) + descs[0] + str;

    v = num[9];
    if (+v > 0) str = (tens[+v]) + descs[1] + (str?'and ':'') + str;

    v = num[7] + num[8];
    if (+v > 0) str = (tens[+v] || (tees[+num[7]] + ' ' + tens[+num[8]])) + descs[2] + str;

    v = num[5] + num[6];
    if (+v > 0) str = (tens[+v] || (tees[+num[5]] + ' ' + tens[+num[6]])) + descs[3] + str;

    v = num[3] + num[4];
    if (+v > 0) str = (tens[+v] || (tees[+num[3]] + ' ' + tens[+num[4]])) + descs[4] + str;

    v = num[2];
    if (+v > 0) str = (tens[+v]) + descs[1] + (str?'and ':'') + str;

    v = num[0] + num[1];
    if (+v > 0) str = (tens[+v] || (tees[+num[0]] + ' ' + tens[+num[1]])) + descs[2] + str;


    return str.trim();
}

const descs2 = [' ', 'hundred ', 'thousand ', 'million ', 'billion '];
function words_american(n: number) {
    let num = '0000000000000' + Math.round(+n);
    num = num.substring(num.length - 12);
    let str = '';
    let v = num[10] + num[11];
    if (+v > 0) str = (tens[+v] || (tees[+num[10]] + ' ' + tens[+num[11]])) + descs2[0] + str;
    
    v = num[9];
    if (+v > 0) str = (tens[+v]) + descs2[1] + (str?'and ':'') + str;

    v = num[7] + num[8];
    if (+v > 0) str = (tens[+v] || (tees[+num[7]] + ' ' + tens[+num[8]])) + descs2[2] + str;

    v = num[6];
    if (+v > 0) str = (tens[+v]) + descs2[1] + (str?'and ':'') + str;

    v = num[4] + num[5];
    if (+v > 0) str = (tens[+v] || (tees[+num[4]] + ' ' + tens[+num[5]])) + descs2[3] + str;

    v = num[3];
    if (+v > 0) str = (tens[+v]) + descs2[1] + (str?'and ':'') + str;

    v = num[1] + num[2];
    if (+v > 0) str = (tens[+v] || (tees[+num[1]] + ' ' + tens[+num[2]])) + descs2[4] + str;

    v = num[0];
    if (+v > 0) str = (tens[+v]) + descs2[1] + (str?'and ':'') + str;

    return str.trim();
}

@Pipe({
    name: 'numberword'
})
export class NumberWordPipe implements PipeTransform {
    transform(number: number, args?: any): any {
        if (+number == 0 || isNaN(number)) return 'zero';
        if (args?.[0] == 'american') return words_american(number);
        return words_asian(number);
    }
}