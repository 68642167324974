import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { excelDateToDate } from 'insapi';
import moment from 'moment';
import { environment } from './../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InsDatePickerAdapter extends NativeDateAdapter {

    __apply_mask(val: string, fmt: string) {
        if (!val) return null;
        let dt = moment();
        let ret: string = '';
        for (let i=0; i<fmt.length; i++) {
            if (fmt[i] === 'D' || fmt[i] === 'M' || fmt[i] === 'Y') ret += val[i] ?? fmt[i];
            else ret += fmt[i];
        }
        ret = ret.replace('MM', dt.format('MM'));
        ret = ret.replace('YYYY', dt.format('YYYY'));
        ret = ret.replace('DD', dt.format('DD'));
        dt = moment(ret, fmt, true);
        if (dt.isValid()) return dt.toDate();
        console.log('date: appy-mask: invalid', val);
        return this.invalid();
    }

    dateFormat: string = environment.vendor.dateFormat || 'DD-MM-YYYY';
    parse(value: any): Date | null {
        if (typeof value === 'string') {
            let ret = this.__apply_mask(value, this.dateFormat);
            return ret;
        }

        let mdt = excelDateToDate(value, undefined, this.dateFormat);
        if (mdt) return mdt.toDate();
        return null;
    }

    format(date: Date, displayFormat: Object): string {
        if (displayFormat == "input" || displayFormat == "MMM YYYY")
            return moment(date).format(this.dateFormat);
        return moment(date).format('YYYY-MM-DD');
    }
}