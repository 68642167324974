import { Component, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import { Router } from "@angular/router";
import { IField, animShowHide, animShowHideOpacity } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { insapi, Policy, IPolicy } from 'insapi';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-master-policy',
    templateUrl: './master-policy.component.html',
    styleUrls: ['./master-policy.component.scss']
})
export class MasterPolicyComponent implements OnInit {
    @ViewChild("mpage") paginator!: MatPaginator;
    @ViewChild("msort") sort!: MatSort;
    @ViewChild("vpage") vpaginator!: MatPaginator;
    @ViewChild("vsort") vsort!: MatSort;

    @Output() onChange = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    policy!: Policy;
    readonly: boolean = false;
    data: any = {};
    voyageTable: any[] = [];
    mpTable: any[] = [];
    mpDetailsTable: any[] = [];
                
    voyages: any[] = [];
    voyages_arr_name: string = 'arr_commodity';

    dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    columns: string[] = [];
    coldef: any = {};
    columns_all: string[] = [];

    voySource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    vcolumns: string[] = [];
    vcoldef: any = {};
    vcolumns_all: string[] = [];


    selected: boolean = false;
    mpolicy: any = null;

    psubscription: Subscription | undefined = undefined;
    constructor(private router: Router, 
        private preferences: PreferencesService,
        public _MatPaginatorIntl: MatPaginatorIntl) { }

    ngOnInit(): void {
        this._MatPaginatorIntl.itemsPerPageLabel = 'Page size';
        if (this.policy) {
            // everytime master policy number is changed, we need to reload the voyages
            // 
            this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
            // this.psubscription = this.policy.policySubject.subscribe((policy: IPolicy|null) => this._policy_changed());
            
            if (this.policy.product) {
                if (this.preferences.vendor?.masterpolicy) {
                    let grp_name = this.policy.product.product_group_name.toLowerCase();
                    console.log("grp_name masterpolicy",grp_name,this.preferences.vendor.masterpolicy[grp_name])    
                    if (this.preferences.vendor.masterpolicy[grp_name] && 
                        this.preferences.vendor.masterpolicy[grp_name]['masterpolicy']) {
                        this.mpTable = this.preferences.vendor.masterpolicy[grp_name]['masterpolicy'];
                        if (this.preferences.vendor.masterpolicy[grp_name]['voyage'])
                            this.voyageTable = this.preferences.vendor.masterpolicy[grp_name]['voyage'];
                        if (this.preferences.vendor.masterpolicy[grp_name]['mpDetails'])
                            this.mpDetailsTable = this.preferences.vendor.masterpolicy[grp_name]['mpDetails'];

                        if (this.preferences.vendor.masterpolicy[grp_name].voyage_array_name)
                            this.voyages_arr_name = this.preferences.vendor.masterpolicy[grp_name].voyage_array_name;
                    } else {
                        this.mpTable = this.preferences.vendor.masterpolicy['all-groups']['masterpolicy'];
                        this.voyageTable = this.preferences.vendor.masterpolicy['all-groups']['voyage'];
                        this.mpDetailsTable = this.preferences.vendor.masterpolicy['all-groups']['mpDetails'];
                        if (this.preferences.vendor.masterpolicy['all-groups'].voyage_array_name)
                            this.voyages_arr_name = this.preferences.vendor.masterpolicy['all-groups'].voyage_array_name;
                    }
                }

                this.columns = this.mpTable.map((x: any) => x.name);
                this.coldef = this.mpTable.reduce((a: any, x: any) => {a[x.name] = x; return a;}, {});
                this.columns_all = ['link', ...this.columns]


                this.vcolumns = this.voyageTable.map((x: any) => x.name);
                this.vcoldef = this.voyageTable.reduce((a: any, x: any) => {a[x.name] = x; return a;}, {});
                this.vcolumns_all = ['link', ...this.vcolumns]

        		// force reload master policy balances
            	//
                if (this.data['master_policy_selected_idx'] && this.policy.product?.master_policies?.length <= this.data['master_policy_selected_idx'])
                    this.data['master_policy_selected_idx'] = 0;
                // insapi._load_master_policies(this.policy.product, true);
                this.__init();
            }
        }
    }

    async __init() {
        if (!this.policy?.product) return;
        await insapi._load_master_policies(this.policy.product, true);
        
        this.dataSource.data = this.policy.product.master_policies;
        let selected = -1;
        for (let i=0; i<this.policy.product.master_policies.length; i++) {
            let mpol = this.policy.product.master_policies[i];
            if (mpol.policy_no == this.data.master_policy_no) selected = i;
            if (!mpol.coi_allowed) mpol.status = 6;
        }

        if (this.data.master_policy_no) await this.loadVoyages(this.data.master_policy_no);

        if (selected >= 0) this.select(this.policy.product.master_policies[selected]);

        setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort;}, 100);
    }
    
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    _policy_changed() {
        //if (this.data.master_policy_no) this.loadVoyages(this.data.master_policy_no);
    }

    async select(mp: any) {
        if (!mp || !this.policy || mp.status != 0 || this.readonly) return;
        let idx = this.policy?.product?.master_policies.findIndex((x: any) => x.policy_no == mp.policy_no);
        if (idx < 0) return;
        
        this.mpolicy = this.policy?.product?.master_policies[idx] || null;
        // do not reload if we have already done that
        //
        if (this.data['master_policy_no'] == mp.policy_no && this.voyages.length > 0) return;

        // reset any voyage property we may have set (based on previously selected voyage)
        //
        if (this.data['master_policy_no'] != mp.policy_no) {
            this.clearVoyage();
            this.data['master_policy_selected_idx'] = idx;
            this.data['master_policy_no'] = mp.policy_no;
            this.group.patchValue({master_policy_no: mp.policy_no});
            await this.loadVoyages(mp.policy_no);
            this.onChange.emit(this.field);
        }
    }

    async loadVoyages(masterPolicyNo: string) {
        if (this.voyageTable.length <= 0) return;

        // voyage details are unlikely to change during this period, use cache
        //
        let voyages = (await insapi._xget_cache("/api/v1/mop/voyage?master_policy_no=" + encodeURIComponent(masterPolicyNo))) || [];

        // keys in voyages must be sanitized (spaces replaced with _) before we use them
        //
        this.voyages = voyages.map((v: any) => Object.keys(v).reduce((a: any, x: string) => {a[x.split(' ').join('_').toLowerCase()] = v[x]; return a}, {}));

        // find the currently selected voyage by walking through voyage parameters in data
        //
        this.markSelected();

        console.log('voyages:', this.voyages)
        // pre-select since there is only one option left
        //
        if (this.voyages.length == 1) this.selectVoyage(0);

        this.voySource.data = this.voyages;
        setTimeout(() => {this.voySource.paginator = this.vpaginator; this.voySource.sort = this.vsort;}, 100);

    }

    async selectVoyage(idx: number) {
        console.log('selectVoyage:', idx)
        if (this.readonly) return this.markSelected();

        if (idx < 0 || idx >= this.voyages.length) return;
        for (let v of this.voyages) v.selected = false;

        let voyage = this.voyages[idx];
        voyage.selected = true;
        this.selected = true;
        let fieldchange_obj = [];
        for (let key in voyage) {
            let datakey = key.split(' ').join('_').toLowerCase();
            this.data[datakey] = voyage[key];  
            fieldchange_obj.push({field_name: datakey, value: this.data[datakey]});
            
        }
        this.onChange.emit(fieldchange_obj) ;
        
        this.data[this.voyages_arr_name+'_selected_index'] = idx;
        // this.group.patchValue(voyage);
    }

    async markSelected() {
        // find the voyage that matches the data
        // for (let voyage of this.voyages) {
        for (let i=0; i<this.voyages.length; i++) {
            let voyage = this.voyages[i];
            voyage.selected = false;
            
            let found = true;
            for (let key in voyage) {
                if (key == 'selected') continue;
                let name = key.split(' ').join('_').toLowerCase();
                if (this.data[name] != voyage[key]) {found = false; break;}
            }
            if (found) {
                if (this.data[this.voyages_arr_name+'_selected_index'] != i) {
                    console.log('**** mismatch ', this.voyages_arr_name+'_selected_index', this.data[this.voyages_arr_name+'_selected_index'], '!=', i);
                }
                voyage.selected = true;
                this.selected = true;
                break;
            }
        }
    }

    async clearVoyage() {
        // reset the selected voyage values
        if (this.voyages.length <= 0) return;
        for (let key in this.voyages[0]) {
            if (key == 'selected') continue;
            let name = key.split(' ').join('_').toLowerCase();
            delete this.data[name];
        }
        this.selected = false;
        this.data[this.voyages_arr_name+'_selected_index'] = null;
    }

    async bulk_upload(decl: boolean) {
        let v = this.voyages.filter(x => x.selected);
        if (v.length == 0) return;

        let product = this.policy.product?.product_name.toLowerCase();
        let params: any = {};

        let keys = Object.keys(v[0]);
        if (this.preferences.vendor?.bulk && product) {
            let conf = this.preferences.vendor.bulk[product];
            if (conf.voyage) keys = conf.voyage;
        }

        for (let key of keys) {
            if (key == 'selected') continue;
            params[key.split(' ').join('_').toLowerCase()] = v[0][key];
        }

        params.master_policy_no = this.data['master_policy_no'];
        if (decl) params.decl = decl;
        this.router.navigate(['/bulk/' + product], {queryParams: params, queryParamsHandling: 'merge', replaceUrl: true});
		
    }

    
}
