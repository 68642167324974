import { Component, OnInit } from '@angular/core';
import { IProfile, insapi } from 'insapi';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    userName!: string;
    data: any = {};
    subscription: any;
    profile: any;
    constructor() { 
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {
            this.profile = profile;
            this.init();
        });
        
    }

    ngOnInit(): void {
        //this.init();
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    init() {
        if (this.profile) {
            this.userName = this.profile.email;
            this.data = this.profile.data;
        }
        //this._load();
    }

    async _load() {
        if (!this.userName) return;
        let profile = await insapi.__xget('/api/v1/profile/'+encodeURIComponent(this.userName));
        this.data = profile.data;
    }

    userChanged() {
        this._load();
    }

}
