<div *ngIf="policy && policy.product && policy.product.master_policies" class="mp">
    <div>
        <div class='mp-caption'>Select Master Policy</div>
        <table mat-table [dataSource]="dataSource" style="width: 100%" *ngIf="!readonly" #msort="matSort" matSort>
            <ng-container matColumnDef="link">
                <th mat-header-cell *matHeaderCellDef style="padding-right: 1em;"> </th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon fontSet="material-icons-outlined" style='color: #0A0;' *ngIf="row.policy_no == data.master_policy_no">done</mat-icon>
                    <mat-icon fontSet="material-icons-outlined" style='color: #DDD;' *ngIf="row.status!=0">link_off</mat-icon>
                </td>
            </ng-container>
            <ng-container *ngFor="let col of columns; let i = index" [matColumnDef]="col">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-right: 1em;"> {{coldef[col].disp | titlecase}} </th>
                <td mat-cell *matCellDef="let row" style="padding-right: 1em;" (click)="select(row)">
                    <div style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{row[col] | generic2: coldef[col].type}}</div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns_all"></tr>
            <tr mat-row *matRowDef="let row; columns: columns_all;"></tr>
        </table>
        <mat-paginator #mpage [pageSizeOptions]="[5, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>

    <div *ngIf="mpolicy" style="padding-top: 1em;">
        <div class='mp-caption'>Policy Details - {{data?.sum_insured_option}}</div>
        <div class="mp1-container mp-header" [style.grid-template-columns]="'repeat(' + mpDetailsTable.length+', 1fr)'">
            <div class="mp-col-header" *ngFor="let d of mpDetailsTable">{{d.disp | capitalize}}</div>
        </div>
        <div class="mp1-container" [style.grid-template-columns]="'repeat(' + mpDetailsTable.length+', 1fr)'">
            <div class="vy-cell" *ngFor="let d of mpDetailsTable; let j=index">
                {{data[d.name]  || mpolicy?.[d.name] | generic2: d.type }} <span class="vy-disp">{{d.disp}}</span>
            </div>
        </div>
    </div>


    <div class="voyage-container" *ngIf="data['master_policy_no'] && voyageTable.length>0">
        <div class='voyage-caption'>Select voyage</div>
        <table mat-table [dataSource]="voySource" style="width: 100%" matSort #vsort="matSort">
            <ng-container matColumnDef="link">
                <th mat-header-cell *matHeaderCellDef style="padding-right: 1em;"> </th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon fontSet="material-icons-outlined" style='color: #0A0;' *ngIf="row.selected">done</mat-icon>
                </td>
            </ng-container>
            <ng-container *ngFor="let col of vcolumns; let i = index" [matColumnDef]="col">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-right: 1em;"> {{vcoldef[col].disp | titlecase}} </th>
                <td mat-cell *matCellDef="let row; let j=index" style="padding-right: 1em;" (click)="selectVoyage(j)">
                    <div style="cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{row[col] | generic2: vcoldef[col].type}}</div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="vcolumns_all"></tr>
            <tr mat-row *matRowDef="let row; columns: vcolumns_all;"></tr>
        </table>
        <mat-paginator #vpage [pageSizeOptions]="[5, 20, 50, 100]" showFirstLastButtons></mat-paginator>

        <div *ngIf="!selected" style="padding: 1em 1em;">
            <div style="display: inline-block; padding-right: 2em;"><button mat-button ibutton (click)="bulk_upload(true)">Upload Declarations</button></div>
            <div style="display: inline-block; padding-right: 2em;"><button mat-button ibutton (click)="bulk_upload(false)">Upload Certificates</button></div>
        </div>
    </div>
</div>
