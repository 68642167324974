<div *ngIf="policy && policy.endorsement && stages.length>0">
    <div style="text-align: center; color: #888; padding-top: .5em; cursor: pointer;" (click)="gotoPolicy()">
        Endorse {{policy.endorsement.policy_no}} - {{policy.endProduct.endorsement_name}}<!-- {{policy.policy.policy_start_date | idate}} - {{policy.policy.policy_end_date | idate}} -->
    </div>
    <div style="text-align: center; color: #888; padding-top: .5em; cursor: pointer;">
        <ng-template  [ngIf]="policy.endorsement.endorsement_id"> Endorsement_id : {{policy.endorsement.endorsement_id}}</ng-template>
    </div>
    <div style="text-align: center; color: #888;">
        <div *ngIf="inMyGroup">
            This policy has been assigned to one of your Groups ({{assignedTo}}), would you like to process it? <button mat-button ibutton (click)="takeOver()">Take over</button>
        </div>
        <div *ngIf="!profile || !profile.is_underwriter || !policy.endorsement.assigned_to?.startsWith('G00')">
            <small>{{assignedTo}}</small>
        </div>
        
    </div>
    
    <istepper #cdkStepper [linear]="false" [layout]="workflow.layout.stepper">
        <cdk-step *ngFor="let stage of stages" [editable]="true" [label]="stage.name" [optional]="stage.ready" [completed]="stage.readonly && stage.ready">
            <div class="policy-container">
                <div *ngIf="stage.ready" class="form-wrapper"
                    [ngStyle]="stage.form.layout.style"
                    [style.max-width]="stage.form.layout.width" 
                    [style.min-width]="stage.form.layout.width">
                    <div class="stage-fg">
                        <div *ngFor="let grp of stage.form.groups">
                            <field-group #fgs *ngIf="policy.endorsement[stage.module.name]"
                                [fieldGroup]="grp" 
                                [data]="policy.endorsement[stage.module.name].data"
                                [policy]="policy"
                                [readonly]="stage.readonly"
                                [layout]="grp.layout || stage.form.layout"
                                (onAction)="onAction($event)"
                                (onChange)="onChange($event)">
                            </field-group>
                        </div>
                    </div>

                    <div class="summary-footer" *ngIf="documents.length>0 || stage.actions">
                        <div class="stage-summary-doc" *ngIf="documents.length>0">
                            <div class="summary-doc-caption"><mat-icon>attach_file</mat-icon> Attachments</div>
                            <div class="summary-doc-item" *ngIf="documents.length>0 && policy.endorsement.document_id ">
                                <div class="doc-upload" *ngFor="let document of documents">
                                    <div *ngIf="document.document_details_id" >
                                        <div (click)="downloadDocument(document)" class="done-lite" ><mat-icon>done</mat-icon></div>
                                        <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">{{document.document_description}}</div>
                                        <div style="font-size: 0.8em"><span style="text-decoration: underline; cursor: pointer;" (click)="removeDocument(document)" >Remove</span></div>
                                    </div>
                                    <div *ngIf="!document.document_details_id">
                                        Upload {{document.document_description}}
                                        <input type="file" [accept]="document.document_extns" (change)="uploadDocument($event, document)" />
                                    </div>
                                </div>
                            </div>
                            <div class="summary-doc-item" *ngIf="documents.length>0 && !policy.endorsement.document_id ">
                                Click save to enable attachments
                            </div>
                        </div>
                        
                        <div class="stage-summary-actions" *ngIf="stage.actions">
                            <field-group [fieldGroup]="stage.actions" 
                                [data]="policy.endorsement[stage.module.name].data"
                                [readonly]="stage.readonly"
                                [policy]="policy"
                                [layout] = "{cls: 'action-wrapper', grids: 12}"
                                (onAction)="onAction($event)"
                                (onChange)="onChange($event)">
                            </field-group>
                        </div>

                        <div class="stage-summary-dnld">
                            <div *ngIf="downloads.length==0" style="padding: 0.5em 0em; color: #FAFAFA;">Downloads not ready yet</div>
                            <div class="stage-summary-item" *ngFor="let dnld of downloads">
                                <mat-icon fontSet="material-icons-outlined" style="font-size: 1.4em; vertical-align: middle; text-align: center;" (click)="emailPDF(dnld)">email_outline</mat-icon>
                                <mat-icon fontSet="material-icons-outlined" style="font-size: 1.4em; vertical-align: middle; text-align: center;" (click)="downloadPDF(dnld)">download_outline</mat-icon>
                                <span style="font-size: 0.9em; color: #FFF;">{{dnld.mod | titlecase}}</span>
                            </div>
                        </div>


                    </div>
                    
                </div>
            </div>
        </cdk-step>
    </istepper>

    <div class="nstp-wrapper" *ngIf="chatType!=2 && policy.endorsement[nstpType]">
        <div>
            <referral-message [policy]="policy" [messages]="policy.endorsement[nstpType].details"></referral-message>
        </div>
    </div>
    <div class="nstp-wrapper" *ngIf="chatType==2">
        <referral-message [policy]="policy" [messages]="policy.endorsement?.inspect?.details" [type]="'inspect'"></referral-message>
    </div>

    
