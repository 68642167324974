import { trigger, state, style, transition, animate, query, stagger } from '@angular/animations';

export const animShowStaggered = trigger('showStaggered', [
    transition('* => *', [
        query(':enter', [
            style({opacity: 0}),
            stagger('50ms', [animate('500ms', style({opacity: 1}))])
        ], {optional: true})
    ])
]);


// export const animScaleX = trigger('animScaleX', [
//     state('true', style({'transform' : 'scaleX(1)' })),
//     state('false', style({'transform' : 'scaleX(0)' })),
//     transition('false <=> true', animate(200))
// ]);

export const animSlideFromTop = trigger('animSlideFromTop', [
    transition(':enter', [
        style({transform: 'translateY(-50%)'}),
        animate('200ms ease-in', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
        style({opacity: 1}),
        animate('100ms ease-in', style({opacity: 0}))
    ])
])

export const animRotate = trigger('animRotate', [
    transition(':enter', [
        style({transform: 'rotate(-180deg)'}),
        animate('200ms ease-in', style({transform: 'rotate(0deg)'}))
    ]),
    transition(':leave', [
        style({opacity: 1, transform: 'rotate(0deg)'}),
        animate('100ms ease-in', style({opacity: 0, transform: 'rotate(180deg)'}))
    ])
])


// export const animSliderBall = trigger('sliderBall', [
//     state('true', style({'left' : '18px', 'border': '2px solid rgba(30,144,255,.75)' })),
//     state('false', style({'left' : '0px', 'border': '1px solid #d6d6d6'})),
//     transition('false <=> true', animate('600ms ease-out'))
// ]);
// export const animSliderBg = trigger('sliderBg', [
//     state('true', style({'background-color': 'rgba(30,144,255,.65)' })),
//     state('false', style({'background-color': '#d6d6d6'})),
//     transition('false <=> true', animate('600ms ease-out'))
// ]);
