import { insapi } from './insapi';
import { ilock } from './lock';
import moment from 'moment';
import { config } from 'rxjs';

interface InfluxSeries {
    columns: any[];
    name: string;
    values: any[];
    tags: {[key: string]: string}
}

interface InfluxResult {
    statement_id: string;
    series: InfluxSeries[]
}

interface Echart {
    series: any[],
    xAxis?: any;
    yAxis?: any;
    title?: any;
    legend: {show: boolean, data: string[]},
    [key: string]: any;
}

interface NormalChart {
    tags: string[];
    tagMap: {[key: string]: string};
    series: {[key: string]: any};
    maxValues: {[key: string]: number};
}

const chartpalette = ['#FB5B5B', '#FEC031', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
const cache: {[key: string]: any} = {};


class InsReport {

    async __get(url: string) {
        try{
            return await insapi.xget(url);
        } catch (e) {
            return null;
        }
    }

    async _ix_get(url: string): Promise<InfluxResult[]> {
        try{
            let ret = await insapi.xget(url);
            return ret ? (ret.results||[]) : [];
        } catch (e) {
            // console.log('_ix_get:', url, e);
            return [];
        }
    }

    async _influxTags(table: string): Promise<string[]> {
        let url = '/api/v1/stats/influx_schema/tag' + (table ? '/'+encodeURIComponent(table) : '');
        let ir: InfluxResult[] = await this._ix_get(url);
        if (ir.length==0 || !ir[0].series) return [];
        let ret = ir[0].series.reduce((a: any, x: any) => {a[x.name] = x.values.flat(); return a;}, {});
        if (table) return ret[table];
        return ret;
    }

    async _influxFields(table: string = '', floadOnly: boolean = true): Promise<{[key: string]:string[]}> {
        let url = '/api/v1/stats/influx_schema/field';
        let ir: InfluxResult[] = await this._ix_get(url);
        if (ir.length==0 || !ir[0].series) return {};
        if (table) {
            console.log('ir:', table, ir);
            ir[0].series = ir[0].series.filter((x: any) => x.name == table);
        }
        
        // we are interested in numeric fields that can be aggregated
        //
        let ret = ir[0].series.reduce((a: any, x: InfluxSeries) => {
            a[x.name] = x.values.filter((y: string[]) => floadOnly ? y[1]==='float' : y[1]!=='float').map((z: string[]) => z[0]);
            return a;
        }, {});
        return ret;
    }

    async influxFields(): Promise<{[key: string]:string[]}> {
        let ctag = 'fields-all';
        await ilock.lock(ctag);
        try{
            if (!cache.hasOwnProperty(ctag)) cache[ctag] = await this._influxFields();
        } catch (e) {}
        finally {
            ilock.unlock(ctag);
        }
        return cache[ctag] || {};
    }

    async influxTags(table: string): Promise<string[]> {
        let ctag = 'tags-' + table;
        if (!cache.hasOwnProperty(ctag)) {
            let tags = await this._influxTags(table);
            // console.log('tags:', tags);
            
            // // merge non-float fields also as tags here
            // let flds = await this._influxFields(table, false);
            // if (flds && flds[table]) {
            //     tags = [...new Set([...tags, ...flds[table]])];
            // }

            cache[ctag] = tags;
        }
        return cache[ctag];
    }

    async _influxTagValues(table: string, tag: string, prefix?: string): Promise<string[]> {
        let url = '/api/v1/stats/influx_schema/values/' + encodeURIComponent(table) + '/' + encodeURIComponent(tag);
        if (prefix) url += "?prefix=" + encodeURIComponent(prefix);
        let ir = await this._ix_get(url);
        if (ir.length==0 || !ir[0].series) return [];

        let ret = ir[0].series.reduce((a: any, x: any) => {a[x.name] = x.values.map((y: any) => y[1]).flat(); return a;}, {});
        return ret[table].filter((x: string) => x /*&& x !== 'undefined'*/);
    }

    async influxTagValues(table: string, tag: string, prefix?: string): Promise<string[]> {
        let ctag = 'v-'+table + '-' + tag + '-' + (prefix||'');
        await ilock.lock(ctag);
        try{
            if (!cache.hasOwnProperty(ctag)) cache[ctag] = await this._influxTagValues(table, tag, prefix);
        } catch (e) {
        } finally {
            ilock.unlock(ctag);
        }
        return cache[ctag];
    }

    async query(qryname: string, params: {[key: string]: any}) {
        let parray =Object.keys(params).map(x => x + '=' + encodeURIComponent(params[x] instanceof moment ? params[x].format('YYYY-MM-DD'):params[x]));
        return await this.__get('/api/v1/stats/query/'+qryname+'?'+parray.join('&'));
    }

    /**
     * 
     * @param params [table]: table name, [fields]: tags/fields, [group]: fields, [filters]: name/value filters, [interval]: chart interval, [period]
     * @param fmt echart|raw
     * @returns 
     */
    async influx(params: any) {
        let filters = params.filters;
        delete params.filters;
        
        if (params.interval == 'monthly') params.interval = '1mo';
        else if (params.interval == 'daily') params.interval = '1d';
        
        if (typeof params.group === 'string') params.group = params.group.split(',').map((x: string) => x.trim());
        if (params.group) params.group = params.group.filter(Boolean);
        for (let f in filters) if(filters[f].length>0) params[f] = filters[f] instanceof Array ? filters[f].join(',') : filters[f];
        let url = '/api/v1/stats/influx?' + Object.keys(params).map(x => x + '=' + encodeURIComponent(params[x])).join('&');
        let data = await this.__get(url);
        return data ? (params._flux ? data : data.results) : [];
    }

    _timeseries_yaxis(res: any[]) {
        if (res.length < 2) return {};

        let minmax = res.map(x => {
            let min = x.y[0];
            let max = x.y[0];
            for (let i=0; i < x.y.length; i++) {
                if (x.y[i] < min) min = x.y[i];
                if (x.y[i] > max) max = x.y[i];
            }
            return {min, max, range: max - min, arange: Math.abs(max - min)};
        });
        let maxrange = minmax[0];
        for (let i=0; i < minmax.length; i++) {
            if (minmax[i].arange > maxrange.arange) maxrange = minmax[i];
        }
        let minrange = null;
        
        for (let i=0; i < minmax.length; i++) {
            if (minmax[i].arange == maxrange.arange) {
                res[i].yAxisIndex = 0;
            } else {
                if (!minrange) minrange = {...minmax[i]};
                let lbound = Math.max(maxrange.min, minmax[i].min);
                let ubound = Math.min(maxrange.max, minmax[i].max);
                let range = Math.abs(ubound - lbound);
                if (maxrange.arange / range > 3 ) res[i].yAxisIndex = 1;
                else res[i].yAxisIndex = 0;

                if (minmax[i].min < minrange.min) minrange.min = minmax[i].min;
                if (minmax[i].max > minrange.max) minrange.max = minmax[i].max;
            }
        }
        if (res.filter(x => x.yAxisIndex == 1).length == 0 ) return {};
        if (!minrange) return {min: maxrange.min, max: maxrange.max, range: maxrange.max - maxrange.min, arange: Math.abs(maxrange.max - maxrange.min)};

        return [{type: 'value', min: maxrange.min, max: maxrange.max + ((maxrange.range/4)|0), splitNumber: 4},
                {type: 'value', min: minrange.min, max: minrange.max + ((minrange.range/4)|0), splitNumber: 4, splitLine: {show: false}}];
    }

    _format_influx_result_echart_timeseries(result: any, config: any) {
        let res = this._influx_timeseries(result).flat();
        if (res.length==0) return null;

        let axisLabel = {show: true, formatter: (value: any, index: string) => moment(value).format('YYYY-MM-DD')};
        let stack: string | null = null;
        let areaStyle: any = null;
        let lineStyle = {width: 3};
        let yAxis: any = config.axis == 'auto' ? this._timeseries_yaxis(res) : {};
        let xAxis: any = { type: 'category', data: res[0].x, silent: false, splitLine: {show: false}, axisLabel};
        let grid = null;

        // console.log('yAxis: ', yAxis, config.grid);
        if (config.grid == 'back-to-back' && yAxis instanceof Array) {
            grid = [{left: 50, right: 50, height: '35%'}, {left: 50, right: 50, height: '35%', top: '60%'}];
            xAxis = [{ type: 'category', data: res[0].x, silent: false, splitLine: {show: false}, axisLabel},
                     { type: 'category', data: res[0].x, silent: false, splitLine: {show: false}, axisLabel, gridIndex: 1, position: 'top', show: false}];
            yAxis[1].gridIndex = 1;
            yAxis[1].inverse = true;
            delete yAxis[1].splitLine;
            for (let i=0; i <res.length; i++) res[i].xAxisIndex = res[i].yAxisIndex;
        }

        let type = config.type;
        if (type == 'area-stack') {type = 'line'; areaStyle = {opacity: 0.8}, stack = "stack"};
        if (type == 'area') {type = 'line'; areaStyle = {opacity: 0.8}};
        return {
            legend: { data: res.map((x: any) => x.name), align: 'center'}, grid,
            xAxis, yAxis,
            series: res.map((x: any) => ({name: x.name, type, connectNulls: true, smooth: true, stack, 
                            yAxisIndex: x.yAxisIndex||0, xAxisIndex: x.xAxisIndex||0,
                            data: x.y, animationDelay: (idx: number)=>idx*10, lineStyle, areaStyle })),
            animationEasing: 'elasticOut',
            color: chartpalette,
            tooltip: {show: true}
        }
    }

    generateData() {
        var data = [];
        for (var i = 0; i <= 10; i++) {
            for (var j = 0; j <= 10; j++) {
                var value = Math.round(Math.random()*30);
                data.push([i, j, value * 2 + 4]);
            }
        }
        return data;
    }
    
    _fix_year_mon(ym: string[]) {
        let re: RegExp = /\d\d\d\d\-(\d\d)/;
        let months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ym.map(x => x.replace(re, (m, p1) => x.replace(m, months[+p1])));
    }

    _format_influx_result_echart_category(result: InfluxResult, confg: any, idx: number): Echart | null {
        let type = confg.type;
        
        console.log('category: ', type, result);

        if (type == 'angle-bar' || type == 'radial-bar') {
            if (!result.series || result.series.length==0 || !result.series[0].tags)return null;
            let dims = Object.keys(result.series[0].tags);
            let d1 = dims[0];
            let dim2: string[] = [];
            let ret: any = {};
            let title: any[] = [{text: result.series[0].columns[1], show: true, textStyle: {fontSize: '1em'}}];
            result.series.map((x: any) => {
                let d1v: string = x.tags[d1];
                if (!ret[d1v]) ret[d1v] = {};
                for (let i=1; i<dims.length; i++) {
                    let dxv = x.tags[dims[i]];
                    dim2.push(dxv);
                    ret[d1v][dxv] = x.values[0][1];
                }
            });
            dim2 = [... new Set(dim2)];

            let dim1 = Object.keys(ret);
            let series = dim2.map(d2 => ({type: 'bar', data: dim1.map(x => ret[x][d2] || 0), name: d2, stack: 'A', coordinateSystem: 'polar'}));
            let legend = {show: true, data: dim2};
            let angleAxis = type == 'angle-bar' ? {type: 'category', data: dim1} : {};
            let radiusAxis= type == 'radial-bar' ? {type: 'category', data: dim1.map(x => x.substr(0,4)), z: 10} : {};
            return {title, angleAxis, series, radiusAxis, polar: {}, emphasis: {focus: 'series'}, legend};
        }

        let res: any = this._influx_category(result);
        if (res && type == 'bar3D') {
            let dim1 = Object.keys(res);
            let charts = Object.keys(res[dim1[0]]);
            let keys: any = {};
            let vals: any = {};
            for (let dim of dim1) {
                for (let chart of charts) {
                    for (let item of res[dim][chart]) {
                        keys[item.name] = 1;
                        vals[dim+'~'+chart+'~'+item.name] = item.value;
                    }
                }
            }
            let dim2 = Object.keys(keys).sort();
            let series = [];
            for (let chart of charts) {
                let data = [];
                for (let i=0; i<dim1.length; i++) {
                    for (let k=0; k<dim2.length; k++) {
                        data.push([dim2[k], dim1[i], vals[dim1[i]+'~'+chart+'~'+dim2[k]] || 0]);
                    }
                }
                series.push({type, data, stack: 'stack',shading: 'lambert', emphasis: {label: {show: false}} });
            }

            return {xAxis3D: {type: 'category', name: ''}, 
                legend: {show: true, data: Object.keys(charts)},
                yAxis3D: {type: 'category', name: ''}, zAxis3D: {type: 'value', name: ''}, 
                grid3D: {}, series};
        }
        return null;
    }



    format_influx_results(results: any[], confg: any) {
        if (!results) return null;

        // console.log('format: ', results);
        if (confg.timeseries){
            return results.map(x => this._format_influx_result_echart_timeseries(x, confg)).filter(Boolean);
        }

        let ret = this._format_influx_category_echart(results, confg);
        if (ret) return ret;

        // merge series back into first chart
        //
        let charts: any[] = results.map((x, i) => this._format_influx_result_echart_category(x, confg, i)).filter(Boolean);
        if (!charts || charts.length == 0) return null;
        
        ret = charts[0];
        for (let i=1; i<charts.length; i++) {
            for (let s=0; s<charts[i].series.length; s++) {
                charts[i].series[s].name = charts[i].series[s].name + '-Prev';
                ret.legend.data.push(charts[i].series[s].name);
            }
            ret.series.push(...charts[i].series);
        }
        console.log('format-ret: ', ret);
        return ret;
    }

    _influx_timeseries(result: any) {
        if (!result.series || result.series.length == 0) return [];
        let x = result.series[0].values.map((x: any) => x[0]);
        if (result.series[0].tags ) {
            return result.series.map((s: any) => {
                let name = Object.values(s.tags).join('-') + '-';
                if (result.series[0].columns.length>2)
                    return s.columns.map((c: string, i: number) => ({tags: s.tags, name: name + c, x, y: s.values.map((z: any) => z[i])})).slice(1);
                return s.columns.map((c: string, i: number) => ({tags: s.tags, name: name, x, y: s.values.map((z: any) => z[i])})).slice(1);
            });
        } else {
            return result.series.map((s: any) => {
                return s.columns.map((c: string, i: number) => ({name: c, x, y: s.values.map((z: any) => z[i])})).slice(1);
            });
        }
    }

    _influx_category(result: any) {
        // console.log('category-in: ', result);
        if (!result || !result.series || result.series.length == 0) return null;
        let cols = result.series[0].columns.slice(1);
        let tags = Object.keys(result.series[0].tags);
        let key  = tags.pop() || '';
        let res: any = {};
        result.series.map((x: any) => {
            let name = tags.map(tag => x.tags[tag]).join('-');
            cols.map((c: string, i: number) => {
                if (!res[name]) res[name] = {};
                if (!res[name][c]) res[name][c] = [];
                res[name][c].push({name: x.tags[key], value: x.values[0][i+1] });
            });
        });
        return res;
        //return Object.keys(res).map(x => ({name: x, series: res[x]}));;
    }


    /* -- */

    _year_month_map_to_next(ym: string) {
        return moment(ym+'-01', 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM');
    }

    _influx_category_normalize(results: InfluxResult[]): NormalChart | null{
        if (results.length == 0) return null;
        let re: RegExp = /\d\d\d\d\-(\d\d)/;
        let months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        let cols = null;

        // columns are aggregate measurements (like sum(x) ) that forms an 
        // individual chart series
        //
        for (let i=0; i<results.length; i++) {
            if (!cols && results[i].series && results[i].series.length > 0)
                cols = results[i].series[0].columns.slice(1);
        }

        if (!cols) return null;

        // get the xAxis legends from tags if present, otherwise use the
        // cols as legends. Ther is no specific order imposed here
        //
        let tags: string[] = [];
        for (let i=0; i<results.length; i++) {
            for (let s=0; s<results[i].series.length; s++) {
                tags.push(...Object.keys(results[i].series[s].tags));
            }
        }
        tags = [...new Set(tags)];

        // compose all charts based on the tag-values, category charts are expected
        // to have one value per serie
        //
        let charts: any = {};
        let tagValues: any = {};
        let maxValues: {[key: string]: number} = {};

        for (let i=0; i<results.length; i++) {
            for (let s=0; s<results[i].series.length; s++) {
                let serie = results[i].series[s];
                if (serie.values.length > 1) console.log('serie: has more than one value', serie);
                for (let c=0; c<cols.length; c++) {
                    let name = cols[c] + (i==0 ? '' : (i > 1 ? '-YoY'+i : '-YoY'));
                    let tagValue = tags.map(x => serie.tags ? serie.tags[x] : '').join('~');
                    if (i>0) {
                        tagValue = this._year_month_map_to_next(tagValue);
                    }

                    let xval = tagValue.replace(re, (m, p1) => tagValue.replace(m, months[+p1]));  // un-orderable

                    tagValues[tagValue] = xval;
                    if (!charts[name]) charts[name] = {};
                    charts[name][xval] = serie.values[0][c+1]; // we have skipped time column
                    if (maxValues[tagValue] === undefined || maxValues[tagValue] < serie.values[0][c+1]) maxValues[tagValue] = serie.values[0][c+1];
                }
            }
        }

        console.log('cols: ', cols, tags, 'tagMap', tagValues);
        console.log('normal: ', charts);
        return {tags, tagMap: tagValues, maxValues, series: charts};
    }

    _format_influx_category_echart(results: any[], config: any) {
        let ncharts: NormalChart | null = this._influx_category_normalize(results);
        if (!ncharts) return null;
        if (config.type == 'line' || config.type == 'bar')
            return this._format_influx_category_line(ncharts, config);
        if (config.type == 'pie')
            if (!config.merged) return this._format_influx_category_pie(ncharts, config);
            else return this._format_influx_category_pie_merged(ncharts, config);
        if (config.type == 'radar')
            return this._format_influx_category_radar(ncharts, config);
        return null;
    }

    _format_influx_category_line(charts: NormalChart, config: any) {
        let tv = Object.keys(charts.tagMap).sort();
        let ret: any = {
            legend: {show: true, data: Object.keys(charts.series)},
            xAxis: {type: 'category', data: tv.map(x => charts.tagMap[x])},
            yAxis: {type: 'value'},
            series: []
        }
        for (let name in charts.series) {
            let data = tv.map(x => charts.series[name][charts.tagMap[x]]);
            ret.series.push({name, data, type: config.type, smooth: true});
        }
        return ret;
    }
    _format_influx_category_pie_merged(charts: NormalChart, config: any) {
        let ret: any = {
            legend: {show: true, data: ["combined"]},
            tooltip: {trigger: 'item'},
            series: []
        };
        let tv = Object.keys(charts.tagMap).sort();
        let valnames = Object.keys(charts.series);
        let data = [];

        for (let tag of tv) {
            let v: any = {value: charts.series[valnames[0]][charts.tagMap[tag]], name: tag};
            for (let j=1; j<valnames.length; j++) v[valnames[j]] = charts.series[valnames[j]][charts.tagMap[tag]];
            data.push(v);
        }
        ret.series.push({name: valnames[0], data, radius: ['40%', '70%'],
            type: config.type, avoidLabelOverlap: true, title: {subtext: name, show: true},
            itemStyle: {borderRadius: 1, borderColor: '#fff', borderWidth: 1}});
        // console.log('merged:', ret);
        return ret;
    }

    _format_influx_category_pie(charts: NormalChart, config: any) {
        let tv = Object.keys(charts.tagMap).sort();
        let msrments = Object.keys(charts.series);
        let cw = (100 / msrments.length);
        let cx = cw  / 2;
        let ret: any = {
            legend: {show: true, data: msrments},
            tooltip: {trigger: 'item'},
            series: []
        };
        for (let i=0; i<msrments.length; i++) {
            let name = msrments[i];
            let data = tv.map(x => ({value: charts.series[name][charts.tagMap[x]], name: x}));
            ret.series.push({
                name, data, itemStyle: {borderRadius: 1, borderColor: '#fff', borderWidth: 1},
                radius: ['40%', '70%'], center: [(cx + i * cw)+'%', '50%'], type: config.type, avoidLabelOverlap: true,
                title: {subtext: name, show: true}
            });
        }
        return ret;
    }
    
    _format_influx_category_radar(charts: NormalChart, config: any) {
        let tv = Object.keys(charts.tagMap).sort();
        let ret: any = {
            legend: {show: true, data: Object.keys(charts.series)},
            tooltip: {trigger: 'item'},
            radar: {indicator: tv.map(x => ({name: x, max: charts.maxValues[x]}))},
            series: [{type: config.type, areaStyle:{}, data: []}]
        };
        for (let name in charts.series) {
            ret.series[0].data.push({name: name, value: tv.map(x => charts.series[name][charts.tagMap[x]])});
        }
        console.log('radar: ', ret);
        return ret;
    }

    convert_period(period: string | number) {
        if (period == 'today' || !period) {
            return moment().startOf('day').toISOString() + ',' + moment().toISOString();
        } else if (!isNaN(+period)) {
            return moment().add(-(period||7), 'days').toISOString() + ',' + moment().toISOString();
        } else if (period == 'onemonth') {
            return moment().add(-1, 'month').toISOString() + ',' + moment().toISOString();
        } else if (period == 'thismonth') {
            return moment().startOf('month').toISOString() + ',' + moment().toISOString();
        } else if (period == 'thisyear') {
            return moment().startOf('year').toISOString() + ',' + moment().toISOString();
        } else if (period == 'lastyear') {
            return moment().add(-1, 'year').startOf('year').toISOString() + ',' + moment().add(-1, 'year').endOf('year').toISOString();
        } else if (typeof period == 'string' && period.endsWith('h')) {
            let nperiod = Number.parseInt(period.substring(0, period.length-1));
            return moment().add(-nperiod, 'hour').toISOString() + ',' + moment().toISOString();
        } else if (typeof period == 'string' && period.endsWith('d')) {
            let nperiod = Number.parseInt(period.substring(0, period.length-1));
            return moment().add(-nperiod, 'day').toISOString() + ',' + moment().toISOString();
        } else {
            return period; //moment().startOf('day').toISOString() + ',' + moment().toISOString();
        }
    }

}

export const insreport = new InsReport();