<!-- <input type="text" [placeholder]="_placeholder" matInput [matAutocomplete]="auto" (input)="filterName($event)" #mc style="max-width: 100%;">
<mat-autocomplete #auto="matAutocomplete" #as
    (optionSelected)="setSelected($event.option.value)"
    [displayWith]="nameOf.bind(this)">
    <mat-option *ngFor="let c of countries" [value]="c.name">{{c.emoji}} {{c.name}}</mat-option>
</mat-autocomplete> -->

<ng-template [ngIf]="iconMode" [ngIfElse]="nicmode">
    <div class="country-emoji" (click)="showCountrySelector()" *ngIf="!__show">
        <div *ngIf="__country" class="country-emoji-icon">{{__country.emoji}}</div><mat-icon class="country-arrow" *ngIf="__country && !disabled">arrow_drop_down</mat-icon><div *ngIf="__country && !disabled" style="text-wrap: nowrap;">{{__country.phone.startsWith('+')?__country.phone:('+'+__country.phone)}}</div>
        <div *ngIf="!__country">...</div>
    </div>
    <div class="country-selector" *ngIf="__show">
        <input type="text" [placeholder]="_placeholder" matInput [matAutocomplete]="auto" (focusout)="inpFocusOut()"
            (input)="filterName($event)" #mc style="max-width: 100%;">
        <mat-autocomplete #auto="matAutocomplete" #as
            (optionSelected)="setSelected($event.option.value)"
            [displayWith]="nameOf.bind(this)">
            <mat-option *ngFor="let c of countries" [value]="c">{{c.emoji}} {{c.name}}</mat-option>
        </mat-autocomplete>
    </div>
</ng-template>
<ng-template #nicmode>
    <input [disabled]="_disabled" type="text" [placeholder]="_placeholder" matInput [matAutocomplete]="auto" (input)="filterName($event)" #mc style="max-width: 100%;">
    <mat-autocomplete #auto="matAutocomplete" #as
        (optionSelected)="setSelected($event.option.value)"
        [displayWith]="nameOf.bind(this)">
        <mat-option *ngFor="let c of countries" [value]="c">{{c.emoji}} {{c.name}}</mat-option>
    </mat-autocomplete>
</ng-template>

