import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';

@Component({
    selector: 'app-password',
    template: `
    <mat-form-field [formGroup]="group">
        <input matInput [formControlName]="field.field_name" [placeholder]="field.label||''" [type]="type" [attr.data-iid]="field.field_name">
        <mat-icon matSuffix (click)="showPassword()" style="cursor: pointer; color: #aae;">visibility_outline</mat-icon>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
            <mat-error *ngIf="control && validation.validator && control.hasError(validation.validator.name)">{{field.errors || (field.field_name | capitalize) + validation.message}}</mat-error>
        </ng-container>
    </mat-form-field>
  `,
    styles: [
    ]
})
export class PasswordComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    type: string = "password";
    control!: FormControl;
    constructor() { }

    ngOnInit(): void {
    }

    showPassword() {
        this.type = "text";
        setTimeout(() => this.type="password", 3000);
    }

}
