<div style="padding: 1em 1em;" *ngIf="policy && proposalObj?.otp_verified" class="proposal-otp-verified-label">
    <div style="color: #AAF; font-size: 0.9em;">
        OTP Verification/Rejection Completed on {{proposalObj?.otp_verified == '1' ? proposalObj?.u_ts :proposalObj?.otp_verified}}
    </div>
</div>
<div *ngIf="policy && !proposalObj?.otp_verified" class="proposal-otp-container">
    <div style="padding: 1em 1em;" *ngIf="!otpsent" class="proposal-otp-send">
        <div style="color: #AAF; font-size: 0.9em;">Verification/Rejection OTP</div>
        <div style="align-items: center;" ><button mat-button (click)="sendOTP()">{{btnlabel}}</button></div>
    </div>
    <div style="padding: 1em 1em;" *ngIf="otpsent" class="proposal-otp-verify">
        <mat-form-field style="width: 100%;">
            <mat-label>OTP</mat-label>
            <input matInput [(ngModel)]="otp" [attr.data-iid]="otp">  
        </mat-form-field>
        <mat-label matSuffix style="color: #AAF; font-size: 0.9em;" *ngIf="counter>0"> (Resend in {{120-counter}} seconds)</mat-label>
        <div> <button mat-button id="accept" (click)="verify(false)">{{"Accept"}}</button>
        </div>
    </div>
</div>





