import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule, MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { DateAdapter } from '@angular/material/core';

import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree'; 
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips'; 
import { MatRippleModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion'; 

import { InsFormComponent } from './ins-form.component';

import { InputComponent } from '../form/widgets/input/input.component';
import { IFieldDirective } from '../form/i-field/i-field.directive';
import { FieldGroupComponent } from '../form/field-group/field-group.component';
import { LookupComponent } from '../form/widgets/lookup/lookup.component';
import { DateComponent } from '../form/widgets/date/date.component';
import { ButtonComponent } from '../form/widgets/button/button.component';
import { ButtonsComponent } from '../form/widgets/buttons/buttons.component';
import { RadioComponent } from '../form/widgets/radio/radio.component';
import { CheckboxComponent } from '../form/widgets/checkbox/checkbox.component';
import { PasswordComponent } from '../form/widgets/password/password.component';
import { HtmlComponent } from '../form/widgets/html/html.component';
import { FileComponent } from '../form/widgets/file/file.component';
import { EmailComponent } from '../form/widgets/email/email.component';
import { PriceComponent } from '../form/widgets/price/price.component';
import { PolicyMetaDataComponent } from '../form/widgets/policy-meta-data/policy-meta-data.component';
import { PeriodComponent } from '../form/widgets/period/period.component';
import { ReadonlyComponent } from '../form/widgets/readonly/readonly.component';
import { BenefitsComponent } from '../form/widgets/benefits/benefits.component';
import { OutputsComponent } from '../form/widgets/outputs/outputs.component';
import { PlanComponent } from '../form/widgets/plan/plan.component';
import { MultiChoiceComponent } from '../form/widgets/multi-choice/multi-choice.component';
import { GridComponent } from '../form/widgets/grid/grid.component';
import { MasterPolicyComponent } from '../form/widgets/master-policy/master-policy.component';
import { DummyComponent } from '../form/widgets/dummy/dummy.component';
import { NumberComponent } from '../form/widgets/number/number.component';
import { ComboPolicyComponent } from '../form/widgets/combo-policy/combo-policy.component';
import { AutocompleteComponent } from '../form/widgets/autocomplete/autocomplete.component';
import { VerifyotpComponent } from '../form/widgets/verifyotp/verifyotp.component';
import { PolicySummaryComponent } from '../form/widgets/policy-summary/policy-summary.component';
import { EndorsementsComponent } from '../form/widgets/endorsements/endorsements.component';
import { CompanyComponent } from '../form/widgets/company/company.component';
import { CustomerComponent } from '../form/widgets/customer/customer.component';
import { CoinsuranceComponent } from '../form/widgets/coinsurance/coinsurance.component';
import { MaskedInputComponent } from '../form/controls/masked-input/masked-input.component';
import { IntlPhoneNumberComponent } from '../form/controls/intl-phone-number/intl-phone-number.component';
import { CountryComponent } from '../form/controls/country/country.component';
import { GoalSeekComponent } from '../form/widgets/goal-seek/goal-seek.component';
import { AnimhHeightDirective } from '../form/anim-height.directive';

import { WordingsComponent } from '../form/widgets/wordings/wordings.component';
import { SummaryComponent } from '../form/widgets/summary/summary.component';
import { IntermediaryComponent } from '../form/widgets/intermediary/intermediary.component';
import { FetchComponent } from '../form/widgets/fetch/fetch.component';
import { AddonGridComponent } from '../form/widgets/grid/addon-grid/addon-grid.component';
import { AddressComponent } from '../form/widgets/address/address.component';
import { IagreeComponent } from '../form/widgets/iagree/iagree.component';
import { InstallmentsComponent } from '../form/widgets/installments/installments.component';

import { IButtonDirective } from '../form/i-button/i-button.directive';
import { FlowComponent } from '../common/flow/flow.component';
import { EmailPDFComponent } from '../common/email.component';
import { GenericMessageComponent } from '../common/generic-message.component';
import { PartPaymentComponent } from '../common/part-payment.component';
import { TAndCComponent } from '../common/t-and-c.component';
import { IstepperComponent } from '../common/istepper/istepper.component';
// import { PaymentComponent } from '../common/payment/payment.component';
import { CartComponent } from '../common/cart/cart.component';


import { ShortNumberPipe } from './pipes/short-number.pipe';
import { NumberWordPipe } from './pipes/number-word.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DatePipe } from './pipes/idate.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed.pipe';
import { GenericPipe, GenericPipe2 } from './pipes/generic-pipe';
import { InsDatePickerAdapter } from './date.adapter';
// import { CurrencyPipe } from './pipes/currency.pipe';

import { CsvImportComponent } from './csv-import/csv-import.component';
import { components } from './../form/i-field/components';
import { PreferencesService, INS_VENDOR_TOKEN, Vendor } from '../lib/services/preferences.service';

import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
    declarations: [
        InsFormComponent,
        InputComponent,
        IFieldDirective,
        AnimhHeightDirective,
        IButtonDirective,
        FieldGroupComponent,
        LookupComponent,
        DateComponent,
        ButtonComponent,
        ButtonsComponent,
        RadioComponent,
        CheckboxComponent,
        PasswordComponent,
        HtmlComponent,
        FileComponent,
        EmailComponent,
        PriceComponent,
        PolicyMetaDataComponent,
        PeriodComponent,
        ReadonlyComponent,
        BenefitsComponent,
        OutputsComponent,
        PlanComponent,
        GridComponent,
        CustomerComponent,
        MasterPolicyComponent,
        DummyComponent,
        NumberComponent,

        CapitalizePipe,
        DateTimePipe,
        GenericPipe,
        GenericPipe2,
        DatePipe,
        ShortNumberPipe,
        NumberWordPipe,
        TimeElapsedPipe,
        CsvImportComponent,
        ComboPolicyComponent,
        AutocompleteComponent,
        VerifyotpComponent,
        MultiChoiceComponent,
        PolicySummaryComponent,
        EndorsementsComponent,
        CompanyComponent,
        CoinsuranceComponent,
        MaskedInputComponent,
        IntlPhoneNumberComponent,
        FlowComponent,
        EmailPDFComponent,
        GenericMessageComponent,
        PartPaymentComponent,
        TAndCComponent,
        CartComponent,
        IstepperComponent,
        // PaymentComponent,
        GoalSeekComponent,
		
		WordingsComponent,
        SummaryComponent,
        IntermediaryComponent,
        FetchComponent,
        AddonGridComponent,
        AddressComponent,
        IagreeComponent,
        CountryComponent,
        InstallmentsComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatRadioModule,
        MatTreeModule,
        MatSliderModule,
        MatChipsModule,
        MatRippleModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        OverlayModule
    ],
    exports: [
        InsFormComponent,
        InputComponent,
        IFieldDirective,
        IButtonDirective,
        FieldGroupComponent,
        LookupComponent,
        DateComponent,
        ButtonComponent,
        ButtonsComponent,
        RadioComponent,
        CheckboxComponent,
        PasswordComponent,
        HtmlComponent,
        FileComponent,
        EmailComponent,
        PriceComponent,
        PolicyMetaDataComponent,
        PeriodComponent,
        ReadonlyComponent,
        BenefitsComponent,
        OutputsComponent,
        PlanComponent,
        GridComponent,
        CustomerComponent,
        MasterPolicyComponent,
        DummyComponent,
        NumberComponent,
        VerifyotpComponent,
        MaskedInputComponent,
        IntlPhoneNumberComponent,
        FlowComponent,
        IstepperComponent,
        // PaymentComponent,
        CartComponent,

        CapitalizePipe,
        DateTimePipe,
        GenericPipe,
        GenericPipe2,
        DatePipe,
        ShortNumberPipe,
        NumberWordPipe,
        TimeElapsedPipe,
        
        CsvImportComponent,
        OverlayModule,
        GenericMessageComponent,
        PartPaymentComponent
    ],
    providers: [
        CurrencyPipe, 
        DecimalPipe,
        CapitalizePipe,
        DateTimePipe,
        GenericPipe,
        GenericPipe2,
        DatePipe,
        ShortNumberPipe,
        TimeElapsedPipe,
        { provide: DateAdapter, useClass: InsDatePickerAdapter}
    ]
})
export class InsFormModule {
    static forRoot(config: Vendor): ModuleWithProviders<InsFormModule> {
        // console.log('forRoot: ', config);
        return {
            ngModule: InsFormModule,
            providers: [{ provide: INS_VENDOR_TOKEN, useValue: config }],
        }
    }
}
