<div class="coinsurer-container">
    <div class="grid-12">
        <div class="grid-cols-4">
            <mat-form-field style="width: 100%;">
                <mat-label>{{field.label}}</mat-label>        
                <mat-select [(ngModel)]="data.coinsurance.type" (selectionChange)="typeChanged()">
                    <mat-option *ngFor="let type of types" [value]="type.value">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    
    <div class="coins" *ngIf="data.coinsurance.type!='Direct'">
        <div>
            <table class="gtable" style="max-width: 100% !important;">
                <tr><th *ngFor="let name of names">{{name.disp}}</th></tr>
                <tr *ngFor="let coin of data.coinsurers; let i=index">
                    <td *ngFor="let name of names" (click)="editCoin(i)">
                        <ng-template [ngIf]="this.fmap[name.name]">
                            {{coin[name.name] | generic2: this.fmap[name.name].pipe}}
                        </ng-template>
                        <ng-template [ngIf]="!this.fmap[name.name]">
                            {{coin[name.name]}}
                        </ng-template>
                    </td>
                </tr>
                <tr *ngIf="data.coinsurers.length==0"><td [attr.colspan]="names.length+1">No coinsurers added yet</td></tr>
            </table>
        </div>
        <div *ngIf="edit.index>=-2 && total != 100">
            <button mat-button ibutton (click)="addNew()">Add Coinsurer</button>
        </div>
        <div class="coin-add" *ngIf="edit.index>=-1">
            <div *ngFor="let grp of coinsgroups">
                <field-group #fgs
                    [fieldGroup]="grp" 
                    [data]="edit.data"
                    [policy]="policy"
                    [readonly]="readonly"
                    [layout]="grp.layout"
                    (onChange)="changed($event)">
                </field-group>
            </div>
            <button mat-button ibutton (click)="update()" *ngIf="edit.index==-1">Add</button>
            <button mat-button ibutton (click)="update()" *ngIf="edit.index>=0">Save</button>
            <button mat-button ibutton (click)="removeCoin()" *ngIf="edit.index>=0">Remove</button>
            <button mat-button ibutton (click)="cancel()">Cancel</button>
        </div>
        <div *ngIf="errors.length>0">
            <div style="padding-left: 1em;">Errors:</div>
            <div class="error" *ngFor="let error of errors">{{error}}</div>
        </div>
    </div>
</div>
