import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { excelDateToDate } from 'insapi';
import { PreferencesService } from '../services/preferences.service';

@Pipe({
    name: 'idate'
})
export class DatePipe implements PipeTransform {
    constructor(protected preferences: PreferencesService) {}

    transform(str: Date | moment.Moment | string | number, args?: any): any {
        if (!str) return '';
        if (typeof str == 'string' || typeof str === 'number') {
            let edate = excelDateToDate("" + str);
            if (edate != null) str = edate;
        } else if (str instanceof Date) {
            str = moment(str);
        }
        return (str as moment.Moment).format(this.preferences.dateFormat);
    }
}