import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { IField } from '../../form.interface';

@Component({
    selector: 'i-number',
    templateUrl: './number.component.html',
    styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {

    @Input() field!: IField;
    @Input() data: any;
    @Input() decimals: number = 0;

    duration: number = 500;
    value: number = 0;
    targetValue: number = 0;
    initValue: number = 0;
    startTs: number | null = null;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (this.data && changes['data']) {
            this.animateTo(+this.data[this.field.field_name]);
        }
        if (isNaN(this.value)) this.value = 0;
    }

    animateTo(target: number) {
        if (isNaN(target)) target = 0;
        this.targetValue = target;
        this.initValue = this.value;
        this.startTs = null;
        window.requestAnimationFrame((t) => this.step(t));
    }

    step(ts: number) {
        if (this.startTs === null) this.startTs = ts;
        let elapsed = (ts - this.startTs);
        this.value = +(this.initValue + ((this.targetValue - this.initValue) * elapsed / this.duration)).toFixed(this.decimals);
        if (isNaN(this.value) || elapsed >= this.duration) this.value = this.targetValue;
        else window.requestAnimationFrame((t) => this.step(t));
    }
}
