import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import 'echarts-extension-gmap';

// import 'echarts-gl/dist/echarts-gl.min.js';
// import 'echarts-gl';
// import { Bar3DChart } from 'echarts-gl/charts';
// import { Grid3DComponent } from 'echarts-gl/components';
// echarts.use([Bar3DChart, Grid3DComponent]);


if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
