import { Y } from '@angular/cdk/keycodes';
import moment from 'moment';

export interface FluxResult {
    grouped: {[key: string]: any};
    cardinality: {rows: {[key: string]: any}, cols: {[key: string]: any}};
    fields: string[];
    xdata: string[][];
}

class flux_parser {
    line_to_json(str: string) {
        let sep = ',';
        let esc = '\\';
        let q = '';
        let acc = '';
        let ret = [];
        for (let i=0; i<str.length; i++) {
            if (str[i] == sep) {
                ret.push(acc);
                acc = '';
            } else if (str[i] == esc) { // consume escaped character
                acc += str[i+1];
                i ++;
            } else if (str[i] == '"' || str[i] == "'") {
                if (q) {q = ''; for(; i<str.length-1; i++) if (str[i+1]==sep) break;} // skip trailing white spaces after end "
                else q = str[i]; //todo: assert acc is empty here
            } else {
                acc += str[i];
            }
        }
        ret.push(acc);
        return ret;
    }
    array(str: string) {
        let lines = str.split('\n');
        let ret = [];
        for (let i=0; i<lines.length; i++) {
            lines[i] = lines[i].trim();
            if (lines[i].startsWith('#')) continue;
            ret.push(this.line_to_json(lines[i]));
        }
        return ret;
    }

    _fix_year_mon(str: string, add: number) {
        let re: RegExp = /(\d\d\d\d\-\d\d)/;
        return str.replace(re, (m, p1) => moment(p1+'-01').add(add, 'year').format('YYYY-MM'));
    }
    
    merge(r1: any, r2: any, fields: string[]) {
        let grouped: any = {};
        let dummy = fields.map(x => undefined);
        for (let g in r1.grouped) {
            grouped[g] = {};
            let g2 = this._fix_year_mon(g, -1);
            for (let sg in r1.grouped[g]) {
                let sg2 = this._fix_year_mon(sg, -1);
                grouped[g][sg] = [...r1.grouped[g][sg], ...(r2.grouped[g2]?.[sg2] || dummy)];
            }
        }
        for (let g in r2.grouped) {
            let g1 = this._fix_year_mon(g, +1);
            if (!r1.grouped[g1]) grouped[g1] = {};
            for (let sg in r2.grouped[g]) {
                let sg1 = this._fix_year_mon(sg, +1);
                if (r1.grouped[g1] && r1.grouped[g1][sg1]) continue;
                grouped[g1][sg1] = [...dummy, ...r2.grouped[g][sg]];
            }
        }

        let ret: any = {grouped, cardinality: {}};
        ret.fields = [...fields, ...fields.map(x => x + '-YoY')];
        ret.cardinality.cols = {...r1.cardinality.cols};
        ret.cardinality.rows = {...r1.cardinality.rows};
        for (let x in r2.cardinality.cols) ret.cardinality.cols[this._fix_year_mon(x, +1)] = 1;
        for (let x in r2.cardinality.rows) ret.cardinality.rows[this._fix_year_mon(x, +1)] = 1;
        
        ret.xdata = r1.xdata.map((x: any) => new Array());
        for (let x in ret.cardinality.cols) {
            let parts = x.split('~');
            for (let i=0; i<parts.length; i++) ret.xdata[i].push(parts[i]);
        }
        ret.xdata.reverse();
        console.log('card: cols ', ret.cardinality.cols, ret.cardinality.rows);
        return ret;
    }

    group_result(lines: string[], rows: string[], cols: string[], fields: string[]): FluxResult {
        let head = this.line_to_json(lines[0]);
        let ridx = rows.map(x => head.indexOf(x));
        let cidx = cols.map(x => head.indexOf(x));
        let vidx = fields.map((x: string, i: number) => head.indexOf(fields.length==1 ? '_value' : '_value_d'+i));
        let grouped: {[key: string]: any} = {};
        let cardinality: any = {cols: {}, rows: {}};

        // initialize xdata (hierarchical) with number of columns
        //
        let xdata: string[][] = [];
        if (cidx.length == 0) xdata.push(new Array());
        else cidx.map(x => xdata.push(new Array()));

        
        for (let i=1; i<lines.length; i++) {
            let line = this.line_to_json(lines[i]);
            if (line.length < head.length) continue;
            let rkey = ridx.map(x => line[x]).join('~');
            let ckey = cidx.map(x => line[x]).join('~');
            if (!grouped[ckey]) grouped[ckey] = {};
            grouped[ckey][rkey] = vidx.map(x => Number(line[x]).toFixed(0));
            cardinality.cols[ckey] = 1;
            cardinality.rows[rkey] = 1;
        }
        for (let key of Object.keys(grouped)) {
            let parts = key.split('~');
            for (let i=0; i<parts.length; i++) xdata[i].push(parts[i]);
        }

        xdata.reverse();
        // for (let i=0; i<xdata.length; i++) {
        //     let xd = [xdata[i][0]];
        //     for (let j=1; j<xdata[i].length; j++) {
        //         xd.push( xdata[i][j] == xdata[i][j-1] ? '' : xdata[i][j]);
        //     }
        //     xdata[i] = xd;
        // }
        // console.log('xdata: ', xdata);
        return {cardinality, grouped, fields, xdata};
    }

    _fix_field_name(name: string) {
        let parts = name.split(' as ');
        if (parts.length == 2) return parts[1].substring(1, parts[1].length-1);
        return name;
    }

    parse_flux(str: string, rows: string[], cols: string[], fields: string[]): FluxResult | null {
        let lines = str.split('\n');
        let parts: any[] = [], part: any[] = [];
        for (let l=0; l<lines.length; l++) {
            if (lines[l].startsWith('#')) continue;
            if (lines[l].trim().length == 0) {if(part.length > 0) parts.push(part); part = [];}
            else part.push(lines[l].trim());
        }
        if (parts.length == 0) return null;
        fields = fields.map(x => this._fix_field_name(x));
        let groups: FluxResult[] = parts.map(x => this.group_result(x, rows, cols, fields));
        console.log('result: ', groups);
        if (groups.length > 1) return this.merge(groups[0], groups[1], fields);
        return groups[0];
    }

    _add_growth(res: any) {
        let flen = res.fields.length / 2;
        for (let g in res.grouped) {
            for (let sg in res.grouped[g]) {
                for (let i=0; i<flen; i++) {
                    let cv = res.grouped[g][sg][i];
                    let pv = res.grouped[g][sg][i+flen];
                    let gv = 0;
                    if (cv && pv) gv = +(Number(100 * (cv - pv) / pv).toFixed(1));
                    // res.grouped[g][sg].push(gv);
                    res.grouped[g][sg] = [gv];
                }
            }
        }
        for (let i=0; i<flen; i++) {
            // res.fields.push(res.fields[i] + '-Growth');
            res.fields = [res.fields[i] + '-Growth'];
        }
    }


    /**
     * 
     * @param csv 
     * @param rows string[] list of columns in row
     * @param cols string[]  list of columns in series
     * @param fields string[] measures (numbers) that are aggregated
     * @returns 
     * 
     * flux csv has multi table format  separated by empty line
     * #group,false,false,true,true,false
     * #default,_result,,,,
     * ,result,table,product_name,stage,_value
     * ,,0,d1,d2,d3
     * 
     * #group,false,false,true,true,false
     * #default,_result,,,,
     * ,result,table,product_name,stage,_value
     * ,,0,d1,d2,d3
     * 
     */
    parse(csv: string, rows: string[], cols: string[], fields: string[]) {
        console.log('ds: parse rows:', rows, 'cols:', cols, fields);
        let lines = csv.split('\n');
        let parts: string[][][] = [], table: string[][] = [];
        for (let l=0; l<lines.length; l++) {
            if (lines[l].startsWith('#')) continue;
            if (lines[l].trim().length == 0) {if(table.length > 0) parts.push(table); table = [];}
            else table.push(this.line_to_json(lines[l].trim()));
        }
        if (parts.length == 0) return null;

        fields = fields.map(x => this._fix_field_name(x));
        let tables: any = parts.map(x => this._process_flux_table(x, rows, cols, fields));
        // console.log('ds: tables', tables);
        if (tables.length > 1) tables = this._merge_yoy(tables[0], tables[1]);
        console.log('ds: tables', tables);
        return tables;
    }

    _process_flux_table(lines: string[][], rows: string[], cols: string[], fields: string[]) {
        let head = lines.shift();
        console.log('ds: head', head);
        if (!head) return [];
        let ridx = rows.map(x => head?.indexOf(x));
        let cidx = cols.map(x => head?.indexOf(x));
        let dataset: any[] = [[...head, 'ckey', 'rkey', 'id']];
        for (let i=0; i<lines.length; i++) {
            let ckey = cidx.map((c: any) => lines[i][c]).join('~');
            let rkey = ridx.map((r: any) => lines[i][r]).join('~');
            dataset.push([...lines[i], ckey, rkey, ckey+'~'+rkey]);
        }
        let vidx: number[] = fields.map((x: string, i: number) => head?.indexOf(fields.length==1 ? '_value' : '_value_d'+i)||0);
        return {dataset, vidx, cidx, ridx, ckey: dataset[0].length-3, rkey: dataset[0].length-2, id: dataset[0].length-1};
    }

    // _merge_yoy(t1: string[][], t2: string[][], vidx: number[]) {
    _merge_yoy(t1: any, t2: any) {
        let res: any = {dataset: [], ckey: t1.ckey, rkey: t1.rkey, id: t1.id, 
            vidx: [...t1.vidx, ...t2.vidx.map((x: number, i: number) => t1.dataset[0].length+i)],
            gidx: [...t1.vidx.map((x: number, i: number) => t1.dataset[0].length+t2.vidx.length+i)]
        };

        res.dataset.push([...t1.dataset[0], ...t2.vidx.map((_:any) => '')]); // add top most header in t1
        t2.dataset.shift(); // remove the top most header row in t2

        for (let r=1; r<t1.dataset.length; r++) {
            let row = t1.dataset[r];
            let id = row[t1.id];
            let found = false;
            for (let j=0; j<t2.dataset.length; j++) {
                if (id == t2.dataset[j][t2.id]) { // found a matching record in YoY, append to existing
                    let vals = t2.vidx.map((x: any) => t2.dataset[j][x]);
                    let perc = t1.vidx.map((x: any, i: number) => +x != 0 ? +(Number(((+row[x] - +t2.dataset[j][t2.vidx[i]]) * 100) / (+t2.dataset[j][t2.vidx[i]])).toFixed(1)) : 0);
                    res.dataset.push([...row, ...vals, ...perc]);
                    found = true;
                    t2.dataset.splice(j, 1); // speed up subsequent searches
                    break;
                }
            }

            if (!found) {
                res.dataset.push([...row, ...t2.vidx.map((_: any) => '')]);
            }
        }

        // append all left out t2 records
        for (let r=0; r<t2.dataset.length; r++) {
            let row = new Array(t1.dataset[0].length);
            row[t1.ckey] = t2.dataset[r][t2.ckey];
            row[t1.rkey] = t2.dataset[r][t2.rkey];
            row[t1.id] = t2.dataset[r][t2.id];
            t2.cidx.map((x: any, i:number) => row[t1.cidx[i]] = t2.dataset[r][x]);
            t2.ridx.map((x: any, i:number) => row[t1.ridx[i]] = t2.dataset[r][x]);

            let vals = t2.vidx.map((x: any) => t2.dataset[r][x]);
            let perc = t1.vidx.map((x: any) => 0);
            res.dataset.push([...row, ...vals, ...perc]);
        }

        return res;
    }
}
export const flux = new flux_parser();
