import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Route, NavigationStart } from "@angular/router";
import { insapi } from 'insapi';
import { environment } from './../../../environments/environment';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-bulk',
    templateUrl: './bulk.component.html',
    styleUrls: ['./bulk.component.scss']
})
export class BulkComponent implements OnInit {
    @ViewChild(MatPaginator) recpage!: MatPaginator;
    @ViewChild(MatPaginator) sespage!: MatPaginator;

    @ViewChild(MatSort) set recsort(value: MatSort)  {this.recSource.sort = value;}
    // @ViewChild(MatSort) recsort!: MatSort;
    @ViewChild(MatSort) sessort!: MatSort;

    params: any = {};
    sessions: any[] = [];

    session: any = null;
    records: any[] = [];
    session_status: any = {0: 'Not started', 1: 'Running', 2: 'Paused', 3: 'Cancelled', 4: "Failed", 5: 'Completed'};
    record_status: any = {0: 'Not started', 1: 'Running', 2: 'Completed', 3: "Errors", 4: 'Failed'};
    declarations: boolean = false;
    progress: any = {};
    timer: any = null;
    refId: string = "";

    // display related
    conf: any = environment.vendor['bulk']['all-groups'];
    recSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    sesSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
    columns: string[] = [];
    sescolumns: string[] = ['session_id', 'started_at', 'ended_at', 'status_disp', 'declaration'];
    productName: string = '';
    counts: any = {total: 0, errors: 0};
    rsubscription: Subscription | null = null;

    // new code 
    baseURL: string = "";

    constructor(private activatedRoute: ActivatedRoute) {
        this.rsubscription = this.activatedRoute.queryParams.subscribe(params => this._load(params));
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.recSource.paginator = this.recpage;
        this.recSource.sort = this.recsort;

        this.sesSource.paginator = this.sespage;
        this.sesSource.sort = this.sessort;
    }

    ngOnDestroy(): void {
        if (this.timer) clearTimeout(this.timer);
        this.timer = null;
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
    }


    async _load(params: any) {
        this.params = {...params};
        if (this.params.decl) this.declarations = true;
        if (this.params.product_id) {
            let prod = await insapi.productFromId(this.params.product_id);
            if (prod) this.productName = prod.product_name;
        }

        delete this.params['decl'];
        await this._reload_sessions();
    }

    // get the list of all bulk upoload sessions (authored by me and my team) ...
    //
    async _reload_sessions() {
        let prod = this.activatedRoute.snapshot.paramMap.get("product");
        if (!prod) return;
        this.conf = environment.vendor['bulk'][prod] || environment.vendor['bulk']['all-groups'];

        let url = '/api/v1/bulker?product=' + encodeURIComponent(prod);
        if (this.params.master_policy_no) url += '&master_policy_no=' + encodeURIComponent(this.params.master_policy_no);
        this.sessions = await insapi.__xget(url) || [];
        for (let ses of this.sessions) {
            ses.status_disp = this.session_status[ses.status] || 'unk';
            let constants = JSON.parse(ses.constants||'{}');
            ses.declaration = constants.declaration;
        }
        console.log('sessions:', this.sessions);
        if (this.sesSource) {
            this.sesSource.paginator = this.sespage;
            this.sesSource.sort = this.sessort;
            this.sesSource.data = this.sessions;
        }
    }

    // new change srimathi 

    async download_template() {

        var url = document.getElementsByTagName('base')[0].href;

        var arr = url.split("/");

        this.baseURL = arr[0] + "//" + arr[2] + "/";

        window.location.href = this.baseURL + "bulk_decl_upload_temp.csv";

    }

    async onUpload(ev: any, truncate: boolean = false) {
        let prod = this.activatedRoute.snapshot.paramMap.get("product");
        if (!prod) return;

        // ev.target.files
        const formData = new FormData();
        formData.append('file', ev.target.files[0], ev.target.files[0].file_name);
        formData.append('ref_id', this.refId);
        if (truncate) formData.append('truncate', '1');
        for (let key in this.params) formData.append(key, this.params[key]);

        let url = '/api/v1/bulker/attach/' + encodeURIComponent(prod);
        if (this.params.master_policy_no) {
            url = '/api/v1/mop/attach/certificates/';
            if (this.declarations) url = '/api/v1/mop/attach/declarations/';
            url += this.params.product_id;
        }

        // new change srimathi
        // let ret = await insapi.__xpost(url, formData);
        // await this._reload_sessions();
        // if (ret) this.selectSession(ret.session_id);
        // console.log('ret:', ret);

        let arraylist: any = [];
        const reader = new FileReader();
        reader.readAsText(ev.target.files[0]);
        reader.onload = async (ev) => {
            const text = reader.result;
            arraylist = this.csvJSON(text);
            //console.log(arraylist.length);
            if (arraylist.length != 0) {
                let masterDataValidation = await insapi.__xpost('/api/v1/mop/bulkUploadValidation?master_policy_no=' + encodeURIComponent(this.params.master_policy_no), { arraylist });
                if (masterDataValidation.status == 0) {
                    let ret = await insapi.__xpost(url, formData);
                    await this._reload_sessions();
                    if (ret) this.selectSession(ret.session_id);
                    console.log('ret:', ret);
                } else {
                    insapi.showMessage(masterDataValidation.txt, 0);
                }
            }
        };
    }
    csvJSON(csvText: any) {
        let lines: any = [];
        const linesArray = csvText.split('\n');
        // for trimming and deleting extra space
        linesArray.forEach((e: any) => {
            const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ',').trim();
            lines.push(row);
        });
        // for removing empty record
        lines.splice(lines.length - 1, 1);
        const result = [];
        const headers = lines[0].split(",");

        for (let i = 1; i < lines.length; i++) {

            const obj:any = {};
            const currentline = lines[i].split(",");

            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }
            result.push(obj);
        }
        //return result; //JavaScript object
        // return JSON.stringify(result); //JSON
        return result;
    }
    // end 

    async _reload_records() {
        // load session records
        let records = await insapi.__xget('/api/v1/bulker/records/' + encodeURIComponent(this.session.session_id));
        this.records = [];
        this.counts = {total: 0, errors: 0};
        for (let i=0; i<records.length; i++) {
            this.records.push({i, ...records[i].data, ...records[i], status_disp: this.record_status[''+records[i].status] || 'Unk'});
            if (records[i].status >= 3) this.counts.errors ++;
            this.counts.total ++;
        }

        if (this.recSource) this.recSource.data = this.records;
        this.columns = this.conf.columns.map((x: any) => x.disp);
        this.recSource.paginator = this.recpage;
        this.recSource.sort = this.recsort;
    }

    async selectSession(sessionId: string) {
        this.session = null;
        this.refId = '';
        for (let ses of this.sessions) 
            if (sessionId == ses.session_id) {
                this.session = ses;
                this.refId = ses.ref_id;
            }

        if (!this.session) return;
        await this._reload_records();
        this._start_status_check();
        console.log('records:', this.records);
    }

    async start() {
        if (!this.session) return;
        this.session.status = 1;
        await insapi.__xpost('/api/v1/bulker/start/' + encodeURIComponent(this.session.session_id), {});
        this._start_status_check();
    }

    async pause() {
        if (!this.session) return;
        await insapi.__xpost('/api/v1/bulker/pause/' + encodeURIComponent(this.session.session_id), {});
        this._start_status_check();
    }
    async cancel() {
        if (!this.session) return;
        await insapi.__xpost('/api/v1/bulker/cancel/' + encodeURIComponent(this.session.session_id), {});
        this._start_status_check();
    }


    async applyFilter(ev: any) {
        if (ev.target?.value)
            this.recSource.filter = ev.target.value.trim().toLowerCase();
        else
            this.recSource.filter = "";
    }

    _start_status_check() {
        if (!this.session || this.timer) return;
        this._update_status();
    }

    async _update_status() {
        if (this.session) {
            try {
                this.progress = await insapi.xget('/api/v1/bulker/progress/' + encodeURIComponent(this.session.session_id));
                if (this.session) this.session.status = this.progress.status;
                console.log('progress: ', this.progress);
                if (this.progress.status == 5) {
                    await this._reload_records();
                    return; // end, no more update ...
                }
            } catch (e) {
                console.log(e);
            }    
        }

        this.timer = null;
        if (+this.session.status < 3 )
            this.timer = setTimeout(() => this._update_status(), 2000);
    }
    async switchToSessions() {
        this.session = null;
        this.refId = '';
        if (this.timer) clearTimeout(this.timer);
        this.timer = null;
        await this._reload_sessions();
    }
}
