<ng-template [ngIf]="(!field.link_parent) || parent">
    <div *ngFor="let ag of field.addon_groups; trackBy: trackAg" class="addon-group-container">
        <div [class]="'addon-group-caption' + (parent?'-linked':'')">{{ag.group_name || 'ADDONs/Covers'}}</div>
        <field-group #fgs
            [fieldGroup]="field.fldgrp" 
            [data]="data"
            [policy]="policy"
            [readonly]="readonly"
            [layout]="field.fldgrp.layout"
            [disableAnim]="true"
            (onChange)="onChange.emit($event)">
            <ng-template #fgcaption></ng-template>
            <ng-template #fgcontent let-form="form">
                <ng-container *ngTemplateOutlet="addons; context: {form, addons: ag.addons, level: 1, ctrclass: 'addon-container'}" ></ng-container>
            </ng-template>
        </field-group>
    </div>
</ng-template>

<ng-template #addons let-form="form" let-level="level" let-addons="addons" let-ctrclass="ctrclass">
    <div [class]="ctrclass">
        <ng-template ngFor let-ao [ngForOf]="addons" [ngForTrackBy]="trackAo">
            <ng-container *ngTemplateOutlet="addon; context: {form, ao, level}" ></ng-container>
        </ng-template>
    </div>
</ng-template>

<ng-template #addon let-ao="ao" let-form="form" let-level="level">
    <ng-container *ngTemplateOutlet="alignleft ? addon_left : addon_right; context: {form, ao, readonly, level}" ></ng-container>
    <ng-template [ngIf]="ao.row?.[field.addon_def.opted_name]=='Yes'">
        <ng-container *ngTemplateOutlet="addons; context: {form, level: level+1, addons: ao.children}" ></ng-container>
    </ng-template>
</ng-template>

<ng-template #addon_left let-ao="ao" let-form="form" let-readonly="readonly" let-level="level">
    <div class="grid-12" *ngIf="ao.visible && ao.rfixed">
        <div class="grid-cols-5" [class]="'indent-level-'+level" style="overflow: hidden; position: relative;" [attr.data-ifw]="ao.fopted.field_name">
            <ng-container ifield [field]="ao.fopted" [group]="form" [readonly] = "ao.fopted.readonly || readonly" [policy]="policy" [data]="data"
            (onChange)="onChange.emit($event)"></ng-container>
        </div>
        <div class="grid-cols-4" [class]="'indent-level-'+level"  *ngIf="!alignleft">{{ao.desc}}</div>

        <ng-container *ngTemplateOutlet="parameditor; context: {form, ao}" ></ng-container>

        <div class="grid-cols-1">
            <div *ngIf="ao.row[field.addon_def.opted_name]=='Yes'" style="text-align: right;">{{ao.row[ao.hilite_name]}}</div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="parameditor_exp; context: {form, ao}" ></ng-container>
</ng-template>

<ng-template #addon_right let-ao="ao" let-form="form" let-readonly="readonly" let-level="level">
    <div class="grid-12" *ngIf="ao.visible && ao.rfixed">
        <div class="grid-cols-4" [class]="'indent-level-'+level" >{{ao.desc}}</div>
        <ng-container *ngTemplateOutlet="parameditor; context: {form, ao}" ></ng-container>
        <div class="grid-cols-1">
            <div *ngIf="ao.row?.[field.addon_def.opted_name]=='Yes'" style="text-align: right;">{{ao.row[ao.hilite_name]}}</div>
        </div>
        <div class="grid-cols-1" style="overflow: hidden; position: relative;" [attr.data-ifw]="ao.fopted.field_name">
            <ng-container ifield [field]="ao.fopted" [group]="form" [readonly] = "ao.fopted.readonly || readonly" [policy]="policy" [data]="data"
                (onChange)="onChange.emit($event)"></ng-container>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="parameditor_exp; context: {form, ao}" ></ng-container>
</ng-template>

<ng-template #parameditor let-ao="ao" let-form="form" let-readonly="readonly">
    <div class="grid-cols-6" [class]="'grid-' + (field.addon_def.max_params*4)">
        <ng-template [ngIf]="ao.row?.[field.addon_def.opted_name]=='Yes' && ao.pfields?.length < 3">
            <ng-template ngFor let-field [ngForOf]="ao.pfields" [ngForTrackBy]="trackParam">
                <div [class]="['grid-cols-'+field.span]" style="overflow: hidden; position: relative;" *ngIf="field.type!='hidden' && field.visible" [attr.data-ifw]="field.field_name">
                    <ng-container #fcs ifield [field]="field" [group]="form" [readonly] = "field.readonly || readonly" [policy]="policy" [data]="data"
                        (onChange)="onChange.emit($event)"></ng-container>
                </div>
            </ng-template>
        </ng-template>
    </div>
</ng-template>
<ng-template #parameditor_exp let-ao="ao" let-form="form" let-readonly="readonly">
    <div class="grid-cols-12" [class]="'grid-12'" *ngIf="ao.row?.[field.addon_def.opted_name]=='Yes' && ao.pfields?.length >= 3" style="padding-left: 2em;">
        <ng-template ngFor let-field [ngForOf]="ao.pfields" [ngForTrackBy]="trackParam">
            <div [class]="['grid-cols-'+field.span]" style="overflow: hidden; position: relative;" *ngIf="field.type!='hidden' && field.visible" [attr.data-ifw]="field.field_name">
                <ng-container #fcs ifield [field]="field" [group]="form" [readonly] = "field.readonly || readonly" [policy]="policy" [data]="data"
                    (onChange)="onChange.emit($event)"></ng-container>
            </div>
        </ng-template>
    </div>
</ng-template>

<!-- <ng-template #addons_accordion let-form="form" let-level="level" let-addons="addons">
    <div style="padding-top: 1em; padding-bottom: 1em;">
        <mat-accordion #accordion="matAccordion" [multi]="true">
            <mat-expansion-panel *ngFor="let ao of addons" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ao.desc}}</mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-template>
 -->
