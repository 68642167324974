import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { insapi, IProfile, Policy } from 'insapi';
import { Subscription } from 'rxjs';
import { IField } from '../../form.interface';



let pipes: { [key: string]: any } = {
    date: DatePipe,
    currency: CurrencyPipe,
    number: DecimalPipe
};

@Component({
    selector: 'lib-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

    field!: IField;
    policy!: Policy;
    profile!: IProfile | null;
    layout: any = {
        "cls": "",
        "header_style": {},
        "style": {},
        "format": "",
        "type": ""
    };
    listDef: any = {
        /* "headers": ["caption","INR","USD"],
         "fields":[
             {"caption":"Sum Insured Summary :","INR":"","USD":"","test":"", "layout" : {"cls" : "","style" : {"font-weight": "bold"}}},
             {"caption":"Total Sum Insured-non Floater :","INR":"total_of_all_location_SI","USD":"total_of_all_location_SI","test":"total_of_all_location_SI", "layout" : {"cls" : "","style" : {"margin-left": "1rem"}}},
             {"caption":"Peak Location SI -Non floater :","INR":"max_SI_location","USD":"max_SI_location","test":"max_SI_location", "layout" : {"cls" : "","style" : {"margin-left": "0rem"}}},
             {"caption":"Location SI -Non floater :","INR":"min_SI_location","USD":"min_SI_location","test":"max_SI_location"}
         ],
         "columnlayout" : {
           "caption" :{
             "cls" : "",
             "header_style" : {"text-indent": "-9999px", "font-weight":"bold", "width" : "max-content"},
             "style" : {"text-align": "left"},
             "format" : "",
             "type" : "string"
           },
           "INR" : {
             "cls" : "",
             "header_style" : {"text-align": "right", "font-weight":"bold"},
             "style" : {"text-align": "right"},
             "format" : "currency",
             "type" : "data"
           },
           "USD" : {
             "cls" : "",
             "header_style" : {"text-align": "right", "font-weight":"bold" },
             "style" : {"text-align": "right"},
             "format" : "",
             "type" : "data"
           }
         }*/
    }
    data: any = {};

    subscription: Subscription | null = null;
    
    constructor() {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {this.profile = profile;});
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    ngOnInit(): void {
        this.listDef = this.field.props;
        if (this.listDef.headers) {
            for (let item of this.listDef.headers) {
                if (!this.listDef.columnlayout[item])
                    this.listDef.columnlayout[item] = this.layout;

                if (this.listDef.columnlayout[item].format && typeof this.listDef.columnlayout[item].format == 'string') {
                    let format = this.listDef.columnlayout[item].format || '';
                    console.log("format", format)
                    let parts = format.split(',');
                    let pname = parts.shift();
                    this.listDef.columnlayout[item].format = [pipes[pname], parts[0]];
                }
            }

            if (this.listDef.fields) {
                for (let item of this.listDef.fields) {
                    if (!item.layout)
                        item.layout = this.layout;

                    if (item.if && !item.ifFunc)
                        item.ifFunc = this._evalFunc(item.if, null);
                    
                    for (let key in item) {
                        if (this.listDef.columnlayout[key] && this.listDef.columnlayout[key].type != 'string' && item[key] && !item[key+'func']) {
                            //console.log("columnlayout",key,this.listDef.columnlayout[key],item[key]);
                            item[key+'func'] = this._evalFunc(item[key], null);
                        }
                    }
                }
                //console.log("this.listDef.fields",this.listDef.fields)
            }
        }
    }

    mergeStyle(columnlayout: any, rowlayout: any) {
        return Object.assign({}, columnlayout, rowlayout);
    }

    _evalFunc(expr: string, field: IField | null) {
        expr = expr.replace(/this\.mod\.data/g, 'data');
        expr = expr.replace(/this\.mod/g, 'policy');
        expr = expr.replace(/this\.data/g, 'data');
        let _expr = "try{/*console.log('if func: ', profile);*/return ("+expr+");}catch(e){/*console.log('if func: " + (field?field.field_name:'') + "', e.message);*/ return false;}";
        // console.log('expr: ', _expr);
        return new Function('data', 'profile', 'policy', "with(data){" + _expr +"}");
    }
}
