import { Component, OnInit } from '@angular/core';
import { insapi } from 'insapi';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-roles',
    templateUrl: './roles.component.html',
    styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

    roleName: string = '';
    userName: string = '';
    privName: string = '';
    roleUsers: string[] = [];
    rolePrivileges: {[key: string]: any} = {GET: [], POST: [], DELETE: []};
    privileges: {[key: string]: any[]} = {};
    privNames: string[] = [];
    rsubscription: Subscription | null = null;
    constructor(private activatedRoute: ActivatedRoute) {
        this.rsubscription = this.activatedRoute.paramMap.subscribe(params => {
            this.roleName = params.get('role_name') || '';
            this.roleChanged();
        });
    }
    ngOnDestroy(): void {
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
    }

    ngOnInit(): void {
    }

    async _get_role_privileges() {
        let rolePriv = await insapi.__xget('/api/v1/role/privilege?role_name='+encodeURIComponent(this.roleName));
        
        this.rolePrivileges = {GET: [], POST: [], DELETE: []};
        for(let p in rolePriv) {
            let prv: any = this.privileges[p];
            prv.disp_name = prv.priv_name;
            if (prv.disp_name.toLowerCase().startsWith('view '))prv.disp_name = prv.disp_name.substr(5);
            if (prv.disp_name.toLowerCase().startsWith('modify '))prv.disp_name = prv.disp_name.substr(7);
            this.rolePrivileges[prv.api_method].push(prv);
        }
    }
    async roleChanged() {
        if (!this.roleName) return;
        this.roleUsers = await insapi.__xget('/api/v1/role/users?role_name='+encodeURIComponent(this.roleName));
        this.privileges = (await insapi.privileges()).reduce((a: any, x: any) => {a[x.priv_name]=x; return a;}, {});
        this.privNames = Object.keys(this.privileges);
        await this._get_role_privileges();
    }

    async userChanged() {
        console.log('user changed ...', this.userName);
    }
    async privChanged() {
        console.log('priv changed ...', this.privName);
    }
    async addPrivilege() {
        if (!this.privName || !this.privileges[this.privName]) return;
        await insapi.__xpost('/api/v1/role/privilege/'+encodeURIComponent(this.roleName)+'/'+encodeURIComponent(this.privName), {});
        await this._get_role_privileges();
    }
    async removePriv(privName: string) {
        await insapi.__xdel('/api/v1/role/privilege/'+encodeURIComponent(this.roleName)+'/'+encodeURIComponent(privName));
        await this._get_role_privileges();
    }
    async addUser() {
        if (!this.userName) return;
        await insapi.__xpost('/api/v1/role/'+encodeURIComponent(this.roleName)+'/'+encodeURIComponent(this.userName), {});
        this.roleUsers = await insapi.__xget('/api/v1/role/users?role_name='+encodeURIComponent(this.roleName));
    }
    async removeUser(userName: string) {
        await insapi.__xdel('/api/v1/role/user/'+encodeURIComponent(this.roleName)+'/'+encodeURIComponent(userName));
        this.roleUsers = await insapi.__xget('/api/v1/role/users?role_name='+encodeURIComponent(this.roleName));
    }

}
