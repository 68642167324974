import { Injectable, InjectionToken, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IEndorsement, IPolicy, http, insapi } from 'insapi';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface Vendor {
    [key: string]: any
};
export interface PremiumDetails {
    total_amount: number;
    premium_value: number;
    message: string;
    total_pipe?: string[];
    premium_pipe?: string[];
    tax_pipe?: string[];
    charges?: {
        total: number;
        charge_parts: any[];
    },
    tax: {
        tax: number;
        tax_parts: {
            absolute: boolean; name: string; rate: number; value: number; desc: string;
}[];
    }
}
export const INS_VENDOR_TOKEN = new InjectionToken<Vendor>('INS_VENDOR');

export interface Country {
    name: string;
    lname: string;
    iso2: string;
    liso2: string;
    phone: string;
    currency: string;
    emoji: string;
}

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {

    constructor(@Inject(INS_VENDOR_TOKEN) public readonly vendor: Vendor | null,
        private _sanitizer: DomSanitizer) {
        console.log('ins-form: vendor', this.vendor);
        this.company = this.vendor?.company || '';
        this.fyStart = this.vendor?.fyStart || "01-01";
        this.dateFormat = this.vendor?.dateFormat || 'DD-MM-YYYY';
        this.dateTimeFormat = this.vendor?.dateTimeFormat || 'DD-MM-YYYY';
        this.buttonStyle = this.vendor?.button || '';

        if (this.vendor && !this.vendor.toast) {
            this.vendor.toast = {"duration" : {"long": 5000, "short": 2500}};
        }

        if (this.vendor && !this.vendor.widgets.grid) {
            this.vendor.widgets.grid = {
                "editor": {"layout": {"caption": {"show": false},"grids": 12,"style": {"max-width": "99%", "min-width": "99%"}}}
            }
        }
    }

    premiumData: PremiumDetails = {total_amount: 0, 
        premium_value: 0, message: '', 
        tax: {tax: 0, tax_parts: []},
    };
    premiumSubject = new BehaviorSubject<any>(this.premiumData);

    title: string = 'Insillion';
    company: string = '';
    dateTimeFormat: string = 'DD-MM-YYYY HH:mm:ss';
    dateFormat: string = 'DD-MM-YYYY';
    fyStart: string = "01-01";
    buttonStyle: string = "";

    countries: Country[] = [];
    name2Country: {[key: string]: Country} = {};

    tmplfuncs: {[key: string]: Function} = {};

    _capitalize(name: string) {
        if (!name) return '';
        return name.split('_').map(x => (x[0]||'').toUpperCase() + x.slice(1)).join(' ');
    }
    
    _update_premium(policy: IPolicy | IEndorsement | null, uw: boolean, obj: any) {
        if (!policy || (policy.endorsement && !this.vendor?.endorsement_premiumAtNavBar)) {
            this.premiumData.premium_value = 0;
            this.premiumData.total_amount = 0;
            this.premiumData.message = '';
            this.premiumData.tax = {tax: 0, tax_parts: []};
        } else {
            if (policy.eproposal?.data.premium_value !== undefined) {
                // this.premiumData.premium_value = policy.eproposal?.data.premium_value || 0;
                this.premiumData.premium_value = policy.inspect?.data.computed_premium_value || policy.eproposal?.data.computed_premium_value || 0;
                this.premiumData.tax = policy.inspect?.data.tax_details || policy.eproposal?.data.tax_details || {tax: 0, tax_parts: []};
                this.premiumData.charges = policy.inspect?.data.charges || policy.eproposal?.data.charges || null;
            } else {
                this.premiumData.premium_value = (policy.proposal?.proposal_id ? policy.proposal?.data.premium_value : policy.quote?.data.premium_value) || 0;
                this.premiumData.tax = policy.proposal?.data.tax_details || policy.quote?.data.tax_details || {tax: 0, tax_parts: []};
                this.premiumData.charges = policy.proposal?.data.charges || policy.quote?.data.charges || null;
            }
            

            this.premiumData.total_amount = (policy.total_amount || 0);
            this.premiumData.message = '';
            
            if (isNaN(this.premiumData.premium_value)) this.premiumData.premium_value = 0;

            if (obj?.referral && !uw) {
                this.premiumData.message = this.vendor?.policy?.referral?.premiumMessage || 'Referral case';
                this.premiumData.total_amount = 0;
                this.premiumData.premium_value = 0;
            }
            if(this.premiumData?.tax?.tax_parts){
                for (let part of this.premiumData.tax.tax_parts) {
                    if (part.absolute) part.desc = ''; // '@' + part.rate;
                    else if (part.rate > 0) part.desc = '@' + part.rate + '%';
                    else part.desc = '';
                }
            }

            this.premiumData.premium_pipe = this.vendor?.policy?.premium?.premiumFormat || ['currency'];
            this.premiumData.total_pipe = this.vendor?.policy?.premium?.totalFormat ?? this.premiumData.premium_pipe;
            this.premiumData.tax_pipe = this.vendor?.policy?.premium?.taxFormat ?? this.premiumData.premium_pipe;

            if (obj.product?.data?.total_value_precision != '' && !isNaN(obj.product?.data?.total_value_precision)) {
                this.premiumData.total_amount = Number(Number(+this.premiumData.total_amount).toFixed(+obj.product?.data?.total_value_precision));
                console.log('rounding off total:', this.premiumData.total_pipe);
            } else if (obj.product?.data?.premium_value_precision != '' && !isNaN(obj.product?.data?.premium_value_precision)) {
                this.premiumData.premium_value = Number(Number(+this.premiumData.premium_value).toFixed(+obj.product?.data?.premium_value_precision));
                this.premiumData.total_amount = Number(Number(+this.premiumData.total_amount).toFixed(+obj.product?.data?.premium_value_precision));
            }
            // console.log('policy-referral:', obj?.referral, uw, this.premiumData.message, 'total:', this.premiumData.total_amount, 'prem:', this.premiumData.premium_value);
        }
        this.premiumSubject.next({...this.premiumData});
        return {...this.premiumData};
    }

    __update_countries(countries: Country[]) {
        this.countries = countries;
        this.name2Country = {};
        for (let c of this.countries) {
            this.name2Country[c.lname] = c;
            this.name2Country[c.iso2.toLowerCase()] = c;
            this.name2Country[c.phone.toLowerCase()] = c;
        }
    }

    async __load_countries() {
        let countries = localStorage.getItem('countries');
        try {
            if (countries !== null) {
                this.__update_countries(JSON.parse(countries));
                return;
            }
        } catch (e) {
            countries = null;
            console.log(e)
        }

        if (countries === null) {
            let res = await fetch('https://idata.insillion.com/public/res/countries.json');
            if (res.ok) {
                let countries = await res.json();
                for (let c of countries) {c.lname = c.name.toLowerCase(); c.liso2 = c.iso2.toLowerCase();}
                this.__update_countries(countries);
                localStorage.setItem('countries', JSON.stringify(this.countries));
            }
        }
    }

    async template(type: string, productId: string) {
        let tkey = type + '-' + productId;
        if (this.tmplfuncs[tkey]) return this.tmplfuncs[tkey];
        const options: {[key: string]: any} = {
            responseType: 'text'
        };
        let tmpl = null;
        try {tmpl = await http._http?.get<any>('assets/' + tkey+ '.tmpl', options).toPromise()} catch (e) {}
        try {if (!tmpl) tmpl = await http._http?.get<any>('assets/' + type + '-default.tmpl', options).toPromise()} catch (e) {console.log(e)}
        
        if (tmpl) {
            if (tmpl.startsWith('{')) {
                this.tmplfuncs[tkey] = new Function('data', tmpl.substring(1, tmpl.length-1));
            } else {
                let parts = tmpl.replace(/\}\}/g, '{{').split('{{');
                let stmt = 'return ';
                for (let i=0; i<parts.length; i+=2) {
                    if (i+1 < parts.length)
                        stmt += '"' + parts[i] + '" + ' + parts[i+1].replace(/\./g, '?.') + ' + ';
                    else
                        stmt += '"' + parts[i] + '"'
                }
                console.log('raw-tmpl:', stmt);
                try {
                    this.tmplfuncs[tkey] = new Function('data', stmt);
                } catch (e) {
                    this.tmplfuncs[tkey] = () => 'invalid template, expected valid JS expression';
                }
                
            }
        } else {
            this.tmplfuncs[tkey] = () => '';
        }
        return this.tmplfuncs[tkey];
    }

    async render(type: string, productId: string, data: any): Promise<SafeHtml> {
        let tmpl = await this.template(type, productId);
        return this._sanitizer.bypassSecurityTrustHtml(tmpl(data));
    }

    async fix_names(rows: any[], colnames: string[]) {
        if (colnames.length == 0) return;
        let emails: {[key: string]: boolean} = {};
        
        // collect all the email ids that needs to be converted into name
        //
        for (let row of rows) {
            for (let col of colnames) {
                if (typeof row[col] === 'string' && row[col]?.startsWith('G00')) {
                    row[col] = insapi.groupName(row[col]);
                } else if (insapi.users[row[col]]) {
                    row[col+'_email'] = row[col];
                    row[col] = insapi.users[row[col]].name;
                } else {
                    emails[row[col]] = true;
                }
            }
        }

        let mails = Object.keys(emails);
        if (mails.length == 0) return;
        
        await insapi.names(mails);      // give it a try and redo the above loop
        for (let row of rows) {
            for (let col of colnames) {
                if (insapi.users[row[col]]) {
                    row[col+'_email'] = row[col];
                    row[col] = insapi.users[row[col]].name;
                }
            }
        }
    }
}
