import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { IField } from '../../form.interface';
import { Subscription } from 'rxjs';
import { insapi, Policy, IPolicy } from 'insapi';
import { PreferencesService } from '../../../lib/services/preferences.service';

const deffields = "company_id,name,address,state,city,email";

interface company {
    company_id: string;
    name: string;
    [key: string]: any;
}

@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
    // @ViewChild("fminput") compName!: ElementRef;
    @Output() onChange = new EventEmitter();
    control!: FormControl;

    readonly: boolean = false;
    entityName = "";
    compName: string = '';

    data!: any;
    field: IField = {field_name: '', type: ''};
    policy!: Policy;

    filteredCompanies: company[] = [];
    debounceTimer: any = null;
    psubscription: Subscription | undefined = undefined;

    flags = {canEdit: true, canSave: false, edit: false, save: false, search: true};

    selected: any = {};
    fields: string[] = [];

    constructor(private preferences: PreferencesService) { }

    ngOnInit(): void {
        if (this.data && !this.data[this.field.field_name]) {
            this.data[this.field.field_name] = {name: ''};
        }
        this.fields = (this.field.fields || deffields).split(",");
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        this.flags.canSave = false;
        let pidx = insapi.profile?.privileges?.indexOf('Modify Companys') ?? -1;
        if (pidx >= 0) this.flags.canSave = true;
        if (this.data[this.field.field_name].name) this.flags.search = false;
        this.entityName = this.preferences.vendor?.widgets?.company?.name || 'Company';
        if (this.readonly) {
            this.flags.canEdit = false;
            this.flags.canSave = false;
        }
    }
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
        clearTimeout(this.debounceTimer);
    }

    async _load_options(value: string) {
        try {
            let ret = await insapi.xget('/api/v1/company/search/' + encodeURIComponent(value+'%')) || [];
            this.filteredCompanies = ret;
        } catch (e: any) {
            console.log('failed to get companies', e.message);
        }
    }

    _debounce() {
        if (this.debounceTimer) clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(async () => {
            await this._load_options(this.compName);
        }, 400);
    }

    onKeydown(ev: any) {
        // this._reset_data();
        console.log(ev.target.value)
        if (ev.target?.value?.length>2) this._debounce();
    }

    editMode() {
        if (this.readonly) return;
        this.flags.search = true;
        if (!this.flags.canEdit || !this.flags.canSave) return;
        this.flags.edit = true;
        // this.flags.search = true;
    }

    __send_change_notification() {
        let obj = this.data[this.field.field_name];
        let changed = Object.keys(obj).map(x => ({field_name: this.field.field_name + '.' + x, value: obj[x]}));
        changed.push({field_name: this.field.field_name, value: obj});
        this.onChange.emit(changed);
    }

    companySelected(ev: any) {
        this.compName = ev.option.value;
        let match = this.filteredCompanies.find(x => x.name == this.compName);
        if (match) this.__copy_to_data(match);
        this.flags.edit = false;
        this.flags.search = false;
        // this.__check_selected();
        this.__send_change_notification();
    }


    __copy_to_data(src: any) {
        if (this.data[this.field.field_name] === undefined || typeof this.data[this.field.field_name] === 'string')
            this.data[this.field.field_name] = {};
        for (let key of this.fields) {
            if (src[key] !== undefined) this.data[this.field.field_name][key] = src[key];
            else if (this.data[this.field.field_name][key] === undefined) this.data[this.field.field_name][key] = '';
        }
    }

    _reset_data() {
        if (this.selected.name == this.compName) return; // nothing to rest
        for (let key of this.fields) {
            if (this.selected[key] == this.data[this.field.field_name][key]) this.data[this.field.field_name][key] = '';
        }
        this.data[this.field.field_name].name = this.compName;
    }

    _policy_changed() {
        if (this.data[this.field.field_name] === undefined || typeof this.data[this.field.field_name] === 'string') {
            setTimeout(() => {this.__copy_to_data({}); this.control.setValue(this.data[this.field.field_name])}, 100);
        } else {
            if (this.data[this.field.field_name].name)
                this.compName = this.data[this.field.field_name].name;
        }
    }

    paramChanged(param: string) {

    }

    async addUpdate() {
        await insapi.__xpost('/api/v1/company', this.data[this.field.field_name]);
        await this._load_options(this.data[this.field.field_name]['name']);
        this.__send_change_notification();
        this.flags.edit = false;
        this.flags.search = false;
    }

    addNew() {
        this.flags.edit = true;
        this.data[this.field.field_name] = this.fields.reduce((a: any, x: string) => {a[x]=''; return a;}, {});
        this.data[this.field.field_name].name = "New"
    }

    closeEdit() {
        this.flags.edit = false;
    }
}
