<div [class]="layout.cls" [ngStyle] = "layout.style || {}" *ngIf="visible" animHeight [@.disabled]="disableAnim" [style.visiblity]="visible">
    <ng-container *ngTemplateOutlet="fgcaption || deffgcaption; context: {fieldGroup, layout, fieldDone, fieldCount}" ></ng-container>
    <ng-container *ngTemplateOutlet="fgcontent || deffgcontent; context: {fieldGroup, policy, data, readonly, form}" ></ng-container>
</div>
<ng-template #ovlay>
    <div class="history-overlay" *ngIf="histField">
        <div class="history-overlay-caption">{{histField.label || histField.field_name}}</div>
        <div class="history-overlay-item">
            <ng-template ngFor let-end [ngForOf]="policy.ehistory[histField.field_name]">
                <div *ngIf="end.desc" (click)="gotoEndorsement(end)">
                    <div style="font-size: 0.8em; color: #888;">{{end.date}}</div>
                    <div>{{end.desc}}</div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>

<ng-template #deffgcaption let-fieldGroup="fieldGroup" let-layout="layout" let-fieldDone="fieldDone" let-fieldCount="fieldCount">
    <div class="field-group-caption" *ngIf="layout.caption.show && fieldGroup.name" [ngStyle] = "layout.caption.style">
        <div>{{fieldGroup.name}} <small *ngIf="layout.caption.description.show==1" [ngStyle] = "layout.caption.description.style">{{fieldGroup.desc}}</small> <small *ngIf="layout.caption.count" >{{fieldDone}} / {{fieldCount}}</small></div>
        <div>
            <mat-icon (click)="fieldGroup.collapsed=false" *ngIf="fieldGroup.collapsed">expand_more</mat-icon>
            <mat-icon (click)="fieldGroup.collapsed=true" *ngIf="!fieldGroup.collapsed">expand_less</mat-icon>
        </div>
    </div>
</ng-template>

<ng-template #deffgcontent let-policy="policy" let-data="data" let-readonly="readonly" let-form="form">
    <form class="field-group" [formGroup]="form" *ngIf="!fieldGroup.collapsed">
        <div [class]="'grid-' + layout.grids">
            <ng-template ngFor let-field [ngForOf]="fieldGroup.fields" [ngForTrackBy]="trackField">
                <div [class]="['grid-cols-'+field.span]" [class.dirty]="form?.get(field.field_name)?.dirty" style="overflow: hidden; position: relative;" *ngIf="field.type!='hidden' && field.visible" [attr.data-ifw]="field.field_name">
                    <ng-container #fcs ifield [field]="field" [group]="form" [readonly] = "readonly || field.rdofunc(data, profile, policy)" [policy]="policy" [data]="data"
                        (onAction)="onAction.emit($event)" (onChange)="onChange.emit($event)"></ng-container>
                    <mat-icon class="history-icon" *ngIf="field.has_history" fontSet="material-icons-outlined" #histicon (click)="showHistory(ovlay, histicon._elementRef, field)" >history_toggle_off</mat-icon>
                </div>
            </ng-template>
        </div>
    </form>
</ng-template>
