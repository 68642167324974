import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from "@angular/forms";
import { IField } from '../../form.interface';
import { Policy, insapi } from 'insapi';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { TAndCComponent } from '../../../common/t-and-c.component';

@Component({
    selector: 'lib-iagree',
    templateUrl: './iagree.component.html',
    styleUrls: ['./iagree.component.scss']
})
export class IagreeComponent implements OnInit {
    data!: any;
    field!: IField;
    policy!: Policy;
    readonly: boolean = false;
    control!: FormControl;
    @Output() onChange = new EventEmitter();

    prompt: string = '';
    agreed: boolean = false;
    agreedOn: string = '';
    tandc: string = '';
    psubscription: Subscription | undefined = undefined;

    constructor(private preferences: PreferencesService, public dialog: MatDialog) { }

    ngOnInit(): void {
        if (!this.field.iagree) this.field.iagree = {};
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        this._policy_changed();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    async _policy_changed() {
        console.log('propos:', this.field.props)
        this.agreed = (this.policy.policy?.proposal.agreed_on) ? true : false;
        this.prompt = this.field.iagree.prompt || this.field.props?.prompt || this.preferences.vendor?.iagree?.prompt || 'I have read and agree to the terms and conditions';
        this.tandc = this.policy.policy?.iagree?.agreement_tandc || '';
        
        if (this.field.props?.popup && this.field.iagree.popup === undefined)  this.field.iagree.popup = true;

        if (this.policy.policy?.proposal.agreed_on) {
            this.agreedOn = moment.utc(this.policy.policy?.proposal.agreed_on).local().format('YYYY-MM-DD HH:mm:ss');
        } else {
            this.agreedOn = '';
        }
    }

    showTandC() {
        let data = {
            agrees: this.agreed,
            agreedOn: this.agreedOn,
            prompt: this.prompt,
            tandc: this.tandc,
            label: this.field.label
        };
        let ref = this.dialog.open(TAndCComponent, {data, maxWidth: '70%' });
        ref.afterClosed().subscribe(async (action) => {
            if (action == 'agree') await this.policy.__acceptTermsAndConditions();
        });
    }

    async agree() {
        if (!this.agreed) return insapi.showMessage('Please accept terms and conditions', 0);
        await this.policy.__acceptTermsAndConditions();
    }
}
