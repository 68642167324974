import { Component, OnInit, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { insapi, IPolicy, IWorkflow, Policy, NameValue, InsapiService, deepMerge, _clone, ExcelForm, IProfile, IEndorsement } from 'insapi';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { environment } from './../../../environments/environment';
import moment from 'moment';
import { FlowComponent, PreferencesService } from 'ins-form';
import { SettingsService } from '../../services/settings.service';

@Component({
    selector: 'app-endorsement',
    templateUrl: './endorsement.component.html',
    styleUrls: ['./endorsement.component.scss']
})
export class EndorsementComponent extends FlowComponent implements OnInit {

    rsubscription: Subscription | null = null;
    esubscription: Subscription | null = null;
    ssubscription: Subscription | null = null;

    premiumDisp: any = {field_name: 'premium_value', pipe: ['currency'], style: {'font-size': '1.6em', color: 'var(--header-fg-hilite)'}};
    premiumData: any = {premium_value: 0, message: ''};
    referralMessage: string = '';
    inMyGroup: boolean = false;
    chatType: number = 0;

    constructor(protected activatedRoute: ActivatedRoute,
        protected insapiService: InsapiService,
        protected preferences: PreferencesService,
        protected settings: SettingsService,
        protected router: Router,
        public dialog: MatDialog) {
            super(insapiService, preferences, router, activatedRoute, dialog);
            this.rsubscription = this.activatedRoute.queryParams.subscribe(params => {
                if (params.product_id) this.newEndorsement(params.product_id, params.policy_id);
                else if (params.endorsement_id) this.loadEndorsement(params.endorsement_id, params.policy_id);
            });
            if (environment.vendor.premiumAtNavBar) {
                this.premiumDisp = null;
            }
            if (environment.vendor?.policy?.referral?.hidepremium || environment.vendor?.policy?.referral?.hidepremium === undefined) {
                this.referralMessage = environment.vendor?.policy?.referral?.premiumMessage || 'Referral case';
            } else {
                this.referralMessage = '';
            }

        }

    ngOnInit(): void {
    }
    ngOnDestroy(): void {
        insapi.changeFunc = null;
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
        if (this.esubscription) this.esubscription.unsubscribe();
        this.esubscription = null;
        if (this.ssubscription) this.ssubscription.unsubscribe();
        this.ssubscription = null;
        this.preferences._update_premium(null, false, null);
    }

    async newEndorsement(eprdId: string, policyId: string) {
        let policy = new Policy(this.options);
        await policy.__load(policyId, true);
        await this.__new_endorsement(policy, eprdId);
        // await this._init(policy);
    }

    async loadEndorsement(endId: string, policyId: string) {
        await this.insapiService.loadProfile();
        let policy = new Policy(this.options);
        await policy.__load_endorsement(endId);
        // await policy.__load(policyId, true);
        await this._init(policy);
    }

    async __new_endorsement(policy: Policy, eprdId: string) {
       // copy policy proposal/quote to endorsement
        if (!policy.policy || !policy.product) return;
        if (policy.policy.status == 8) return insapi.showMessage("Policy has been cancelled, cannot be endorsed", 1);
        await policy.__new_endorsement(eprdId, moment().format('YYYY-MM-DD'));
        await this._init(policy);
        this.onChange({});  // trigger premium calc
    }

    async _init(policy: Policy) {
        if (!policy.endorsement) return;
        // if we have already initialized the workflow, lets not repeat it again
        //
        if (this.workflow && this.workflow.wf_id == policy.endorsement.wf_id) {
            this._update_stage_status();
            this._move_to_inprogress();
            return;
        }

        console.log('loading wf: ', policy.endorsement.endorsement?.data.wf_id)
        this.workflow = await policy.workflow(policy.endorsement.endorsement?.data.wf_id);
        if (!this.workflow) return;

        this.workflow.layout = deepMerge(environment.vendor.layout.workflow||{}, this.workflow.layout);
        
        await this._prepare_excel_forms(policy);
        if (!this.policy) return;

        if (this.esubscription) this.esubscription.unsubscribe();
        this.esubscription = this.policy?.changeSubject?.subscribe((endorsement: IPolicy | IEndorsement | null) => {
            if (!endorsement?.prd_endorsement_id) return; // not an endorsement
            
            let cur = endorsement.stage_status.filter(x => x.status == 'inprogress').map(x => x.stage);
            this.chatType = 0;

            if (this.nstpType && endorsement[this.nstpType] && endorsement[this.nstpType].nstp_enabled=='Yes') {
                this.chatType = 1;
            }
            if (endorsement.inspect?.need_inspetion && cur.indexOf('inspect') >= 0) {
                this.chatType = 2;
            }
            this._update_list_options();
            this.premiumData = this.preferences._update_premium(endorsement, this.profile?.is_underwriter?true:false, this.policy);
            this.inMyGroup = false;
            if (endorsement?.assigned_to?.startsWith('G0')) {
                let grpName = insapi.groupName(endorsement?.assigned_to);
                //if (grpName != endorsement?.assigned_to) this.inMyGroup = true;
                if (grpName != endorsement?.assigned_to) {
                    if (this.profile?.groups?.indexOf(endorsement?.assigned_to)>=0)
                        this.inMyGroup = true;
                }
            }
            this.__update_router();
        });

        if (this.ssubscription) this.ssubscription.unsubscribe();
        this.ssubscription = this.policy?.stateSubject.subscribe((endorsement: IPolicy | IEndorsement | null) => {
            console.log('end: state changed', policy.endorsement?.prd_endorsement_id, policy.endorsement?.eproposal?.data.prd_endorsement_id);
            if (endorsement) {
                this.__move_to_inprogress();
                this._update_stage_status();
            }
        });

        return;
    }


    async onAction(ev: any) {
        if (ev.field_name === 'collect_cash' || ev.field_name === 'cash_payment' ) {
            await this.policy?.__payCash(undefined, ev.sub_action);
            this._move_to_inprogress();
            this._update_stage_status();
        } else {
            super.onAction(ev);
        }
    }

    __copy_event_data(data: any, ev: any) {
        if (ev instanceof Array && ev[0]?.field_name) {
            for (let fld of ev) data[fld.field_name] = fld.value;
        } else {
            if (ev && !ev.field_name) deepMerge(data, ev);
        }
    }


    async onChange(ev: any) {
        if (!this.policy?.endorsement) return;
        let changed: any = undefined;

        this.__copy_event_data(this.policy?.endorsement?.eproposal.data, ev);
        // if (ev && !ev.field_name) deepMerge(this.policy?.endorsement?.eproposal.data, ev);

        let data = JSON.parse(JSON.stringify(this.policy?.policy?.proposal?.data || this.policy?.policy?.quote?.data || {}));
        data = {...data, ...this.policy?.endorsement?.eproposal.data, ...this.policy?.endorsement?.inspect?.data};
        changed = await this.policy.__premium(data, false);

        // array of inputs changed (in suplimentary widgets) that needs to be updated in main widgets
        //
        if (ev instanceof Array) {
            if (!changed) changed = {};
            for (let key in ev) if (!changed[ev[key].field_name]) changed[ev[key].field_name] = ev[key].value;
        }

        this.fgs.forEach(x => x._changed(changed)); // update all controls that have changed with new values
        this._handle_errors(ev.field_name);
        
    }


    __reset_dirty() {
        if (this.fgs) this.fgs.find(x => x.dirty = false);
    }

    __check_form() {
        if (!this.fgs) return true;
        let inv = this.fgs.find(x => !x.form.valid);
        if (!inv) return true;
        // console.log('errMap', this.policy?.errMap);
        for (let name in inv.form.controls) {
            if (inv.form.controls[name].invalid) inv.form.controls[name].updateValueAndValidity();

            if (inv.form.controls[name].invalid) {
                // console.log('validators', name, this.policy?.policy?.quote.data[name], inv.form.controls[name].value);
                let msg = 'Invalid '+name+' please fill it in';
                for (let err in inv.form.controls[name].errors||{}) {
                    if (err == 'ssValidator') msg = inv.form.controls[name].errors?.[err].msg;
                }
                if (insapi.messageFunc) insapi.messageFunc(msg);
                return false;
            }
        }
        return true;
    }

    gotoPolicy() {
        if (!this.policy) return;
        let params: any = {policy_id: this.policy.endorsement?.policy_id, endorsements: 1};
        this.router.navigate(['/policy'], { state: {asd:1}, queryParams: params});
    }

    async takeOver() {
        if (this.policy && this.policy.endorsement) {
            await insapi.acquireEndorsement(this.policy.endorsement.data?.endorsement_id);
            await this.policy.__load_endorsement(this.policy.endorsement.data?.endorsement_id);
            //await this.loadEndorsement(this.policy.endorsement.data?.endorsement_id,this.policy.endorsement.policy_id);
        }
    }

}
