import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IField, animShowHide, animShowHideOpacity } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { Policy, is_num, IPolicy, IEndorsement } from 'insapi';
import { Subscription } from 'rxjs';


@Component({
    selector: 'plan',
    templateUrl: './plan.component.html',
    styleUrls: ['./plan.component.scss'],
    animations: [animShowHide, animShowHideOpacity]
})
export class PlanComponent implements OnInit {

    @Output() onChange = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    policy!: Policy;
    readonly: boolean = false;
    data: any = {};

    plans: {[key: string]: any}[] = [];
    pstart: number = 0;
    pmax: number = 3;

    params: any = {};
    columns: string[] = [];
    hidden: string[] = [];
    allcolumns: string[] = [];
    allparams: {[key: string]: any}[] = [];
    gridCols: string = "";
    temp: any = {};
    more: any = {};
    editor: string = '';

    multiSelect: boolean = false;
    selectedPlans: {[key: string]: string} = {};
    psubscription: Subscription | undefined = undefined;
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe((policy: IPolicy | IEndorsement | null) => {
            if (!this.policy) return;
            if (!this.field.orientation) this.field.orientation = 'vertical';
            this.multiSelect = (+this.policy.product?.data.max_plans > 1) ? true : false;
            if (this.multiSelect && !this.data.plans) this.data.plans = [];
            this._prepare_plans(this.policy.plans);
        });
        this._prepare_plans(this.policy.plans);
        if (this.data?.plan_id) {
            this.temp[this.data?.plan_id] = {};
            let idx = 0;
            for (let p of this.policy.plans||[]) {
                if (p.plan_id == this.data.plan_id) {
                    this._fix_plans();
                    for (let key in p.param_map) {
                        this.temp[this.data?.plan_id][key] = this.data[key]||'';
                    }
                    break; 
                }
                idx++;
                if (idx >= this.pmax) this.pstart++;
            }
        }
        
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    _prepare_plans(plans: any) {
        let params: any = {};

        if (this.readonly) {
            let params: any = {};
            for (let plan of this.data.plans||[])
                for (let key in plan)
                    params[key] = this.policy.product?.dictionary?.inputs?.[key]?.label || this.policy.product?.dictionary?.inputs?.[key+'_tmpl']?.label || key;
            delete params.plan_id;
            delete params.version;
            let keys = Object.keys(params);
            this.allparams = keys.map((x: string) => ({param: x, label: params[x]}))
            return;
        }

        if (this.multiSelect) {
            console.log('prepare-plans:', this.data.plans, plans);
            for (let i=0; i<this.data.plans.length; i++) {
                let p = this.data.plans[i];
                if (p.plan_id) {
                    let found = false;
                    for (let plan of plans) {
                        if (p.plan_id == plan.plan_id) {found = true; break;}
                    }
                    if (!found) {
                        console.log('invalid plan id removing', p);
                        this.data.plans.splice(i, 1);
                        i --;
                    }
    
                } else {
                    for (let plan of plans) {
                        if (p.plan_name == plan.plan_name) {
                            p.plan_id = plan.plan_id;
                            p.version = plan.version; 
                            console.log('fixed plan by name', p);
                            break;
                        }
                    }
                    if (!p.plan_id) {
                        console.log('invalid plan removing', p);
                        this.data.plans.splice(i, 1);
                        i --;
                    }
                }
            }
        }

        for (let plan of plans) {
            if (this.multiSelect) {
                this.temp[plan.plan_id] = {};
                for (let p of this.data.plans||[]) if (p.plan_id == plan.plan_id) {
                    this.temp[plan.plan_id] = p;
                    break;
                }
            } else {
                if (!this.temp[plan.plan_id]) this.temp[plan.plan_id] = {};
            }

            plan.param_map = {};
            for (let param of plan.params) {
                if (param.param_type == 'filter' || 
                    param.param_type == 'highlite' || 
                    param.status != 0 ||
                    param.visible != 1) continue;

                // console.log('param:', param.param_name, param.param_type)
                if (!param.values || !(param.values instanceof Array))
                    param.values = (''+param.param_values).split(',');

                params[param.param_name] = param.param_label;
                plan.param_map[param.param_name] = param;
                
                if (param.param_type != 'range') {
                    if (this.temp[plan.plan_id][param.param_name] === undefined || !param.values.includes(this.temp[plan.plan_id][param.param_name]))
                        this.temp[plan.plan_id][param.param_name] = param.values[0];
                } else {
                    if (!this.__is_in_numeric_range(param.values, +this.temp[plan.plan_id][param.param_name]))
                        this.temp[plan.plan_id][param.param_name] = param.values[0];
                }
                
                param.editable = "No";
                if (param.values.length > 1) {
                    for (let value of param.values) if (!is_num(value)) param.editable = "option";
                    if (param.editable == 'No') param.editable = "Yes";
                }
            }
        }
        
        this.params = params;
        this.allcolumns = Object.keys(params);
        this.hidden = Object.keys(params);
        this.columns = this.hidden.splice(0, this.field.max_params || 4);
        this.gridCols = "32px 200px " + this.columns.map(x => '1fr').join(' ') + ' 32px';

        // sort allcolumns according to first plan parameter order
        this.allcolumns.sort((a: string, b: string) => plans[0]?.param_map[a]?.param_index - plans[0]?.param_map[b]?.param_index);
        this.columns.sort((a: string, b: string) => plans[0]?.param_map[a]?.param_index - plans[0]?.param_map[b]?.param_index);

        this.pmax = this.field.max_plans||3;
        if (this.field.orientation == 'horizontal' && plans.length > this.pmax) {
            this._fix_plans();
        } else {
            this.pstart = 0;
            this.plans = plans;
        }

        if (this.multiSelect) {
            this.data.plan_id = '';
            this.selectedPlans = {};
            for (let p of this.data.plans) this.selectedPlans[p.plan_id] = p.plan_id;
        } else {
            delete this.data.plans;
        }
    }

    __is_in_numeric_range(values: number[], value: number) {
        for (let i=0; i<values.length; i+=2) {
            if (value >= +values[i]) {
                if (i+1 >= values.length || value <= +values[i+1] ) return true;
            }
        }
        return false;
    }


    selectPlan(plan: any) {
        if (this.readonly) return;
        if (this.multiSelect) {
            console.log('selectPlan: ', this.selectedPlans);
            if (this.selectedPlans[plan.plan_id]) {
                console.log('selectPlan: find', plan.plan_id)
                for (let i=0; i<this.data.plans.length; i++) if (this.data.plans[i].plan_id == plan.plan_id) {this.data.plans.splice(i, 1); break;}
            } else {
                console.log('selectPlan: add', plan.plan_id)
                this.data.plans.push({plan_id: plan.plan_id, plan_name: plan.plan_name, version: plan.version, ...(this.temp[plan.plan_id] || {})});
            }
            this.selectedPlans = {};
            for (let p of this.data.plans) this.selectedPlans[p.plan_id] = p.plan_id;
            this.onChange.emit([{field_name: 'plans', value: this.data.plans}]);
            console.log('plans:', this.data.plans)
        } else {
            
            this.selectedPlans = {};
            this.data.plan_id = plan.plan_id;
            this.group.get(this.field.field_name)?.setValue(plan.plan_id);
            for (let key in this.temp[plan.plan_id]) this.data[key] = this.temp[plan.plan_id][key];
   
            let changed = [{field_name: 'plan_id', value: plan.plan_id}, {field_name: 'plan_name', value: plan.plan_name}];
            for (let key in this.temp[plan.plan_id]) changed.push({field_name: key, value: this.data[key]});
            console.log('sel-plan:', plan.plan_id, plan.plan_name, changed)
            this.onChange.emit(changed);
        }
    }

    paramEdited(plan: any, param: string) {
        if (this.multiSelect) {
            for (let p of this.data.plans) {
                if (p.plan_id == plan.plan_id) {
                    this.data.plans[plan.plan_id] = {...this.data.plans[plan.plan_id], ...this.temp[plan.plan_id]};
                    break;
                }
            }
            this.onChange.emit([{field_name: 'plans', value: this.data.plans}]);
        } else {
            if (this.data.plan_id != plan.plan_id) return;
            this.data[param] = this.temp[plan.plan_id][param];
            console.log('paramEdited:', this.data[param]);
            this.onChange.emit([{field_name: param, value: this.data[param]}]);
        }
        this.editor = '';
    }

    editParam(plan: any, param: string) {
        if (!this.multiSelect && this.data.plan_id != plan.plan_id) return;
        if (this.multiSelect && !this.selectedPlans[plan.plan_id]) return;

        this.editor = '';
        let values = plan.param_map[param].values;
        if (plan.param_map[param].editable == 'option') {
            // special case, when there are only two options lets make it a toggle
            //
            if (values.length == 2) {
                this.temp[plan.plan_id][param] = this.temp[plan.plan_id][param] == values[0] ? values[1] : values[0];
                if (this.data.plan_id == plan.plan_id) {
                    this.data[param] = this.temp[plan.plan_id][param];
                    this.onChange.emit([{field_name: param, value: this.data[param]}]);
                }
            } else {
                this.editor = plan.param_map[param].plan_param_id;
            }
        } else if (plan.param_map[param].editable == 'Yes') {
            this.editor = plan.param_map[param].plan_param_id;
        }
    }

    showMore(planId: string) {
        this.more[planId] = 1;
    }

    _fix_plans() {
        if (!this.policy) return;
        if ((this.pstart + this.pmax) >= this.policy.plans.length) this.pstart = this.policy.plans.length - this.pmax;
        if (this.pstart < 0) this.pstart = 0;
        this.plans = this.policy.plans.slice(this.pstart, this.pstart + this.pmax);
    }

    prev() {
        this.pstart --;
        this._fix_plans();
    }
    next() {
        this.pstart ++;
        this._fix_plans();
    }

    async _update_dependents() {
        if (!this.data.plan_id || !this.plans || this.multiSelect) return;

        // for (let plan of this.plans) {
        //     if (plan.plan_id == this.data.plan_id) {
        //         for (let param of plan.params) {
        //             if (this.data[param.param_name] !== undefined && this.data[param.param_name] !== null)
        //                 this.temp[plan.plan_id][param.param_name] = this.data[param.param_name];
        //         }
        //     }
        // }
    }
}
