import { Component, Input, OnInit, Inject } from '@angular/core';
import { Policy, excelDateToDate, insapi } from 'insapi';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { PreferencesService } from 'ins-form';
import { jdiff } from '../../../lib/jdiff';

@Component({
    selector: 'app-policy-timeline',
    templateUrl: './policy-timeline.component.html',
    styleUrls: ['./policy-timeline.component.scss']
})
export class PolicyTimelineComponent implements OnInit {

    @Input() policy!: Policy;
    @Input() stages!: any;

    selected: string = 'quote';
    inputs: {[key: string]: any} = {};
    timelines: {[key: string]: any} = {};

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<any>,
        public preferences: PreferencesService
        ) { }

    ngOnInit(): void {
        if (this.data?.policy) this.policy = this.data.policy;
        if (this.data?.stages) this.stages = this.data.stages;
        if (this.policy.policy?.proposal_id) this.selected = 'proposal';
        this.__inputs();
        // this.dialogRef.updateSize('600px', '400px');

        // this.timelines.quote = [
        //     {u_ts_disp: '10:00', author: 'uw@insilllll.', other: false},
        //     {u_ts_disp: 'Jan 10 2023 10:00', author: 'xyz@insilllll.', other: true},
        //     {u_ts_disp: 'Jan 20 2023 10:00', author: 'xyz@insilllll.', other: true},
        // ];
    }

    __inputs() {
        if (!this.stages) return;
        for (let stage of this.stages) {
            for (let grp of stage.form?.groups||[]) {
                for (let fld of grp.fields) {
                    let label = fld.label || fld.field_name;
                    if (this.policy.product?.dictionary?.inputs[fld.field_name]) label = this.policy.product?.dictionary.inputs[fld.field_name].label;
                    if (label.startsWith('* ')) label = label.substr(2);
                    if (fld.type == 'plan') this.inputs['plan_name'] = 'Plan name';
                    this.inputs[fld.field_name] = {...fld, label};
                }
            }
        }
        // console.log('inpputs', this.inputs)
    }

    __value(v: any, isDate: boolean) {
        if (!isDate) return v;
        return excelDateToDate(v)?.format(this.preferences.dateFormat);
    }

    __change(name: string, chg: any, isArray: boolean, isDate: boolean): any {
        let op = '';
        let ov = '';
        let nv = '';
        if (isArray) {
            let changes = [];
            let keys: any = {};
            for (let idx in chg) {
                let tmp: any = {};
                for (let key in chg[idx]) {
                    if (!chg[idx][key]) continue;
                    tmp[key] = {...this.__change(key, chg[idx][key], false, false), idx}
                    keys[key] = true;
                }
                changes.push(tmp);
            }
            let knames = Object.keys(keys);
            if (changes.length == 0 || knames.length == 0) return null;
            return {name, label: this.inputs[name]?.label || name, op: '[]', changes, keys: knames};
        }

        if (chg.added) {op = '+'; nv = chg.added;}
        else if (chg.removed) {op = '-'; ov = this.__value(chg.removed, isDate)}
        else if (chg.changed) {op = '*'; ov = this.__value(chg.changed[0], isDate); nv = this.__value(chg.changed[1], isDate);}
        return {name, label: this.inputs[name]?.label || name, op, ov, nv};
    }

    __json_diff(cv: any, pv: any): any[] {
        if (!cv || !pv) return [];
        let ret = jdiff(pv, cv);
        console.log('jdiff:', ret);
        let changed: any = [];
        for (let fld in this.inputs) {
            console.log(fld, this.inputs[fld]);
            if (ret[fld]) {
                let chg = this.__change(fld, ret[fld], cv[fld] instanceof Array || pv[fld] instanceof Array, this.inputs[fld].type == 'date');
                if (chg) changed.push(chg);
            }
        }
        // console.log('changed:', changed)
        return changed;
    }

    async _load_timeline(mod: string) {
        if (!this.policy?.policy) return;

        // get the current version from the policy and add it as a starting point
        let base: any = this.policy.endProduct ? this.policy.endorsement : this.policy.policy;
        let urlBase = "/api/v" + (this.policy.endProduct ? '2' : '1') + "/" + mod + "/";
        let pmod = {...(base[mod] || {}), changed: [], count: 3};
        pmod.u_ts_disp = moment.utc(pmod.u_ts).local().format(this.preferences.dateTimeFormat);
        this.timelines[mod] = [pmod];

        let url = urlBase + "data_version?" + mod + "_id=" + base[mod + "_id"];
        url += '&data_type=json';
        let version = await insapi.__xget(url) || [];
        version = version.sort((a: any, b: any) => a.u_ts > b.u_ts ? -1 : +1);
        
        for (let i=0; i<version.length; i++) {
            let v = version[i];
            v.changed = [];
            v.loading = true;
            url = urlBase + 'data_history/' + encodeURIComponent(base[mod + "_id"]) + '/json?version=' + v.version;
            try {v.data = JSON.parse(await insapi.xget(url));} catch (e) {console.log('v:', e);}
            v.changes = [];
            v.u_ts_disp = moment.utc(v.u_ts).local().format(this.preferences.dateTimeFormat);
            v.count = 3;
            v.loading = false;

            this.timelines[mod].push(v);    // i+1 entry
            this.timelines[mod][i].changed = this.__json_diff(this.timelines[mod][i].data, v.data);

            v.other = v.author != insapi.profile?.email;// (this.timelines[mod][i].author != v.author);
        }
        console.log('tls:', this.timelines)
    }
}
