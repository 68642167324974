import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { insapi, IProduct, IProfile, flattenJSON, is_obj, http, excelDateToDate } from 'insapi';
import moment from 'moment';
import { environment } from './../../../environments/environment';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
/* import { ReassignPolicyComponent } from '.../reassign-policy.component'; */
import { Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreferencesService, EmailPDFComponent } from 'ins-form';
const defType = {

  "columns": [{ "name": "quote_id", "disp": "Certificate ID", "type": "string" },
  { "name": "quote.data.master_policy_no", "disp": "Master Policy No", "type": "string" },
  { "name": "quote.data.proposer_name", "disp": "Customer Name", "type": "string" },
  { "name": "quote.premium_value", "disp": "Premium (excl. tax)", "type": "currency", "style": { "text-align": "right", "justify-content": "center" } },
  { "name": "quote.data.voyage_type", "disp": "Voyage", "type": "string" },
  { "name": "quote.data.departure_date_input", "disp": "Departure Dt", "type": "datetime" },
  { "name": "assigned_to", "disp": "Owner", "type": "string" },
  { "name": "u_ts", "disp": "Rejected Date", "type": "datetime" },
  { "name": "actions", "disp": "Act", "type": "action" }
  ],
  "formats": ",decimal,,",
  "filters": [
    { "field_name": "period", "type": "period", "span": 6 },
    { "field_name": "quote.data.master_policy_no", "type": "string", "label": "Master Policy No", "span": 3 },
    { "field_name": "quote_id", "type": "string", "label": "Certificate ID", "span": 3 },
    { "field_name": "quote.status", "default": 8, "type": "hidden", "label": "Status", "span": 3 },
    { "field_name": "filter", "default": "", "type": "hidden", "label": "filter", "span": 3 },
    { "field_name": "assigned_to", "default": "", "type": "hidden", "label": "Assigned To", "span": 3 }

  ],
  "layout": { "cls": "fg-wrapper", "grids": 12, "maxWidth": 1200 }
}
@Component({
  selector: 'app-soa-report',
  templateUrl: './soa-report.component.html',
  styleUrls: ['./soa-report.component.scss'],

})
export class SoaReportComponent implements OnInit {


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  endorsement: boolean = false;

  profile: IProfile | null = null;
  product: IProduct | null = null;
  filtered_list: boolean = false;
  filtered_product: string = '';
  type: string = '';
  userInfo:any;
  types: string[] = [];//['inprogress', 'completed', 'referral', 'team'];


  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  data: any[] = [];
  filters: { [key: string]: string } = {};
  listDef: any = null;
  form: any = { groups: [{ fields: [] }], layout: { "cls": "fg-wrapper" } };
  columns: string[] = [];
  subscription: Subscription | null = null;
  rsubscription: Subscription | null = null;
  qsubscription: Subscription | null = null;
  actions: any = this.preferences?.vendor?.['policy-list'].actions || {};
  product_id: string = '';

  constructor(private route: ActivatedRoute, private router: Router,
    public dialog: MatDialog,
    protected preferences: PreferencesService
  ) { }

  ngOnInit(): void {
    // this.listDef = defType;
    this.subscription = insapi.profileSubject.subscribe((profile: IProfile | null) => {
      this.profile = profile;
      if (!profile) return;
      this.__init_def();
      this.changeProduct();

      
      if (this.qsubscription) this.qsubscription.unsubscribe();
      this.qsubscription = this.route.queryParamMap.subscribe((params: ParamMap) => { });
    });

    for (let key in this.actions.actions) {
      this.actions.actions[key].code = key;
      if (this.actions.actions[key].if && !this.actions.actions[key].ifFunc) this.actions.actions[key].ifFunc = this._evalFunc(this.actions.actions[key].if);

      if (this.actions.actions[key].url) this.actions.actions[key].urlFunc = this._urlFunc(this.actions.actions[key].url);
      if (this.actions.actions[key].source && this.actions.actions[key].source.url) this.actions.actions[key].urlFunc = this._urlFunc(this.actions.actions[key].source.url);

    }
  }
  __init_def() {
    let listdef =
      environment.vendor['soa-report']?.[this.product?.product_group_id || ''] ||
      environment.vendor['soa-report']?.[this.product?.product_group_name || ''] ||
      environment.vendor['soa-report']?.['all-groups'];

    if (listdef) this.listDef = listdef;
    for (let col of listdef.columns) {
      col.pipe_args = null;
      if (!col.pipe && col.type == 'currency') col.pipe = CurrencyPipe;
      if (!col.pipe && col.type == 'decimal') { col.pipe = DecimalPipe; col.pipe_args = '1.0-2' }
    }

  }
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = null;
    if (this.rsubscription) this.rsubscription.unsubscribe();
    this.rsubscription = null;
    if (this.qsubscription) this.qsubscription.unsubscribe();
    this.qsubscription = null;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  @HostListener('window:resize', ['$event']) onResize(ev: any) { this._set_layout_width(); }
  _set_layout_width() {
    let iw = window.innerWidth;
    this.form.layout.width = (+this.form.layout.maxWidth > iw) ? '100%' : (this.form.layout.maxWidth || 860) + 'px';
  }

  __try_list(list: any) {
    if (!list) return null;
    this.types = Object.keys(list);
    let deftype = this.types.find(key => list[key]['default'] == true);  // use this if no state specific conf is provided
    // this.type = this.type || deftype || 'inprogress';
    this.type = 'Cancel';
    return list[this.type] || null;
  }

  __get_list_def(product: IProduct | null) {
    let plist = null;
    console.log(product);
    if (!product) {
      plist = this.__try_list(environment.vendor['policy-list']?.['all-groups']);
      console.log('pliust:', this.type, plist)
      return plist || defType;
    }
    if (this.endorsement) {
      plist = this.__try_list(environment.vendor['end-list']?.[product.product_group_id]);
      if (!plist) plist = this.__try_list(environment.vendor['end-list']?.[product.product_group_name]);
      if (!plist) plist = this.__try_list(environment.vendor['end-list']?.['all-groups']);
    } else {
      plist = this.__try_list(environment.vendor['policy-list']?.[product.product_group_id]);
      if (!plist) plist = this.__try_list(environment.vendor['policy-list']?.[product.product_group_name]);
      if (!plist) plist = this.__try_list(environment.vendor['policy-list']?.['all-groups']);
    }
    console.log('plist', plist)
    if (!plist) console.log('listdef: using default');
    if (!plist) plist = defType;

    return plist;
  }

  async _select_product() {

    let options = this.profile?.products.map(x => {
      return { name: x.product_name, value: x.product_id }
    });

    options = options.filter(function (item) {
      return item.name == 'Marine Open Policy'
    })

    this.form.groups[0].fields = [{ field_name: 'product_id', type: 'lookup', options }];
    this.form = JSON.parse(JSON.stringify(this.form));
  }

  async __add_endorsement_actions(rows: any[], uw: boolean) {
    if (!this.profile) return;
    for (let row of rows || []) {
      let actions = [];
      for (let key in this.actions.actions) {
        if (key == "clone" || key == "clonedocs" || key == "version" || key == 'delete' || key == 'assign')
          continue;

        if (this.actions.actions[key].ifFunc && !this.actions.actions[key].ifFunc(row, this.profile, null))
          continue;

        if (key == 'takeover' && (!row.Owner || !row.Owner.startsWith('G00'))) continue;
        if (key == 'refer') {
          if (row.nstp_enabled != 'Yes' || !uw) continue;
        }
        if (key == 'download' && (!row.endorsement_no)) continue;
        actions.push(this.actions.actions[key]);
      }
      // if (row.nstp_enabled === 'Yes' && uw) actions.push(this.actions.actions.refer);
      // if (row.endorsement_no) {
      //     actions.push(this.actions.actions.download);
      // }
      row.actions = actions.filter(Boolean);
    }
  }

  async __add_policy_actions(rows: any[], uw: boolean) {
    if (!this.profile) return;

    for (let row of rows || []) {
      let actions = [];
      for (let key in this.actions.actions) {
        if (this.actions.actions[key].ifFunc && !this.actions.actions[key].ifFunc(row, this.profile, null))
          continue;

        if (key == "clone" || key == "clonedocs" || key == "version") {
          if (this.profile.privileges.indexOf('Revise Quotation') < 0) continue;
        }
        if (key == 'takeover' && (!row.Owner || !row.Owner.startsWith('G00'))) continue;
        if (key == 'delete') {
          if ((row.policy_no != "null" && row.policy_no != '')) continue;
          if (this.profile.privileges.indexOf("Remove Quotes") < 0) continue;
        }
        if (key == 'refer') {
          if (row.nstp_enabled != 'Yes' || !uw) continue;
        }
        if (key == 'assign' && this.type != 'team') continue;
        if (key == 'download' && (row.policy_no == "null" || row.policy_no == '')) continue;

        actions.push(this.actions.actions[key]);
      }
      row.actions = actions.filter(Boolean);
    }
  }


  async __load(productId: string | null, download: string = '') {
    console.log('productId:', productId);

    if (!productId || !this.profile) return this._select_product();

    if (productId != 'all') {
      //**added below line to show status bucket - start**//
      this.filtered_list = false;
      //** End **//
      this.product = await insapi.productFromId(productId);
      if (!this.product || !this.product?.product_group_id) return;
    }
    let listDef = this.listDef;

    // let listDef = this.__get_list_def(this.product);
    // if (!listDef) {console.log('could not find list definition, fix vendor.json'); return;}

    let status = this.type == 'completed' ? 2 : 0;
    let filters: any = {
      product_id: this.product?.product_id,
      [this.endorsement ? "endorsement.status" : "quote.status"]: status
    }

    if (this.type == 'referral') filters['referral'] = 1;
    else if (this.type == 'inspect') filters['inspect'] = 1;
    else if (this.type == 'team') filters.filter = 'assigned_to_team'; //filters['team'] = 1; 
    else if (this.type == 'team_group') filters.filter = 'assigned_to_team_group';
    else if (this.type != 'completed') filters.filter = 'assigned_to_me'; //filters['assigned_to'] = this.profile ? this.profile.email : null;

    // add user provided filters
    for (let key in this.filters) filters[key] = this.filters[key];
    filters = flattenJSON(filters);
    for (let key in filters) if (is_obj(filters[key]) && Object.keys(filters[key]).length == 0) delete filters[key];

    // add users hidden provided filters
    for (let key in listDef.filters) {
      if (listDef.filters[key].type == "hidden") {
        filters[listDef.filters[key].field_name] = listDef.filters[key].default;
      }
	 /*  if(this.profile) */
    }
	console.log("this. listDef.filters" ,filters)
	//added for masterpolicy
	
	// var filterss=[];
	/* for(var i=0;i<listDef.filters.length;i++){
		if(this.profile.is_broker){
		if(this.listDef.filters[i].type == "lookup"){
			console.log(this.listDef.filters[i])
			this. listDef.filters .push(this.listDef.filters[i]);
		}
		}else if(this.listDef.filters[i].type =="string"){
			
		}
	} */
	//listDef.filters=filterss; 
	if(this.profile.is_broker){
		 let index=listDef.filters.findIndex((obj:any) => obj.type === 'string');
		 console.log("index",index)
		 if(index !== -1){listDef.filters.splice(index,1)}
		
	}
	if(!this.profile.is_broker){
		 let index=listDef.filters.findIndex((obj:any) => obj.type === 'lookup');
		 console.log("index",index)
		 if(index !== -1){listDef.filters.splice(index,1)}
		
	}
    let cols = this.endorsement ? ['endorsement_id', 'policy_id', 'quote_id', 'policy_no', 'endorsement_no'] : ['policy_id', 'quote_id', 'policy_no'];
    if (this.product?.master_policy_product_id) cols.push('master_policy_no');
    let columns = [...(!download ? cols : []), ...listDef.columns.map((x: any) => x.name + ' ' + (x.disp || x.name))].join(',');
    if (download) filters.download = '1';

    filters.fmt = download || 'csv';
    let use2 = true;
    if (filters.filter == 'assigned_to_team_group' && !this.endorsement) {
      use2 = false;
      filters.fmt = download || 'array';  // list3
    }

    let ret = null;
    this.userInfo=null;
    console.log("filter load=>",filters);
    if(filters && filters.master_policy_no != undefined){
      ret = await insapi.__xget('/api/v1/soa_report/report?policy_no='+filters.master_policy_no);
      console.log("ret=>",ret);
      if(ret['userInfo']) this.userInfo=ret['userInfo']; 
      ret=ret['reportData'];
    }
    
    
    console.log("this.userInfo=>",this.userInfo);
    this.columns = listDef.columns.map((x: any) => x.disp);
    this.data = ret || [];
    this.listDef = listDef;

    // let coltypes = listDef.columns.reduce((a: any, x: any) => {a[x.disp]=x.type; return a;}, {});
    let dates = listDef.columns.filter((x: any) => x.type == 'datetime');
    let refers = listDef.columns.filter((x: any) => x.type == 'refer');
    let exprs = listDef.columns.filter((x: any) => x.expr);


    let uw = this.profile ? (this.profile.privileges.indexOf('Nstp Approve') >= 0 ||
      this.profile?.privileges.indexOf('Qnstp Approve') >= 0 ||
      this.profile?.privileges.indexOf('Pnstp Approve') >= 0) : false;

    if (this.endorsement) this.__add_endorsement_actions(this.data, uw);
    else this.__add_policy_actions(this.data, uw);
    console.log('columns:', listDef.columns)
    for (let row of this.data) {
      for (let date of dates) {
        if (row[date.disp]) row[date.disp] = moment.utc(excelDateToDate(row[date.disp])).local().format(this.preferences.dateFormat);
      }
      for (let r of refers) {
        if (row[r.disp] && row[r.disp]?.startsWith('G00')) row[r.disp] = insapi.groupName(row.Owner);
      }
      for (let e of exprs) {
        if (row[e.disp]) {
          if (!e.efunc) e.efunc = new Function('data', 'return ' + e.expr.replace(/\./g, '?.'));
          row[e.disp] = e.efunc(row);
        }
      }
    }

    if (this.dataSource) this.dataSource.data = this.data;

    this.form.groups[0].fields = this.listDef.filters;
    this.form = JSON.parse(JSON.stringify(this.form));
    for (let fld of this.form.groups[0].fields) {
      if (fld.type == 'period') {
        fld.range = moment().add(-180, 'days').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD');
      }
    }
    if (this.listDef.layout) this.form.layout = this.listDef.layout;
    if (this.form.layout && !this.form.layout.maxWidth) this.form.layout.maxWidth = 860;
    this._set_layout_width();
  }

  async switchType(type: string) {
    this.type = type;
    this.filters = {};
    if (this.product) this.__load(this.product.product_id);
  }

  async menuClicked(action: any, row: any) {
    if (!row.policy_id) return;
    if (action.code === 'clone' || action.code === 'clonedocs' || action.code === 'version') {
      if (!row.quote_id) return;
      // let policy = await insapi.cloneQuote(row.quote_id, action.code === 'clonedocs', (action.code === 'version') ? '' : '1', row['master_policy_no']);
      // if (policy.length > 0)
      //   this.router.navigate(['/policy'], { state: { asd: 1 }, queryParams: { policy_id: policy[0].policy_id } });
    } else if (action.code === 'delete') {
      if (!row.quote_id) return;
      let ret = await insapi.markDeleted(row.quote_id);
      if (ret.status == 0 && this.dataSource)
        this.dataSource.data = this.dataSource.data?.filter((r: any) => (r.quote_id != row.quote_id));

    } else if (action.code === 'refer') {
      if (!row.quote_id) return;
      this.router.navigate(['/referral'], { state: { asd: 1 }, queryParams: { policy_id: row.policy_id } });
    }
    /* else if (action.code === 'assign') {
            if (!row.quote_id) return;
            const dialogRef = this.dialog.open(ReassignPolicyComponent, {disableClose: true});
            dialogRef.afterClosed().subscribe(async (result) => {
                // console.log('dialog: ', result);
                if (result.action != 'assign' || !result.user) return;
                await insapi.assignTo(row.quote_id, result.user, result.reason||'');
                this.onFilterAction({});
            });
        }  */
    else if (action.code === 'takeover') {
      if (this.endorsement) {
        console.log("takeover");
        if (!row.endorsement_id) return;
        await insapi.acquireEndorsement(row.endorsement_id);
        this.rowClicked(row);
      }
      else {
        if (!row.quote_id) return;
        await insapi.acquireQuote(row.quote_id);
        this.router.navigate(['/policy'], { state: { asd: 1 }, queryParams: { policy_id: row.policy_id } });
      }
    } else if (action.code == 'share') {
      const dialogRef = this.dialog.open(EmailPDFComponent, { data: { policy_id: row.policy_id, mod: 'policy' }, panelClass: 'login-panel' });
      dialogRef.afterClosed().subscribe(result => { console.log('result: ', result) });
    } else if (action.code === 'download') {
      let url = insapi.server + '/api/v1/policy/data/' + row.policy_id + '/policy.pdf/policy.pdf?download=1';
      if (this.endorsement) url = insapi.server + '/api/v2/endorsement/data/' + row.endorsement_id + '/endorsement.pdf/endorsement.pdf?download=1';
      url += '&token=' + encodeURIComponent(insapi.getToken());
      window.location.href = await http.__encrypt_url(url, true);
    } else if (action.urlFunc) {
      let url = action.urlFunc.call(this, row, null);
      console.log('btn-url:', url);
      if (url) {
        url = url.startsWith("http") ? url : (insapi.server + url);

        if (action.source && action.source.method == 'GET') {
          await insapi.__xget(url);
        }
        else if (action.source && action.source.method == 'POST') {
          let post_param: any = {};
          if (action.source.params)
            post_param = action.source.params;
          await insapi.__xpost(url, post_param);
        }
        else {
          url = url + (url.indexOf('?') >= 0 ? '&' : '?') + 'token=' + encodeURIComponent(insapi.getToken());
          window.location.href = await http.__encrypt_url(url, true);
        }
      }
    }
  }

  async rowClicked(row: any) {
    if (this.endorsement) {
      let params: any = { endorsement_id: row.endorsement_id, policy_id: row.policy_id };
      this.router.navigate(['/endorsement'], { state: { asd: 1 }, queryParams: params });
    } else {
      let params: any = { policy_id: row.policy_id, endorsements: row.policy_no ? 1 : undefined };
      this.router.navigate(['/policy'], { state: { asd: 1 }, queryParams: params });
    }
  }

  async changeProduct() {
    this.product = null;
    this._select_product();
  }

  async applyFilter(ev: any) {
    this.dataSource.filter = ev.target?.value.trim().toLowerCase() || '';
  }

  async downloadAsCsv(ev: any) {
    if (this.product?.product_id)
      await this.__load(this.product?.product_id, 'csv');
  }

  async downloadAsXlsx(ev: any) {
    let filters: any = {
      product_id: this.product?.product_id,
      [this.endorsement ? "endorsement.status" : "quote.status"]: status
    }
    // add user provided filters
    for (let key in this.filters) filters[key] = this.filters[key];
    filters = flattenJSON(filters);
    if(filters && filters.master_policy_no != undefined){
      let url = insapi.server + '/api/v1/soa_report/download_report?policy_no=' + filters.master_policy_no;
      url += '&token=' + encodeURIComponent(insapi.getToken());
      window.location.href = await http.__encrypt_url(url, true);
      
    }
    
  }

  async onFilterAction(ev: any) {
    console.log("this.filters=>",this.filters);
    if (this.filters.product_id) this.__load(this.filters.product_id);
    else if (this.product || this.filtered_product) this.__load(this.product?.product_id || this.filtered_product);
  }

  async onFilterChange(ev: any) {
    // if (ev && ev.field_name === 'product_id') this.__load(this.filters.product_id);
    // console.log('ev:', ev)
    if (ev.min && ev.max) {
      let min = moment(ev.min).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      let max = moment(ev.max).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      this.filters['u_ts'] = '[]' + min + ',' + max;
      this.filters['period'] = ev.min + ',' + ev.max;
    }
  }

  _evalFunc(expr: string) {
    expr = expr.replace(/this\.mod\.data/g, 'data');
    expr = expr.replace(/this\.mod/g, 'policy');
    expr = expr.replace(/this\.data/g, 'data');
    let _expr = "try{return (" + expr + ");}catch(e){return false;}";
    return new Function('data', 'profile', 'policy', "with(data){" + _expr + "}");
  }

  _urlFunc(expr: string) {
    try {
      if (expr.startsWith("data.")) {
        let parts = expr.split('.');
        if (parts.length == 2) {
          expr = "(" + expr + ")";
          return new Function('data', 'policy', "return typeof " + expr + " === 'string' ? (" + expr + " ? " + expr + ".split(','):[]) : " + expr);
        } else {
          // console.log("url: data.:", "return (("+parts[0]+"?."+parts[1]+")||[]).map(x => x['"+parts[2]+"'])");
          return new Function('data', 'policy', "return ((" + parts[0] + "?." + parts[1] + ")||[]).map(x => x['" + parts[2] + "'])");

        }
      } else if (expr.startsWith("'")) {
        return new Function('data', 'policy', "with(data){return (" + expr + ");}");
      } else if (expr.indexOf('{{') < 0) {
        if (expr.indexOf('\'') >= 0 || expr.indexOf('\"') >= 0)
          return new Function('data', 'policy', "return " + expr + "");
        return new Function('data', 'policy', "return '" + expr + "'");
      } else {
        return new Function('data', 'policy', `
                    let re = new RegExp(/{{(.*?)}}/g);
                    let match = re.exec(`+ expr + `);
                    let ret = `+ expr + `;
                    while (match != null) {
                        ret = ret.replace(new RegExp( match[0], 'g'), data[match[1]] || '');
                        match = re.exec(`+ expr + `);
                    }
                    return ret;
                `);
      }
    } catch (e) {
      console.log('_expr: ', expr, e);
      return '';
    }

  }

}
