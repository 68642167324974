import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { insapi, IProfile } from 'insapi';
import { Subscription } from 'rxjs';
import { IField } from '../../form.interface';

@Component({
    selector: 'i-buttons',
    template: `
    <div class="buttons-wrapper">
        <div class="button-item" [formGroup]="group" *ngFor="let btn of field.buttons">
            <button type="submit" ibutton cls="flat" color="primary" mat-button (click)="clickAction(btn)" 
                [matMenuTriggerFor]="btn.options ? submenu : null"
                [disabled]="(!btn.no_disable && (btn.readonly || readonly))?true:null" [title]="field.title ?? '' | generic2: field.title_format"
                *ngIf="(!btn.ifFunc || btn.ifFunc(data, profile, policy))" [attr.data-iid]="btn.field_name">{{btn.label}}</button>
            <mat-menu #submenu="matMenu">
                <button mat-menu-item *ngFor="let opt of btn.options||[]" (click)="onSubAction(btn, opt)">{{opt.name}}</button>
            </mat-menu>
        </div>
    </div>
  `,
    styles: [
        `.buttons-wrapper {padding: 0em 1em 0em 0em;}`,
        `.button-item {display: inline-block; padding: 0em 0em 0em 1em;}`
    ]
})
export class ButtonsComponent implements OnInit {

    @Output() onAction = new EventEmitter<any>();
    field!: IField;
    group!: FormGroup;
    readonly!: boolean;
    policy!: any;
    data!: any;
    profile!: IProfile | null;
    
    subscription: Subscription | null = null;
    
    constructor() {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile|null) => {this.profile = profile;});
     }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = null;
    }

    clickAction(btn: any) {
        if (btn.options) return;
        this.onAction.emit(btn);
    }

    onSubAction(btn: any, opt: any) {
        btn.sub_action = opt.value;
        this.onAction.emit(btn);
    }
}
