<div class="div-curved">
    <div class="home-banner" *ngIf="profile">
        <div class="home-compact">
            <div class="home-welcome-banner">
                <div class="welcome">
                    <span *ngIf="!profile.proxyOf">Welcome back</span>
                    <span *ngIf="profile.proxyOf" style="cursor: pointer;" (click)="switchBack()">Proxy by {{profile.proxyOf}}</span>
                    <p>{{profile.name}}</p>
                    <div *ngIf="profile.can_proxy || profile.proxyOf">
                        <div *ngIf="proxy_users.length==0" (click)="loadProxyUsers()">Switch user</div>
                        <div *ngIf="proxy_users.length>0">
                            <select class="proxy-select" [(ngModel)]="proxySelected" (change)="proxy($event)">
                                <option *ngFor="let user of proxy_users" [value]="user.email">{{user.email + '[' + user.broker_code+']'}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="home-deposit-banner" *ngIf="profile.is_broker && (profile.data.gist_email === undefined || profile.data.gist_email=='') && (profile.data.gist_cust_id === undefined || profile.data.gist_cust_id=='')">
                <div class="deposit-wrapper" >
                    <div class="deposit-wide">
                        <div style="text-align: right; border-right: 1px solid #aaa; padding-right: 10px;">
                            <div class="home-deposit-item">Deposit balance</div>
                            <div class="home-deposit-item">As of</div>
                        </div>
                        <div style="padding-left: 10px;">
                            <div class="home-deposit-item">{{profile.deposit_balance | currency}}</div>
                            <div class="home-deposit-item">{{profileAsOf}} &nbsp;&nbsp;
                                <small [routerLink]="['/deposit']" style="text-decoration: underline; cursor: pointer;">Ledger</small>
                            </div>
                        </div>
                    </div>
                    <div class="deposit-narrow">
                        <div>
                            <div style="text-align: right; padding-top: 1em; padding-right: 1em;" [routerLink]="['/deposit']">
                                <div style="font-size: 0.8em">Deposit balance</div>
                                <div>{{profile.deposit_balance | currency}}</div>
                                <div style="color: var(--header-fg-hilite)">{{profileAsOf}}</div>
                                <div><small [routerLink]="['/deposit']" style="text-decoration: underline; cursor: pointer;">Ledger</small></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
        </div>
    </div>
    <div class="svg-curved" *ngIf="profile" >
        <svg viewBox="0 0 500 150" style="height: 100%; width: 100%;" preserveAspectRatio="none">
          <!-- <linearGradient id="gradient"><stop offset="0%" stop-color="#2C2DF2" /><stop offset="100%" stop-color="#2B96F2"/></linearGradient> -->
          <linearGradient id="gradient"><stop offset="0%" class="curve-stop-0" /><stop offset="100%" class="curve-stop-100" /></linearGradient>
        <path d="M-0.85,137.65 C135.72,178.12 336.06,-110.03 500.84,85.35 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: url(#gradient);"></path>
        </svg>
    </div>
</div>


<div *ngIf="profile">
    <div class="home-product-container" *ngIf="flags.topProducts">
        <product-selector [products]="topProducts" (newpolicy)="newPolicy($event)"></product-selector>
    </div>

    <div class="stats-week" *ngIf="(profile.data.gist_email === undefined || profile.data.gist_email=='') && (profile.data.gist_cust_id === undefined || profile.data.gist_cust_id=='')">
        <div class="stats-header">{{weekName}}</div>
        <div class="stats-calendar">
            <div *ngFor="let stat of stats" class="stat" [class.stat-today]="stat.current">
                <div class="stat-day" [class.stat-today-day]="stat.current">{{stat.day}}</div>
                <div [style.color]="stat.current ? '#FFFFFF' : '#8B829D'">{{stat.total | shortNumber}}</div>
                <div [style.color]="stat.current ? '#FFFFFF' : '#8B829D'">{{stat.count | shortNumber}}</div>
            </div>
        </div>
    </div>

    <div class="recent-quotes" *ngIf="flags.pendingWith || flags.approved || flags.rejected || flags.query_by_uw">
         <uw-summary></uw-summary>
    </div>
    
    <div class="recent-quotes" *ngIf="flags.recentQuotes && (profile.data.gist_email === undefined || profile.data.gist_email=='') && (profile.data.gist_cust_id === undefined || profile.data.gist_cust_id=='')">
        <div class="recent-header">Recent quotes</div>
        <div *ngIf="quotes.length==0" style="padding-left: 1em;"><a href="policy-list" (click)="gotoPolicies($event)">search for quotes</a> </div>
        <table class="table-simple" *ngIf="quotes.length>0">
            <thead><tr><th *ngFor="let val of quotesFields">{{val.label | capitalize}}</th></tr></thead>
            <tr *ngFor="let quote of quotes | slice:1" (click)="loadPolicy(quote)" >
                <td [style.color]="types[val.label]?types[val.label].color:undefined"
                    [style.text-align]="types[val.label]?types[val.label].align:'left'"
                    *ngFor="let val of quotesFields; let i=index;">{{quote[i] | generic2: val.pipe :val.pipe_args}}</td>
            </tr>
        </table>
        <div style="font-size: 0.9em; cursor: pointer; text-align: right;" (click)="gotoPolicies()" *ngIf="quotes.length>0">More ...</div>
    </div>

    <div class="recent-quotes" *ngIf="referrals.length>0">
        <div class="recent-header">Recent Referrals</div>
        <table class="table-simple">
            <thead><tr><th *ngFor="let val of referralFields">{{val.label | capitalize}}</th></tr></thead>
            <tr *ngFor="let quote of referrals | slice:1" (click)="loadReferral(quote)" >
                <td [style.color]="types[val.label]?types[val.label].color:undefined"
                    [style.text-align]="types[val.label]?types[val.label].align:'left'"
                    *ngFor="let val of referralFields; let i=index;">{{quote[i] | generic2: val.pipe :val.pipe_args}}</td>
            </tr>
        </table>
        <div style="font-size: 0.9em; cursor: pointer; text-align: right;" (click)="gotoPolicies($event)">More ...</div>
    </div>


</div>
<activity [streamName]="" [mode]="0"></activity>