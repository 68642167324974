<div *ngIf="policy.policy?.iagree">
    <div *ngIf="!field.iagree.popup" class="iagree-inline">
        <div class="igree-tandc" *ngIf="tandc" [innerHTML]="tandc"></div>
        <div><mat-checkbox [(ngModel)]="agreed" [disabled]="agreedOn!=''">{{prompt}}</mat-checkbox></div>
        <div *ngIf="!agreedOn"><button mat-button (click)="agree()">{{field.label}}</button></div>
        <div *ngIf="agreedOn" style="padding: .5em 0em;">Agreed on <span class="agreed">{{agreedOn}}</span> by <span class="agreed">{{policy.policy?.proposal?.agreed_by}}</span></div>
    </div>
    <div *ngIf="field.iagree.popup">
        <button mat-button (click)="showTandC()" *ngIf="!agreedOn">{{field.label}}</button>
        <div *ngIf="agreedOn" style="padding: .5em 0em;">Agreed on <span class="agreed">{{agreedOn}}</span> by <span class="agreed">{{policy.policy?.proposal?.agreed_by}}</span></div>
    </div>
</div>