import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreferencesService } from 'ins-form';

@Component({
    selector: 'csv-import',
    templateUrl: './csv-import.component.html',
    styleUrls: ['./csv-import.component.scss']
})
export class CsvImportComponent implements OnInit{

    parsed: string[][] = [];
    errors: string[][] = [];
    status: boolean[] = [];
    errCount: number = 0;
    showErrors: boolean = false;
    headers = ['col1', 'col2'];

    // data is expected to have 
    //  headers : string array of column headers
    //  validations: array of objects describing validations on each cell value (pass empty object if none needed)
    //
    constructor(public dialogRef: MatDialogRef<CsvImportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public preferences: PreferencesService) {
            this.headers = data.headers;
    }

    ngOnInit() {
        this.dialogRef.updateSize('80%', '60%');
    }

    ngAfterViewInit() {
        document.getElementById('import-file')?.click();
    }

    onFileSelected(files: File[]) {
        if (files.length == 0 || !files[0]) return;
        const reader = new FileReader();
        reader.onload = () => this.csvToJson(reader.result);
        reader.readAsText(files[0]);
    }

    line_to_array(str: string) {
        let sep = ',';
        let esc = '\\';
        let q = '';
        let acc = '';
        let ret = [];
        for (let i=0; i<str.length; i++) {
            if (str[i] == sep) {
                ret.push(acc.trim());
                acc = '';
            } else if (str[i] == esc) { // consume escaped character
                acc += str[i+1];
                i ++;
            } else if (str[i] == '"' || str[i] == "'") {
                if (q) {q = ''; for(; i<str.length-1; i++) if (str[i+1]==sep) break;} // skip trailing white spaces after end "
                else q = str[i]; //todo: assert acc is empty here
            } else {
                acc += str[i];
            }
        }
        ret.push(acc.trim());
        return ret;
    }

    _validate() {
        let errors = [];

        this.status = [];
        this.errCount = 0;
        for (let row of this.parsed) {
            let erow = [];
            let state = false;
            for (let i=0; i<row.length; i++) {
                let err = '';
                if (this.data.validations[i] && this.data.validations[i].options) {
                    if (!this.data.validations[i].options.includes(row[i])) {
                        err = row[i] + ' not allowed';
                        state = true;
                    }
                }
                erow.push(err);
            }
            errors.push(erow);
            this.status.push(state);
            if (state) this.errCount ++;
        }
        this.errors = errors;
        console.log('errors: ', this.errors, this.data.validations)
    }

    csvToJson(txt: string | ArrayBuffer | null) {
        if (!txt) return;
        if (!this.headers || this.headers.length == 0) return;

        if (txt instanceof ArrayBuffer) txt = Buffer.from(txt).toString('utf8');
        
        let lines = txt.split('\n').map(x => x.trim()).filter(Boolean).map(x => this.line_to_array(x));
        let headers = lines.shift();
        if (headers != undefined) {
            headers = headers.map(x => x.toLowerCase());
            let idxs = this.headers.map(x => headers ? headers.indexOf(x.toLowerCase()) : -1);
            this.parsed = lines.map(r => idxs.map(x => x >= 0 ? r[x] : ''));
        }
        this._validate();
    }

    onAppend() {
        this.dialogRef.close({mode: 'append', data: this.parsed});
    }

    onReplace() {
        this.dialogRef.close({mode: 'replace', data: this.parsed});
    }

    onClose() {
        this.dialogRef.close({});
    }
}
