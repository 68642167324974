<div *ngIf="policy && policy.policy && policy.product && policy.product.combo_type">
    <div *ngIf="policy.policy.policy_id" style="font-size: 1.1em; padding-bottom: 1em;">
    </div>
    <div *ngIf="!policy.policy.policy_id && policy.product.combo_type=='simple'">
        <button mat-button ibutton (click)="savePolicy()">Create sub policies</button>
    </div>

    <div *ngIf="policy.policy?.data?.combo && policy.product?.combo_type == 'simple' " style="padding: 0px 1em;">
        <div *ngIf="policy.policy.policy_no">Sub policies</div>
        <div *ngIf="!policy.policy.policy_no">Complete following policies</div>    
        <div class="sub-policy header">
            <div>Product name</div>
            <div>Sub-Policy Number</div>
            <div style="text-align: right;">Premium</div>
            <div style="text-align: right;">Action</div>
        </div>
        <div class="sub-policy" *ngFor="let sub of policy.policy.data.combo">
            <div>
                <span *ngIf="_products[sub.product_id]">{{_products[sub.product_id].product_name}}</span>
                <span *ngIf="!_products[sub.product_id]">{{sub.product_id}}</span>
            </div>
            <div>
                {{sub.policy_no || ''}}
            </div>
            <div style="text-align: right;">
                {{sub.premium}}
            </div>
            <div style="text-align: right; cursor: pointer;">
                <a *ngIf="sub.premium <= 0" (click)="goToPolicy(sub.policy_id)" [attr.data-iid]="field.field_name+'-complete-'+sub.product_id">Complete Proposal 
                    <mat-icon fontSet="material-icons-outlined" style="vertical-align: middle;">navigate_next</mat-icon>
                </a>
                <a *ngIf="sub.premium >  0" (click)="goToPolicy(sub.policy_id)" [attr.data-iid]="field.field_name+'-view-'+sub.product_id">View</a> &nbsp; | &nbsp;
                <a *ngIf="sub.policy_no" (click)="downloadPolicy(sub.policy_id)" [attr.data-iid]="field.field_name+'-download-'+sub.product_id">Download</a>
            </div>
        </div>
    </div>

    <div class="prod-list" *ngIf="policy.product?.combo_type == 'package'">
        <div *ngFor="let sub of subproducts" class="sub-prod" [class.sub-prod-added]="sub.policy_id && sub.selected">
            <div *ngIf="sub.product">
                <div (click)="goToPolicy(sub.policy_id)">{{sub.product.product_name}}</div>
                <div (click)="goToPolicy(sub.policy_id)"style="color: #999;">{{sub.product.display_name}}</div>
                <div *ngIf="sub.policy_id">
                    <button mat-button (click)="removeFromPackage(sub.product_id)">Remvove from package</button>
                </div>
                <div *ngIf="sub.premium_value>0" class="sub-premium" >{{sub.premium_value}}</div>
                <div *ngIf="sub.premium_value<=0" class="sub-premium" style="font-size: 1em; color: #AAA;">Fill details to get Premium</div>

                <div *ngIf="!sub.policy_id">
                    <button mat-button (click)="addToPackage(sub.product_id)">Add to package</button>
                </div>
                <mat-icon class="sub-icon" *ngIf="sub.policy_id && sub.selected" style="color: #080;" (click)="removeFromPackage(sub.product_id)">close</mat-icon>
                <mat-icon class="sub-icon" *ngIf="!sub.policy_id || !sub.selected" style="color: #800;" (click)="addToPackage(sub.product_id)">add</mat-icon>    
            </div>
            <div *ngIf="!sub.product">
                No access
            </div>
        </div>
    </div>
    <div *ngIf="policy.product?.combo_type == 'qms'">
        <div style="padding-left: 1em; font-size: 1.1em;">Choose a product</div>
        <mat-radio-group name="subproducts" [(ngModel)]="selected">
            <mat-radio-button *ngFor="let sub of subproducts" [value]="sub.product_id" (change)="subProdChange($event)">
                <div>{{sub.product.product_name}}</div>
                <div style="color: #999; font-size: .9em;">{{sub.product.display_name}}</div>
            </mat-radio-button>
        </mat-radio-group>

        <div style="padding: 2em 1em;">
            <div style="font-size: 1.1em;">Quotes and clones</div>
            <div class="grid-3" style="gap: 2em;">
                <div *ngFor="let clone of clones" class="tile">
                    <div style="cursor: pointer; align-items: center;">
                        <span (click)="goToPolicy(clone.policy_id)">{{clone.policy_id}}</span>
                        <mat-icon class="mini-mat-icon" style="margin-left: 1em;" (click)="cloneQuote(clone)">content_copy</mat-icon></div>
                    <div (click)="goToPolicy(clone.policy_id)" [innerHTML]="clone.html"></div>
                </div>
            </div>
        </div>
    </div>
</div>

