import { Component, OnInit } from '@angular/core';
import { IField } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { Policy, is_num, excelDateToDate } from 'insapi';
import { Subscription } from 'rxjs';
import { PreferencesService } from '../../../lib/services/preferences.service';

@Component({
    selector: 'lib-policy-summary',
    templateUrl: './policy-summary.component.html',
    styleUrls: ['./policy-summary.component.css']
})
export class PolicySummaryComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    policy!: Policy;
    readonly: boolean = false;
    data: any = {};

    startDate: string = "";
    endDate: string = "";
    issueDate: string = '';
    holder: string = '';
    constructor(public prefernces: PreferencesService) { }

    psubscription: Subscription | undefined = undefined;
    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => {
            if (!this.policy) return;
            let dt = excelDateToDate(this.policy.policy?.policy_start_date||'');
            if (dt) this.startDate = dt.format(this.prefernces.dateFormat);
            dt = excelDateToDate(this.policy.policy?.policy_end_date||'');
            if (dt) this.endDate = dt.format(this.prefernces.dateFormat);
            dt = excelDateToDate(this.policy.policy?.issue_date||'');
            if (dt) this.issueDate = dt.format(this.prefernces.dateFormat);

            let last = this.policy?.policy?.proposal?.data.last_name || this.policy?.policy?.quote?.data.last_name || '';
            this.holder = this.policy?.policy?.proposal?.data.first_name || this.policy?.policy?.quote?.data.first_name || '';
            if (last) this.holder = this.holder ? this.holder + ' '  + last : last;
        });

        // if (this.policy) {
        //     this.psubscription = this.policy.policySubject.subscribe(() => {
        //         let dt = excelDateToDate(this.policy.policy?.policy_start_date||'');
        //         if (dt) this.startDate = dt.format(this.prefernces.dateFormat);
        //         dt = excelDateToDate(this.policy.policy?.policy_end_date||'');
        //         if (dt) this.endDate = dt.format(this.prefernces.dateFormat);
        //         dt = excelDateToDate(this.policy.policy?.issue_date||'');
        //         if (dt) this.issueDate = dt.format(this.prefernces.dateFormat);

        //         let last = this.policy?.policy?.proposal?.data.last_name || this.policy?.policy?.quote?.data.last_name || '';
        //         this.holder = this.policy?.policy?.proposal?.data.first_name || this.policy?.policy?.quote?.data.first_name || '';
        //         if (last) this.holder = this.holder ? this.holder + ' '  + last : last;
        //     });
        // }
    }
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

}
