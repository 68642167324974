import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IField } from '../../form.interface';

@Component({
    selector: 'intermediary',
    templateUrl: './intermediary.component.html',
    styleUrls: ['./intermediary.component.css']
})
export class IntermediaryComponent implements OnInit {
    data!: any;
    fgroups: any = [];
    field: IField = { field_name: '', type: '' };
    @Output() onChange = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
        this.__init();
    }

    __init() {
        this.fgroups = this.field.fldgroups;
        //console.log("this.fgroups",this.fgroups);
    }

    changed(ev: any) {
        this.onChange.emit(this.field);
    }

}
