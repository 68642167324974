import { Component, OnInit, SimpleChange, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { insapi, IPolicy, IEndorsement } from 'insapi';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { IField, animRotate, fldoptions, animToggle } from '../../form.interface';
import { Subscription } from 'rxjs';
import { PreferencesService } from '../../../lib/services/preferences.service';

@Component({
    selector: 'i-lookup',
    template: `
    <mat-form-field [formGroup]="group" *ngIf="(!field.subtype || field.subtype=='country') && control">
        <mat-label [ngClass]="field.labelclass">{{field.label}}</mat-label>
        <mat-select [placeholder]="field.label||''" [formControlName]="field.field_name" [multiple]="field.multi==1" 
            (selectionChange)="onChange.emit(field)" (closed)="clearSearch($event)" [attr.data-iid]="field.field_name" [title]="field.title ?? '' | generic2: field.title_format"
            (click)="fieldopen($event);">
            <ng-template [ngIf]="field.props?.searchable" [ngIfElse]="nosearch" >
                <div class="select-container">
                    <mat-optgroup *ngIf="field.options && field.options.length > 10">
                        <mat-form-field style="width: 100%">
                            <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput (keydown.space)="$event.stopPropagation();"
                                [formControl]="searchTextboxControl" />
                            <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear"
                                (click)="clearSearch($event)" >
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </mat-optgroup>
                    <mat-optgroup *ngIf="(filteredOptions | async)?.length == 0">
                        <div>No results found!</div>
                    </mat-optgroup>
                    <mat-icon class="i-icon" *ngIf="field.icon" matPrefix fontSet="material-icons-outlined" style="padding-right: 8px;">{{field.icon}}</mat-icon>
                    <mat-icon class="i-icon" *ngIf="field.icon_suffix" matSuffix fontSet="material-icons-outlined">{{field.icon_suffix}}</mat-icon>
        
                    <mat-option [disabled]="isOptionDisabled(item)"  (onSelectionChange)="selectionChange($event)" *ngFor="let item of filteredOptions | async" [value]="item.value">
                        {{item.icon}} {{item.name}}
                    </mat-option>
                </div>
            </ng-template>
            <ng-template #nosearch>
                <mat-option [disabled]="isOptionDisabled(item)"  (onSelectionChange)="selectionChange($event)" *ngFor="let item of field.options" [value]="$any(item).value">
                    {{$any(item).icon}} {{$any(item).name}}
                </mat-option>
            </ng-template>

        </mat-select>
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
            <mat-error *ngIf="control && validation.validator && control.hasError(validation.validator.name)">{{field.errors || (field.field_name | capitalize) + validation.message}}</mat-error>
        </ng-container>
        <mat-error *ngIf="control && control.hasError('ssValidator')">{{control.errors?.ssValidator?.msg}}</mat-error>
        <div class="input-sub-text" *ngIf="field.subtext && field.subtext.varname">{{field.subtext.desc}} {{data[field.subtext.varname] | generic2: field.subtext.pipe}}</div>
    </mat-form-field>
    <div *ngIf="field.subtype=='slider' && !control" class="i-slider-container">No control</div>
    <div *ngIf="field.subtype=='slider' && control" [class]="'i-slider-container' + (field.label?'':'-no-label') "  (click)="toggle()" #slider>
        <div class="i-slider" [class.slider-on]="control?.value==yesValue" 
            [class.slider-off]="control?.value!=yesValue" [attr.data-iid]="field.field_name">
            <div class="i-slider-bg" ></div>
            <div class="i-slider-ball">
                <mat-icon 
                    [@animToggle]="onoff" fontSet="material-icons-outlined">check</mat-icon>
            </div>
        </div>
        <div [ngClass]="field.labelclass">{{field.label}}</div>
    </div>

    <div *ngIf="field.subtype=='radio'" [formGroup]="group">
        <div class="bulb-label" [ngClass]="field.labelclass">{{field.label}}: <span>{{data[field.field_name]}}</span></div>
        <div class="radio-container">
            <mat-radio-group [formControlName]="field.field_name">
                <mat-radio-button *ngFor="let item of field.options" [value]="$any(item).value" (change)="changed()">{{$any(item).name}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div *ngIf="field.subtype=='bulb'" >
        <div class="bulb-label" [ngClass]="field.labelclass">{{field.label}}: <span>{{data[field.field_name]}}</span></div>
        <div class="bulb-container">
            <div *ngFor="let item of field.options" class="lookup-bulb" [class.bulb-selected]="data[field.field_name]==$any(item).value" (click)="select($any(item).value)">{{$any(item).name}}</div>
        </div>
    </div>
  `,
    styles: [
        `
        .i-slider-container-no-label {
            cursor: pointer;
            padding: 1em .5em;
            box-sizing: border-box;
        }
        .i-slider-container {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 1em;
            padding: 1em 1em;
            cursor: pointer;
        }
        .i-style-container:disabled {
            color: var(--select-disabled-fg) !important;
        }
        .i-slider {
            display: inline-block;
            position: relative;
            width: 42px;
            height: 24px;
            box-sizing: border-box;
        }
        .i-slider-bg {
            display: inline-block;
            width: 100%;
            height: 16px;
            margin-top: 4px;
            border-radius: 8px;
        }
        .i-slider-ball {
            display: inline-block;
            position: absolute;
            top: 0px;
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background-color: #fff;
            box-sizing: border-box;
            padding-left: 1px;
            padding-top: 0px;
        }
        .slider-on mat-icon {
            color: var(--border-color-65);
            width: 16px;
            height: 16px;
            font-size: 18px;
            padding-bottom: 4px;
        }
        
        
        .slider-off .i-slider-ball {
            left: 0px;
            border: 1px solid var(--border-color-75);
        }
        .slider-on .i-slider-ball {
            left: 18px;
            border: 2px solid var(--border-color-75);
        }
        .slider-on .i-slider-bg {
            background-color: var(--border-color);
        }
        .slider-off .i-slider-bg {
            background-color: var(--border-color-disabled);
        }
        `
    ],
    animations: [animToggle]
})
export class LookupComponent implements OnInit {

    @ViewChild('slider') elemSlider!: ElementRef;
    @ViewChild('search') searchTextBox!: ElementRef;

    @Output() onChange = new EventEmitter();
    data: any = {};
    field!: IField;
    group!: FormGroup;
    control!: FormControl;
    policy!: IPolicy;
    readonly!: boolean;
    slider: boolean = false;
    yesValue: string = '';
    __url: string | null = null;

    searchTextboxControl = new FormControl();
    selectedValues: any[] = [];
    onoff: boolean | undefined = undefined;

    filteredOptions!: Observable<fldoptions[]>;
    subscription: Subscription | null = null;
    constructor(private preferences: PreferencesService) { }

    async ngOnInit(): Promise<void> {
        await this._process_options();
        if (this.field && this.field.options) {
            this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
                startWith(''),
                switchMap((value) => this._filter(value))
            );
        }
        this.subscription = this.policy?.changeSubject?.subscribe((endorsement: IPolicy | IEndorsement | null) => {
            if (this.control && this.data[this.field.field_name]) this.fieldopen(null);
        });
    }
    
    // ?
    ngOnChange(changes: { [propKey: string]: SimpleChange }) {
        if (changes['field']) this._process_options();
    }

    // needs controlvalueaccessor :-(
    // setDisabledState(isDisabled: boolean) {
    //     console.log('......', this.field.field_name, 'disabled:', isDisabled);
    //     if (this.elemSlider) this.elemSlider.nativeElement.disabled = isDisabled;
    // }

    async _update_dependents() {
        if (!this.field.urlFunc) return;
        await this._download_options();
        
        let _options: any[] = this.field.options || [];
        
        let datafilter = _options.filter((X: any) => X.value == this.data[this.field.field_name]);
        if (datafilter?.length > 0) return;
            
        if (this.field.auto_init || _options.length==1) {
            if (_options.length > 0) {
                this.data[this.field.field_name] = _options[0].value;
            }
            if (this.data[this.field.field_name]) {
                if (!this.control?.value || this.control?.value != this.data[this.field.field_name]) {
                    this.control?.setValue(this.data[this.field.field_name], {emitEvent: false});
                    this.onChange.emit(this.field);
                }
            }
        }
        return;

    }

    async __options_from_field(): Promise<fldoptions[]> {
        if (this.field.subtype === 'country' && (!this.field.options || this.field.options.length==0)) {
            this.field.options = this.preferences.countries.map(x => ({name: x.name, icon: x.emoji, value: x.name}));
        }
        console.log('options:', this.field)
        if (!(this.field.options instanceof Array)) return []; // not worth processing
        if (typeof this.field.options[0] === 'string') {
            return this.field.options.map((x: any) => ({name: x, value: x, icon: ''}));
        }
        // expected to have an array of {name: string, value: string}
        return this.field.options ? this.field.options as fldoptions[] : [];
    }

    async __options_from_url(): Promise<fldoptions[] | null> {
        if (!this.field.urlFunc) return [];

        // url could be an array of strings (in case of comma separated values of local variable)
        let url = this.field.urlFunc.call(this, this.data, this.policy);
        let data = url instanceof Array ? url : [];

        let hasOptions = this.field.options instanceof Array ? this.field.options.length > 0 : false;
        if (!(url instanceof Array)) {
            if (this.__url === url && hasOptions) return null;    // no change detected
            try {data = await insapi._xget_cache(url);}
            catch (e) {console.log('failed to download url', url); return [];}
            this.__url = url;
        }
        if (typeof data[0] === 'string') return data.map((x: any) => ({name: x, value: x, icon: ''}));
        let nfld = this.field.source?.name || 'name';
        let vfld = this.field.source?.value || 'value';
        let ifld = this.field.source?.icon || '';
        return data.map((x: any) => ({name: x[nfld], value: x[vfld], icon: ifld?x[ifld]:''}));
    }


    async _download_options() {
        let data = this.field.urlFunc ? await this.__options_from_url() : await this.__options_from_field();
        console.log('options:', data)
        // in a weird case (policy-list recreation filter creates new set of fields), options get reset between the above call and now
        if (data === null && !this.field.options) data = this.field.urlFunc ? await this.__options_from_url() : await this.__options_from_field();

        if (data === null) return;  // nothing changed since last update       

        let empty = data.filter((x: any) => x.value=='');
        if (empty.length > 1) data = data.filter((x: any) => x.value!=='');
        this.field.options = data;
        if (this.field.options && this.field.options.length == 2) {
            let opt: any = this.field.options[0];
            if (opt.value == 'Yes' || opt.value == 'No') {
                this.slider = true;
                this.yesValue = opt.value;
            }
        }

        let options: fldoptions[] = this.field.options as fldoptions[];
        if (this.field.subtype === 'country') {
            for (let opt of options) {
                if (!opt.icon) {
                    let cntry = this.preferences.name2Country[opt.value.toLowerCase()];
                    if (cntry) opt.icon = cntry.emoji;
                }
            }
        }

        // force slider to option 2 if none is selected
        if (this.slider && this.field.getdata) {
            let cvalue = this.field.getdata(this.data);
            if (cvalue === undefined && this.preferences?.vendor?.widgets?.slider.forcedefault) {
                let opt: any = this.field.options[1];
                console.log('forcing slider to ', opt.value);
                this.field.setdata(this.data, opt.value);
            }
        }
    }

    async _process_options() {
        if (!this.field) return;
        if (!this.field.props) this.field.props = {};
        if (this.field.multi) {
            let value = this.group.controls[this.field.field_name].value;
            if (value && !(value instanceof Array))this.group.controls[this.field.field_name].setValue([value]);
        }
        await this._download_options();
        let __options: any[] = this.field.options || [];

        if (this.field.props?.sort_by && this.field.options instanceof Array) {
            
            if (this.field.props?.sort_desc == true) {
                this.field.options = this.field.options?.sort((a: any, b: any) => a[this.field.props?.sort_by] > b[this.field.props?.sort_by] ? -1 : +1);
            } else {
                this.field.options = this.field.options?.sort((a: any, b: any) => a[this.field.props?.sort_by] > b[this.field.props?.sort_by] ? +1 : -1);
            }
        }

        if (this.field.auto_init || __options.length==1) {
            if (!this.data[this.field.field_name] && __options.length > 0) {
                this.data[this.field.field_name] = __options[0].value;
            }

            if (this.data[this.field.field_name]) {
                if (!this.control?.value) {
                    this.control?.setValue(this.data[this.field.field_name], {emitEvent: false});
                    this.onChange.emit(this.field);
                }
            }
        }
        this.onoff = (this.control?.value == this.yesValue);
        return
    }

    // slider toggle (Yes => No => Yes)
    toggle() {
        if (this.readonly) return;
        let ctl = this.group.get([this.field.field_name]);
        if (!ctl || !this.field.options || ctl.disabled) return;
        
        let values = this.field.options.map((x: any) => x.value);
        this.select(ctl.value == values[0] ? values[1] : values[0]);
    }

    select(value: string) {
        if (this.readonly) return;
        if (this.field.setdata) this.field.setdata(this.data, value);
        this.group.get([this.field.field_name])?.setValue(value);
        this.onoff = (value == this.yesValue);
        console.log('vc:', value, this.onoff);
        this.onChange.emit(this.field);
    }

    changed() {
        this.onChange.emit(this.field);
    }

    isOptionDisabled(item: any): boolean {
        let value = typeof item == 'string' ? item : item.value;
        if (this.field.multi && (this.field.multi_maxlimit||0)>0){
            let values = this.group.controls[this.field.field_name].value;
            if ( values.length >= (this.field.multi_maxlimit||0) && !values?.includes(value))
                return true;
        }
        return false;
    }

    compareFn(v1: any, v2: any) {
        // console.log('cm', v1, v2, v1 == v2)
        return v1 == v2;
    }

    fieldopen(e: any) {
        this.filteredOptions = this.searchTextboxControl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this._filter(value))
        );
    }
    private async _filter(name: string): Promise<fldoptions[]> {
        const filterValue = name.toLowerCase();
        if (!this.field || !this.field.options) return [{name:'', value:'', icon: ''}];
        
        // Set selected values to retain the selected checkbox state
        if (this.field.multi) {
            this.setSelectedValues();
            if (this.selectedValues?.length > 0) this.control.patchValue(this.selectedValues);
        }
        return this.field.options?.filter(
            (option:any) => option.name.toString().toLowerCase().indexOf(filterValue) === 0
        ) as fldoptions[];
    }
    
    selectionChange(event:any) {
        if (!this.field.multi) return;
        if (event.isUserInput && event.source.selected == false) {
            let index = this.selectedValues.indexOf(event.source.value);
            this.selectedValues.splice(index, 1);
        }
    }
    
      
    clearSearch(event: any) {
        if (event) event.stopPropagation();
        this.searchTextboxControl.patchValue('');
    }

    setSelectedValues() {
        //console.log('selectFormControl', this.control.value);
        if (this.control.value?.length > 0) {
            this.control.value.forEach((e: fldoptions) => {
                if (this.selectedValues.indexOf(e) == -1) this.selectedValues.push(e);
            });
        }
    }

}