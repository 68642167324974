<div *ngIf="enabled">
    <div *ngIf="policy.policy?.payment?.schedule" style="padding: 1em 1em;">
        Payment schedule
        <table class="gtable">
            <tr>
                <th>Due date</th>
                <th style="text-align: right;">Amount</th>
                <th style="text-align: right;">Late fee</th>
                <th style="text-align: right;">Total</th>
                <th style="text-align: right;">Received</th>
            </tr>
            <tr *ngFor="let sch of policy.policy?.payment?.schedule">
                <td>{{sch.first_date}}</td>
                <td style="text-align: right;">{{sch.amount | currency}}</td>
                <td style="text-align: right;">{{sch.late_fee | currency}}</td>
                <td style="text-align: right;">{{sch.total_amount | currency}}</td>
                <td style="text-align: right;">{{sch.total_recvd | currency}}</td>
            </tr>
        </table>

        <button mat-button (click)="cancelInstallments()">Cancel installments</button>
    </div>
    <div *ngIf="!policy.policy?.payment?.schedule" class="inst">
        <div style="display: grid; grid-template-columns: 100px 1fr 1fr; align-items: center;">
            <div class="form-field">
                <mat-form-field style="width: 100%;">
                    <mat-select placeholder="No Of Installments" [(ngModel)]="emiCount">
                        <mat-option *ngFor="let opt of emiOptions" [value]="opt">{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-field">
                <button mat-button (click)="addInstallments()">Enable installments</button>
            </div>
        </div>
    </div>
</div>
