import { Component, OnInit } from '@angular/core';
import { insapi } from 'insapi';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
    selector: 'groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

    groupName: string = '';
    userName: string = '';
    privName: string = '';
    groupUsers: string[] = [];
    groupPrivileges: {[key: string]: any} = {GET: [], POST: [], DELETE: []};
    privileges: {[key: string]: any[]} = {};
    privNames: string[] = [];
    rsubscription: Subscription | null = null;
    constructor(private activatedRoute: ActivatedRoute) {
        this.rsubscription = this.activatedRoute.paramMap.subscribe(params => {
            this.groupName = params.get('group_name') || '';
            this.groupChanged();
        });
    }
    ngOnDestroy(): void {
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
    }

    ngOnInit(): void {
    }

    async _get_group_privileges() {
        let grpPriv = await insapi.__xget('/api/v1/group/privilege?group_name='+encodeURIComponent(this.groupName));
        
        this.groupPrivileges = {GET: [], POST: [], DELETE: []};
        for(let p in grpPriv) {
            let prv: any = this.privileges[p];
            prv.disp_name = prv.priv_name;
            if (prv.disp_name.toLowerCase().startsWith('view '))prv.disp_name = prv.disp_name.substr(5);
            if (prv.disp_name.toLowerCase().startsWith('modify '))prv.disp_name = prv.disp_name.substr(7);
            this.groupPrivileges[prv.api_method].push(prv);
        }
    }

    async groupChanged() {
        if (!this.groupName) return;
        this.groupUsers = await insapi.__xget('/api/v1/group/users?group_name='+encodeURIComponent(this.groupName));
        this.privileges = (await insapi.privileges()).reduce((a: any, x: any) => {a[x.priv_name]=x; return a;}, {});
        this.privNames = Object.keys(this.privileges);
        await this._get_group_privileges();
    }

    async userChanged() {
        console.log('user changed ...', this.userName);
    }
    async privChanged() {
        console.log('priv changed ...', this.privName);
    }
    async addPrivilege() {
        if (!this.privName || !this.privileges[this.privName]) return;
        await insapi.__xpost('/api/v1/group/privilege/'+encodeURIComponent(this.groupName)+'/'+encodeURIComponent(this.privName), {});
        await this._get_group_privileges();
    }
    async removePriv(privName: string) {
        await insapi.__xdel('/api/v1/group/privilege/'+encodeURIComponent(this.groupName)+'/'+encodeURIComponent(privName));
        await this._get_group_privileges();
    }
    async addUser() {
        if (!this.userName) return;
        await insapi.__xpost('/api/v1/group/user/'+encodeURIComponent(this.groupName)+'/'+encodeURIComponent(this.userName), {});
        this.groupUsers = await insapi.__xget('/api/v1/group/users?group_name='+encodeURIComponent(this.groupName));
    }
    async addUsers(ev: any) {
        let files = ev.target.files;
        const formData = new FormData();
        formData.append('name', files[0].name);
        formData.append('file', files[0], files[0].name);
        await insapi.__xpost('/api/v1/group/attach/users/'+encodeURIComponent(this.groupName), formData);
        this.groupChanged();
    }
    async removeUser(userName: string) {
        await insapi.__xdel('/api/v1/group/user/'+encodeURIComponent(this.groupName)+'/'+encodeURIComponent(userName));
        this.groupUsers = await insapi.__xget('/api/v1/group/users?group_name='+encodeURIComponent(this.groupName));
    }
}
