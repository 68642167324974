
<div class="div-curved" *ngIf="policy && policy.policy && stages.length>0" >
    <div *ngIf="policy.product" style="text-align: center; font-size: 1em;">
            {{policy.product.product_name}}
            <ng-template [ngIf]="premiumDisp"><i-number *ngIf="!premiumData.message" [field]="premiumDisp" [data]="premiumData" decimals="2"></i-number>{{premiumData.message}}</ng-template>
            <ng-template [ngIf]="upgrade"><span style="cursor: pointer;" (click)="upgradeVersion()">You are on older pricing version, switch to current pricing?</span></ng-template>
            <div>
                <ng-template #referance_id [ngIf]="policy.policy.quote[referranceDisp.field_name] || policy.policy.quote.data[referranceDisp.field_name]"> {{referranceDisp.label}} : {{policy.policy.quote[referranceDisp.field_name] || policy.policy.quote.data[referranceDisp.field_name]}}</ng-template>
            </div>
            <div>
                <div *ngIf="inMyGroup">
                    This policy has been assigned to one of your Groups ({{assignedTo}}), would you like to process it? <button mat-button ibutton (click)="takeOver()">Take over</button>
                </div>
                <div *ngIf="!profile || !profile.is_underwriter || !policy.policy.assigned_to.startsWith('G00')">
                    <small>{{assignedTo}}</small>
                </div>
                
            </div>
            <div><small>{{policy.policy.policy_no}}</small></div>
        
    </div>
    <div class="svg-curved">
        <svg viewBox="0 0 500 150" style="height: 100%; width: 100%;" preserveAspectRatio="none">
          <linearGradient id="gradient"><stop offset="0%" class="curve-stop-0"/><stop offset="100%" class="curve-stop-100"/></linearGradient>
          <path d="M-0.85,137.65 C135.72,178.12 336.06,-110.03 500.84,85.35 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: url(#gradient);"></path>
        </svg>
    </div>
</div>
<div style="display: grid; grid-template-columns: 1fr auto;">
    <div *ngIf="policy && policy.policy && stages.length>0">
        <istepper #cdkStepper [linear]="false" [layout]="workflow.layout.stepper">
            <cdk-step *ngFor="let stage of stages" [editable]="true" [label]="stage.name" [optional]="stage.ready" [completed]="stage.readonly && stage.ready">
                <div class="policy-container">
                    <div *ngIf="stage.ready" class="form-wrapper"
                        [ngStyle]="stage.form.layout.style"
                        [style.max-width]="stage.form.layout.width" 
                        [style.min-width]="stage.form.layout.width">
                        <div class="stage-fg">
                            <div *ngFor="let grp of stage.form.groups">
                                <field-group #fgs *ngIf="policy.policy[stage.module.name]"
                                    [fieldGroup]="grp" 
                                    [data]="policy.policy[stage.module.name].data"
                                    [policy]="policy"
                                    [readonly]="stage.readonly"
                                    [layout]="grp.layout || stage.form.layout"
                                    (onAction)="onAction($event)"
                                    (onChange)="onChange($event)">
                                </field-group>
                            </div>
                        </div>
    
                        <div class="summary-footer" *ngIf="documents.length>0 || stage.actions">
    
                            <div class="stage-summary-doc" *ngIf="documents.length>0 && policy.policy.document_id">
                                <div class="summary-doc-caption"><mat-icon>attach_file</mat-icon> Attachments</div>
                                <ng-template ngFor let-item [ngForOf]="docgroups | keyvalue" >
                                    <div class="dg-uploaded" *ngFor="let doc of item.value.uploaded">
                                        <div (click)="downloadDocument(item.value.def, doc)" class="done-lite" ><mat-icon>done</mat-icon></div>
                                        <div class="dg-doc-type">{{doc.document_desc || item.value.def.document_description}}</div>
                                        <div class="dg-doc-filename">{{doc.name}}</div>
                                        <div style="font-size: 0.8em"><span style="text-decoration: underline; cursor: pointer;" (click)="removeDocument(doc)" >Remove</span></div>
                                    </div>
                                </ng-template>
                                <ng-template ngFor let-item [ngForOf]="docgroups | keyvalue" >
                                    <ng-template [ngIf]="(!item.value.def.ifFunc || item.value.def.ifFunc({}, profile, policy.policy))">
                                    <div class="dg-upload" *ngIf="item.key!='others'">
                                        <div>Upload</div>
                                        {{item.value.def.document_description}} <span *ngIf="item.value.def.document_mandatory=='Yes'">*</span>
                                        <input type="file" [accept]="item.value.def.document_extns" (change)="uploadDocument($event, item.value.def)" />
                                    </div>
                                    </ng-template>
                                    <div class="dg-others" *ngIf="item.key=='others'">
                                        <div>
                                            Document description
                                            <input type="text" placeholder="Document type" [(ngModel)]="custDocType">
                                        </div>
                                        <div class="dg-upload" *ngIf="custDocType">
                                            <div>Upload</div>
                                            <input type="file" [accept]="item.value.def.document_extns" (change)="uploadDocument($event, item.value.def)" />
                                        </div>
                                    </div>
                                </ng-template>
    
    
                            </div>
                            
                            <div class="stage-summary-actions" *ngIf="stage.actions">
                                <field-group [fieldGroup]="stage.actions" 
                                    [data]="policy.policy[stage.module.name].data"
                                    [readonly]="stage.readonly"
                                    [policy]="policy.policy"
                                    [layout] = "{cls: 'action-wrapper', grids: 12}"
                                    (onAction)="onAction($event)"
                                    (onChange)="onChange($event)">
                                </field-group>
                            </div>
    
                            <div class="stage-summary-dnld">
                                <div style="display: grid; grid-template-columns: 1fr auto; align-items: center;">
                                    <div class="stage-summary-dnld-not-ready" *ngIf="downloads.length==0" style="padding: 0.5em 0em; color: #FAFAFA;">Downloads not ready yet</div>
                                    <div *ngIf="downloads.length>0">
                                        <div class="stage-summary-item" *ngFor="let dnld of downloads">
                                            <mat-icon fontSet="material-icons-outlined" style="font-size: 1.4em; vertical-align: middle; text-align: center;" (click)="emailPDF(dnld)">email_outline</mat-icon>
                                            <mat-icon fontSet="material-icons-outlined" style="font-size: 1.4em; vertical-align: middle; text-align: center;" (click)="downloadPDF(dnld)">download_outline</mat-icon>
                                            <span style="font-size: 0.9em; color: #FFF;">{{dnld.desc | titlecase}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="catalogs.length > 0" >
                                        <span [matMenuTriggerFor]="cmenu">{{preferences?.vendor?.policy?.strings?.catalog}}</span> <mat-icon fontSet="material-icons-outlined" [matMenuTriggerFor]="cmenu" style="cursor: pointer;">expand_more</mat-icon>
                                        <mat-menu #cmenu>
                                            <button mat-menu-item *ngFor="let cat of catalogs" (click)="onDownloadCatalog(cat)">{{cat.field_name}}</button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </cdk-step>
        </istepper>
    
        <div class="nstp-wrapper" *ngIf="chatType!=2">
            <div>
                <referral-message [policy]="policy" [messages]="policy.policy[nstpType]?.details"></referral-message>
            </div>
        </div>
        <div class="nstp-wrapper" *ngIf="chatType==2">
            <referral-message [policy]="policy" [messages]="policy.policy?.inspect?.details" [type]="'inspect'"></referral-message>
        </div>
    
    </div>
    <div *ngIf="showMinibar">
        <policy-mini-bar [policy]="policy" [stages]="stages" *ngIf="policy?.policy?.policy_id"></policy-mini-bar>
    </div>
</div>


