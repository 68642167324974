<mat-form-field [formGroup]="group">
    <mat-label [ngClass]="field.labelclass">{{field.label}}</mat-label>
    <input matInput [formControlName]="field.field_name" [title]="field.title" 
        [placeholder]="field.label||''" 
        type="text" 
        [attr.data-iid]="field.field_name">
    <mat-icon class="i-icon" *ngIf="field.icon" matPrefix fontSet="material-icons-outlined" style="padding-right: 8px;" (click)="iconClicked()">{{field.icon}}</mat-icon>
    <mat-icon class="i-icon" *ngIf="field.icon_suffix || !field.icon" matSuffix fontSet="material-icons-outlined" (click)="iconClicked()">{{field.icon_suffix || 'search'}}</mat-icon>
    <div class="input-sub-text" *ngIf="field.subtext && field.subtext.varname">{{field.subtext.desc}} {{data[field.subtext.varname] | generic2: field.subtext.pipe}}</div>
</mat-form-field>
