import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'part-payment',
	template: `
	<div class="part-payment">
		<div style="padding: 0em 1.2em 1.1em 0em; text-align: left; font-size: 1.6em; letter-spacing: .07em;">{{data.caption}}</div>
		<div>{{data.description}}</div>
		<div>
            <div class="form-field">
                <mat-form-field style="width: 100%;">
                    <input matInput [(ngModel)]="data.amount" placeholder="Amount to be paid" >
                </mat-form-field>
            </div>
		</div>
	</div>
	<div class="message-footer">
        <button mat-button ibutton (click)="onAction()">Make payment</button>
        <button mat-button ibutton (click)="dialogRef.close()">Cancel</button>
	</div>

	`,
	styles: [
        `.part-payment {}`,
		`.message-btn {display: inline-block; margin-right: 1em;}`, 
        `.message-footer {padding: 2em 0em 0em 0em;}`,
		`button:focus {outline:0; letter-spacing: normal; transition: .5s}`
	]
})
export class PartPaymentComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<PartPaymentComponent>) {
    }
    async onAction() {
        this.dialogRef.close(this.data);
    }
}
