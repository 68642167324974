import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { insapi } from 'insapi';

@Component({
	selector: 'app-email',
	template: `
	<div *ngIf="!message">
		<div class="email-popup">
			<div style="padding: 0em 1.2em 1.1em 0em; text-align: left; font-size: 1.2em;">
				Share {{data.mod | titlecase}} PDF
			</div>
			<div>
				<mat-form-field style="width: 100%;">
					<mat-label>Alternate Email</mat-label>
					<input matInput [(ngModel)]="email">
				</mat-form-field>
			</div>
			<div>
				<mat-form-field style="width: 100%;">
					<mat-label>Alternate mobile no</mat-label>
					<input matInput [(ngModel)]="mobileNo">
				</mat-form-field>
			</div>
			<div style="display: grid; grid-template-columns: 1fr 1fr; gap: 1em;">
				<mat-checkbox [(ngModel)]="params.no_email">Skip Email</mat-checkbox>
				<mat-checkbox [(ngModel)]="params.no_sms">Skip SMS/Whatsapp</mat-checkbox>
			</div>
		</div>
		<div style="display: grid; grid-template-columns: 1fr 1fr; padding: 1em 1em;">
			<div (click)="cancel()">
				<button mat-button>CANCEL</button>
			</div>
			<div (click)="onEmail()">
				<button mat-button>SHARE</button>
			</div>
		</div>
	</div>
	<div *ngIf="message" class="email-popup">
		{{message}}
	</div>
	`,
	styles: [
        `.email-popup {padding: 2em 2em 1em 2em;}`
	]
})
export class EmailPDFComponent {
    email: string = '';
	mobileNo: string = '';
    message: string = '';
	params: any = {no_email: false, no_sms: false};
	constructor(public dialogRef: MatDialogRef<EmailPDFComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }
	
    async onEmail() {
		if (this.params.no_email && this.params.no_sms)
			return insapi.showMessage("Both Email & SMS cannot be skipped", 1);

		if (!this.params.no_email && !this.email.trim())
			return insapi.showMessage("Email cannot be empty", 1);
		
		if (!this.params.no_sms && !this.mobileNo.trim())
			return insapi.showMessage("Mobile No cannot be empty", 1);
		

        this.message = 'Sending email please wait ...';
		let params = {no_email: this.params.no_email?1:0, no_sms: this.params.no_sms?1:0};
		let ret = null;
		try {
			if (this.data.policy) {
				ret = await this.data.policy.sendEmail(this.data.mod, this.email, this.mobileNo, params);
			} else {
				let url = '/api/v1/'+(this.data.mod||'policy')+'/email/' + encodeURIComponent(this.data.policy_id);
				ret = await insapi.xpost(url, {...(params||{}), email: this.email||'', mobile_no: this.mobileNo||''});
			}
			if (ret) insapi.showMessage(ret.txt || "PDF sent successfully", 0);
		} catch (e: any) {
			insapi.showMessage(e.message || e, 0);
		}
        this.dialogRef.close(this.email);
    }
    async cancel() {
        this.dialogRef.close();
    }
}
