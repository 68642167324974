import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-InsForm',
  template: `
    <p>
      ins-form works!
    </p>
  `,
  styles: [
  ]
})
export class InsFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
