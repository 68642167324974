<div style="padding-bottom: .4em;">
    <period [field]="pfield" [data]="data" (onChange)="periodChanged($event)"></period>
</div>



<!-- Pending -->
<div *ngIf="home.flags.pendingWith">
    <div class="recent-header"  *ngIf="this.segment_validation">
        Pending with - Rater product
    </div>
    <div class="recent-header"  *ngIf="!this.segment_validation">Pending with</div>
    <div *ngIf="groups.length==0">No pending items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of groups" class="pending-at">
            <div (click)="showList(grp,'!Non Rater','pending')">{{grp.name}}</div>
            <div (click)="showList(grp,'!Non Rater','pending')">{{grp.count}}</div>
        </div>
    </div>
</div>

<div *ngIf="home.flags.pendingWith && this.segment_validation">
    <br>
    <div class="recent-header">Pending with - Non Rater product</div>
    <div *ngIf="groups_non_rater.length==0">No pending items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of groups_non_rater" class="pending-at">
            <div (click)="showList(grp,'Non Rater','pending')">{{grp.name}}</div>
            <div (click)="showList(grp,'Non Rater','pending')">{{grp.count}}</div>
        </div>
    </div>
</div>

<!-- Approved -->

<div *ngIf="home.flags.approved">
    <br>
    <div class="recent-header"  *ngIf="this.segment_validation">
        Approved - Rater product
    </div>
    <div class="recent-header"  *ngIf="!this.segment_validation">Approved</div>
    <div *ngIf="approved_groups.length==0">No Approved items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of approved_groups" class="pending-at">
            <div (click)="showList(grp,'!Non Rater','approved')">{{grp.name}}</div>
            <div (click)="showList(grp,'!Non Rater','approved')">{{grp.count}}</div>
        </div>
    </div>
</div>

<div *ngIf="home.flags.approved && this.segment_validation">
    <br>
    <div class="recent-header">Approved - Non Rater product</div>
    <div *ngIf="approved_groups_non_rater.length==0">No Approved items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of approved_groups_non_rater" class="pending-at">
            <div (click)="showList(grp,'Non Rater','approved')">{{grp.name}}</div>
            <div (click)="showList(grp,'Non Rater','approved')">{{grp.count}}</div>
        </div>
    </div>
</div>


<!-- Rejected -->

<div *ngIf="home.flags.rejected">
    <br>
    <div class="recent-header"  *ngIf="this.segment_validation">
        Rejected - Rater product
    </div>
    <div class="recent-header"  *ngIf="!this.segment_validation">Rejected</div>
    <div *ngIf="rejected_groups.length==0">No Rejected items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of rejected_groups" class="pending-at">
            <div (click)="showList(grp,'!Non Rater','rejected')">{{grp.name}}</div>
            <div (click)="showList(grp,'!Non Rater','rejected')">{{grp.count}}</div>
        </div>
    </div>
</div>

<div *ngIf="home.flags.rejected && this.segment_validation">
    <br>
    <div class="recent-header">Rejected - Non Rater product</div>
    <div *ngIf="rejected_groups_non_rater.length==0">No Rejected items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of rejected_groups_non_rater" class="pending-at">
            <div (click)="showList(grp,'Non Rater','rejected')">{{grp.name}}</div>
            <div (click)="showList(grp,'Non Rater','rejected')">{{grp.count}}</div>
        </div>
    </div>
</div>


<!-- Approved -->

<div *ngIf="home.flags.query_by_uw">
    <br>
    <div class="recent-header"  *ngIf="this.segment_validation">
        Query by UW - Rater product
    </div>
    <div class="recent-header"  *ngIf="!this.segment_validation">Query by UW</div>
    <div *ngIf="query_groups.length==0">No Query by UW items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of query_groups" class="pending-at">
            <div (click)="showList(grp,'!Non Rater','Query by UW')">{{grp.name}}</div>
            <div (click)="showList(grp,'!Non Rater','Query by UW')">{{grp.count}}</div>
        </div>
    </div>
</div>

<div *ngIf="home.flags.query_by_uw && this.segment_validation">
    <br>
    <div class="recent-header">Query by UW - Non Rater product</div>
    <div *ngIf="query_groups_non_rater.length==0">No Query by UW items found in this period</div>
    <div class="pendings grid-4">
        <div *ngFor="let grp of query_groups_non_rater" class="pending-at">
            <div (click)="showList(grp,'Non Rater','Query by UW')">{{grp.name}}</div>
            <div (click)="showList(grp,'Non Rater','Query by UW')">{{grp.count}}</div>
        </div>
    </div>
</div>