import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { PreferencesService } from '../services/preferences.service';

@Pipe({
    name: 'timeelapsed'
})
export class TimeElapsedPipe implements PipeTransform {
    constructor(protected preferences: PreferencesService) {}

    transform(str: Date | moment.Moment | number | string, args?: any): any {
        if (!str) return '';
        if (typeof str == 'string') {
            if (str.length > 10) str = moment.utc(str)
            else str = moment.utc(str)
        } else if (typeof str == 'number') {
            str = moment.utc(str);
        } else if (str instanceof Date) {
            str = moment.utc(str);
        }
        return (str as moment.Moment).local().fromNow();
    }
}