import { Directive, OnChanges, Input, HostBinding, ElementRef, SimpleChange } from '@angular/core';

@Directive({
    selector: '[animHeight]',
    host: { '[style.display]': '"block"', '[style.overflow]': '"hidden"' }
})
export class AnimhHeightDirective implements OnChanges {
    @Input() visiblity!: any;
    realHeight!: number;

    constructor(private element: ElementRef) { }

    @HostBinding('@grow')
    get grow() {
        return { value: this.visiblity, params: { realHeight: this.realHeight} };
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        this.realHeight = this.element.nativeElement.clientHeight;
        // this.realHeight = this.visiblity ? this.element.nativeElement.clientHeight : 0;
    }
}