import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { PreferencesService } from './preferences.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericMessageComponent } from '../../common/generic-message.component';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    LONG = 1;
    SHORT = 0;
    constructor(private snackBar: MatSnackBar, 
        protected preferences: PreferencesService,
        public dialog: MatDialog) { }

    async modalDialog(msg: string, type: number): Promise<Boolean> {
        return new Promise((resolve, reject) => {
            let data = {
                description: msg,
                actions: [{name: 'continue', disp: 'Yes'}, {name: 'close', disp: 'Cancel'}]
            };
            let ref = this.dialog.open(GenericMessageComponent, {data});
            ref.afterClosed().subscribe((action) => {
                if (action.name == 'continue') resolve(true);
                else resolve(false);
            });
        });
    }
    showMessage(msg: string, type: number): Promise<Boolean> | void {
        if (type == 2) {
            return this.modalDialog(msg, type);
        }

        let duration = type == 1 ? (this.preferences?.vendor?.toast.duration.long || 5000) : (type == 0 ? (this.preferences?.vendor?.toast.duration.short || 2500) : type);
        let options: MatSnackBarConfig = {
            duration,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['itoast-success']
        }
        if( type == 1 )options['panelClass'] = ['itoast-warn'];
        this.snackBar.open(msg, 'Close', options);
    }
}
