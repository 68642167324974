<ng-template [ngIf]="data[field.field_name]">
    <div class="address-caption">
        <div>{{field.label}}</div>
        <div *ngIf="field.address.same_as">
            <mat-checkbox [(ngModel)]="data[field.field_name].same_as" [attr.data-iid]="field.field_name+'-sameas'">Same as {{field.address.same_as}}</mat-checkbox>
        </div>
    </div>
    <div *ngIf="!data[field.field_name].same_as">
        <div class="withmap" *ngIf="field.address?.use_map">
            <div>
                <ng-container [ngTemplateOutlet]="field.address.reverse_order ? revaddrform : addrform" [ngTemplateOutletContext]="{policy}"></ng-container>
            </div>
            <div class="map-container">
                Map
            </div>
        </div>
        <div *ngIf="!field.address?.use_map">
            <div>
                <ng-container [ngTemplateOutlet]="field.address.reverse_order ? revaddrform : addrform" [ngTemplateOutletContext]="{policy}"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #addrform let-policy="policy">
    <div class="grid-12">
        <div [style.grid-column]="addr_span">
            <ng-container [ngTemplateOutlet]="seladdr" [ngTemplateOutletContext]="{policy, list: addr1List, aname: 'address_1', label: 'Address line 1'}"></ng-container>
        </div>
        <div *ngIf="field.address.address_lines>1"  [style.grid-column]="addr_span">
            <ng-container [ngTemplateOutlet]="seladdr" [ngTemplateOutletContext]="{policy, list: addr2List, aname: 'address_2', label: 'Address line 2'}"></ng-container>
        </div>
        <div *ngIf="field.address.address_lines>2"  [style.grid-column]="addr_span">
            <ng-container [ngTemplateOutlet]="seladdr" [ngTemplateOutletContext]="{policy, list: addr3List, aname: 'address_3', label: 'Address line 3'}"></ng-container>
        </div>
        <div *ngIf="field.address.city" style="grid-column: span 4">
            <ng-container [ngTemplateOutlet]="seladdr" [ngTemplateOutletContext]="{policy, list: cityList, aname: 'city', label: 'City'}"></ng-container>
        </div>
        <div *ngIf="field.address.state" style="grid-column: span 4">
            <ng-container [ngTemplateOutlet]="selstate" [ngTemplateOutletContext]="{policy}"></ng-container>
        </div>
        <div *ngIf="field.address.country" style="grid-column: span 4">
            <mat-form-field style="width: 100%;">
                <mat-label>Country</mat-label>
                <input type="text" matInput  placeholder="Country" [(ngModel)]="data[field.field_name].country">
            </mat-form-field>
        </div>
        <div *ngIf="field.address.zipcode" style="grid-column: span 4">
            <mat-form-field style="width: 100%;">
                <mat-label>Zipcode</mat-label>
                <input type="text" matInput  placeholder="Zipcode" [(ngModel)]="data[field.field_name].zipcode">
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #selstate>
    <mat-form-field style="width: 100%;">
        <mat-label>Select state</mat-label>
        <input type="text" matInput [(ngModel)]="data[field.field_name].state" placeholder="State name" [matAutocomplete]="stateauto" (input)="filterList($event, 'state')">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #stateauto="matAutocomplete" (optionSelected)="setSelected($event.option.value, 'state')" [displayWith]="nameOf.bind(this)">
            <mat-option *ngFor="let item of fltStates" [value]="item">{{item.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>

<ng-template #seladdr let-aname="aname" let-list="list" let-label="label">
    <mat-form-field style="width: 100%;">
        <mat-label>{{label}}</mat-label>
        <input type="text" matInput [(ngModel)]="data[field.field_name][aname]" placeholder="Address line" [matAutocomplete]="addrauto" (input)="filterList($event, aname)">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #addrauto="matAutocomplete" (optionSelected)="setSelected($event.option.value, aname)" >
            <mat-option *ngFor="let item of list" [value]="item">{{item}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-template>

<ng-template #revaddrform let-policy="policy">
    <div class="grid-12">
        <div *ngIf="field.address.country" style="grid-column: span 4">
            <mat-form-field style="width: 100%;">
                <mat-label>Country</mat-label>
                <input type="text" matInput  placeholder="Country" [(ngModel)]="data[field.field_name].country">
            </mat-form-field>
        </div>
        <div *ngIf="field.address.zipcode" style="grid-column: span 4">
            <mat-form-field style="width: 100%;">
                <mat-label>Zipcode</mat-label>
                <input type="text" matInput  placeholder="Zipcode" [(ngModel)]="data[field.field_name].zipcode">
            </mat-form-field>
        </div>
        <div *ngIf="field.address.city" style="grid-column: span 4">
            <mat-form-field style="width: 100%;">
                <mat-label>City</mat-label>
                <input type="text" matInput  placeholder="City" [(ngModel)]="data[field.field_name].city">
            </mat-form-field>
        </div>
        
        <div *ngIf="field.address.state" style="grid-column: span 4">
            <ng-container [ngTemplateOutlet]="selstate" [ngTemplateOutletContext]="{policy}"></ng-container>
        </div>
        <div [style.grid-column]="addr_span">
            <mat-form-field style="width: 100%;">
                <mat-label>Address line 1</mat-label>
                <input type="text" matInput  placeholder="Address line 1" [(ngModel)]="data[field.field_name].address_1">
            </mat-form-field>
        </div>
        <div *ngIf="field.address.address_lines>1"  [style.grid-column]="addr_span">
            <mat-form-field style="width: 100%;">
                <mat-label>Address line 2</mat-label>
                <input type="text" matInput  placeholder="Address line 2" [(ngModel)]="data[field.field_name].address_2">
            </mat-form-field>
        </div>
        <div *ngIf="field.address.address_lines>2"  [style.grid-column]="addr_span">
            <mat-form-field style="width: 100%;">
                <mat-label>Address line 3</mat-label>
                <input type="text" matInput  placeholder="Address line 2" [(ngModel)]="data[field.field_name].address_3">
            </mat-form-field>
        </div>

    </div>
</ng-template>