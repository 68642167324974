<div *ngIf="field && field.mode!='slider'">
  <div *ngFor="let p of intervals" class="period-item" [class.period-item-select] = "p === selected" (click)="changePeriod(p)">{{p}}</div>
  <div class="mobile-break"></div>
  <mat-form-field style="width:1px;visibility:hidden; height: 1px; padding: 0px !important;">
      <input matInput [matDatepicker]="spicker" [(ngModel)]="calstart" (dateChange)="changePeriod('start')"><mat-datepicker #spicker></mat-datepicker>
  </mat-form-field>
  <div class="period-item-cal" (click)="spicker.open()" >{{perioddisp[0]}}<div class="material-icons-outlined cal-icon">calendar_month</div></div>
  
  <div class="material-icons-outlined cal-range" style="vertical-align: middle;">swap_horiz</div>
  
  <mat-form-field style="width:1px;visibility:hidden; height: 1px; padding: 0px !important;"><input matInput [matDatepicker]="epicker" [(ngModel)]="calend" (dateChange)="changePeriod('end')"><mat-datepicker #epicker></mat-datepicker></mat-form-field>
  <div class="period-item-cal" (click)="epicker.open()" >{{perioddisp[1]}}<div class="material-icons-outlined cal-icon">calendar_month</div></div>  
</div>

<div class="period-container" *ngIf="field && field.mode=='slider'">
  <div style="padding-right: 0px;">
    <input matInput #sinput [matDatepicker]="spicker" [(ngModel)]="minValueDt" 
      style="visibility: hidden; width: 2px;" (dateChange)="minValuePicked($event)" [attr.data-iid]="field ? field.field_name+'-min' : ''">
    <mat-datepicker-toggle matSuffix [for]="spicker" ></mat-datepicker-toggle>
    <mat-datepicker #spicker></mat-datepicker>
  </div>
  <div style="width: 1px; height: 10px; background-color: #000; margin-top: 20px;"></div>
  <div class="period">
    <div class="full-width ranger-slider">
        <mat-slider
          (change)="valueChange()"
          (input)="minValueInput($event)"
          [(ngModel)]="minValue"
          [displayWith]="formatLabel"
          [max]="max"
          [min]="min"
          [thumbLabel]="thumbLabel"
          class="full-width cdk-focused slider-over slider-min-value"
          step="1">
        </mat-slider>
        <mat-slider
          (change)="valueChange()"
          (input)="maxValueInput($event)"
          [(ngModel)]="maxValue"
          [displayWith]="formatLabel"
          [max]="max"
          [min]="min"
          [thumbLabel]="thumbLabel"
          class="full-width cdk-focused slider-over slider-max-value"
          step="1">
        </mat-slider>
        <div class="full-width slider-over slider-line-cover-wrapper">
          <mat-divider [class]="isMinValueInit? 'slider-line-cover-init' : 'slider-line-cover'"></mat-divider>
          <mat-divider [class]="isMaxValueInit? 'slider-line-cover-init' : 'slider-line-cover'"></mat-divider>
        </div>
      
        <div *ngIf="showRuler" class="outer">
          <div class="middle">
            <div *ngFor="let key of rulerArray" class="inner">|</div>
          </div>
          <div class="middle">
            <div *ngFor="let key of rulerArray" class="inner"><span>{{key}}</span></div>
          </div>
        </div>
      </div>
  </div>
  <div style="width: 1px; height: 10px; background-color: #000; margin-top: 20px;"></div>
  <div>
    <input matInput [matDatepicker]="epicker" [(ngModel)]="maxValueDt"
      style="visibility: hidden; width: 2px;" (dateChange)="maxValuePicked($event)" [attr.data-iid]="field ? field.field_name+'-max' : ''">
    <mat-datepicker-toggle matSuffix [for]="epicker"></mat-datepicker-toggle>
    <mat-datepicker #epicker></mat-datepicker>
  </div>
</div>