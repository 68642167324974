import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';

@Component({
    selector: 'ihtml',
    template: `
    <iframe [id]="field.field_name" [src]="field._url" class="ihtml"></iframe>
  `,
    styles: [
      '.ihtml {border: 0px; width: 100%; height: 300px;}'
    ]
})
export class HtmlComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    constructor() { }

    ngOnInit(): void {
    }

}
