import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { insapi } from 'insapi';
import { PreferencesService } from 'ins-form';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';

@Component({
    selector: 'payment-ledger',
    templateUrl: './payment-ledger.component.html',
    styleUrls: ['./payment-ledger.component.scss']
})
export class PaymentLedgerComponent implements OnInit {

    depositName: string = '';
    balance: string = '';
    ledger: any[] = [];
    constructor(public prefernces: PreferencesService,
        private route: ActivatedRoute, private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        this._init();
    }

    async _init() {
        this.depositName = this.data?.ledger.disp || '';
        this.balance = this.data?.ledger.balance || '';
        let ledger = this.data?.ledger.transactions || await insapi.ledger() || [];
        let grp: any = {};
        for (let item of ledger) {
            let time = moment(item.u_ts, 'YYYY-MM-DD HH:mm:ss').fromNow();
            if (!grp[time]) grp[time] = {time: item.u_ts, disp: time, items: []};
            grp[time].items.push(item);
        }
        let keys = Object.keys(grp).sort((a: string, b: string) => a < b ? -1 : 1);
        this.ledger = keys.map(x => grp[x]);
        console.log('ledger: ', this.ledger);
    }

    async gotoPayment(item: any) {
        if (item.module_id) {
            this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: item.module_id}});
            return;
        }

        if (!item.payment_id) return;
        let policies = await insapi.quotes(undefined, 'policy_id', 1, {payment_id: item.payment_id});
        if (policies && policies.length > 0) {
            console.log('policy: ', policies[0].policy_id);
            this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: policies[0].policy_id}});
        } else {
            insapi.showMessage('You do not have access to this policy', 0);
        }
    }

}
