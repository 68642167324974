<div class="div-curved">
    <div class="svg-curved">
        <svg viewBox="0 0 500 150" style="height: 100%; width: 100%;" preserveAspectRatio="none">
          <linearGradient id="gradient"><stop offset="0%" class="curve-stop-0"/><stop offset="100%" class="curve-stop-100"/></linearGradient>
          <path d="M-0.85,137.65 C135.72,178.12 336.06,-110.03 500.84,85.35 L500.00,0.00 L0.00,0.00 Z" style="stroke: none; fill: url(#gradient);"></path>
        </svg>
    </div>
</div>

<div class="order-container">
    <div class="filter-form" *ngIf="!orderId">
        <div class="grid-3">
            <div class="form-field">
                <mat-form-field style="width: 100%;">
                    <input matInput [matDatepicker]="dpicker1" [(ngModel)]="from" placeholder="From (00:00:00)">
                    <mat-datepicker-toggle matSuffix [for]="dpicker1"></mat-datepicker-toggle>
                    <mat-datepicker #dpicker1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field style="width: 100%;">
                    <input matInput [matDatepicker]="dpicker2" [(ngModel)]="till" placeholder="Till (11:59:00)">
                    <mat-datepicker-toggle matSuffix [for]="dpicker2"></mat-datepicker-toggle>
                    <mat-datepicker #dpicker2></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field style="width: 100%;">
                    <input matInput [(ngModel)]="name" placeholder="Product name (like)">
                </mat-form-field>
            </div>
        </div>
        <div>
            <button mat-button ibutton (click)="applyFilters()">Apply filter</button>
        </div>

    </div>


    <div class="order-caption" *ngIf="!orderId">
        YOUR PAST ORDERS 
        <!-- <span style="font-size: 0.8em; color: #888; padding-left: .7em;">({{orders.length}} found)</span> -->
    </div>
    <div class="order-caption" *ngIf="orderId">YOUR ORDER</div>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="u_ts">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row; let i=dataIndex;">{{row.u_ts | datetime}}</td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row" [attr.data-label]="status">{{status[row.status]}}</td>
        </ng-container>
        <ng-container matColumnDef="count">
            <th mat-header-cell *matHeaderCellDef>Items</th>
            <td mat-cell *matCellDef="let row" [attr.data-label]="count">{{row.count}}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row" [attr.data-label]="amount">{{row.amount | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="premium">
            <th mat-header-cell *matHeaderCellDef>Premium</th>
            <td mat-cell *matCellDef="let row" [attr.data-label]="premium">{{row.premium | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="tax">
            <th mat-header-cell *matHeaderCellDef>Tax</th>
            <td mat-cell *matCellDef="let row" [attr.data-label]="tax">{{row.tax | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="item">
            <td mat-cell *matCellDef="let row" [attr.data-label]="item" colspan="6" >
                <div *ngIf="edit.index==row.index" style="padding: 1em 1em;" [@detailExpand]="row.index==edit.index ? 'expanded' : 'collapsed'">
                    <div style="font-size: 1.2em; padding-bottom: 1em;">Order ID: {{row.order_id}}</div>
                    <div style="font-size: 1.2em; padding-bottom: 1em;">Policies</div>
                    <table class="item-table">
                        <thead>
                            <tr>
                                <th>Policy no</th>
                                <th>Name</th>
                                <th>Desc</th>
                                <th style="text-align: right; padding-right: 1em;">Amount</th>
                                <th style="text-align: right; padding-right: 1em;">Premium</th>
                                <th *ngIf="!isMobile" style="text-align: right; padding-right: 1em;">Tax</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of row.items">
                                <td style="cursor: pointer;" (click)="goto(item.policy_id)">{{item.policy_no}}</td>
                                <td style="cursor: pointer;" (click)="goto(item.policy_id)">{{item.item_name}}</td>
                                <td>{{item.item_desc}}</td>
                                <td style="text-align: right; padding-right: 1em;">{{item.amount | currency}}</td>
                                <td style="text-align: right; padding-right: 1em;">{{item.premium | currency}}</td>
                                <td *ngIf="!isMobile" style="text-align: right; padding-right: 1em;">{{item.tax | currency}}</td>
                                
                            </tr>
                            <tr class="total-row">
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right; padding-right: 1em;">{{row.premium | currency}}</td>
                                <td *ngIf="!isMobile"></td>
                                
                            </tr>
                            <tr *ngFor="let t of row.taxdetails | keyvalue">
                                <td style="padding-left: 2em;">{{t.key}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right; padding-right: 1em;">{{t.value | currency}}</td>
                                <td *ngIf="!isMobile"></td>
                                
                            </tr>
                            <tr class="net-row">
                                <td>Net</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right; padding-right: 1em;">{{row.amount | currency}}</td>
                                <td *ngIf="!isMobile"></td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <button mat-button ibutton (click)="edit.index=-1">Close</button>
                    <button mat-button ibutton (click)="process(row.order_id)" *ngIf="row.process">Process</button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" class="normal-row" (click)="toggle(row)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['item'];" class="detail-row" ></tr>
    </table>
    <button mat-button ibutton [disabled]="start<=0" (click)="prev()">Prev</button>
    <button mat-button ibutton [disabled]="orders.length<count" (click)="next()">Next</button>

</div>
