<div class="wordings" *ngIf="wordings.length>0">
    <div [class]="['grid-'+(field.grid||2), 'wordings-wrap']">
        <div [class]="['grid-'+(field.itemgrid||1), 'wordings-item']" *ngFor="let op of wordings">
            <div class="wordings-item-caption" (click)=editwordings(op); >{{op.value}} : {{op.disp}}</div>
            <mat-icon *ngIf="!readonly && op.applicability=='custom'" class="wordings-item-delete" (click)=deletewordings(op);>delete</mat-icon>
            <mat-icon class="wordings-item-info" (click)="openTempDialog(op)">info</mat-icon>
            <div *ngIf="readonly">  
                <mat-icon class="wordings-item-readonly-check" *ngIf="op.applicability=='fixed' || op.selected">check</mat-icon>
                <mat-icon class="wordings-item-readonly-block" *ngIf="op.applicability!='fixed' && !op.selected">block</mat-icon>
            </div>
            <div class="i-slider-container" (click)="_toggle($event, op)"  *ngIf="!readonly && field.editable=='true'">
                <div class="i-slider" [class.slider-on]="(op.applicability=='fixed' || op.selected) ? true : null" 
                    [class.slider-off]="(op.applicability!='fixed' && !op.selected) ? true : null" [attr.data-iid]="op.value">
                    <div class="i-slider-bg" [class.disabled]="(op.applicability=='fixed') ? true : null"></div>
                    <div class="i-slider-ball" [class.disabled]="(op.applicability=='fixed') ? true : null">
                        <mat-icon *ngIf="(op.applicability=='fixed' || op.selected) ? true : null" 
                            [@animRotate] fontSet="material-icons-outlined">check</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custom-wordings" *ngIf="!readonly && field.custom" >
    <!-- <div class="field-group-caption">{{field.custom.label}}</div> -->
    <mat-form-field class="example-full-width">
        <mat-label>{{field.custom.name | titlecase}}</mat-label>
         <input matInput #clauseName  [(ngModel)]="selected.disp" 
            [placeholder]="field.custom.name||''" type="text" [maxlength]="field.custom.field_limit?.name" [attr.data-iid]="field.custom.name">
         
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>{{field.custom.description | titlecase}}</mat-label>
        <textarea #description matInput [maxlength]="field.custom.field_limit?.description" [(ngModel)]="selected.tooltip"></textarea>
      </mat-form-field> 
      <button mat-button #word_value class="wording_save"  id = "field.label" (click)="save_wordings($event,selected)" mat-button>{{selected.value != '' ? "Update":"Add"}}</button>
      <button mat-button #word_clearvalue class="wording_clear"  id = "field.label" (click)="clear()" mat-button>Clear</button>
</div>

<ng-template #dialogRef let-data>
    <div class="message">
        <div style="padding: 0em 1.2em 1.1em 0em; text-align: left; font-size: 1.2em;">{{data.caption}}</div>
        <div>{{data.description}}</div>
        <div>
        </div>
    </div>
    <button mat-button mat-dialog-close="I am from dialog land...">Close</button>
</ng-template>