import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IEndorsement, IPolicy, Policy, insapi } from 'insapi';
import { trigger, state, style, animate, transition } from '@angular/animations';
// import { MatAccordion } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { PreferencesService } from 'ins-form';
import { MatDialog } from '@angular/material/dialog';
import { PolicyCalendarComponent } from '../policy-calendar/policy-calendar.component';
import { PaymentLedgerComponent } from '../../admin/payment-ledger/payment-ledger.component';
import { PolicyTimelineComponent } from '../policy-timeline/policy-timeline.component';

@Component({
    selector: 'policy-mini-bar',
    templateUrl: './policy-mini-bar.component.html',
    styleUrls: ['./policy-mini-bar.component.scss'],
    animations: [trigger('slideAnim', [
        transition(':enter', [style({ 'margin-right': 0 }), animate('.25s ease-out', style({ 'margin-right': -268 }))])
        ]
    )]
})
export class PolicyMiniBarComponent implements OnInit {
    // @ViewChild('accordion',{static:true}) accordion!: MatAccordion
    @Input() policy!: Policy;
    @Input() stages: any;
    data: any = {};

    summary: SafeHtml = "";

    visible: boolean = false;
    psubscription: Subscription | null = null;
    limits: any[] = [];
    ledgers: any = {};

    constructor(private preferences: PreferencesService, public dialog: MatDialog) { }

    ngOnInit(): void {
        this.psubscription = this.policy.changeSubject.subscribe((policy: IPolicy | IEndorsement | null) => {
            if (policy && !policy.prd_endorsement_id) this.__policy_changed(policy as IPolicy);
        });
    }

    async __update_master_policy_details() {
        if (this.limits.length > 0 || !this.policy.product || !this.policy.policy) return;
        let products = await insapi.__xget('/api/v1/product/' + encodeURIComponent(this.policy.product.product_id));
        let product = products[0];
        this.limits = product?.data.limit_property.split(',')
            .map((x: string) => x.trim())
            .filter(Boolean)
            .map((x: string) => ({name: product?.dictionary?.inputs[x] || x, value: x}));
        this.ledgers = await insapi.__xget('/api/v1/policy/ledgers/' + encodeURIComponent(this.policy.policy.policy_id));

        for (let limit of this.limits) {
            if (this.ledgers[limit.value]) this.ledgers[limit.value].disp = limit.name;
        }
    }

    async __policy_changed(policy: IPolicy) {
        this.data = ((policy?.proposal_id ? policy.proposal?.data : policy.quote?.data)) || {};
        this.summary = await this.preferences.render('summary', this.policy.product?.product_name||'', this.data);
        
        let product = this.policy.product;
        if (product && product?.data.master_policy_enabled === 'Yes') await this.__update_master_policy_details();
    }

    showLedger(ledger: any) {
        if (!ledger?.transactions) return;
        let ret = this.dialog.open(PaymentLedgerComponent, {data: {ledger: ledger}});
    }

    showCalendar() {
        this.dialog.open(PolicyCalendarComponent, {data: {policy: this.policy}});
    }
    showTimeline() {
        this.dialog.open(PolicyTimelineComponent, {data: {policy: this.policy, stages: this.stages}});
    }
}
