import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, Injector } from '@angular/core';
import { PreferencesService } from '../services/preferences.service';
import { CapitalizePipe } from './capitalize.pipe';
import { DateTimePipe } from './date-time.pipe';
import { DatePipe } from './idate.pipe';
import { NumberWordPipe } from './number-word.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { TimeElapsedPipe } from './time-elapsed.pipe';

let pipes: any = {
    number: DecimalPipe,
    shortnumber: ShortNumberPipe,
    date: DatePipe,
    datetime: DateTimePipe,
    timeelapsed: TimeElapsedPipe,
    currency: CurrencyPipe,
    capitalize: CapitalizePipe,
    numberword: NumberWordPipe,
    idate: DatePipe,
};

@Pipe({
    name: 'generic'
})
export class GenericPipe implements PipeTransform {
    constructor(protected preferences: PreferencesService, private injector: Injector) {}

    transform(value: any, type?: any): any {
        if (!type) return value;
        let pipe = this.injector.get<PipeTransform>(type.pipe);
        return pipe.transform(value, ...(type.args?type.args:[]));
    }
}

@Pipe({
    name: 'generic2'
})
export class GenericPipe2 implements PipeTransform {
    constructor(protected preferences: PreferencesService, private injector: Injector) {}

    transform(value: any, type?: any, args?: any[]): any {
        if (!type || typeof value === 'boolean' /*|| !args*/) return value;
        if (!(type instanceof Array)) {
            try {
                if (typeof type === 'string') type = pipes[type];
                if (!type) return value;
                return this.injector.get<PipeTransform>(type).transform(value);
            } catch (e) {console.log('invalid pipe', type); return value;}
        }
        type = type.filter(x => x !== undefined);
        if (type.length > 0 && typeof type[0] === 'string') type[0] = pipes[type[0].trim()];
        if (type.length == 0 || !type[0]) return value;
        if (type[0] instanceof DecimalPipe || type[0] instanceof CurrencyPipe || type[0] instanceof NumberWordPipe) {
            if (isNaN(value)) return value;
        }

        let pipe = this.injector.get<PipeTransform>(type.shift());
        if (type.length == 0) return pipe.transform(value);
        // console.log('g2-param:', value, pipe.constructor.name, type, pipe.transform(value, ...type));
        return pipe.transform(value, ...type);
    }
}