import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { IField } from '../../form.interface';
import { insapi, IPolicy } from 'insapi';
import { DefaultUrlSerializer } from '@angular/router';

@Component({
    selector: 'fetch',
    templateUrl: './fetch.component.html',
    styleUrls: ['./fetch.component.css']
})
export class FetchComponent implements OnInit {
    @Output() onChange = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    data!: any;
    policy!: IPolicy;

    constructor() { }

    ngOnInit(): void {
    }

    __get_data(data: any, fname: any) {
        if (!fname || fname.indexOf('.') < 0) return data[fname];
        let parts = fname.split('.');
        let obj = data;
        for (let i=0; i<parts.length-1; i++) {
            if (!obj[parts[i]]) obj[parts[i]] = {};
            obj = obj[parts[i]];
        }
        return obj[parts[parts.length-1]];
    }
    
    __set_data(data: any, fname: any, value: any) {
        if (!fname || fname.indexOf('.') < 0) { data[fname] = value; return;}
        
        let parts = fname.split('.');
        let obj = data;
        for (let i=0; i<parts.length-1; i++) {
            if (!obj[parts[i]]) obj[parts[i]] = {};
            obj = obj[parts[i]];
        }
        obj[parts[parts.length-1]] = value;
    }
    
    async __call_url() {
        let url = this.field.urlFunc?.call(this, this.data, this.policy);
        if (!url) return false;
        let method = (this.field.source?.method || 'get').toLowerCase();
        
        let data = undefined;
        if (method == 'post') {
            let ut = (new DefaultUrlSerializer()).parse(url);
            data = ut.queryParams;
        }

        let ret = null;
        insapi.showSpinner(true);
        try {ret = await insapi.xreq(url, method, data, this.field.source?.params||undefined, 1);}
        catch (e: any) {insapi.showMessage(e.message || e, 1); return false;}
        finally {insapi.showSpinner(false);}

        // non-insillion APIs need response validation expression
        //
        if (!url.startsWith('/')) {
            if (!this.field.vfunc) this.field.vfunc = new Function('data', 'with (data) {return ' + this.field.source?.valid || 'status==0}');
            if (!this.field.vfunc(ret)) return false;
            return ret;
        } else if (ret.status == 0) {
            if (ret.txt) insapi.showMessage(ret.txt, 0);
            return ret.data || {};
        }
        return false;
    }

    async iconClicked() {
        let data = await this.__call_url();
        for (let fname in this.field.source?.map) {
            let tname = this.field.source?.map[fname];  // target field name
            let value = data === false ? '' : this.__get_data(data, fname);
            this.__set_data(this.data, tname, value);
            if (this.group.controls[tname]) this.group.controls[tname].setValue(value);
        }

        this.onChange.emit(this.field);
    }

}
