import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { init } from 'echarts';
import { IEndorsement, insapi, IPolicy, Policy } from 'insapi';
import { Subscription } from 'rxjs';
import { PreferencesService } from "../../../lib/services/preferences.service"

@Component({
    selector: 'app-verifyotp',
    templateUrl: './verifyotp.component.html',
    styleUrls: ['./verifyotp.component.css']
})
export class VerifyotpComponent implements OnInit {

    @Output() onAction = new EventEmitter<any>();

    policy!: Policy;
    proposalObj : any = {}
    otp: string = '';
    otpsent: boolean = false;
    verfied: boolean = false;
    counter: number = 0;
    timeInt: any = null;
    timeOut: any = null;
    subscription: Subscription | null = null;
    btnlabel = "Send OTP";
    psubscription: Subscription | undefined = undefined;

    constructor(public preferences: PreferencesService) {  
        
    }
    
    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject.subscribe((policy: IPolicy | IEndorsement | null) => {
            console.log("this.policy",this.policy);
            if (!this.policy) return;
            this._init();
        });
        this._init();
    }

    _init(){
        if (this.policy) {
            if (this.policy?.endorsement){
                this.proposalObj = this.policy?.endorsement?.eproposal;
            } else {
                this.proposalObj = this.policy?.policy?.proposal;
            }
        }
        console.log("verifyotp",this.policy,this.proposalObj); 
    }

    ngOnDestroy() {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
        if (this.timeOut) clearTimeout(this.timeOut);
        this.timeOut = null;
        if (this.timeInt) clearInterval(this.timeInt);
        this.timeInt = null;
    }

    waitForVerify() {
        this.otpsent = true;
        this.counter = 0;
        if (!this.timeInt)
            this.timeInt = setInterval(() => { this.counter++; }, 1000);

        if (!this.timeOut)
            this.timeOut = setTimeout(() => {
                this.otpsent = this.verfied;
                this.counter = 0;
                clearInterval(this.timeInt);
                this.timeInt = null;
                this.timeOut = null;
            }, 120000);
    }

    async sendOTP() {
        this.otpsent = true;
        this.btnlabel = "Resend OTP";
        if (await this.policy?.sendProposalOTP()) {
            insapi.showMessage('OTP Sent to your Mobile No, please provide that in Verify Box', 1);
            this.waitForVerify();
        }
    }

    async verify(type: any) {
        if (!this.otp) return;
        let rett = await this.policy?.verifyProposalOTP(this.otp, 'proposal-accept').catch((e: Error) => {
            insapi.showMessage("Entered OTP is Invalid", 1);
        });

        if (!rett) return;
        if (rett && rett.length > 0) {
            //this.policy = rett[0];
            if (this.policy?.endorsement){
                this.proposalObj = rett[0].eproposal;
            } else {
                this.proposalObj = rett[0].proposal;
            }
            this.otpsent = false;
            insapi.showMessage(type ? "Proposal rejection completed" : "OTP verification completed", 1);
            this.verfied = true;
            
        } else {
            this.otpsent = false;
            if (this.timeInt) clearInterval(this.timeInt);
            if (this.timeOut) clearTimeout(this.timeOut);
            this.counter = 0;
            this.verfied = false;
            this.timeInt = null;
            this.timeOut = null;
        }
    }


}
