import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { PreferencesService } from '../services/preferences.service';

@Pipe({
    name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
    constructor(protected preferences: PreferencesService) {}

    transform(str: Date | moment.Moment | string | number, args?: any): any {
        if (!str) return '';
        if (typeof str == 'string') {
            if (str.length > 10) str = moment(str, 'YYYY-MM-DD HH:mm:ss');
            else str = moment(str, 'YYYY-MM-DD');
        } else if (str instanceof Date || typeof str === 'number') {
            str = moment(str);
        }
        return (str as moment.Moment).format(this.preferences.dateTimeFormat);
    }
}