import { Component, forwardRef, Input, Output, EventEmitter, ViewChild, ElementRef, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, NgControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'calendar-selector',
    template: `<div style="max-width: 280px; margin: auto;">
    <mat-calendar [selected]="value" (selectedChange)="dateSelected($event)"></mat-calendar>
    </div>
    `,
    styles: [
    ],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CalendarSelectorComponent), multi: true}
    ]
})
export class CalendarSelectorComponent implements ControlValueAccessor {
    value: Date = new Date();
    disabled: boolean = false;

    constructor(public injector: Injector) {}

    ngOnInit(): void {}
    
    onChange = (_:any) => {};
    onTouched= (_:any) => {};
    registerOnChange(fn: any) {this.onChange = fn;}
    registerOnTouched(fn: any){this.onTouched = fn;};
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any) {
        try{this.value = new Date(value ? value : new Date());}
        catch(e) {console.log('calendar: write', e);}
    }


    dateSelected(ev: any) {
        this.onChange(ev.toISOString());
        this.onTouched(ev.toISOString());
        this.value = ev;
    }
   
}
