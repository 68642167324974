<div class="z-container" [class.z-cotainer-search]="narrow" *ngIf="mode==0 && !zulip.disabled">
    <div style="display: grid; grid-template-columns: 1fr auto; gap: .5em; padding: 0.3em 1em; align-items: center;">
        <div>{{zulip.unread_count}} New</div>
        <div style="color: #BBB;" (click)="narrow=true" *ngIf="!narrow"><mat-icon>search</mat-icon></div>
        <div style="color: #BBB;" *ngIf="narrow" >
            <input type="text" #zsearch class="search" (input)="searchChanged()" [(ngModel)]="search" [@inOutAnimation]>
            <mat-icon (click)="clearSearch()">close</mat-icon>
        </div>
    </div>
    

    <div class="z-msgs" #zontainer>
        <ng-template [ngIf]="narrow">
            <div *ngIf="search && zulip.narrow.length==0">No matching messages found for "{{search}}"</div>
            <div class="z-msg" *ngFor="let m of zulip.narrow" [innerHTML]="m.html" (click)="messageClicked($event, m)"></div>
        </ng-template>
        <ng-template [ngIf]="!narrow">
            <div class="z-msg" *ngFor="let m of zulip.messages" [innerHTML]="m.html" (click)="messageClicked($event, m)"></div>
        </ng-template>
        
    </div>
</div>

<div class="z-container" *ngIf="mode==1 && !zulip.disabled">
    <div class="z-streams" *ngIf="!zulip.stream">
        <div class="z-stream" *ngFor="let it of zulip.streams | keyvalue" (click)="selectStream(it.value.stream_id)">
            <span>{{it.value.name}}</span><span style="text-align: center;"><div class="z-ur-count" *ngIf="it.value.unread.size>0">{{it.value.unread.size}}</div></span>
            <div class="z-stream-summary" >{{it.value.summary}}</div><span class="z-stream-time">{{it.value.stime}}</span>
        </div>
    </div>
    <div *ngIf="zulip.stream">
        <div class="z-stream-hdr">
            <div (click)="zulip.select_stream(-1)"><mat-icon>arrow_back_ios</mat-icon></div>
            <div>{{zulip.stream.name}}</div>
            <div>{{zulip.stream.unread.size}} New</div>
        </div>
        
        <div class="z-msgs" #zontainer>
            <div class="z-msg" *ngFor="let m of zulip.stream.messages" [innerHTML]="m.html" (click)="messageClicked($event, m)"></div>
        </div>
    </div>
</div>

<div class="z-container" *ngIf="mode==2 && !zulip.disabled && zulip.stream">
    <div class="z-stream-hdr">
        <div>{{zulip.stream.name}}</div>
        <div>{{zulip.stream.unread.size}} New</div>
    </div>
    <div class="z-msgs" #zontainer>
        <div class="z-msg" *ngFor="let m of zulip.stream.messages" [innerHTML]="m.html" (click)="messageClicked($event, m)"></div>
    </div>
    <div class="z-stream-ftr">
        <div *ngIf="attach" style="grid-column: span 2;" (click)="attach=!attach">
            <div>Attach document(s)</div>
            <div *ngFor="let type of doctypes" [class.attached]="type.attached" (click)="blockClick($event)" style="display: inline-block;">
                <div class="attach">
                    {{type.desc}} 
                    <mat-icon class="material-symbols-outlined" style="cursor: pointer; font-size: 1.4em; padding-top: 4px;">attach_file</mat-icon>
                    <input type="file" [accept]="'csv'" (change)="attachFile($event, type)"/>
                </div>
            </div>
            <div>
                <div class="attach">
                    Others
                    <mat-icon class="material-symbols-outlined" style="cursor: pointer; font-size: 1.4em; padding-top: 4px;">attach_file</mat-icon>
                    <input type="file" [accept]="'csv'" (change)="attachFile($event)"/>
                </div>
            </div>
        </div>
    
        <textarea [(ngModel)]="msg"></textarea>
        <div>
            <mat-icon class="material-symbols-outlined" style="display: inline-block; cursor: pointer; padding: 0em .3em;" (click)="showAttachment()">attachment</mat-icon>
            <mat-icon class="material-symbols-outlined" style="display: inline-block; cursor: pointer; padding: 0em .3em;" (click)="send(zulip.stream.stream_id)">arrow_forward_ios</mat-icon>
        </div>
    </div>

</div>



<div class="activity-container" *ngIf="mode==2 && activities.length>0">
    <div class="activity-caption" (click)="show=!show">Recent activities</div>
    <div class="activity-list" *ngIf="show">
        <div class="activity-item" *ngFor="let act of activities">
            <ng-container [ngSwitch]="act.type">
                <ng-template [ngSwitchCase]="'notify'">
                    <ng-container [ngSwitch]="act.action">
                        <ng-template [ngSwitchCase]="'announcement'">
                            <div class="activity-sub">
                                <div class="activity-icon"><mat-icon>notifications</mat-icon></div>
                                <div class="activity-user">
                                    {{act.from}}
                                </div>
                                <div class="activity-time" >{{act.u_ts | timeelapsed}}</div>
                            </div>
                            <div class="activity-msg">{{act.msg}}</div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="'finalize'">
                            <div class="activity-sub">
                                <div class="activity-icon"><mat-icon>thumb_up_alt</mat-icon></div>
                                <div class="activity-user">{{act.from}}</div>
                                <div>{{act.u_ts | timeelapsed}}</div>
                            </div>
                            <div class="activity-msg">Completed a {{act.module | titlecase}}</div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="'assign'">
                            <div class="activity-sub">
                                <div class="activity-icon"><mat-icon>thumb_up_alt</mat-icon></div>
                                <div class="activity-user">{{act.from}}</div>
                                <div>{{act.u_ts | timeelapsed}}</div>
                            </div>
                            <div class="activity-msg" (click)="onClick(act.id, act.module || act.modname)">Assigned policy to {{act.to}}</div>

                        </ng-template>
                        <ng-template [ngSwitchCase]="'finalize'">
                            <div class="activity-sub">
                                <div class="activity-icon"><mat-icon>thumb_up_alt</mat-icon></div>
                                <div class="activity-user">{{act.from}}</div>
                                <div>{{act.u_ts | timeelapsed}}</div>
                            </div>
                            <div class="activity-msg" (click)="onClick(act.id, act.module || act.modname)">Completed {{act.modname}}</div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="'paid'">
                            <div class="activity-sub">
                                <div class="activity-icon"><mat-icon>thumb_up_alt</mat-icon></div>
                                <div class="activity-user">{{act.from}}</div>
                                <div>{{act.u_ts | timeelapsed}}</div>
                            </div>
                            <div class="activity-msg" (click)="onClick(act.id, act.module || act.modname)">Completed Payment</div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="'policy'">
                            <div class="activity-sub">
                                <div class="activity-icon"><mat-icon>thumb_up_alt</mat-icon></div>
                                <div class="activity-user">{{act.from}}</div>
                                <div>{{act.u_ts | timeelapsed}}</div>
                            </div>
                            <div class="activity-msg" (click)="onClick(act.id, act.module || act.modname)">Issued policy</div>
                        </ng-template>
                        <ng-template [ngSwitchDefault]>
                            {{act.action}}
                        </ng-template>
                    </ng-container>
                </ng-template>

            </ng-container>
        </div>
    </div>
</div>
