import { Component, HostBinding, Input, OnDestroy, Optional, Inject, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
import { MatFormFieldControl, MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { formats, MaskFormat, __update_phone_code } from './number-format';
import { PreferencesService } from '../../../lib/services/preferences.service';

@Component({
    selector: 'intl-phone-number',
    host: {'(pointerdown)': 'pointerDown($event)'},
    templateUrl: './intl-phone-number.component.html',
    styleUrls: ['./intl-phone-number.component.scss'],
    providers: [
        {provide: MatFormFieldControl, useExisting: IntlPhoneNumberComponent, multi: true}
    ]
})
export class IntlPhoneNumberComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy, Validator {
    @ViewChild('mi') mi!: ElementRef;
    stateChanges = new Subject<void>();
    _disabled: boolean = false;
    _placeholder!: string;
    _required = false;
    focused = false;
    touched = false;
    countryControl: FormControl = new FormControl();

    onChange = (value: string | number) => {};          // the function we need to call when there is a change in value detected
    onTouched = (value: string | number) => {};         // the function we need to call when the control is touched
    onValidatorChanged = () => {};

    registerOnChange = (onChange: any) => this.onChange = onChange;
    registerOnTouched = (onTouched: any) => this.onTouched = onTouched;
    setDisabledState = (disabled: boolean) => this._disabled = disabled;
    registerOnValidatorChange = (onValidatorChanged: any) => this.onValidatorChanged = onValidatorChanged;
    
    focusIn(ev: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }
    focusOut(ev: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(ev.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched(1);
            this.stateChanges.next();
        }
    }
    pointerDown(ev: any) {/*this.touched = true; this.onTouched(ev);*/}

    static nextId = 0;
    @HostBinding() id = `mskinp-${IntlPhoneNumberComponent.nextId++}`;
    
    @Input() showFlag: boolean = false;

    @Input() mandatory: boolean = false;

    @Input()
    get placeholder() {return this._placeholder;}
    set placeholder(plh: string) {this._placeholder = plh; this.stateChanges.next();}

    get empty() {return this.unfvalue ? false : true}
    @HostBinding('class.floating') get shouldLabelFloat() {return this.focused || !this.empty;}

    @Input()
    get required() {return this._required;}
    set required(req) {this._required = req ? true : false; this.stateChanges.next();}
    

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) {this._disabled = value ? true : false; this.stateChanges.next();}

    __length_error() {
        if (!this.__fmt) return false;  // no format no error

        //if (this.mandatory && +this.fmtvalue.length <= 1+this.default_code.length) return true;
        
        if (this.__fmt.max !== undefined && this.unfvalue.length > this.__fmt?.max) return true;
        if (this.__fmt.min !== undefined && this.unfvalue.length < this.__fmt?.min) return true;

        let fmtval = (this.showFlag ? this.__fmt?.prefix + ' ' : '') + this.fmtvalue;
        if (this.__fmt.max === undefined && this.__fmt.min === undefined) {
            let masklen = this.__fmt?.masks?.[0]?.length;
            if (fmtval.length == masklen) return false;
            if (fmtval.length > this.prefix.length+1 && fmtval.length < masklen) {
                return true;
            }
        }
        return false;
    }

    get errorState(): boolean {
        if(!this.touched && !this.ngControl?.control?.touched) return false;
         if (this.__fmt?.masks?.length > 0) {
            if (!this.__fmt?.masks?.[0]?.length/* || !this.mandatory*/) return false;
            if (this.fmtvalue.length == this.__fmt?.masks?.[0]?.length) return false;
        }
        
        if (this.__length_error()) {
            if (!this.ngControl?.control?.hasError("ssValidator")) this.ngControl?.control?.setErrors({required: true});
            return true;
        }

        /*
        if ((+this.fmtvalue.length > +this.default_code.length) && this.fmtvalue.length < this.__fmt?.masks?.[0]?.length) {
            if (!this.ngControl?.control?.hasError("ssValidator")) this.ngControl?.control?.setErrors({required: true});
            return true;
        }
        */


        // if (this.touched && (+this.fmtvalue.length == +this.default_code.length)) {
        //     if (!this.ngControl?.control?.hasError("ssValidator")) this.ngControl?.control?.setErrors({required: true});
        //     return true;
        // }
        // // if (this.unfvalue.length <= this.country.length+1) return true;
        if (this.ngControl?.invalid && this.ngControl?.control?.touched) return true;
        return !this.unfvalue && this.touched;
    }

    @Input('aria-describedby') userAriaDescribedBy!: string;
    setDescribedByIds(ids: string[]) {
        this._elementRef.nativeElement?.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick(event: MouseEvent) {
    }

    _update_value(value: string) {
        let [fmt, unf, skipped] = this.format(value);
        this.unfvalue = unf;
        // this handler's change detection runs after we make the change and would not reconize it unless we force it to run now
        if (skipped) this.cdr.detectChanges();
        this.fmtvalue = fmt;
        console.log(value, this.fmtvalue, this.unfvalue);
    }

    writeValue(value: string) {
        if(value) {
            this.__fmt = this._get_masks(value);

            if (this.__fmt && value.startsWith(this.__fmt.prefix?.substring(1)))
                 value = value.substring(this.__fmt.prefix.substring(1).length)
        }
        if (!value && !this.touched && !this.showFlag) value = this.default_code;
        this._update_value(value);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // if (this.ngControl?.invalid) return { internal: true };
        return null;    // {errorDesc: {control.value}};
    }

    changed() {
        this._update_value(this.fmtvalue);
        this.onChange(this.unfvalue.startsWith('+')?this.unfvalue.substring(1):this.unfvalue);
        this.onValidatorChanged();
    }


    controlType = "masked-input";
    unfvalue: string = '';
    fmtvalue: string = '';
    country: string = '';
    __fmt: any = null;
    get value() {return this.unfvalue;}
    set value(val: string) {this.writeValue(val);this.stateChanges.next();}

    @Input() default_code = "+91";
    prefix: string = this.default_code;
    constructor(public ngControl: NgControl, 
        private preferenaces: PreferencesService,
        private _elementRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField) {
        if (this.ngControl) this.ngControl.valueAccessor = this;
        __update_phone_code(preferenaces.countries);
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }


    __match_prefix(fmts: MaskFormat, value: string): MaskFormat | null {
        if (!value) return null;
        
        let key = value;
        let f = null;
        for (let i = value.length - 1; i >= 0; i--) {
            if (fmts[key]) return this._get_format_mask(fmts[key], value.substring(i));
            key = value.substring(0,i)  
        }
        return null;
        // if (!value) return null;
        // let key = value[0];
        // if (fmts[key]) return this._get_format_mask(fmts[key], value.substring(1));

        // if (!value[1]) return null;
        // key += value[1];
        // if (fmts[key]) return this._get_format_mask(fmts[key], value.substring(2));
        
        // if (!value[2]) return null;
        // key += value[2];
        // if (fmts[key]) return this._get_format_mask(fmts[key], value.substring(3));
        // return null;
    }

    _get_format_mask(fmt: any, val: string): MaskFormat | null {
        // console.log('fmt:', fmt, val)
        if (fmt.masks instanceof Array) return fmt;
        
        return this.__match_prefix(fmt, val);
    }

    _get_masks(value: string): MaskFormat | null {
        if (!value) return null;
        
        if (value[0] == '+') value = value.substring(1);
        return this.__match_prefix(formats, value);
    }



    format(val: string): [string, string, number, boolean] {
        val = val ? ''+val : '';
        let skipped = 0;
        let value = ''; // val?.replace(/\D/g, '') || '';
        for (let i=0; i<val.length; i++) {
            if ((val[i] >= '0' && val[i] <= '9')) value += val[i];
            else skipped = 1;
        }

        // let masks = this._get_masks(value);
        if (this.showFlag) {
            if (!value && !this.__fmt) value = this.default_code;
            this.__fmt = this._get_masks( this.__fmt ? this.__fmt.prefix + value : value);
        } else {
            this.__fmt = this._get_masks(value);
        }

        if (!this.__fmt || !(this.__fmt.masks instanceof Array)) {
            if (this.showFlag) return [value, value, val.length>0?1:0, false];
            return ['+'+value, value, val.length>0?1:0, false];
        }
        let masks: string[] = <string[]>this.__fmt.masks;
        this.country = (''+this.__fmt.code).toLowerCase();

        // if (!masks) return ['+'+value, value, val.length>0?1:0, false];

        let mask = masks[0];
        let ret = '';
        let unf = '';        
        let vi = 0;

        let clen = this.__fmt.prefix.length - 1;
        if (this.showFlag && mask) {
            mask = mask.substring(clen+2);  // strip of prefix
        }

        for (let i=0; i<(this.__fmt?.max || mask.length) && vi < value.length; i++) {
            if (!mask || (mask[i] == 'd' || (mask[i] >= '0' && mask[i] <= '9'))) {
                ret = ret + value[vi];
                unf = unf + value[vi];
                vi ++;
            } else {
                ret = ret + mask[i];
            }
            if (this.__fmt?.max !== undefined && (vi+clen) >= this.__fmt?.max-1) break;
        }

        if (vi < value.length) skipped = 1;

        let i = ret.length-1;
        for (; i>=0; i--) {
            if (ret[i] >= '0' && ret[i] <= '9') break;
        }
        if (+unf == +this.prefix) unf = '';

        ret = ret.substring(0, i+1);


        if (this.showFlag) {
            if (ret.startsWith(this.__fmt.prefix)) ret = ret.substring(this.__fmt.prefix.length);
            unf = this.__fmt.prefix.substring(1) + unf;
        }
        if (+unf == +this.prefix) unf = '';
        else if (this.__fmt.min !== undefined && +unf.length < +this.__fmt.min) unf = '';

        unf = unf.startsWith('+')?unf : '+'+unf;
        // console.log('ret:', ret.substring(0, i+1), 'skipped', skipped);
        return [ret, unf, skipped, true];
    }

    countryChanged(ev: any) {
        console.log('country changed:', ev.detail);
        // if (!ev.detail) return;

        this.prefix = ev.detail?.phone || '';
        this.prefix = this.prefix.replace(/[^0-9.-]/g, '');
        if (this.showFlag) {
            this.__fmt = this._get_masks(this.prefix);
            console.log('country:', this.__fmt);
            this.ngControl.control?.setValue('');
            this._update_value('');
        } else {
            this.ngControl.control?.setValue(this.prefix);
            this._update_value(this.prefix);    
        }

        this.mi.nativeElement.focus();
        setTimeout(()=>this.mi.nativeElement.focus(), 100)
    }
}
