<div style="padding: 1em 1em;" *ngIf="policy">
    <div *ngIf="policy.policy && policy.policy.policy_no">{{policy.policy.policy_no}}</div>
    <div *ngIf="policy.endorsement && policy.endorsement.endorsement_no">{{policy.endorsement.endorsement_no}}</div>
    
    <div>
        <div style="align-items: center;">
            Meta data <mat-icon style="font-size: 1.2em; cursor: pointer;" (click)="add=true">add</mat-icon>
        </div>

        <div *ngIf="policy.policy && policy.policy.data && policy.policy.data.meta" class="meta-container">
            <div *ngFor="let item of policy.policy.data.meta | keyvalue" class="meta-item">
                <div style="color: #AAF; font-size: 0.9em;">{{item.key}}</div>
                <div>{{item.value}}</div>
            </div>
        </div>
        <div *ngIf="policy.endorsement && policy.endorsement.data && policy.endorsement.data.meta" class="meta-container">
            <div *ngFor="let item of policy.endorsement.data.meta | keyvalue" class="meta-item">
                <div style="color: #AAF; font-size: 0.9em;">{{item.key}}</div>
                <div>{{item.value}}</div>
            </div>
        </div>
        <div *ngIf="add" style="padding: 1em 1em;">
            <div>Name<br><input type="text" [(ngModel)]="name" style="width: 100%; border: 1px solid #AAF;"></div>
            <div><textarea style="width: 100%; border: 1px solid #AAF;" [(ngModel)]="value"></textarea></div>
            <div><button mat-button ibutton (click)="addMeta()">Add meta data</button></div>
        </div>
    </div>
</div>

