import { insapi, IData, IWorkflow, IProduct, NameValue, IEndProduct } from './insapi';
import { dateToExcel, excelDateToDate, is_obj } from './insutils';
import { BehaviorSubject, Subject } from 'rxjs';
import { http } from '../lib/httpng';


export interface Overrides {
    [key: string]: {name: string, source: string, values: string|string[], type?: string}
}

export interface IStageStatus {
    stage: string;
    stage_index: number;
    status: string;
}

export interface INstp {
    nstp_id: string;
    stage: string;
    policy_id: string;
    product_id: string;
    product_group_id: string;
    quote_id: string;
    proposal_id: string;
    nstp_enabled: string;
    nstp_status: string;
    reason: string;
    status: number;
    created_by: string;
    author: string;
    proxy: string;
    c_ts: string;
    u_ts: string;
    data: {[key: string]: any};
}

export interface IPolicy {
    policy_id: string;
    policy_no: string;
    quote_id: string;
    proposal_id: string;
    document_id: string;
    payment_id: string;
    product_id: string;
    cart_id: string;
    quote: {[key: string]: any};
    proposal: {[key: string]: any};
    payment: {[key: string]: any};
    document: {[key: string]: any};
    policy?: {[key: string]: any};
    nstp?: {[key: string]: any};
    qnstp?: {[key: string]: any};
    pnstp?: {[key: string]: any};
    stage_status: IStageStatus[];
    // premium_value: number;
    total_amount: number;
    policy_start_date: string;
    policy_end_date: string;
    assigned_to: string;
    created_by: string;
    [key: string]: any;
}

export interface IEndorsement {
    policy_id: string;
    policy_no: string;
    eproposal_id: string;
    edocument_id: string;
    epayment_id: string;
    prd_endorsement_id: string;
    eproposal: {[key: string]: any};
    epayment: {[key: string]: any};
    document: {[key: string]: any};
    endorsement?: {[key: string]: any};
    enstp?: {[key: string]: any};
    pnstp?: {[key: string]: any};
    stage_status: IStageStatus[];
    total_amount: number;
    endorsement_date: string;
    assigned_to: string;
    created_by: string;
    [key: string]: any;
}

export interface IListOptions {
    [key: string]: {name: string; value: string}[];
}

export class Policy {
    policy: IPolicy | null = null;
    endorsement: IEndorsement | null = null;
    product: IProduct | null = null;
    endProduct: IEndProduct | null = null;
    lists: IListOptions = {};
    plans: {[key: string]: any}[] = [];
    overrides: Overrides = {};
    statuses: {[key: string]: string} = {};
    dbg: boolean = true;
    // policySubject = new BehaviorSubject<IPolicy|null>(null);
    // endorsSubject = new BehaviorSubject<IEndorsement|null>(null);
    changeSubject = new BehaviorSubject<IPolicy|IEndorsement|null>(null);
    stateSubject = new BehaviorSubject<IPolicy|IEndorsement|null>(null);

    input_cell_ids: {[key: string]: number} = {};
    errors: ({name: string, msg: string, code?: string, devErr: string|undefined} | string)[] = [];
    errMap: {[key: string]: any} = {};
    referral: boolean = false;
    ehistory: any= {}//{[key: string]: {date: string, value: any, future: boolean, desc: string, endorsement_id: string}[]} = {};
    defaults: {[key: string]: string}={};
    dirty: boolean = false;
    addons: any = {};       // allowed list of addons for this user, for this product

    dateAsString: boolean = false;
    callseq: number = 1;
    processing: boolean = false;
    constructor(options?: {[key: string]: any}) {
        if (options?.dateAsString) this.dateAsString = true;
    }

    __default_data(product: IProduct, defaults?: {[key: string]: string}) {
        let q: any = {data: {...(defaults||{}), product_id: product.product_id, quote_id: '', policy_id: ''}};
        let ivalid = product['premium_calc_validations'];
        for (let name in ivalid) {
            if (ivalid[name].grid || ivalid[name].status == 1) continue;
            if (ivalid[name].default && !ivalid[name].defaultif) {
                let value = ivalid[name].default;
                if (ivalid[name].default[0] === '{') {
                    if (!ivalid[name].deffunc)
                        ivalid[name].deffunc = new Function('data', 'profile', 'policy', ivalid[name].deffunc);
                    value = ivalid[name].deffunc(q.data, insapi.profile, q);
                }

                if (name.indexOf('.') > 0) {
                    let parts = name.split('.');
                    if (!q.data[parts[0]]) q.data[parts[0]] = {};
                    q.data[parts[0]][parts[1]] = value;
                } else {
                    if (name.endsWith('_tmpl')) {
                        this.defaults[name] = value;
                        this.defaults[name.substring(0, name.length-5)] = value;
                    } else {
                        // do not store the array defaults in main object
                        q.data[name] = value;
                    }
                }
            }
        }
        return q;
    }

    /**
     * Initialize the Policy object with empty data.
     * @param productId product ID
     */
    async init(productId: string, defautls?: {[key: string]: string}): Promise<boolean|NameValue> {
        this.product = await insapi.productFromId(productId);
        if (!this.product) {
            insapi.showMessage("Invalid/Unauthorized product", 1);
            return false;
        }

        let wfId = defautls?.wf_id;
        if (!wfId && insapi.b2c_mode) wfId = this.product.data.b2c_wf_id;
        if (!wfId) wfId = this.product.data.bb_wf_id || this.product.product_type;

        if (!defautls) defautls = {};
        defautls['wf_id'] = wfId+'';

        this.policy = {
            policy_id: '',
            policy_no: '',
            quote_id: '',
            proposal_id: '',
            document_id: '',
            payment_id: '',
            cart_id: '',
            product_id: productId,
            wf_id: wfId,
            quote: this.__default_data(this.product, defautls), // {data: {product_id: productId, quote_id: '', policy_id: ''}},
            proposal: {data: {product_id: productId, quote_id: '', proposal_id: '', policy_id: ''}},
            payment: {data: {}},
            document: {data: {}, details: []},
            // policy: {data: {meta: {}}},
            stage_status: [{stage: 'quote', stage_index: 0, status: 'inprogress'}],
            total_amount: 0,
            assigned_to: '',
            created_by: '',
            policy_start_date: '',
            policy_end_date: ''
        };
        this._update_statuses();
        if (insapi.changeFunc) insapi.changeFunc('policy', this.policy);

        if (defautls) {
            for (var def in defautls) this.policy.quote.data[def] = defautls[def]
        }

        this.__init_inputs_with_defaults(this.policy.quote.data);
        this.__array_input_cell_ids();

        // trigger premium calculation to get the lists populated (load in background)
        //
        // this.__premium(this.policy.quote.data, true);
        return true;
    }

    __init_inputs_with_defaults(data: any) {
    }

    __array_input_cell_ids() {
        if (!this.product || !this.product.pages) return;
        if (this.product?.data.ignore_cell_ids) return;
        
        let pages = this.product.pages['premium_calc'] || [];
        for (let page of pages) {
            for (let grp of page.groups) {
                for (let fld of grp.fields) {
                    if (fld.t == 'grid') {
                        for (let r=fld.range.rs; r<=fld.range.re; r++) {
                            for (let c=fld.range.cs; c<=fld.range.ce; c++) {
                                this.input_cell_ids[fld.range.sheet+'_'+r+'_'+c] = 1;
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Renew an existing policy. Does not store the renewed policy until save is called. The current
     * policy object remains unchanged
     * @param policyNo Policy number (not ID) of existing valid policy
     * @throws When error is encountered
     * @returns new Policy object
     */
    static async renew(policyNo: string): Promise<Policy> {
        let params: any = isNaN(+policyNo) ? {policy_no: policyNo} : {renewable_id: policyNo};
        let data = await insapi.xpost('/api/v1/renewable/renew', params);
        const policy = new Policy();
        await policy.init(data[0].product_id);
        if (policy.policy) {
            policy.policy.quote.data = {...policy.policy.quote.data, ...data[0].data};
        }
        
        // console.log('renew:', data);
        // policy._policyAPIReturn([data[0].data]);
        // if (policy.policy && policy.policy.quote) policy.premium(policy.policy.quote.data, true);
        return policy;
    }

    /**
     * Renew an existing policy. Does not store the renewed policy until save is called. The current
     * policy object remains unchanged. Shows spinner and error messages
     * @param policyNo Policy number (not ID) of existing valid policy
     * @returns new Policy object or null
     */
    static async __renew(policyNo: string): Promise<Policy | null> {
        insapi.showSpinner(true);
        try{
            return await Policy.renew(policyNo);
        } catch (e: any) {
            insapi.showMessage(e.message || e, 1);
            return null;
        } finally {
            insapi.showSpinner(false);
        }
    }

    /**
     * Update the lists member with new set of values
     * @param key Name of the list
     * @param values Values of the list
     */
    setCustomList(key: string, values: string[]) {
        this.lists[key] = values.map(x => ({name: x, value: x}));
    }

    /**
     * @ignore
     */
    _update_statuses() {
        let old = this.statuses;
        let changed = false;
        // this.statuses = {}; -rr 2023-May-05 endorsement status would get overwritten if this is reset here
        // common modules (like payment) should not overwrite endorsment status
        for (const entry of this.policy?.stage_status || []) {
            if (this.endorsement && (entry.stage === 'payment' || entry.stage === 'pnstp') ) continue;
            changed = changed || old[entry.stage] != entry.status;
            this.statuses[entry.stage] = entry.status || '';
        }
        
        let nstp = this.policy?.qnstp || this.policy?.pnstp || this.policy?.nstp;
        let flag = this.policy?.qnstp ? this.product?.data.qnstp_flag : (this.policy?.pnstp ? this.product?.data.pnstp_flag : this.product?.data.nstp_flag);
        let nvalue = this.policy?.qnstp ? this.product?.data.qnstp_value : (this.policy?.pnstp ? this.product?.data.pnstp_value : this.product?.data.nstp_value);
        this.referral = false;
        if (nstp && flag) {
            this.referral = nvalue == (this.policy?.qnstp ? this.policy?.quote?.data[flag] : this.policy?.proposal?.data[flag]);
            if (this.referral && nstp.nstp_status === 'approved') {
                this.referral = false;
            }
        }
        return changed;
    }

    _update_plan_overrides(changed: NameValue|null) {
        let data = this.policy?.proposal?.proposal_id ? this.policy?.proposal?.data : this.policy?.quote?.data;
        if (!data || !data.plan_id || !this.plans) {
            // console.log('plan-skipped:', data?'.':'nodata', data.plan_id?'.':'no-plan', this.plans?'.':'noplans')
            return;
        }

        for (let plan of this.plans) {
            if (plan.plan_id == data.plan_id) {
                for (let key in this.overrides) if (this.overrides[key].source === 'plan') delete this.overrides[key];
                for (let param of plan.params) {
                    let values: string|string[] = param.param_values;
                    if (param.value_func && !param.vfunc) {
                        param.vfunc = new Function('data', 'profile', param.value_func);
                    }

                    if (param.vfunc) {
                        param.param_values = param.vfunc(data, insapi.profile||{});
                    }

                    if (param.param_type == "list" || param.param_type == "range") {
                        let pvalues = param.param_values instanceof Array ? param.param_values : param.param_values.split(',');
                        values = (pvalues.length <= 1) ? pvalues[0] : pvalues;
                    }

                    this.overrides[param.param_name] = {name: param.param_name, values: values, source: 'plan'};
                    if (typeof values === 'string') {
                        data[param.param_name] = values;
                        if (changed) changed[param.param_name] = values;
                    }
                }
                break;
            }
        }
    }

    /**
     * @ignore
     */
    _update_premium() {
        if (!this.policy) return;
        this.policy.total_amount = 0;
        if (this.policy) {
            if (this.policy.proposal && this.policy.proposal_id)
                this.policy.total_amount = +this.policy.proposal.data.premium_value + +this.policy.proposal.data.total_tax + +(this.policy.proposal.data.charges?.total||0);
            else if (this.policy.quote?.data)
                this.policy.total_amount = +this.policy.quote.data.premium_value + +this.policy.quote.data.total_tax + +(this.policy.quote.data.charges?.total||0);
        }
        if (isNaN(this.policy.total_amount)) this.policy.total_amount = 0;
    }

    __merge_endorsements() {
        // sort endorsements by endorsement_date and cut-it off at today
        //
        if (!this.policy?.edata || !(this.policy?.edata instanceof Array)) return;
        let edata = this.policy?.edata; //.sort((a: any, b:any) => {return a.endorsement_start_date > b.endorsement_start_date ? -1 : 1});
        let qdata = this.policy?.quote?.data;
        let pdata = this.policy?.proposal?.data || qdata;
        
        let opdata = JSON.parse(JSON.stringify(pdata ?? {}));
        this.ehistory = {};
        
        for (let data of edata) {
            if (!isNaN(data.endorsement_start_date))
                data.endorsement_start_date = excelDateToDate(data.endorsement_start_date)?.format('YYYY-MM-DD');
            let edate = new Date(data.endorsement_start_date);
            let future = (edate.getTime() > new Date().getTime());
            
            //for (let key in fdata) {    // interested in original policy keys only
            for (let key in data) {
                if (pdata[key] === undefined) continue;
                if (data[key] && typeof data[key] === 'object' && data[key].length > 0 &&
                this.product?.premium_calc_validations?.[key]?.multi != '1') { //to skip multi-lookup, data stored as array
                    let akeys = Object.keys(data[key][0]);
                    for (let key2 of akeys) {
                        for (let d in data[key]){
                            this._history_endorsement_tmpl(key2, pdata, data, opdata, future, true, d, key);
                        }
                    }
                }
                else {
                   this._history_endorsement(key, pdata, qdata, data, opdata, future, false);
                }
            }
            console.log('edata', data['insured_name'])
        }
    }

    _history_endorsement(key: string, pdata: any, qdata: any, data: any, opdata: any, future: any, isTmpl: boolean) {
        if (pdata[key] instanceof Date || data[key] instanceof Date || key == 'policy_start_date') {
            let pdate = dateToExcel(pdata[key]);
            let date = dateToExcel(data[key]);
            let fdate = dateToExcel(opdata[key]);
            if (pdate == date && fdate == date) return;
        } else if (pdata[key] == data[key] && opdata[key] == data[key]) {
            return;
        }

        let historyKey = key;
        // console.log('pdata:', key, data[key], pdata[key], pdata[key] instanceof Date, fdata[key])
        if (!this.ehistory[historyKey]) {
            let pdt = excelDateToDate(this.policy?.policy?.issue_date || this.policy?.policy?.c_ts);
            this.ehistory[historyKey] = [{date: pdt?.format('YYYY-MM-DD')||'', value: pdata[key], future: false, desc: '', endorsement_id: ''}];
        }

        if (pdata[key] != data[key]) pdata[key] = data[key];
        if (qdata[key] != data[key]) qdata[key] = data[key];
        if (!future) {
            if (pdata[key] != data[key]) pdata[key] = data[key];
            if (qdata[key] != data[key]) qdata[key] = data[key];
        }
        let h = this.ehistory[historyKey];
        //console.log("ehistory",key,this.ehistory[key],pdata[key])
        if (h[h.length-1].value == data[key]) return;
        let desc = (data.created_by ? data.created_by + ' ' : '') + 'Changed from '+ h[h.length-1].value + ' to ' + data[key];
        this.ehistory[historyKey].push({date: data.endorsement_start_date, value: data[key], future, desc, endorsement_id: data.endorsement_id});
    }

    _history_endorsement_tmpl(key: string, pdata: any, data: any, fdata: any, future: any, isTmpl: boolean, idx: string, parent: string) {
        
        if (!pdata[parent]?.[idx] ) pdata[parent][idx] = {};
        if (!fdata[parent]?.[idx] ) fdata[parent][idx] = {};
        
        if (pdata[parent][idx][key] instanceof Date || data[parent][idx][key] instanceof Date || key == 'policy_start_date') {
            let pdate = dateToExcel(pdata[parent][idx][key]);
            let date = dateToExcel(data[parent][idx][key]);
            let fdate = dateToExcel(fdata[parent][idx][key]);
            if (pdate == date && fdate == date) return;
        } else if (pdata[parent][idx][key] == data[parent][idx][key] && fdata[parent][idx][key] == data[parent][idx][key]) {
            return;
        }
        let historyKey = key;
        //console.log('pdata:', key, data[parent][idx][key], pdata[parent][idx][key], pdata[parent][idx][key] instanceof Date, fdata[parent][idx][key], idx)
        if (!this.ehistory[historyKey]?.[idx]) {
            let pdt = excelDateToDate(this.policy?.policy?.issue_date || this.policy?.policy?.c_ts);
            if (!this.ehistory[historyKey])this.ehistory[historyKey]={};
            this.ehistory[historyKey][idx] = [{date: pdt?.format('YYYY-MM-DD')||'', value: pdata[parent][idx][key], future: false, desc: '', endorsement_id: ''}];

        }

        if (pdata[parent][idx][key] != data[parent][idx][key]) pdata[parent][idx][key] = data[parent][idx][key];
        if (!future) {
            if (pdata[key] != data[key]) pdata[parent][idx][key] = data[parent][idx][key];
        }
        
        let h = this.ehistory[historyKey][idx];
        if (h[h.length-1].value == data[parent][idx][key]) return;
        let desc = (data.created_by ? data.created_by + ' ' : '') + 'Changed from '+ h[h.length-1].value + ' to ' + data[parent][idx][key];
        this.ehistory[historyKey][idx].push({date: data.endorsement_start_date, value: data[parent][idx][key], future, desc, endorsement_id: data.endorsement_id});

    }

    async __handle_inprogress() {
        let state: any[] = [];
        if (this.endorsement) {
            state = this.endorsement?.stage_status.filter(x => (x.stage === 'eproposal') && (x.status === 'inprogress'||x.status === ''));
        } else if (this.policy) {
            state = this.policy?.stage_status.filter(x => (x.stage === 'quote' || x.stage === 'proposal') && x.status === 'inprogress');
        }
        
        if (state && state.length > 0) {
            let data = this.policy?.quote.data || {};
            if (state[0].stage == 'eproposal') data = this.endorsement?.eproposal.data;
            else if (state[0].stage == 'proposal') data = this.policy?.proposal?.data;
            if (!data) data = this.policy?.quote.data;

            await this.premium(data, (state[0].stage === 'quote'));
        } else {
            // if we are not calling premium calc, we should notify change to others explicitly
            this.changeSubject.next(this.endorsement || this.policy);
        }
    }

    /**
     * Initialize the Policy object with existing policy data.
     * @param policyId Policy ID
     * @throws When error is encountered
     * @returns IPolicy object reference
     */
    async load(policyId: string, endorsements: boolean = false): Promise<IPolicy | null> {
        const ret = await insapi.xget('/api/v1/policy/' + encodeURIComponent(policyId) + (endorsements?'?elist=1':''));
        if (ret && ret.length > 0 && ret[0]) {
            if (this.product?.product_id !== ret[0]?.product_id)
                this.product = await insapi.productFromId(ret[0]?.product_id);
            if (this.product === null) throw new Error('Do not have access to product');
        }

        // this.dbg && console.log('loaded ', ret)
        if (!this._policyAPIReturn(ret, true)) throw new Error('Policy not found');
        if (!this.policy) return null;

        // if endorsements are requeseted, merge the edata with the proposal/quote
        //
        this.__merge_endorsements();

        this.__array_input_cell_ids();

        await this.__handle_inprogress();
        
        // if the quote or proposal is in inprogress state, we should call premium API to get
        // lists loaded
        //
        // let state: any[] = [];
        // if (this.endorsement) {
        //     state = this.endorsement?.stage_status.filter(x => (x.stage === 'eproposal') && (x.status === 'inprogress'||x.status === ''));
        // } else {
        //     state = this.policy?.stage_status.filter(x => (x.stage === 'quote' || x.stage === 'proposal') && x.status === 'inprogress');
        // }
        
        // if (state && state.length > 0) {
        //     let data = this.policy.quote.data;
        //     if (state[0].stage == 'eproposal') data = this.endorsement?.eproposal.data;
        //     else if (state[0].stage == 'proposal') data = this.policy.proposal?.data;
        //     if (!data) data = this.policy.quote.data;

        //     // data = (state[0].stage === 'quote') ? this.policy.quote.data : (this.policy.proposal?.data || this.policy.quote?.data)
        //     await this.premium(data, (state[0].stage === 'quote'));
        // } else if (!endorsements) {
        //     this.changeSubject.next(this.endorsement || this.policy);
        // }
        return this.policy;
    }

    async __load(policyId: string, endorsements: boolean = false): Promise<IPolicy|boolean> {
        return this.__api_wrapper( async () => await this.load(policyId, endorsements) );
    }

    __merge_with_eproposal(edata: any) {
        if (!this.endorsement) return;
        let ignore: {[key: string]: number} = {
            wf_id: 1, eproposal_id: 1, endorsement_id: 1, 
            uuid: 1, proposal_no: 1, cert_id:1 , status:1, broker_id: 1, broker_code: 1, 
            agent_code: 1, user_code: 1, broker_name: 1, broker_type: 1, catalog: 1, __finalize:1, product_name:1, 
            product_desc:1, product_group_id:1, publish_date:1, eproposal_no:1, 
            endorsement_name:1, endorsement_type:1, endorsement_index:1, policy_no:1, pdf_date:1, endorsement_date:1,
            otp_verified:1, _ready:1, policywordings:1, endorsement_no:1, prd_endorsement_id: 1
        };
        for (let i in edata)
            if (!ignore[i] && edata[i] !== undefined && edata[i] !== null) this.endorsement.eproposal.data[i] = edata[i];
    }

    async __new_endorsement(endPrdId: string, endDate: string) {
        if (!this.policy || !this.product) return;
        this.endProduct = await insapi.endProductFromId(endPrdId, this.product.product_id);
        if (!this.endProduct) return;
        this.endorsement = {
            policy_id: this.policy.policy_id, 
            policy_no: this.policy.policy_no,
            eproposal_id: '',
            edocument_id: '',
            epayment_id: '',
            prd_endorsement_id: this.endProduct.prd_endorsement_id,
            eproposal: {data: {eproposal_id: '',endorsement_id:'',endorsement_start_date: dateToExcel(endDate), prd_endorsement_id: this.endProduct.prd_endorsement_id, policy_id: this.policy.policy_id}},
            epayment: {data: {}},
            document: {data: {}},
            endorsement: {wf_id: this.endProduct.wf_id, data: {wf_id: this.endProduct.wf_id}},
            // enstp: {data: {}},
            // pnstp: {data: {}},
            stage_status: [{stage: 'eproposal', stage_index: 0, status: 'inprogress'}],
            total_amount: 0,
            endorsement_date: endDate,
            assigned_to: '',
            created_by: ''
        };

        let data = this.policy.proposal?.data || this.policy.quote?.data || {};
        this.__merge_with_eproposal(data);
        // let ignore: {[key: string]: number} = {wf_id: 1, eproposal_id: 1, endorsement_id: 1};
        // for (let k in data) if (!ignore[k ]) this.endorsement.eproposal.data[k] = data[k];
        
        for (let edata of this.policy.edata) {
            this.__merge_with_eproposal(edata);
            // for (let i in data) if (!ignore[i] && edata[i] !== undefined && edata[i] !== null)
            //     this.endorsement.eproposal.data[i] = edata[i];
        }
    }

    async __load_endorsement(endId: string) {
        const ret = await insapi.xget('/api/v2/endorsement/' + encodeURIComponent(endId));
        if (ret?.length > 0 && ret[0]) {
            if (!this.product) this.product = await insapi.productFromId(ret[0]?.product_id);
            this.endProduct = await insapi.endProductFromId(ret[0]?.prd_endorsement_id, ret[0]?.product_id);
            if (this.endProduct === null) throw new Error('Do not have access to endorsement product');
            this.endorsement = ret[0];
            let changed = this._update_endorsement_statuses();
            await this.__handle_inprogress();
            if (changed) this.stateSubject.next(this.endorsement);
            // this.changeSubject.next(this.endorsement);   handle_inpr will take care of calling it
        }
    }

    /**
     * @ignore
     */
    _policyAPIReturn(ret: any[], nonotify: boolean = false) {
        if (!(ret instanceof Array) || ret.length <= 0) return false;
        this.policy = ret[0];
        if (!this.policy) return false;

        this._fix_errors(this.policy);
        let changed = this._update_statuses();
        this._update_premium();
        this._update_plan_overrides(null);
        if (insapi.changeFunc) insapi.changeFunc('policy', this.policy);
        if (!nonotify) {
            this.changeSubject.next(this.endorsement || this.policy);
            if (changed) this.stateSubject.next(this.endorsement || this.policy);
        }
        this.addons = this.policy.addons || {};
        return true;
    }
    _endorsementAPIReturn(ret: any[]) {
        if (!(ret instanceof Array) || ret.length <= 0) return false;
        this.endorsement = ret[0];
        if (!this.endorsement) return false;
        console.log('_endorsementAPIReturn:', this.endorsement.cert_id);

        this._fix_errors(this.endorsement);
        let changed = this._update_endorsement_statuses();
        this._update_endorsement_premium();
        this.changeSubject.next(this.endorsement);
        if (changed) this.stateSubject.next(this.endorsement);
        return true;
    }

    /**
     * Finalize the stage where module is part of and move to next stage. Update the current
     * policy object with updated data.
     * @param modName [quote] Name of module [quote|proposal|payment|document|nstp|pnstp|qnstp|iagree|covernote|policy]
     * @throws Module specific errors (like Quotation has already been completed)
     */
    async finalize(modName: string): Promise<boolean> {
        
        if (this.endorsement) {
            let paymentId = this.endorsement?.[modName+'_id'];
            if (!paymentId) return false;
            modName = modName === 'payment' ? 'epayment' : modName;
            return this._endorsementAPIReturn(await insapi.xpost('/api/v2/'+modName+'/finalize/' + encodeURIComponent(paymentId), {}));
        }
        console.log('inspol: finalize-policy', modName);
        modName = modName || 'quote';
        if (!this.policy || !this.policy[modName+'_id']) {
            console.log('finalize: not ready ', modName, modName+'_id' );
            return false;
        }
        let modId = this.policy[modName+'_id'];
        return this._policyAPIReturn(await insapi.xpost('/api/v1/'+modName+'/finalize/' + encodeURIComponent(modId), {}));
    }

    async __finalize(modName: string): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.finalize(modName));
    }

    async __api_wrapper(cb: ()=>any){
        insapi.showSpinner(true);
        try{
            return await cb();
        } catch (e: any) {
            console.log('Error occured', e);
            insapi.showMessage(e.message || e, 1);
            return false;
        } finally {
            insapi.showSpinner(false);
        }
    }

    __fix_data_date(data: any) {
        for (let key in data) {
            if (data[key] instanceof Date) {
                if (this.dateAsString) {
                    let rt = excelDateToDate(data[key]);
                    if (rt) data[key] = rt.format('YYYY-MM-DD');
                } else {
                    data[key] = dateToExcel(data[key]);
                }
            } else if (data[key] instanceof Array) {
                for (let mem of data[key]) {
                    for (let k in mem) {
                        if (mem[k] instanceof Date) {
                            if (this.dateAsString) {
                                let rt = excelDateToDate(mem[k]);
                                if (rt) mem[k] = rt.format('YYYY-MM-DD');
                            } else {
                                mem[k] = dateToExcel(mem[k]);
                            }                            
                        }
                    }
                }
            } else if (is_obj(data[key])) {
                for (let k in data[key]) delete data[key+'.'+k];
                this.__fix_data_date(data[key]);
            }
        }
    }


    /**
     * @ignore
     *  Ideally we should be sending only the input variables, but then it would be very specific. 
     * So we are using all data and just getting rid of cell-ids and formatted cell-data
     */
    _merge_data(cur: any, data: any) {
        var ndata: any = {};

        if (this.product?.data.ignore_cell_ids) {
            for (let key in cur) {
                if (key.endsWith('_tmpl')) continue;
                if (key[0] == 'f' && key[1] <= '_') continue;
                ndata[key] = cur[key];
            }
    
            for (let key in (data||{})) {
                if (key.endsWith('_tmpl')) continue;
                if (key[0]=='f' && key[1]<='_') continue;
                ndata[key] = data[key];
            }
        } else {
            for (let key in cur) {
                if (this.product?.data.ignore_cell_ids && key.endsWith('_tmpl')) continue;
                if (!this.input_cell_ids[key] && key[0]>='0' && key[0]<='9') continue;
                if (key[0] == 'f' && key[1] <= '_') continue;
                ndata[key] = cur[key];
            }
    
            for (let key in (data||{})) {
                if (this.product?.data.ignore_cell_ids && key.endsWith('_tmpl')) continue;
                if (!this.input_cell_ids[key] && key[0]>='0' && key[0]<='9') continue;
                if (key[0]=='f' && key[1]<='_') continue;
                ndata[key] = data[key];
            }
        }
       
        this.__fix_data_date(ndata);

        delete ndata.tax_details;
        return ndata;
    }

    async saveInspect(data: IData) {
        if (this.endorsement){
            if (!this.endorsement?.inspect_id || !data) return;
        }else if (!this.policy?.inspect_id || !data) return;
        data['inspect_id'] = this.endorsement?.inspect_id || this.policy?.inspect_id;
        //return this._policyAPIReturn(await insapi.xpost('/api/v1/inspect', data));
        let ret = await insapi.xreq((this.endorsement?'/api/v2/':'/api/v1/') + 'inspect', 'POST', data, undefined, 1);
        if (ret?.status == 0) this.dirty = false;
        if (ret?.txt && ret.txt.toLowerCase().indexOf('assigned') >= 0) insapi.showMessage(ret.txt, 1);
        return this.endorsement?this._endorsementAPIReturn(ret.data):this._policyAPIReturn(ret.data);
    }


    /**
     * Save the new quotation with new set of data. Error validation is performed but does not stop the save
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @throws When error is encountered
     */
    async saveQuote(data: IData) {
        await insapi.__load_customer();
        data = this._merge_data(this.policy?.quote.data, data);
        if (this.policy && this.policy.quote_id) data['quote_id'] = this.policy.quote_id;
        // let ret = await insapi.xreq('/api/v1/quote', 'POST', data, undefined, true);
        let ret = await this.__overlapped_post('/api/v1/quote', data, undefined, 2);
        if (!ret) return false;
        if (ret?.status == 0) this.dirty = false;
        if (ret?.txt /*&& ret.txt.toLowerCase().indexOf('assigned') >= 0*/) insapi.showMessage(ret.txt, 1);
        return this._policyAPIReturn(ret.data);
    }

    /**
     * Saves the quotation and consumes exception. Shows spinner and error messages.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @returns true when done, false in case of error
     */
    async __saveQuote(data: IData): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.saveQuote(data));
    }

    /**
     * Saves and finalizes the quotation.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @throws When error is encountered
     */
    async saveAndFinalizeQuote(data: IData): Promise<boolean> {
        return await this.saveQuote({...data, __finalize: 1});
    }

    /**
     * Saves and finalizes the quotation. Shows spinner and error messages.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @returns true when done, false in case of error
     */
    async __saveAndFinalizeQuote(data: IData): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.saveAndFinalizeQuote(data));
    }

    /**
     * Saves the proposal with new data and re-computes the premium.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @returns true when done
     * @throws When error is encountered
     */
    async saveProposal(data: IData): Promise<boolean> {
        if (!this.policy || !this.policy.proposal_id) return false;
        await insapi.__load_customer();
        let ndata = this._merge_data(this.policy.proposal.data, data);
        ndata['proposal_id'] = this.policy.proposal_id;
        // let ret = await insapi.xreq('/api/v1/proposal', 'POST', ndata, undefined, true);
        let ret = await this.__overlapped_post('/api/v1/proposal', ndata, undefined, 1);
        if (!ret) return ret;
        if (ret?.status == 0) this.dirty = false;
        if (ret?.txt && ret.txt.toLowerCase().indexOf('assigned') >= 0) insapi.showMessage(ret.txt, 1);
        return this._policyAPIReturn(ret.data);
    }

    /**
     * Saves the proposal with new data and re-computes the premium. Shows spinner and error messages.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @returns true when done, false in case of error
     */
    async __saveProposal(data: IData): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.saveProposal(data));
    }

    /**
     * Saves and finalizes the quotation.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @returns true when done
     * @throws When error is encountered
     */
    async saveAndFinalizeProposal(data: IData): Promise<boolean> {
        return await this.saveProposal({...data, __finalize: 1});
    }

    /**
     * Saves and finalizes the quotation. Shows spinner and error messages.
     * @param data Name/Value pair of data. Custom data can also be stored as long as it is serializable.
     * @returns true when done, false in case of error
     */
    async __saveAndFinalizeProposal(data: IData): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.saveAndFinalizeProposal(data));
    }

    /**
     * Computes the premium for given input. Does not save anything. Shows error messages.
     * @param data Name/Value pair of data
     * @param isQuote Compute quotation premium (when true) or proposal premium (when false)
     * @param outputs List of additional outputs to be computed
     * @returns true when done, false in case of error
     */
    async __premium(data: IData, isQuote: boolean, outputs?: string[], block: boolean = false): Promise<boolean|NameValue> {
        if (block) insapi.showSpinner(true);
        try {
            return await this.premium(data, isQuote, outputs);
        } catch (e: any) {
            insapi.showMessage(e.message || e, 1);
            return false;
        } finally {
            if (block) insapi.showSpinner(false);
        }
    }

    // if more than one call has been made to the server (like two premium calc), only the last one 
    // would be used (first call's return values are ignored)
    // todo: what happens if a save followed by premium-calc is called?
    async __overlapped_post(url: string, data: IData | FormData, params?: IData, full: number=0): Promise<any> {
        let callseq = ++this.callseq;
        this.processing = true;
        const ret = await insapi.xreq(url, 'post', data, params, full);
        if (this.callseq != callseq) {
            console.log('call-seq overlap, ignoring result', callseq, this.callseq, url);
            return false;  // another premium-calc/save has been initiated, ignore this result
        }
        this.processing = false;
        return ret;
    }

    /**
     * Computes the premium for given input. Does not save anything.
     * @param data Name/Value pair of data
     * @param isQuote Compute quotation premium (when true) or proposal premium (when false)
     * @param outputs List of additional outputs to be computed
     * @returns name value pair of all changed inputs (due to restrictions and rules)
     * @throws When error is encountered
     */
    async premium(data: IData, isQuote: boolean, outputs?: string[]): Promise<boolean|NameValue> {

        if (this.endorsement){
            if (!this.endorsement.eproposal?.data?.product_id) return false;
        } else if (!this.policy || !this.policy.product_id) return false;
        
        //Ignore premium calculation at the time of payment
        let payStage = (this.endorsement||this.policy)?.stage_status.filter(x => x.stage == "payment" && x.status == 'inprogress') || [];
        if (payStage?.length > 0) return false;
        
        const params: {[key: string]: any} = {
            inputs: this._merge_data(data, {}),
            outputs: [this.product?.data.premium_value || 'premium_value', 'nstp_flag', ...(outputs||[]) ]
        };
        let wfId = this.endorsement?.endorsement?.wf_id || data.wf_id || this.policy?.wf_id || '';

        let url = '/api/v1/product/calc/' + encodeURIComponent(this.endorsement?.eproposal?.data?.product_id || this.policy?.product_id);
        if (wfId) url += '?wf_id=' + encodeURIComponent(wfId);
        
        // if (data.wf_id) url += '?wf_id=' + encodeURIComponent(''+data.wf_id);
        // else if (this.policy.wf_id) url += '?wf_id=' + encodeURIComponent(''+this.policy.wf_id);
        
        if (!this.endorsement) url +=  (isQuote) ? '&type=premium_calc' : '&type=proposal_form';
        else url += "&prd_endorsement_id=" + encodeURIComponent(this.endorsement.prd_endorsement_id);

        // const rdata = await insapi.xpost(url, params);
        const rdata = await this.__overlapped_post(url, params);
        if (!rdata) return false;   // happens when auth function is not modal

        if (!rdata.changed) rdata.changed = {};
        this.addons = rdata.addons || {};
        if (rdata.cells) {
            // const dst = isQuote ? this.policy.quote?.data : this.policy.proposal?.data;
            let dst = isQuote ? this.policy?.quote?.data : this.policy?.proposal?.data;
            if (this.endorsement) dst = this.endorsement.inspect?.data || this.endorsement.eproposal?.data;
            for (const key in rdata.cells) dst[key] = rdata.cells[key]; // changed outputs
            for (const key in rdata.changed||{}) dst[key] = rdata.changed[key]; // changed inputs

            
            if (rdata.choices) {
                let outputs = this.product?.multi_quote?.outputs||['premium_value'];
                for (let i=0; i<rdata.choices.length; i++) {
                    if (dst.choices.length > i) {
                        for (let output of outputs) {
                            dst.choices[i][output.param_name] = rdata.choices[i][output.param_name];
                        }
                    }
                }
            }
            dst['total_tax'] = rdata.total_tax ? rdata.total_tax : 0;
            dst['tax_details'] = rdata.tax_details ? rdata.tax_details : {};
            dst['subpolicies'] = rdata.subpolicies ? rdata.subpolicies : {};
            if (this.endorsement) {
                this._update_endorsement_premium();
                this._update_endorsement_statuses();
            } else {
                this._update_premium();
                this._update_statuses();
            }
            if (insapi.changeFunc) insapi.changeFunc(this.endorsement ? 'edata' : (isQuote ? 'qdata' : 'pdata'), dst);
        }


        // if (!this.endorsement) {
            this.plans = Object.values(rdata.plans || {});
            this.plans = this.plans.sort((a: any, b:any) => a.plan_index - b.plan_index);
            // .sort((a: any, b:any) => a.plan_index-b.plan_index);
            this._update_plan_overrides(rdata.changed);
        // }

        if (rdata.named_lists) {
            for (let key in rdata.named_lists) {
                let opts: {name: string; value: string}[] = [];
                for (let k in rdata.named_lists[key]) opts.push({name: rdata.named_lists[key][k], value: k});
                this.lists[key] = opts;
                // this.lists[key] = Object.keys(rdata.named_lists[key]);
            }
                

            for (let key in rdata.olist)
                this.lists[key] = rdata.olist[key];
                // this.lists[key] = rdata.olist[key].map((x: any) => x.name);
            if (insapi.changeFunc) insapi.changeFunc('lists', rdata.named_lists);
            if (insapi.changeFunc) insapi.changeFunc('olist', rdata.olist);
        }
        
        //-rr Dec-12-2022 moved fix errors ahead of notify
        this._fix_errors(rdata);

        this.changeSubject.next(this.endorsement || this.policy);
        return rdata.changed;
    }

    async seek(data: IData, isQuote: boolean, goal: any): Promise<{found: NameValue, errors: string[]}|null> {
        if (!this.policy || !this.policy.product_id) return null;
        const params: {[key: string]: any} = {
            inputs: this._merge_data(data, {}),
            goal
        };

        let url = '/api/v1/product/goal/' + encodeURIComponent(this.policy.product_id);
        if (data.wf_id) url += '?wf_id=' + encodeURIComponent(''+data.wf_id);
        else if (this.policy.wf_id) url += '?wf_id=' + encodeURIComponent(''+this.policy.wf_id);
        if (!this.endorsement) url +=  (isQuote) ? '&type=premium_calc' : '&type=proposal_form';
        const rdata = await insapi.__xpost(url, params);
        if (!rdata) return null;
        console.log('goal-ret:', rdata);
        for (let name in rdata.found) {
            if (!isNaN(+rdata.found[name])) {
                rdata.found[name] = +((+rdata.found[name]).toFixed(2));
                data[name] = rdata.found[name];
            }
        }
        return rdata;
    }

    _fix_errors(data: any) {
        this.errMap = {};
        this.errors = data.errors || [];
        if (!data.errors) return;
        this.errors.sort((a: any, b: any) => a.index - b.index);

        for (let i=0; i<this.errors.length; i++) {
            let err: any = this.errors[i];
            if (typeof err !== 'string') {
                this.errMap[err.name] = err;
            } else {
                // if (this.product?.data.display_errors) insapi.showMessage(err, 0);
            }
        }
        delete data.errors;
        if (this.errors.length > 0) {
            // console.log('errors: ', this.errors);
            console.log('errMap: ', this.errMap);
        }
    }

    async __save_if_needed() {
        if (this.policy?.policy_id || this.endorsement) return;
        await this.saveQuote(this.policy?.quote?.data);
    }

    /**
     * Upload a document against the current policy object
     * @param docType Type of the document
     * @param docDescription Description text
     * @param evfile Javascript file object selected by the user
     * @param fileName [uploaded-filename] Name of the file to be stored
     * @returns true when done
     * @throws When error is encountered
     */
    async uploadDocument(docType: string, docDescription: string, evfile: any, fileName?: string): Promise<boolean> {
        await this.__save_if_needed();  // very first time, we need to save to get policy-id and document-id

        let documentId = this.endorsement?.document.document_id || this.policy?.document_id;
        console.log('upload:', documentId, 'docType:', docType);
        if (!documentId || !evfile || !docType) return false;

        const formData = new FormData();
        formData.append('document_id', documentId);
        formData.append('document_type', docType);
        formData.append('name', evfile.name);
        formData.append('document_desc', docDescription || '');
        formData.append('file', evfile, fileName || evfile.name);

        let res: any[] = [];
        if (this.endorsement?.document.document_id) {
            res = await insapi.xpost('/api/v2/edocument/attach', formData);
            if (res.length > 0 && this.endorsement) {
                this.endorsement.document = res[0].document;
                return true;
            }
        } else {
            res = await insapi.xpost('/api/v1/document/attach', formData) || [];
            if (res.length > 0 && this.policy) {
                this.policy.document = res[0].document;
                return true;
            }
        }

        return false;
    }

    /**
     * Upload a document against the current policy object. Shows spinner and error messages. 
     * @param docType Type of the document
     * @param docDescription Description text
     * @param evfile Javascript file object selected by the user
     * @param fileName [uploaded-filename] Name of the file to be stored
     * @returns true when done false when error encountered
     */
    async __uploadDocument(docType: string, docDescription: string, evfile: any, fileName?: string) {
        return await this.__api_wrapper(async () => await this.uploadDocument(docType, docDescription, evfile, fileName));
    }

    /**
     * Marks an already uploaded document as deleted.
     * @param documentDetailsId 
     * @returns true when done
     * @throws When error is encountered
     */
    async deleteDocument(documentDetailsId: string): Promise<boolean> {
        if (!documentDetailsId) return false;
        let res: any[] = [];
        if (this.endorsement?.document.document_id) {
            res = await insapi.xdel('/api/v2/edocument?document_details_id=' + encodeURIComponent(documentDetailsId));
            if (res.length > 0 && this.endorsement) {
                this.endorsement.document = res[0].document;
                this.changeSubject.next(this.endorsement);
                return true;
            }
        } else {
            res = await insapi.xdel('/api/v1/document?document_details_id=' + encodeURIComponent(documentDetailsId)) || [];
            if (res.length > 0 && this.policy) {
                this.policy.document = res[0].document;
                this.changeSubject.next(this.policy);
                return true;
            }
        }

        return false;
    }

    /**
     * Marks an already uploaded document as deleted. Shows spinner and error messages.
     * @param documentDetailsId 
     * @returns true when done false when error encountered
     */
    async __deleteDocument(documentDetailsId: string): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.deleteDocument(documentDetailsId));
    }

    async __redirect_to(url: string) {
        if (url) window.location.href = await http.__encrypt_url(url, true);
    }

    /**
     * Launch a new URL to download the document
     * @param documentDetailsId Document ID
     */
    async downloadDocument(documentDetailsId: string): Promise<void> {
        this.__redirect_to(await this.downloadDocumentURL(documentDetailsId));
    }

    /**
     * Get the URL to download the document given document details record id.
     * @param detailsId Document Details ID
     * @returns URL or Empty string
     */
    async downloadDocumentURL(detailsId: string): Promise<string> {
        let documentId = this.endorsement?.document?.document_id || this.policy?.document_id;
        let details = this.endorsement?.document?.document_id ? this.endorsement?.document?.details : this.policy?.document.details;
        if (!documentId || !details) return '';

        let url = this.endorsement?.document?.document_id ? '/api/v2/edocument' : '/api/v1/document';
        for (let detail of details) {
            if (detail.document_details_id == detailsId) {
                return url + '/data/' +
                    encodeURIComponent(detail.document_id) +
                    '/' + encodeURIComponent(detail.document_type) +
                    '/' + encodeURIComponent(detail.name) +
                    '?download=1&token=' + encodeURIComponent(insapi.getToken());
            }
        }
        return '';
    }

    async downloadCatalog(cat: any) {
        let cur = this.product?.catalog?.[cat.field_name];
        let url = insapi.server + '/api/v1/product/data/';
        if (cur && cur.version != cat.version) url = insapi.server + '/api/v1/product/data_history/';
        url += encodeURIComponent(cat.module_id) + '/catalog/' + encodeURIComponent(cat.file_name);
        url += '?download=1&version='+cat.version+'&token=' + encodeURIComponent(insapi.getToken());
        this.__redirect_to(url);
    }

    async downloadProductAttachment(dataType: string, fieldName: string) {
        if (!this.product?.product_id) return;
        let url = insapi.server + '/api/v1/product/data/' + encodeURIComponent(this.product?.product_id) + '/';
        url += encodeURIComponent(dataType) + '?field_name=' + encodeURIComponent(fieldName);
        url += '&download=1&token=' + encodeURIComponent(insapi.getToken());
        this.__redirect_to(url);
    }


    /**
     * Launch a new URL to download the module specific PDF when work in progress (before finalize).
     * @param modName [quote] Name of the module
     */
    async downloadDraft(modName: string) {
        modName = modName || 'quote';
        if (!this.policy || !this.policy[modName + '_id']) return;
        let modId = this.policy[modName + '_id'];
        let url = insapi.server + '/api/v1/'+modName+'/draft/' + encodeURIComponent(modId);
        url += '/' + encodeURIComponent('draft-' + modName + '.pdf');
        url += '/' + encodeURIComponent('draft-' + modName + '.pdf');
        url += '?download=1&token=' + encodeURIComponent(insapi.getToken());
        this.__redirect_to(url);
    }

    /**
     * Returns the thumbnail URL for the uploaded document
     * @param docType 
     */
    documentThumbnail(docType: string): string | boolean {
        let details = this.endorsement?.document?.document_id ? this.endorsement?.document?.details : this.policy?.document.details;
        if (!details) return false;
        let url = this.endorsement?.document?.document_id ? '/api/v2/edocument' : '/api/v1/document';
        for (const dd of details) {
            if (dd.document_type.toLowerCase() === docType.toLowerCase()) {
                return url + '/thumbnail/' + encodeURIComponent(dd.document_details_id);
            }
        }
        return false;
    }

    /**
     * Mark document as verified. 
     * @param documentDetailsId 
     * @returns true when done, false when policy is not loaded
     * @throws When error is encountered
     */
    async markDocumentVerified(documentDetailsId: string): Promise<boolean> {
        if (!this.policy || !documentDetailsId) return false;
        return this._policyAPIReturn(await insapi.xpost('/api/v1/verify', {document_details_id: documentDetailsId}));
    }

    /**
     * Uses deposit to make payment
     * @param amount [unpaid balance] Amount to be deducted from deposit
     * @returns true when done, false when parameters are invalid
     * @throws When error is encountered
     */
    async payCash(amount?: number, depositId?: string) {
        if (this.endorsement?.payment.payment_id) {
            if (amount === undefined) amount = (this.endorsement?.payment.total_amount - this.endorsement?.payment.total_recvd);
            const data: any = {
                key: this.endorsement?.payment.payment_id,
                amount: amount
            }
            if (+data.amount < 0) data.amount = 0;// return false;
            if (depositId) data.entity_id = depositId;
            let ret = await insapi.xpost('/api/v2/epayment/cash', data);
            if (ret.length <= 0)
                ret = await insapi.xget('/api/v2/endorsement/' + encodeURIComponent(this.endorsement.endorsement_id));
            return this._endorsementAPIReturn(ret);
        }
        
        if (!this.policy || !this.policy.payment) return false;
        if (!amount) {
            if (this.policy.payment.schedule) {
                for (let sch of this.policy.payment.schedule) {
                    if (+sch.total_recvd < +sch.total_amount) {
                        amount = (+sch.total_amount - +sch.total_recvd);
                        if (amount >= 0.1) break;
                    }
                }
            }

            if (!amount)
                amount = (this.policy.payment.total_amount - this.policy.payment.total_recvd);
        }

        const data: any = {
            key: this.policy.payment_id,
            amount: amount
        }
        if (+data.amount < 0) return false;
        if (depositId) data.entity_id = depositId;

        let ret = await insapi.xreq('/api/v1/payment/cash', 'POST', data, undefined, 1);
        if (ret.txt?.indexOf('assigned') > 0) insapi.showMessage(ret.txt, 1);
        insapi.__update_profile();
        if (ret.data.length > 0) return this._policyAPIReturn(ret.data);
        
        // we have to reload the policy since the use does not have access to it
        //
        ret = await insapi.xget('/api/v1/policy/' + encodeURIComponent(this.policy.policy_id));
        return this._policyAPIReturn(ret);
    }

    /**
     * Uses deposit to make payment. Shows spinner and error messages. 
     * @param amount [unpaid balance] Amount to be deducted from deposit
     * @returns true when done, false when parameters are invalid
     */
    async __payCash(amount?: number, depositId?: string) {
        return await this.__api_wrapper(async () => await this.payCash(amount, depositId));
    }

    /**
     * Adds the current policy to cart
     * @returns true when done, false when parameters are invalid
     * @throws When error is encountered
     */
     async addToCart(subId?: string) {
        if (!this.policy || !this.policy.payment) return false;
        // const data = {payment_id: this.policy.payment_id, sub_id: subId||''}
        // let ret = await insapi.xpost('/api/v1/payment/cart', data);
        let ret = await insapi._cart_add(this.policy.payment_id, subId);
        ret = await insapi.xget('/api/v1/policy/' + encodeURIComponent(this.policy.policy_id));
        return this._policyAPIReturn(ret);
    }


    /**
     * Adds the current policy to cart (Payment will have to be done by checking out cart)
     * @returns true when done, false when parameters are invalid
     */
     async __addToCart(subId: string) {
        return await this.__api_wrapper(async () => await this.addToCart(subId));
    }

    async viewKey(type: string = 'pview') {
        if (this.endorsement)
            return await insapi.xget('/api/v2/endorsement/view/key?endorsement_id=' + encodeURIComponent(this.endorsement?.endorsement_id) + '&type=' + encodeURIComponent(type));
        if (!this.policy?.policy_id) return;
        return await insapi.xget('/api/v1/policy/view/key?policy_id=' + encodeURIComponent(this.policy?.policy_id) + '&type=' + encodeURIComponent(type));
    }

    /**
     * Extracts payment key to be sent to the third-parties (for online payments)
     * @returns Key
     * @throws When error is encountered
     */
    async paymentKey() {
        if (this.endorsement && this.endorsement?.epayment?.data.amount > 0) {
            return await insapi.xget('/api/v2/epayment/key?payment_id=' + encodeURIComponent(this.endorsement?.epayment_id));
        }
        if (this.policy && +this.policy.payment.data.amount > 0) {
            return await insapi.xget('/api/v1/payment/key?payment_id=' + encodeURIComponent(this.policy.payment_id));
        }
        return '';
    }

    /**
     * Finalizes payment stage and moves to the next stage.
     * @returns true when done, false when parameters are invalid
     */
    async finalizePayment() {
        return await this.finalize('payment');
    }

    /**
     * Launch a URL to download the PDF generated (after finalizing module)
     * @param modName Name of the module
     * @param pdfName [modName+'.pdf'] Name of the PDF
     */
    downloadPDF(modName: string, pdfName?: string, certId?: string, id: string='') {
        if (!modName) return;
        let path = '/api/v1/';
        if (modName == 'eproposal' || modName == 'endorsement') path = '/api/v2/';

        if (!id) {
            if (modName == 'eproposal' || modName == 'endorsement') {
                if (!this.endorsement) return;
                id = this.endorsement[modName + '_id'];
            } else {
                if (!this.policy) return;
                id = this.policy[modName + '_id'];
            }
        }

        // const id = this.policy[modName + '_id'];
        if (certId) {
            console.log(insapi.server + path + modName + '/data/' + encodeURIComponent(id) + 
            '?data_id=' + encodeURIComponent(certId)+'&download=1&token=' + encodeURIComponent(insapi.getToken()));
            this.__redirect_to(insapi.server + path + modName + '/data/' + encodeURIComponent(id) + 
                '?data_id=' + encodeURIComponent(certId)+'&download=1&token=' + encodeURIComponent(insapi.getToken()));
        } else {
            const dataType = pdfName || modName + '.pdf';
            this.__redirect_to(insapi.server + path + modName + '/data/' +
                encodeURIComponent(id) +
                '/' + encodeURIComponent(dataType) +
                '/' + encodeURIComponent(dataType) +
                '?download=1&token=' + encodeURIComponent(insapi.getToken()));
        }
    }

    /**
     * Launch a URL to download the Input template as XLSX file.
     */
    downloadInputTempate() {
        if (this.policy)
            this.__redirect_to(insapi.server + '/api/v1/quote/download/' + encodeURIComponent(this.policy.quote_id) +
                '?token=' + encodeURIComponent(insapi.getToken()));
    }

    async sendMessage(status: string, reason: string, type: string, extId?: string, code?: string){
        if (!await this.__save_if_dity(type)) return false;

        let obj: any = this.endorsement || this.policy

        if (!obj) return false;
        type = type || 'nstp';
        const data: any = {
            nstp_id: '',
            reason: reason,
            ext_id: extId || '',
            nstp_status: status,
            status_code: code || ''
        };

        if (type == 'inspect' && obj.inspect) {
            data.inspect_id = obj.inspect.inspect_id;
            data.insp_status = status;
            delete data.nstp_status;
            delete data.nstp_id;
        }
        if (type == 'qnstp' && obj.qnstp) data.nstp_id = obj.qnstp.nstp_id;
        if (type == 'pnstp' && obj.pnstp) data.nstp_id = obj.pnstp.nstp_id;
        if (type == 'nstp'  && obj.nstp ) data.nstp_id = obj.nstp.nstp_id;
        if (type == 'enstp' && obj.qnstp) data.nstp_id = obj.enstp.nstp_id;
        
        let ret = await insapi.xreq((this.endorsement?'/api/v2/':'/api/v1/') + type + '/message', 'POST', data, undefined, 2);
        // if (ret.txt && ret.txt.toLowerCase().indexOf('assigned to') >= 0) insapi.showMessage(ret.txt, 1);
        if (ret.txt) insapi.showMessage(ret.txt, 1);
        if (ret.status != 0) return false;
        
        //assignments happen after finalize and assignedto tag gets updated after data return to client. Need to reload the object.
        if (this.endorsement)
            await this.__load_endorsement(ret.data?.[0]?.endorsement_id);
        else
            await this.load(ret.data?.[0]?.policy_id);
        return true;

        //return this.endorsement?this._endorsementAPIReturn(ret.data):this._policyAPIReturn(ret.data);
    }

    async __nstp_message(status: string, reason: string, type?: string): Promise<boolean>{
        return await this.__api_wrapper(async () => await this.sendMessage(status, reason, type || 'nstp'));
    }

    async __check_dirty() {
        if (!this.dirty) return true;
        let ret = await insapi.showMessage("You have made changes to the policy, would you like to save and continue?", 2);
        console.log('__check_dirty: ', ret);
        return ret;
    }

    async __save_if_dity(type?: string) {
        if (!this.dirty) return true;
        let ret = await insapi.showMessage("You have made changes to the policy, would you like to save and continue?", 2);
        if (!ret) return false; // use chosen not to save
        if (type == 'qnstp') await this.__saveQuote(this.policy?.quote?.data);
        else if (type == 'pnstp') {
            if (this.endorsement) 
                await this.saveEndorsementProposal(this.endorsement.eproposal.data); 
            else await this.__saveProposal(this.policy?.proposal?.data);
        }
        return true;
    }

    /**
     * Approve a pending NSTP policy
     * @param reason Approval reason
     * @param type [nstp] Type of NSTP (nstp, qnstp, pnstp)
     * @returns true when done, false when parameters are invalid
     * @throws When error is encountered
     */
    async approve(reason: string, type?: string): Promise<boolean>{
        return this.sendMessage('approved', reason, type || 'nstp');
    }

    async __approve(reason: string, type?: string): Promise<boolean>{
       /*  if (!await this.__check_dirty()) 
            return false;
        else { */
            if (type == 'qnstp') await this.__saveQuote(this.policy?.quote?.data);
            else if (type == 'pnstp') {
                if (this.endorsement) 
                    await this.saveEndorsementProposal(this.endorsement.eproposal.data); 
                else await this.__saveProposal(this.policy?.proposal?.data);
            }
        //}
        return await this.__api_wrapper(async () => await this.approve(reason, type));
    }

    /**
     * Approve a pending NSTP policy
     * @param reason Approval reason
     * @param type [nstp] Type of NSTP (nstp, qnstp, pnstp)
     * @returns true when done, false when parameters are invalid
     * @throws When error is encountered
     */
    async reject(reason: string, type?: string, code?: string): Promise<boolean> {
        // if (!await this.__check_dirty()) return false;
        if (!await this.__save_if_dity(type)) return false;
        return this.sendMessage('rejected', reason, type || 'nstp', undefined, code||'');
    }
    async __reject(reason: string, type?: string, code?: string): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.reject(reason, type, code));
    }


    /**
     * Get the messages exchanged between users for a policy
     */
    async messages() {
        if (!this.policy || !this.policy.policy_id) return [];
        return await insapi.xget('/api/v1/policy/note/' + encodeURIComponent(this.policy.endorsement_id || this.policy.policy_id));
    }

    /**
     * Change the current policy's ownership.
     * @param entity New owner
     * @param subject Subject
     * @param message Message
     * @throws When error is encountered
     */
    async assignTo(entity: string, subject: string, message: string, notify_template: string): Promise<boolean> {
        if (!this.policy/* || !entity*/) return false;
        await insapi.xpost('/api/v1/policy/note', {policy_id: this.policy.policy_id, subject, message, assign_to: entity, notify_template: notify_template});
        if (entity && entity!='group') {
            let assTo = entity.startsWith('G00')? insapi.groupName(entity) : entity;
            await insapi.showMessage("Assigned to "+assTo, 0);
        }
        return true;
    }

    async __assignTo(entity: string, subject: string, message: string, notify_template?: string): Promise<boolean> {
        if (!await this.__check_dirty()) return false;
        return await this.__api_wrapper(async () => await this.assignTo(entity, subject, message, notify_template || ''));
    }


    /**
     * Change already finalized proposal. Not all fields can be amended, only those marked
     * in the product as amenable are updated, rest are discarded.
     * @param data 
     * @throws When errors encountered
     * @returns true when completed
     */
    async amendProposal(data: IData) {
        if (!this.policy || !this.policy.proposal_id) return false;
        return this._policyAPIReturn(await insapi.xpost('/api/v1/proposal/amend', {...data, proposal_id: this.policy.proposal_id}));
    }

    /**
     * Change already finalized proposal. Not all fields can be amended, only those marked
     * in the product as amenable are updated, rest are discarded. Shows spinner and error messages.
     * Shows spinner and error messages
     * @param data 
     * @returns true when done, false in case of error
     */
    async __amendProposal(data: IData) {
        return await this.__api_wrapper(async () => await this.amendProposal(data));
    }

    /**
     * Send OTP to customer (email/mobile no) to register their acceptance
     * @param type [proposal-accept] OTP type, must be one of [proposal-accept, proposal-otp-reject]
     * @throws When errors encountered
     * @returns true when completed
     */
    async sendProposalOTP(type?: string) {
        if (this.endorsement) {
            if (!this.endorsement.eproposal_id) await this.saveEndorsementProposal(this.endorsement.eproposal.data); //save eproposal when starts with otp
            return await insapi.xpost('/api/v2/eproposal/otp', {eproposal_id: this.endorsement.eproposal_id, otp_type: type || 'proposal-accept'});
        }
        if (!this.policy || !this.policy.proposal_id) return false;
        return await insapi.xpost('/api/v1/proposal/otp', {proposal_id: this.policy.proposal_id, otp_type: type || 'proposal-accept'});
    }

    /**
     * Send OTP to customer (email/mobile no) to register their acceptance. Shows spinner and error messages
     * @param type [proposal-accept] OTP type, must be one of [proposal-accept, proposal-otp-reject]
     * @returns true when done, false in case of error
     */
    async __sendProposalOTP(type?: string) {
        return await this.__api_wrapper(async () => await this.sendProposalOTP(type));
    }

    /**
     * Marks the proposal accepted by the customer when OTP matches with the one sent with the matching type
     * @param otp 
     * @param type 
     * @throws When errors encountered
     * @returns true when completed
     */
    async verifyProposalOTP(otp: string, type?: string) {
        let ret;
        if (this.endorsement?.eproposal_id) {
            ret = await insapi.xpost('/api/v2/eproposal/otp_verify', {eproposal_id: this.endorsement.eproposal_id, otp, otp_type: type || 'proposal-accept'});
        }else {
            if (!this.policy || !this.policy.proposal_id) return false;
            ret = await insapi.xpost('/api/v1/proposal/otp_verify', {proposal_id: this.policy.proposal_id, otp: otp, otp_type: type || 'proposal-accept'});
        }
        this.endorsement?this._endorsementAPIReturn(ret):this._policyAPIReturn(ret); 
        return ret;
    }

    /**
     * Marks the proposal accepted by the customer when OTP matches with the one sent with the matching type. 
     * Show spinner and error messages
     * @param otp 
     * @param type 
     * @returns true when done, false in case of error
     */
    async __verifyProposalOTP(otp: string, type?: string) {
        return await this.__api_wrapper(async () => await this.verifyProposalOTP(otp, type));
    }

    /**
     * Send module PDF's as email/SMS to customer email (from the parameter or from module data).
     * Shows spinner and error messages
     * @param modName [quote] Name of the module [quote, proposal, payment, policy]
     * @param email Alternate email (optional)
     * @param mobileNo Alternate mobile number (optional)
     * @throws When errors encountered
     * @returns true when completed
     */
    async sendEmail(modName?: string, email?: string, mobileNo?: string, params?: NameValue) {
        if (!this.policy) return false;
        modName = modName || 'quote';
        let modId = this.policy[modName + '_id'];
        let url = '/api/v1/' + modName + '/email/' + encodeURIComponent(modId);
        return await insapi.xpost(url, {...(params||{}), email: email||'', mobile_no: mobileNo||''});
    }

    /**
     * Send module PDF's as email/SMS to customer email (from the parameter or from module data).
     * @param modName [quote] Name of the module [quote, proposal, payment, policy]
     * @param email Alternate email (optional)
     * @param mobileNo Alternate mobile number (optional)
     * @returns true when done, false in case of error
     */
    async __sendEmail(modName?: string, email?: string, mobileNo?: string, params?: NameValue) {
        return await this.__api_wrapper(async () => await this.sendEmail(modName, email, mobileNo, params));
    }

    /**
     * Counter offer letter: restart the current workflow from the proposal stage.
     * @throws When errors encountered
     * @returns true when completed
     */
    async makeCounterOffer() {
        if (!this.policy || !this.policy.proposal_id) return false;
        return await insapi.xpost('/api/v1/proposal/counteroffer', {proposal_id: this.policy.proposal_id});
    }

    /**
     * Counter offer letter: restart the current workflow from the proposal stage. Shows spinner and erro messages
     * @returns true when done, false in case of error
     */
    async __makeCounterOffer() {
        return await this.__api_wrapper(async () => await this.makeCounterOffer());
    }

    /**
     * Set additional third-party (PAS, ERP) policy number to this policy object. Shows spinner and error messages
     * @param tpPolicyNo 
     * @throws When errors encountered
     * @returns true when completed
     */
    async setTPPolicNumber(tpPolicyNo: string) {
        if (!this.policy || !this.policy.covernote_id) return false;
        return this._policyAPIReturn(await insapi.xpost('/api/v1/covernote/tp_policy_no', {covernote_id: this.policy.covernote_id, tp_policy_no: tpPolicyNo}));
    }

    /**
     * Set additional third-party (PAS, ERP) policy number to this policy object.
     * @param tpPolicyNo 
     * @returns true when completed false in case of error
     */
    async __setTPPolicNumber(tpPolicyNo: string) {
        return await this.__api_wrapper(async () => await this.setTPPolicNumber(tpPolicyNo));
    }

    /**
     * Upload signed document as part of proposal acceptance (by the insurer).
     * @param evfile 
     * @param fieldName ['signed_proposal'] named of uploaded (signed) document
     * @param fileType 
     * @returns true when completed
     * @throws When errors encountered
     */
    async uploadSignedProposal(evfile: any, fieldName?: string, fileType?: string) {
        if (!this.policy || !this.policy.proposal_id || !evfile) return false;

        const formData = new FormData();
        formData.append('proposal_id', this.policy.proposal_id);
        formData.append('field_name', fieldName || 'signed_proposal');
        if (fileType) formData.append('file_type', fileType);
        formData.append('name', evfile.name);
        formData.append('file', evfile, evfile.name);

        return this._policyAPIReturn(await insapi.xpost('/api/v1/iagree/attach', formData));
    }

    /**
     * Upload signed document as part of proposal acceptance (by the insurer).
     * @param fieldName 
     * @param evfile 
     * @param fileType 
     * @returns true when done, false in case of error
     */
    async __uploadSignedProposal(evfile: any, fieldName?: string, fileType?: string) {
        return await this.__api_wrapper(async () => await this.uploadSignedProposal(evfile, fieldName, fileType));
    }


    /**
     * Accept the terms and conditions (IAgree module).
     * @returns true when completed
     * @throws When errors encountered
     */
    async acceptTermsAndConditions() {
        if (!this.policy || !this.policy.proposal_id) return false;
        return this._policyAPIReturn(await insapi.xpost('/api/v1/iagree/accept', {proposal_id: this.policy.proposal_id}));
    }

    /**
     * Accept the terms and conditions (IAgree module). Shows spinner and error messages
     * @returns true when done, false in case of error
     */
    async __acceptTermsAndConditions() {
        return await this.__api_wrapper(async () => await this.acceptTermsAndConditions());
    }

    async createEMISchedule(emiCount: number) {
        if (!this.policy || !this.policy.payment_id) return false;
        const data = {
            payment_id: this.policy.payment_id,
            emi_count: emiCount || 12
        }
        return this._policyAPIReturn(await insapi.xpost('/api/v1/payment/installments', data));
    }
    async __createEMISchedule(emiCount: number) {
        return await this.__api_wrapper(async () => await this.createEMISchedule(emiCount));
    }

    async workflow(wfId?: string): Promise<IWorkflow|null> {
        if (wfId) return await insapi.workflow(wfId);
        if (this.policy) {
            if (this.product?.data.b2c_wf_id == this.policy.wf_id && this.product?.b2c_wf)
                return this.product?.b2c_wf;
            return await insapi.workflow(this.policy.wf_id);
        }
        return null;
    }

    async addMetaData(data: any) {
        let meta = {};
        if (this.endorsement)  meta = { ...meta, ...this.endorsement.data?.meta};
        else if (this.policy && this.policy.policy) meta = { ...meta, ...this.policy.policy.data.meta};
        else return;
        meta = { ...meta, ...data};
        return await this.__api_wrapper(async () => {
            if (this.endorsement && this.endorsement.endorsement) {
                let ret = await insapi.xpost('/api/v2/endorsement/meta', {endorsement_id: this.endorsement.endorsement_id, meta});
                if (ret) this._endorsementAPIReturn([ret]);
            }
            else if (this.policy && this.policy.policy) {
                this.policy.policy.data = await insapi.xpost('/api/v1/policy/meta', {policy_id: this.policy.policy_id, meta});
                this.policy = JSON.parse(JSON.stringify(this.policy));
                this.changeSubject.next(this.policy);
            }
        });
    }

    async documents(type: string='') {
        if (type == 'inspect') {
            return this.product?.data.inspect_documents || [];    
        }
        if (this.endorsement) return this.endProduct?.data?.documents || [];
        return this.product?.data.documents || [];
    }

    _update_endorsement_statuses() {
        let old = this.statuses || {};
        let changed = false;
        this.statuses = {};
        for (const entry of this.endorsement?.stage_status || []) {
            changed = changed || (old[entry.stage] != entry.status);
            this.statuses[entry.stage] = entry.status || '';
        }
        
        let nstp = this.endorsement?.enstp;
        let flag = this.endProduct?.data.enstp_flag;
        let nvalue = this.endProduct?.data.qnstp_value;
        this.referral = false;
        if (nstp) {
            this.referral = nvalue == this.endorsement?.eproposal?.data[flag];
            console.log('ereferral:', this.referral, 'expected:', nvalue, 'found:', this.endorsement?.eproposal?.data[flag]);
            if (this.referral && nstp.nstp_status === 'approved') {
                this.referral = false;
            }
        }
        return changed;
    }
    _update_endorsement_premium() {

        if (!this.endorsement) return;
        this.endorsement.total_amount = 0;
        if (this.endorsement.eproposal?.data)
            this.endorsement.total_amount = +this.endorsement.eproposal.data.premium_value + +this.endorsement.eproposal.data.total_tax + +(this.endorsement.eproposal.data.charges?.total||0);
        
        if (this.endorsement.inspect?.data)
            this.endorsement.total_amount = +this.endorsement.inspect.data.premium_value + +this.endorsement.inspect.data.total_tax + +(this.endorsement.inspect.data.charges?.total||0);
            
        if (isNaN(this.endorsement.total_amount)) {
            console.log(this.endorsement.total_amount, 'is not a number');
            this.endorsement.total_amount = 0;
        }
    }



    async saveEndorsementProposal(data: IData) {
        data = this._merge_data(this.endorsement?.eproposal.data, data);
        if (this.endorsement?.eproposal_id) data['eproposal_id'] = this.endorsement.eproposal_id;
        let ret = await insapi.xreq('/api/v2/eproposal', 'POST', data, undefined, 2);
        if (ret?.txt /*&& ret.txt.toLowerCase().indexOf('assigned') >= 0*/) insapi.showMessage(ret.txt, 1);
        return this._endorsementAPIReturn(ret.data);
    }

    async __saveEndorsementProposal(data: IData): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.saveEndorsementProposal(data));
    }

    async saveAndFinalizeEndorsementProposal(data: IData): Promise<boolean> {
        return await this.saveEndorsementProposal({...data, __finalize: 1});
    }

    async __saveAndFinalizeEndorsementProposal(data: IData): Promise<boolean> {
        return await this.__api_wrapper(async () => await this.saveEndorsementProposal({...data, __finalize: 1}));
    }

    __update_row_history(field_name: any, idx: number){
        if (idx>=0) {
            let f_name = field_name.endsWith('_tmpl')?field_name.substring(0, field_name.length-5):field_name;
            this.ehistory[field_name] = this.ehistory?.[f_name]?.[idx];
        }
        return this.ehistory?.[field_name];
    }

    async addSubProduct(productId: string) {
        if (!this.policy?.quote_id) return;
        let ret = await insapi.xreq('/api/v1/quote/sub_product', 'POST', {quote_id: this.policy?.quote_id, sub_product_id: productId}, undefined, 1);
        return this._policyAPIReturn(ret.data);
    }

    async __addSubProduct(productId: string) {
        return await this.__api_wrapper(async () => await this.addSubProduct(productId));
    }

    async removeSubProduct(productId: string) {
        if (!this.policy?.quote_id) return;
        let url = '/api/v1/quote/sub_product?quote_id=' + encodeURIComponent(this.policy.quote_id) + '&sub_product_id=' + encodeURIComponent(productId);
        // let ret = await insapi.xreq(url, 'DELETE', undefined, undefined, true);
        // return this._policyAPIReturn(ret.data);
        return this._policyAPIReturn(await insapi.__xdel(url));
    }

    async __removeSubProduct(productId: string) {
        return await this.__api_wrapper(async () => await this.removeSubProduct(productId));
    }

    async __form_fill(evfile: any, save: boolean = false) {
        const formData = new FormData();
        formData.append('name', evfile.name);
        formData.append('file', evfile, evfile.name);
        return await insapi.xpost('/api/v1/auote/attach/fillin', formData);
    }

    async __merge_imported(jdata: any) {
        if (!this.policy) return;
        let data = this.policy.quote.data;
        for (let key in jdata.common || {}) data[key] = jdata.common[key];
        for (let key in jdata) {
            if (jdata[key] instanceof Array) {
                data[key] = jdata[key];
                console.log('merge:', key, data[key]);
            }
        }
        this.__fix_data_date(data);
        this.policy = structuredClone(this.policy);
        this.changeSubject.next(this.policy);
    }
}
