<div>
    <mat-form-field *ngIf="field.input">
        <input matInput [(ngModel)]="paramValue" [title]="field.title ?? '' | generic2: field.title_format" 
            [placeholder]="field.label||''" 
            type="text" 
            [attr.data-iid]="field.field_name">
        <button mat-icon-button matSuffix (click)="seek()"><mat-icon>search</mat-icon></button>
    </mat-form-field>
    <button mat-button ibutton *ngIf="!field.input" (click)="seek()" [attr.data-iid]="field.field_name">{{field.label}}</button>
</div>
