import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from 'rxjs';
import { IField } from '../../form.interface';
import { map, switchMap, startWith} from 'rxjs/operators';
import { insapi, IPolicy } from 'insapi';

@Component({
    selector: 'i-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {
    @ViewChild("acinput") selectInput!: ElementRef;
    @Output() onChange = new EventEmitter();
    data: any = {};
    field!: IField;
    group!: FormGroup;
    control!: FormControl;
    policy!: IPolicy;
    search: string = '';

    __url: string = '';
    filteredOptions!: Observable<({name: string, value: string})[]>;
    constructor() { }

    ngOnInit(): void {
        if (this.control) this.filteredOptions = this.control.valueChanges.pipe(startWith(''), switchMap(value => this._filter(value)));
        // if (this.control) this.filteredOptions = this.control.valueChanges.pipe(startWith(''), map(value => this._filter2(value)));
    }

    async _init() {
        if (this.field?.multi) {
            if (!(this.control.value instanceof Array))this.control.setValue([this.control.value]);
        }

        if (this.field && this.field.options) {
            if (this.field.options instanceof Array ){
                if (typeof this.field.options[0] == 'string' || typeof this.field.options[0] == 'number')
                    this.field.options = this.field.options.map((x: any) => ({name: x, value: x}));
            } else {
                console.log('lookup: ', this.field.field_name, 'expected options to be an array');
            }
            if (this.field.options) {
                let empty = this.field.options?.filter((x: any) => x.value=='');
                if (empty.length > 1) this.field.options = this.field.options.filter((x: any) => x.value!=='');
            }
        }
    }

    async __options_from_field(): Promise<{name: string, value: string}[]> {
        if (!(this.field.options instanceof Array)) return []; // not worth processing
        if (typeof this.field.options[0] === 'string') {
            return this.field.options.map((x: any) => ({name: x, value: x}));
        }
        // expected to have an array of {name: string, value: string}
        return this.field.options ? this.field.options as {name: string, value: string}[] : [];
    }

    async __options_from_url(prefix: string): Promise<{name: string, value: string}[] | null> {
        if (!this.field.urlFunc) return [];

        // url could be an array of strings (in case of comma separated values of local variable)
        let url = this.field.urlFunc.call(this, this.data, this.policy);
        let data = url instanceof Array ? url : [];
        if (!(url instanceof Array)) {
            if (url.indexOf('?') >= 0) url += '&prefix=' + encodeURIComponent(prefix);
            else url += '?prefix=' + encodeURIComponent(prefix);
            if (this.__url === url) return null;
            try {data = await insapi._xget_cache(url);}
            catch (e) {console.log('failed to download url', url); return [];}
            this.__url = url;
        }

        if (!data) return[];
        if (typeof data[0] === 'string') return data.map((x: any) => ({name: x, value: x}));
        let nfld = this.field.source?.name || 'name';
        let vfld = this.field.source?.value || 'value';
        return data.map((x: any) => ({name: x[nfld], value: x[vfld] }));
    }


    async _download_options(prefix: string) {
        let data = this.field.urlFunc ? await this.__options_from_url(prefix) : await this.__options_from_field();
        if (data === null) return; // no change in data
        let empty = data.filter((x: any) => x.value=='');
        if (empty.length > 1) data = data.filter((x: any) => x.value!=='');
        this.field.options = data;
    }



    async _reload(prefix: string) {
        //if (!this.field.urlFunc) return;
        await this._download_options(prefix);
    }

    private async _filter(value: string): Promise<({name: string, value: string})[]> {
        await this._reload(value);
        if (!this.field || !this.field.options) return [];
        const filterValue = value?.toLowerCase();
        return this.field.options.filter(option => (option as any).value?.toLowerCase().includes(filterValue)) as {name: string, value: string}[];
    }

    changed(ev: any) {
        if (this.field.multi) {

        }
        this.onChange.emit(this.field);
    }

}
