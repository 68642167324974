<div class="ledger-wrapper">
    <div style="padding-bottom: 1em;"><span style="font-size: 1.4em;">{{depositName}}</span> {{balance}}</div>
    <div class="payment-ledger">
        <div>Payment date time</div>
        <div style="text-align: right;">Credit</div>
        <div style="text-align: right;">Debit</div>
        <ng-template ngFor let-grp [ngForOf]="ledger" >
            <div>{{grp.disp}}</div>
            <div></div>
            <div></div>

            <ng-template ngFor let-item [ngForOf]="grp.items" >
                <div class="ledger-item">{{item.u_ts | datetime}} {{item.module_id ? '*' : ''}}</div>
                <div class="ledger-item-deposit">
                    <span  *ngIf="item.amount>0" (click)="gotoPayment(item)">{{item.amount | currency}}</span>
                </div>
                <div style="text-align: right;" class="ledger-item-debit">
                    <span *ngIf="item.amount<=0 && !item.payment_id">{{item.amount | currency}}</span>
                    <span *ngIf="item.amount<=0 && item.payment_id" (click)="gotoPayment(item)" style="cursor: pointer;" >{{item.amount | currency}}</span>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>