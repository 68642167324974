<div [style.withflag]="showFlag" [ngClass]="showFlag ? 'withflag' : '' ">
    <div *ngIf="showFlag">
        <country [(ngModel)]="country" (change)="countryChanged($event)" dispField="" 
            [showFlag]="true"  [placeholder]="''"  [formControl]="countryControl" [iconMode]="true" [disabled]="disabled?true:false"
            ></country>
    </div>
    <div>
        <input matInput type="text" #mi
            [disabled]="disabled?true:false" [attr.disabled]="disabled?true:null" [(ngModel)]="(disabled?__fmt?.prefix:'') + fmtvalue" (input)="changed()" 
            [attr.aria-labelledby]="_formField ? _formField.getLabelId() : ''"
            (focusout)="focusOut($event)" (focusin)="focusIn($event)" style="width: auto; padding-left: 5px;">
    </div>
</div>
