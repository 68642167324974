
import { BenefitsComponent } from '../widgets/benefits/benefits.component';
import { ButtonComponent } from '../widgets/button/button.component';
import { CheckboxComponent } from '../widgets/checkbox/checkbox.component';
import { DateComponent } from '../widgets/date/date.component';
import { EmailComponent } from '../widgets/email/email.component';
import { FileComponent } from '../widgets/file/file.component';
import { HtmlComponent } from '../widgets/html/html.component';
import { InputComponent } from "../widgets/input/input.component";
import { LookupComponent } from "../widgets/lookup/lookup.component";
import { OutputsComponent } from '../widgets/outputs/outputs.component';
import { PasswordComponent } from '../widgets/password/password.component';
import { PeriodComponent } from '../widgets/period/period.component';
import { PolicyMetaDataComponent } from '../widgets/policy-meta-data/policy-meta-data.component';
import { PriceComponent } from '../widgets/price/price.component';
import { RadioComponent } from '../widgets/radio/radio.component';
import { ReadonlyComponent } from '../widgets/readonly/readonly.component';
import { PlanComponent } from '../widgets/plan/plan.component';
// mport { GridComponent } from '../widgets/grid/grid.component';
import { MasterPolicyComponent } from '../widgets/master-policy/master-policy.component';
import { DummyComponent } from '../widgets/dummy/dummy.component';
import { ButtonsComponent } from '../widgets/buttons/buttons.component';
import { ComboPolicyComponent } from '../widgets/combo-policy/combo-policy.component';
import { AutocompleteComponent } from '../widgets/autocomplete/autocomplete.component';
import { VerifyotpComponent } from '../widgets/verifyotp/verifyotp.component';  
import { MultiChoiceComponent } from '../widgets/multi-choice/multi-choice.component';
import { PolicySummaryComponent } from '../widgets/policy-summary/policy-summary.component';
import { EndorsementsComponent } from '../widgets/endorsements/endorsements.component';
import { CompanyComponent } from '../widgets/company/company.component';
import { GoalSeekComponent } from '../widgets/goal-seek/goal-seek.component';
// import { CoinsuranceComponent } from '../widgets/coinsurance/coinsurance.component';
// import { CustomerComponent } from '../widgets/customer/customer.component';
import { WordingsComponent } from '../widgets/wordings/wordings.component';  
import { SummaryComponent } from '../widgets/summary/summary.component';  
import { FetchComponent } from '../widgets/fetch/fetch.component';
import { IagreeComponent } from '../widgets/iagree/iagree.component';
import { InstallmentsComponent } from '../widgets/installments/installments.component';

export const components: {[key: string]: any} = {
    'string': InputComponent,
    input: InputComponent,
    lookup: LookupComponent,
    date: DateComponent,
    radio: RadioComponent,
    checkbox: CheckboxComponent,
    button: ButtonComponent,
    buttons: ButtonsComponent,
    password: PasswordComponent,
    html: HtmlComponent,
    file: FileComponent,
    email: EmailComponent,
    price: PriceComponent,
    meta: PolicyMetaDataComponent,
    period: PeriodComponent,
    readonly: ReadonlyComponent,
    benefits: BenefitsComponent,
    outputs: OutputsComponent,
    plan: PlanComponent,
    // grid: GridComponent,
    'master-policy': MasterPolicyComponent,
    'combo-policy': ComboPolicyComponent,
    autocomplete: AutocompleteComponent,
    dummy: DummyComponent,
    verifyotp: VerifyotpComponent,
    'multi-choice': MultiChoiceComponent,
    'policy-summary': PolicySummaryComponent,
    endorsement: EndorsementsComponent,
    company: CompanyComponent,
    goal: GoalSeekComponent,
    // customer: CustomerComponent,
    // coinsurance: CoinsuranceComponent
    wordings: WordingsComponent,
    summary: SummaryComponent,
    fetch: FetchComponent,
    iagree: IagreeComponent,
    installments: InstallmentsComponent
}
