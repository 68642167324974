<!-- <mat-form-field [formGroup]="group" *ngIf="field.multi">
  <mat-label>{{field.label||''}}</mat-label>
  <div *ngFor="let v of group.controls[field.field_name].value">{{v}}</div>
  <input type="text" [placeholder]="field.label||''" matInput [matAutocomplete]="auto" [title]="field.title" #acinput>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="changed($event)">
  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value" [attr.data-iid]="search">
    {{option.name}}
  </mat-option>
  </mat-autocomplete>

</mat-form-field> -->
<mat-form-field [formGroup]="group" *ngIf="!field.multi">
    <mat-label>{{field.label||''}}</mat-label>
    <input type="text" [placeholder]="field.label||''" matInput [formControlName]="field.field_name" 
      [matAutocomplete]="auto" [title]="field.title">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="changed($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value" [attr.data-iid]="field.field_name">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="control && validation.validator && control.hasError(validation.validator.name)">{{field.errors || (field.field_name | capitalize) + validation.message}}</mat-error>
  </ng-container>
  <mat-error *ngIf="control && control.hasError('ssValidator')">{{control.errors?.ssValidator?.msg}}</mat-error>
  <div class="input-sub-text" *ngIf="field.subtext && field.subtext.varname">{{field.subtext.desc}} {{data[field.subtext.varname] | generic2: field.subtext.pipe}}</div>

</mat-form-field>