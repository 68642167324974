<div class="price-table" *ngIf="payment">
    <div class="price-item">Premium</div>
    <div></div>
    <div data-oid="payment.premium">{{payment.premium_value | currency}}</div>
    
    <ng-template [ngIf]="+charges.total > 0">
        <div class="price-item">Charges</div>
        <div></div>
        <div></div>
        <ng-template ngFor let-part let-i="index" [ngForOf]="charges.charge_parts" >
            <div class="subitem">{{part.name}} <span>{{part.desc}}</span></div>
            <div class="subamount" [attr.data-oid]="part.name">{{part.value | currency}}</div>
            <div></div>
        </ng-template>
        <div class="subitem">Total charges</div>
        <div></div>
        <div data-oid="charges.total">{{charges.total | currency}}</div>

    </ng-template>
    

    <div class="price-item">Tax</div>
    <div></div>
    <div></div>

    <ng-template ngFor let-part let-i="index" [ngForOf]="details.tax_parts" >
        <div class="subitem">{{part.name}} <span>{{part.desc}}</span></div>
        <div class="subamount" [attr.data-oid]="part.name">{{part.value | currency}}</div>
        <div></div>
    </ng-template>
    <div class="subitem">Total tax</div>
    <div></div>
    <div [attr.data-oid]="'tax.total'">{{payment.total_tax | currency}}</div>

    <div class="price-item">Total paid</div>
    <div></div>
    <div [attr.data-oid]="payment.paid">{{payment?.total_recvd | currency}}</div>

    <div style="border-bottom: 1px solid #A0A0FF; width: 100%; height: 1px; grid-column: span 3;"></div>

    <div>To be paid</div>
    <div></div>
    <div [attr.data-oid]="payment.balance">{{(payment.total_amount - payment.total_recvd) | currency}}</div>
</div>
