<div *ngIf="readonly" class="plan-readonly">
    <div *ngIf="multiSelect">
        <table class="plans-table">
            <tr>
                <th></th>
                <th *ngFor="let plan of data.plans">{{plan.name}}</th>
            </tr>
            <tr *ngFor="let p of allparams">
                <td>{{p.label}}</td>
                <td *ngFor="let plan of data.plans">{{plan[p.param]}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="!multiSelect">
        <div style="color: #999;">Plan</div>
        <div class="plan-name">{{data['plan_name']}}</div>
        <div class="plan-desc">{{data['plan_desc']}}</div>
    </div>


</div>
<div  *ngIf="!readonly && field.orientation=='vertical'">
    <div>
        <div class="vert-mobile" *ngFor="let plan of policy.plans" [class.plan-selected]="data.plan_id == plan.plan_id" [class.plan-multi-selected]="selectedPlans[plan.plan_id]">
            <div class="plan-name" (click)="selectPlan(plan)">{{plan.plan_name}} <small class="plan-desc">{{plan.plan_desc}}</small></div>
            <div *ngFor="let col of allcolumns" class="vert-mobile-column">
                <ng-template [ngIf]="plan.param_map[col]">
                    <ng-template [ngIf]="editor == plan.param_map[col].plan_param_id">
                        <div *ngIf="plan.param_map[col].editable == 'Yes'" style="display: grid; grid-template-columns: 1fr 24px;">
                            <input class="param-input" type="text" [(ngModel)]="temp[plan.plan_id][col]" (change)="paramEdited(plan, col)" [attr.data-iid]="field.field_name+'-'+col">
                            <mat-icon fontSet="material-icons-outlined" class="param-icon" (click)="paramEdited(plan, col)" [attr.data-iid]="field.field_name+'-exit'">exit_to_app</mat-icon>
                        </div>
                        <div *ngIf="plan.param_map[col].editable == 'option'">
                        </div>
        
                    </ng-template>
                    <ng-template [ngIf]="editor != plan.param_map[col].plan_param_id">
                        <div [ngSwitch]="temp[plan.plan_id][col]" [class]="'param-edit-' + plan.param_map[col].editable " 
                            (click)="editParam(plan, col)" [title]="plan.param_map[col].editable == 'Yes' || plan.param_map[col].editable == 'option' ? 'Click to customize': '' ">

                            <div class="param-name">{{params[col]}}</div>
                            <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'Yes'" class="param-icon param-yes" >done</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'No'" class="param-icon param-no" >close</mat-icon>
                            <div class="param-value" *ngSwitchDefault>{{temp[plan.plan_id][col]}}</div>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="vert-desk">
        <div class="field-group-caption" style="padding-left: 1em;">{{field.label}}</div>
        <div style="display: grid; padding: 1em;" [style.grid-template-columns]="gridCols">
            <div></div>
            <div></div>
            <div *ngFor="let col of columns" style="text-align: center; color: #6666FF">
                {{params[col]}}
            </div>
        </div>

        <div class="vert-desk" *ngFor="let plan of policy.plans" class="plan" [class.plan-selected]="data.plan_id == plan.plan_id" [class.plan-multi-selected]="selectedPlans[plan.plan_id]" [style.grid-template-columns]="gridCols">
            <div style="width: 24px; height: 24px; cursor: pointer;" (click)="selectPlan(plan)">
                <mat-icon fontSet="material-icons-outlined" class="param-icon" *ngIf="data.plan_id == plan.plan_id || selectedPlans[plan.plan_id]" style="color: #FFF;">task_alt</mat-icon>
                <mat-icon fontSet="material-icons-outlined" class="param-icon" *ngIf="data.plan_id != plan.plan_id && !selectedPlans[plan.plan_id]" style="color: #888;">commit</mat-icon>
            </div>
            <div (click)="selectPlan(plan)">
                <div class="plan-name">{{plan.plan_name}}</div>
                <div class="plan-desc">{{plan.plan_desc}}</div>
            </div>
            <div *ngFor="let col of columns">
                <ng-template [ngIf]="plan.param_map[col]">
                    <ng-template [ngIf]="editor == plan.param_map[col].plan_param_id">
                        <div *ngIf="plan.param_map[col].editable == 'Yes'" style="display: grid; grid-template-columns: 1fr 24px;">
                            <input class="param-input" type="text" [(ngModel)]="temp[plan.plan_id][col]" (change)="paramEdited(plan, col)" [attr.data-iid]="field.field_name+'-'+col">
                            <mat-icon fontSet="material-icons-outlined" class="param-icon" (click)="paramEdited(plan, col)">exit_to_app</mat-icon>
                        </div>
                        
                        <div *ngIf="plan.param_map[col].editable == 'option'">
                            <mat-select class="param-input" [(ngModel)]="temp[plan.plan_id][col]" (selectionChange)="paramEdited(plan, col)" [attr.data-iid]="field.field_name+'-'+col">
                                <mat-option *ngFor="let v of plan.param_map[col].values" [value]="v">{{v}}</mat-option>
                            </mat-select>
                        </div>

                        <div *ngIf="plan.param_map[col].editable == 'constant'">
                            {{plan.param_map[col].editable}}
                        </div>

                    </ng-template>
                    <ng-template [ngIf]="editor != plan.param_map[col].plan_param_id">
                        <div [ngSwitch]="temp[plan.plan_id][col]" [class]="'param-edit-' + plan.param_map[col].editable " 
                            (click)="editParam(plan, col)" [title]="plan.param_map[col].editable == 'Yes' || plan.param_map[col].editable == 'option' ? 'Click to customize': '' "
                            [attr.data-iid]="field.field_name+'-edit-'+col">
                            <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'Yes'" class="param-icon param-yes" >done</mat-icon>
                            <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'No'" class="param-icon param-no" >close</mat-icon>
                            <div class="param-value" *ngSwitchDefault>{{temp[plan.plan_id][col]}}</div>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
            <div>
                <mat-icon fontSet="material-icons-outlined" class="param-more" (click)="showMore(plan.plan_id)" *ngIf="more[plan.plan_id]!=1">expand_more</mat-icon>
                <mat-icon fontSet="material-icons-outlined" class="param-more" (click)="more[plan.plan_id]=0" *ngIf="more[plan.plan_id]==1">expand_less</mat-icon>
            </div>
            <div *ngIf="more[plan.plan_id]==1" style="grid-column: 1/-1; padding: 1em;" class="grid-3" @animShowHide>
                <div *ngFor="let col of hidden" style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
                    <div class="param-value">{{params[col]}}</div>
                    <div [ngSwitch]="temp[plan.plan_id][col]" style="text-align: center;" (click)="editParam(plan, col)">
                        <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'Yes'" class="param-icon param-yes">done</mat-icon>
                        <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'No'" class="param-icon param-no" style="color: #AA0000">close</mat-icon>
                        <div class="param-value" *ngSwitchDefault>{{temp[plan.plan_id][col]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div  *ngIf="!readonly && field.orientation=='horizontal'" class="bg-curve">
    <div [class]="'grid-' + plans.length" style="align-items: baseline;">
        <div *ngFor="let plan of plans"  class="plan-horz">
            <div class="plan-horz-container" [class.plan-selected]="data.plan_id == plan.plan_id" [class.plan-not-selected]="data.plan_id != plan.plan_id" [class.plan-multi-selected]="selectedPlans[plan.plan_id]">
                <div class="plan-horz-name">{{plan.plan_name}}</div>

                <div style="text-align: center; padding: 0em 1em 1em 1em;" *ngIf="field.hide_unselected=='Yes'">
                    <button mat-flat-button color="warn" (click)="selectPlan(plan)" *ngIf="data.plan_id != plan.plan_id && !selectedPlans[plan.plan_id]" style="height:24px; width:24px; line-height: 24px; font-size:13px;">Select</button>
                    <button mat-flat-button color="warn" (click)="selectPlan(plan)" *ngIf="data.plan_id == plan.plan_id || selectedPlans[plan.plan_id]" style="height:24px; line-height: 24px; font-size:13px;">Un select</button>
                </div>

                <div class="plan-horz-desc">{{plan.plan_desc}}</div>

                <ng-template ngFor let-col [ngForOf]="columns">
                    <div *ngIf="field.hide_unselected!='Yes' || temp[plan.plan_id][col]!='No'">
                        <div style="display: grid; grid-template-columns: auto 1fr; padding: 1em 1em 0em 1.5em;">
                            <ng-template [ngIf]="plan.param_map[col] && editor == plan.param_map[col].plan_param_id">
                                <div *ngIf="plan.param_map[col].editable == 'Yes'" style="display: grid; grid-template-columns: 1fr 24px; grid-column: span 2;">
                                    <input class="param-input" type="text" [(ngModel)]="temp[plan.plan_id][col]" (change)="paramEdited(plan, col)">
                                    <mat-icon fontSet="material-icons-outlined" class="param-icon" (click)="paramEdited(plan, col)">exit_to_app</mat-icon>
                                    <div>{{params[col]}}</div>
                                </div>
                                <div *ngIf="plan.param_map[col].editable == 'option'">
                                </div>            
                            </ng-template>
                            <ng-template [ngIf]="plan.param_map[col] && editor != plan.param_map[col].plan_param_id">
                                <div [ngSwitch]="temp[plan.plan_id][col]" [class]="'param-edit-' + plan.param_map[col].editable " 
                                    (click)="editParam(plan, col)" [title]="plan.param_map[col].editable == 'Yes' || plan.param_map[col].editable == 'option' ? 'Click to customize': '' ">
                                    <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'Yes'" class="param-icon param-yes">done</mat-icon>
                                    <mat-icon fontSet="material-icons-outlined" *ngSwitchCase="'No'" class="param-icon param-no">close</mat-icon>
                                    <div class="param-value" *ngSwitchDefault>{{temp[plan.plan_id][col]}}</div>
                                </div>
                                <div class="param-value" style="padding-left: 1em;">{{params[col]}}</div>
                            </ng-template>
                        </div>
                    </div>
                </ng-template>
    

                <div style="text-align: center; padding: 2em;"  *ngIf="field.hide_unselected!='Yes'">
                    <button mat-flat-button color="warn" (click)="selectPlan(plan)" *ngIf="data.plan_id != plan.plan_id && !selectedPlans[plan.plan_id]" style="height:24px; width:24px; line-height: 24px; font-size:13px;">Select</button>
                    <button mat-flat-button color="warn" (click)="selectPlan(plan)" *ngIf="data.plan_id == plan.plan_id || selectedPlans[plan.plan_id]" style="height:24px; line-height: 24px; font-size:13px;">Remove</button>
                </div>
    
            </div>
        </div>
    </div>

    <div style="display: grid; grid-template-columns: 1fr 1fr; padding-top: 1em; box-sizing: border-box; align-items: center;">
        <div [style.color]="pstart>0?'#F61':'#AAA'" (click)="prev()">
            <div style="text-align: left; display: flex; align-items: center;" *ngIf="pstart>0"><mat-icon fontSet="material-icons-outlined">chevron_left</mat-icon> Prev</div>
        </div>
        <div style="display: grid; grid-template-columns: 1fr auto;" [style.color]="pstart+pmax<policy.plans.length?'#F61':'#AAA'" (click)="next()">
            <div></div><div *ngIf="pstart+pmax<policy.plans.length" style="display: flex; align-items: center;">Next <mat-icon fontSet="material-icons-outlined">chevron_right</mat-icon></div>
        </div>
    </div>
</div>
