import { Component, OnInit, Input } from '@angular/core';
import { Router, Route } from "@angular/router";
import { insapi, IProfile } from 'insapi';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { HomeComponent } from '../../home/home.component';
import { environment } from './../../../../environments/environment';

@Component({
    selector: 'uw-summary',
    templateUrl: './uw-summary.component.html',
    styleUrls: ['./uw-summary.component.scss']
})
export class UwSummaryComponent implements OnInit {

    data: any = { period: moment().add(-7, 'day').format('YYYY-MM-DD') + ',' + moment().format('YYYY-MM-DD 23:59:59') };
    pfield: any = { field_name: 'period' };
    groups: any = [];
    groups_non_rater: any = [];
    approved_groups: any = [];
    approved_groups_non_rater: any = [];
    rejected_groups: any = [];
    rejected_groups_non_rater: any = [];
    query_groups: any = [];
    query_groups_non_rater: any = [];
    segment_validation: boolean=false;
    profile: IProfile | null = null;
    dashboard: any = [];
    subscription: Subscription | null = null;
    constructor(private router: Router, public home: HomeComponent) { }

    ngOnInit(): void {
        this.subscription = insapi.profileSubject.subscribe((profile: IProfile | null) => {
            this.profile = profile;
        });
        let non_rater_segment = environment.vendor?.non_rater_segment;
        this.dashboard=environment.vendor['dashboard']?.['list'];
        console.log("dashboard=>",this.dashboard);
        console.log("non_rater_segment=>",non_rater_segment);
        if(non_rater_segment) {
            if(this.profile && this.profile.data.non_rater_segment != undefined && this.profile.data.non_rater_segment != '') this.segment_validation=true;
        }
        console.log("this.segment_validation=>"+this.segment_validation);
        this.__init();
    }

    async __pending_at(filter: string, product_type: string, nstp_status: string) {
        let data = {};
        if (nstp_status == 'pending') {
            data = {
                cfields: 'module_id pcount',
                u_ts: '[]' + this.data.period,
                group: 'assigned_to',
                fmt: 'array',
                nstp_status: '',
                filter,
                "quote.status": 0
            };

        } else if (nstp_status == 'approved' || nstp_status == 'Query by UW') {
            data = {
                "u_ts": '[]' + this.data.period,
                "assigned_to_team_all": 1,
                "qnstp.nstp_status": nstp_status,
                "assigned_to": "",
                "fmt": "csv",
                "fields": "qnstp.nstp_status,policy_id,assigned_to,qnstp.author approved_by",
                "quote.status": 0
            };
        }  else if (nstp_status == 'rejected') {
            data = {
                cfields: 'module_id pcount',
                u_ts: '[]' + this.data.period,
                group: 'assigned_to',
                fmt: 'array',
                nstp_status: nstp_status,
                filter,
                "quote.status": 4
            };
        }
        if (this.segment_validation && product_type == 'Rater') {
            data['quote.data.product_name'] = "!Non Rater";
        } else if (product_type == 'Non Rater') {
            data['quote.data.product_name'] = "Non Rater";
            data['quote.data.lob_name'] = "^" + this.profile.data.non_rater_segment
        }
        if(nstp_status == 'approved' || nstp_status == 'Query by UW') return await insapi.xpost('/api/v1/policy/list2', data);
        else return await insapi.xpost('/api/v1/policy/list3', data);
    }

    async __init() {
        //Pending - Rater product
        this.groups = [];
        let ret = await this.__pending_at('assigned_to_team_group', 'Rater', 'pending');
        for (let g of ret || []) {
            this.groups.push({ name: insapi.groupName(g.assigned_to), gid: g.assigned_to, count: g.pcount });
        }
        ret = await this.__pending_at('assigned_to_team', 'Rater', 'pending');
        for (let g of ret || []) {
            this.groups.push({ name: g.assigned_to, email: g.assigned_to, count: g.pcount });
        }

        // Pending Non Rater products
        this.groups_non_rater = [];
        if (this.segment_validation) {

            ret = await this.__pending_at('assigned_to_team_group', 'Non Rater', 'pending');
            for (let g of ret || []) {
                this.groups_non_rater.push({ name: insapi.groupName(g.assigned_to), gid: g.assigned_to, count: g.pcount });
            }
            ret = await this.__pending_at('assigned_to_team', 'Non Rater', 'pending');
            for (let g of ret || []) {
                this.groups_non_rater.push({ name: g.assigned_to, email: g.assigned_to, count: g.pcount });
            }
        }

        // Approved Rater products

        this.approved_groups = [];
       
        ret = await this.__pending_at('assigned_to_team', 'Rater', 'approved');
        this.approved_groups=this.duplicateCount(ret, 'approved_by');
       
        // Approved Non Rater products
        this.approved_groups_non_rater = [];
        if (this.segment_validation) {

            ret = await this.__pending_at('assigned_to_team', 'Non Rater', 'approved');
            this.approved_groups_non_rater=this.duplicateCount(ret, 'approved_by');
        }
        //Rejected - Rater product
        this.rejected_groups = [];
        
        ret = await this.__pending_at('assigned_to_team', 'Rater', 'rejected');
        for (let g of ret || []) {
            this.rejected_groups.push({ name: g.assigned_to, email: g.assigned_to, count: g.pcount });
        }

        // Rejected Non Rater products
        this.rejected_groups_non_rater = [];
        if (this.segment_validation) {

            ret = await this.__pending_at('assigned_to_team', 'Non Rater', 'rejected');
            for (let g of ret || []) {
                this.rejected_groups_non_rater.push({ name: g.assigned_to, email: g.assigned_to, count: g.pcount });
            }
        }

        // Query by UW Rater products

        this.query_groups = [];
       
        ret = await this.__pending_at('assigned_to_team', 'Rater', 'Query by UW');
        this.query_groups=this.duplicateCount(ret, 'approved_by');
       
        // Query by UW Non Rater products
        this.query_groups_non_rater = [];
        if (this.segment_validation) {

            ret = await this.__pending_at('assigned_to_team', 'Non Rater', 'Query by UW');
            this.query_groups_non_rater=this.duplicateCount(ret, 'approved_by');
        }
        //console.log('uws:', this.groups);
    }

    async showList(grp: any,product:any,nstp_status:any) {
        var assigned_to="assigned_to",segment_param=undefined;
        if(nstp_status == 'approved' || nstp_status == 'Query by UW') assigned_to='approved_by';
        if(product == 'Non Rater') segment_param=this.profile.data.non_rater_segment;
        if (this.segment_validation) {
            if (grp.gid) this.router.navigate(['/policy-quote', 'all'], { state: { asd: 1 }, queryParams: { "type": "team_group", [assigned_to]: grp.gid, "nstp_status": nstp_status, "data_period": "[]" + this.data.period, "product_name": product ,"non_rater_segment":segment_param} });
            else this.router.navigate(['/policy-quote', 'all'], { state: { asd: 1 }, queryParams: { "type": "team", [assigned_to]: grp.email, "nstp_status": nstp_status, "data_period": "[]" + this.data.period, "product_name": product,"non_rater_segment":segment_param} });
        } else {
            if (grp.gid) this.router.navigate(['/policy-quote', 'all'], { state: { asd: 1 }, queryParams: { "type": "team_group", [assigned_to]: grp.gid, "nstp_status": nstp_status, "data_period": "[]" + this.data.period } });
            else this.router.navigate(['/policy-quote', 'all'], { state: { asd: 1 }, queryParams: { "type": "team", [assigned_to]: grp.email, "nstp_status": nstp_status, "data_period": "[]" + this.data.period } });
        }

    }

    async periodChanged(ev: any) {
        console.log('periodChanged: ', ev, this.data.period);
        this.data.period = ev.min + ',' + ev.max + ' 23:59:59';
        await this.__init();
    }

    duplicateCount(arr: any[], key: string) {
        var arr2: any[];
        arr2=[];
        arr.forEach((x) => {
           if (arr2.some((val) => {
                return val[key] == x[key]
            })) {
                arr2.forEach((k) => {
                    if (k[key] === x[key]) {
                        k["count"]++
                    }
                })
            } else {
                let a = {}
                a[key] = x[key]
                a["count"] = 1
                a["email"] = x[key]
                a["name"] = x[key]
                arr2.push(a);
            }
        })

        return arr2
    }
}
