<div *ngIf="mode==0">
    <div *ngIf="entities.length==0">No events found</div>
    <div style="display: grid; grid-template-columns: 1fr auto; gap: 1em;">
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 1em;">
            <div *ngFor="let ent of entities" class="cal-month">
                <div class="cal-hdr">{{ent.disp}}</div>
                <div class="cal-evs">
                    <ng-template ngFor [ngForOf]="ent.events" let-ev>
                        <div>{{ev.ts}}</div> <div>{{ev.type}}</div><div class="ev-sub">{{ev.subject}}</div>
                    </ng-template>
                </div>
                <div class="cal-ftr"><spam *ngIf="ent.events.length>3" (click)="more=ent">...</spam></div>
            </div>
        </div>
        <div *ngIf="more" style="overflow: auto; max-height: 100%;">
            <div style="font-weight: 800; font-size: .9em; padding: .5em 0em;">{{more.disp}}</div>
            <div class="more-evs">
                <ng-template ngFor [ngForOf]="more.events" let-ev>
                    <div class="ev-ts">{{ev.note_ts}}</div><div class="ev-sub">{{ev.type}} - {{ev.subject}}</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
