import { trigger, state, style, transition, animate } from '@angular/animations';

export type fldoptions = {
    name: string;
    value: string;
    icon?: string;
}

export interface IField {
    field_name: string;
    label?: string;
    tip?: string;
    validate?: string;
    type: string;
    multi?: number;
    multi_maxlimit?: number;
    validations?: any[],
    span?: number;
    url?: string;
    source?: {[key: string]: any};
    options?: (fldoptions | string)[];
    [key: string]: any;
}

export interface IFieldGroup {
    name: string;
    desc?: string;
    if?: string | null;
    ifFunc?: any;
    fields: IField[];
    collapsed: boolean;
}

export interface ILayout {
    grids?: number;
    cls?: string;
    maxWidth?: string;
    width?: string;
    style?: {[key: string]: string};
    caption?: any;
}

export interface IForm {
    groups: IFieldGroup[];
    layout: ILayout
}

export const animShowHide = trigger('animShowHide', [
    state('void', style({height: '0px', overflow: 'hidden'})),
    state('*', style({height: '*', overflow: 'hidden'})),
    transition('* => void', animate('200ms ease-in-out')),
    transition('void => *', animate('200ms ease-in-out')),
]);

export const animShowHideOpacity = trigger('animShowHideOpacity', [
    state('void', style({opacity: '0'})),
    state('*', style({opacity: '1'})),
    transition('* => void', animate('0ms')),
    transition('void => *', animate('200ms')),
]);

export const animRotate = trigger('animRotate', [
    transition(':enter', [
        style({transform: 'rotate(-180deg)'}),
        animate('200ms ease-in', style({transform: 'rotate(0deg)'}))
    ]),
    transition(':leave', [
        style({opacity: 1, transform: 'rotate(0deg)'}),
        animate('100ms ease-in', style({opacity: 0, transform: 'rotate(180deg)'}))
    ])
]);

export const animToggle = trigger('animToggle', [
    state('true', style({ transform: 'rotate(0)', opacity: '1' })),
    state('false', style({ transform: 'rotate(-180deg)', opacity: '0' })),
    transition('void => *', animate('0ms ease-out')),
    transition('true => false', animate('300ms ease-out')),
    transition('false => true', animate('300ms ease-in')),
    transition('0 => 1', animate('1500ms ease-out')),
    transition('1 => 0', animate('400ms ease-in'))
])


export const animScaleX = trigger('animScaleX', [
    state('true', style({'transform' : 'scaleX(1)' })),
    state('false', style({'transform' : 'scaleX(0)' })),
    transition('false <=> true', animate(200))
]);

export function __set_data(data: any, field: any, value: any) {
    let fname = typeof field === 'string' ? field : field.field_name;
    if (field.setdata) return field.setdata.call(field, data, value);

    // console.log('set_date', name, value);
    if (!fname || fname.indexOf('.') < 0) {
        data[fname] = value;
        return;
    }
    let parts = fname.split('.');
    let obj = data;
    for (let i=0; i<parts.length-1; i++) {
        if (!obj[parts[i]]) obj[parts[i]] = {};
        obj = obj[parts[i]];
    }
    obj[parts[parts.length-1]] = value;
}

export function __get_data(data: any, field: any) {
    if (field.getdata) return field.getdata.call(field, data);

    if (!field.field_name || field.field_name.indexOf('.') < 0) return data[field.field_name];
    let parts = field.field_name.split('.');
    let obj = data;
    for (let i=0; i<parts.length-1; i++) {
        if (!obj[parts[i]]) obj[parts[i]] = {};
        obj = obj[parts[i]];
    }
    return obj[parts[parts.length-1]];
}

// export function __set_data(data: any, name: string, value: any) {
//     // console.log('set_date', name, value);
//     if (!name || name.indexOf('.') < 0) {
//         data[name] = value;
//         return;
//     }
//     let parts = name.split('.');
//     let obj = data;
//     for (let i=0; i<parts.length-1; i++) {
//         if (!obj[parts[i]]) obj[parts[i]] = {};
//         obj = obj[parts[i]];
//     }
//     obj[parts[parts.length-1]] = value;
// }

// export function __get_data(data: any, name: string) {
//     if (!name || name.indexOf('.') < 0) return data[name];
//     let parts = name.split('.');
//     let obj = data;
//     for (let i=0; i<parts.length-1; i++) {
//         if (!obj[parts[i]]) obj[parts[i]] = {};
//         obj = obj[parts[i]];
//     }
//     return obj[parts[parts.length-1]];
// }