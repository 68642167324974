import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IPolicy } from 'insapi';
import { Subscription } from 'rxjs';
import { IField } from '../../form.interface';

@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.compact.html',
    styleUrls: ['./benefits.component.compact.scss']
})
export class BenefitsComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    data: any = {};
    policy: any = {};
    benefits: any[] = [];
    si_name: string = '';
    plan_name: string = '';
    maxBenefits: number = 3;
    psubscription: Subscription | undefined = undefined;
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._init());
        // if (this.policy)
        //     this.psubscription = this.policy.policySubject.subscribe((policy: IPolicy|null) => this._init());
        this._init();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    async _init() {
        // get list of field names to be displayed
        // let cols = this.field.columns instanceof Array ? this.field.columns : this.field.columns;
        // cols = cols.split(',').trim().filter(Boolean);
        this.benefits = [];
        for (let fname in this.field.props) {
            if (fname == 'sum_insured') this.si_name = this.field.props[fname];
            else if (fname == 'plan_name') this.plan_name = this.field.props[fname];
            else if (this.data[fname] == 'Yes' || this.field.show_all == '1' )
                this.benefits.push({name: fname, disp: this.field.props[fname], value: this.data[fname]});
        }
        console.log('benefits: ', this.benefits);
    }

    // async _reload(changes: any) {
    //     if (changes.policy) await this._init();
    // }

}
