import { Component, forwardRef, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';


@Component({
    selector: 'option-selector',
    template: `
    <mat-form-field style="width: 100%;">
        <mat-label >{{label}}</mat-label>
        <input #acinput type="text" matInput [(ngModel)]="value" 
            [matAutocomplete]="auto" (input)="onOptionInput($event)">
        <mat-icon matSuffix>search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let option of fltOptions" [value]="option">
            {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
  `,
    styles: [
    ],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OptionSelectorComponent), multi: true}
    ]
})
export class OptionSelectorComponent implements ControlValueAccessor {
    @Output() optionSelected = new EventEmitter();
    @Input() label: string = 'Select';
    @Input() options: string[] = [];

    value: string = '';
    disabled: boolean = false;
    fltOptions: string[] = [];
    constructor() {}

    ngOnInit(): void {}
    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['options']) this._apply_filter('');
    }

    onChange = (_:any) => {};
    onTouched= (_:any) => {};
    registerOnChange(fn: any) {this.onChange = fn;}
    registerOnTouched(fn: any){this.onTouched = fn;};
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: any) {
        this.value = value;
    }


    selected(ev: any) {
        let opt = this.options.filter(x => x == ev.option.value);
        if (opt.length > 0) {
            this.onChange(opt[0]);
            this.optionSelected.emit(opt[0]);
            this.onTouched(opt[0]);
        }
    }

    _apply_filter(prefix: string) {
        if (!prefix)this.fltOptions = [...this.options];
        else this.fltOptions = this.options.filter(x => x.toLowerCase().indexOf(prefix)>=0);

    }

    async onOptionInput(ev: any) {
        if (!ev || !ev.target) return;
        this._apply_filter(ev.target.value);
    }
}
