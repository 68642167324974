<div *ngIf="policy.policy && policy.policy.edata">
    <div class="endrs-title">{{field.label}}</div>

    <div class="endrs-list" [style.grid-template-columns]="'repeat(' + (columns.length) + ', 1fr) 54px'" >
        <div class="endrs-cap" *ngFor="let c of columns">{{c.disp}}</div><div class="endrs-cap"></div>
        <div *ngIf="policy.policy.edata.length==0" style="grid-column: span 4; text-align: center;">No endorsements found</div>
        <ng-template ngFor let-end let-i="index" [ngForOf]="policy.policy.edata">
            <div class="endrs-col" (click)="gotoEndorsement(end)" *ngFor="let c of columns">
                {{end[c.name] | generic2: c.pipe :c.pipe_args}}
            </div>
            <div class="endrs-col" (click)="downloadPDF(end)" style="cursor: pointer; padding-right: .5em;"><mat-icon fontSet="material-icons-outlined" >download</mat-icon></div>
        </ng-template>
    </div>

    <div>
        <div class="endrs-title">Pending endorsements (work in progress)</div>
        <table class="gtable">
            <tr>
                <th>Endorsement name</th>
                <th>Created by</th>
                <th>Assigned to</th>
                <th>On</th>
                <th></th>
            </tr>
            <tr *ngFor="let pend of pending">
                <td (click)="gotoEndorsement(pend)">{{pend.endorsement_name}}</td>
                <td>{{pend.created_by}}</td>
                <td>{{pend.assigned_to}}</td>
                <td>{{pend.c_ts_local}}</td>
                <td (click)="cancelEndorsement(pend)">Cancel</td>
            </tr>
        </table>
    </div>
</div>

<div style="display: grid; grid-template-columns: 1fr auto; gap: 1em; align-items: center;" *ngIf="endorsements.length>0 && !(policy?.policy?.status>2)">
    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Select endorsement type</mat-label>
            <mat-select [(ngModel)]="endorsementId">
                <mat-option *ngFor="let end of endorsements" [value]="end.prd_endorsement_id">{{end.endorsement_name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <button mat-flat-button (click)="addNewEndorsement()">Add new endorsement</button>
    </div>
</div>