import { Component, OnInit, OnDestroy, HostBinding, Input, ElementRef, ChangeDetectorRef, ViewChild, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractControl, ValidationErrors, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator } from '@angular/forms';
import { MatFormFieldControl, MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { Country, PreferencesService } from '../../../lib/services/preferences.service';
import { MatSelect } from '@angular/material/select';
import { insapi } from 'insapi';

@Component({
    selector: 'country',
    templateUrl: './country.component.html',
    styleUrls: ['./country.component.scss'],
    providers: [
        { provide: MatFormFieldControl, useExisting: CountryComponent, multi: true }
    ]

})
export class CountryComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy, Validator {
    @ViewChild('mc') mc!: ElementRef;
    @ViewChild('as') as!: MatSelect;
    @Input() showFlag: boolean = true;
    @Input() dispField: string = 'name';
    @Input() iconMode: boolean = false;

    __show: boolean = false;
    __country: Country | null = null;
    _value: string = 'India';
    stateChanges = new Subject<void>(); // mat-form-field needs to know if change detection needs to be run
    touched: boolean = false;
    focused: boolean = false;
    _required: boolean = false;
    _disabled: boolean = false;
    _placeholder: string = 'Country';
    _filter: string = '';
    countries: Country[] = [];

    onChange = (value: string) => { };          // the function we need to call when there is a change in value detected
    onTouched = (value: string) => { };         // the function we need to call when the control is touched
    onValidatorChanged = (value: string) => { };
    registerOnChange = (onChange: any) => this.onChange = onChange;
    registerOnTouched = (onTouched: any) => this.onTouched = onTouched;
    setDisabledState = (disabled: boolean) => this._disabled = disabled;
    registerOnValidatorChange = (onValidatorChanged: any) => this.onValidatorChanged = onValidatorChanged;

    focusIn(ev: FocusEvent) { this.focused = true; this.stateChanges.next(); }
    focusOut(ev: FocusEvent) { this.focused = false; this.stateChanges.next(); console.log('focusout:'); this.__show=false;}
    pointerDown(ev: any) { this.touched = true; this.onTouched(ev); }

    get value() { return this._value; }
    set value(val: string) { this.writeValue(val); this.stateChanges.next(); }

    @Input()
    get placeholder() { return this._placeholder; }
    set placeholder(plh: string) { this._placeholder = plh; this.stateChanges.next(); }

    get empty() { return this._value || this._filter ? false : true }
    @HostBinding('class.floating') get shouldLabelFloat() { return this.focused || !this.empty; }

    @Input()
    get required() { return this._required; }
    set required(req) { this._required = coerceBooleanProperty(req); this.stateChanges.next(); }

    @Input()
    get disabled(): boolean { return this._disabled; }
    set disabled(value: boolean) { this._disabled = coerceBooleanProperty(value); this.stateChanges.next(); }

    get errorState(): boolean {
        // if (this.ngControl?.invalid) return true;
        return !this._value && this.touched;
    }

    @Input('aria-describedby') userAriaDescribedBy!: string;
    setDescribedByIds(ids: string[]) {
        this._elementRef.nativeElement?.setAttribute('aria-describedby', ids.join(' '));
    }
    onContainerClick(event: MouseEvent) {
        console.log('container clicked:', this.mc)
        this.mc?.nativeElement.focus();
    }

    // auto assigned unique id
    static nextId = 0;
    @HostBinding() id = `cntry-${CountryComponent.nextId++}`;

    constructor(public ngControl: NgControl,
        private _elementRef: ElementRef<HTMLElement>,
        private cdr: ChangeDetectorRef,
        private preferenaces: PreferencesService) {
        if (this.ngControl) this.ngControl.valueAccessor = this;
        this.countries = this.preferenaces?.countries || [];
        if (this.countries.length == 0) this.countries = [{ name: 'India', lname: 'india', iso2: 'IN', liso2: 'in', phone: '91', currency: 'INR', emoji: "🇮🇳" }];
        if (this.dispField === undefined) this.dispField = 'name';
    }

    ngOnInit(): void {
    }
    ngOnDestroy() {
        this.stateChanges.complete();
    }

    _update_value(value: string, ctlUpdt: boolean = false) {
        this._value = value;
        this.__country = this.preferenaces.name2Country[value?.toLowerCase()] || null;
        if (ctlUpdt) setTimeout(() => {if (this.__country && this.mc) this.mc.nativeElement.value = this.nameOf(this.__country)}, 100);
        this.cdr.detectChanges();
    }
    writeValue(value: string) {
        console.log("writeValue",value);
        this._update_value(value, !this.iconMode);
    }
    validate(control: AbstractControl): ValidationErrors | null {
        // if (this.ngControl?.invalid) return { internal: true };
        return null;    // {errorDesc: {control.value}};
    }

    nameOf(country: Country) {
        if (!country) return '';
        let disp = (country as any)[this.dispField] || '';
        return this.showFlag ? country.emoji + ' ' + disp : disp;
    }
    setSelected(country: Country) {
        this._update_value(country.name);
        this.touched = true;
        this.onChange(country.name);
        this.onTouched(country.name);
        let ev = new CustomEvent('change', { bubbles: true, detail: country });
        this._elementRef.nativeElement.dispatchEvent(ev);
        this.__show = false;
    }
    showCountrySelector() {
        console.log("_disabled", this._disabled);
        if (this._disabled) return;
        this._filter = '';
        this.countries = this.preferenaces?.countries;
        this.__show = true;
        setTimeout(() => {this.mc.nativeElement.value = ''; this.mc?.nativeElement.focus()}, 100)
    }
    inpFocusOut() {
        setTimeout(() => {
            this.__show = false
            if (this.countries.length == 1) this.setSelected(this.countries[0]);
        }, 300)
    }
    _debounce: NodeJS.Timeout | null = null;
    filterName(ev: any) {
        if (!ev?.target?.value || !this.preferenaces?.countries) {
            this.countries = this.preferenaces?.countries;
            return;
        }
        this._filter = ev?.target?.value.toLowerCase();
        console.log('filter:', this._filter);
        // if (this._filter.charCodeAt(0) < 'a'.charCodeAt(0) || this._filter.charCodeAt(0) > 'z'.charCodeAt(0)) this._filter = this._filter.substring(5);
        if (this._debounce) clearTimeout(this._debounce);
        this._debounce = setTimeout(() => {
            let filter = this._filter.startsWith('+') ? this._filter.substring(1) : this._filter;
            this.countries = this.preferenaces?.countries.filter(x => {
                let y = x.phone.startsWith('+')?x.phone.substring(1):x.phone; 
                if (x.lname.startsWith(filter) || y.startsWith(filter) || y.replace(/[^0-9.]/g, '')?.startsWith(filter)) 
                    return x;
                return false;
                }) || [];
            if (this.countries.length == 0) {
                insapi.showMessage('Invalid filter, no countries found', 0);
                this._filter = '';
                this.countries = this.preferenaces?.countries;
            }
        }, 200);
    }
}
