<div>
    <div class="avatar-container">
        <img [src]="'/res/img/avatar/' + avatar +'?'+ timeStamp" style="width: 200px;">
        <div class="file-upload">
            Upload avatar
            <input type="file" accept=".jpg,.png" (change)="onUpload($event)"/>
        </div>
    </div>
</div>
<div *ngFor="let grp of form.groups">
    <field-group [fieldGroup]="grp" [data]="data" (onAction)="onAction($event)"></field-group>
</div>

<div *ngIf="profile && profile.is_admin" class="other-info">
    <div class="sub-caption">Groups</div>
    <div class="grid-12" style="padding-left: 1em; gap: 1em;">
        <div *ngFor="let grp of groups" class="grid-cols-3" style="cursor: pointer;" [routerLink]="['/group', grp.group_name]">{{grp.group_name | uppercase}}</div>
    </div>

    <div class="sub-caption">Roles</div>
    <div class="grid-12" style="padding-left: 1em; gap: 1em;">
        <div *ngFor="let role of roles" class="grid-cols-3" style="cursor: pointer;" [routerLink]="['/role', role.role_name]">{{role.role_name | uppercase}}</div>
    </div>

</div>