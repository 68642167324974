import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'twofa-image',
	template: `
	<div class="twofa">
	Scan QR Code using Google/Microsoft Authenticator for Two factor authentication
	<br>
	<img class="qrcode" src ={{data.two_f_a_img}}/>
	</div>
	<div class="twofa-footer">
        <div *ngFor="let act of data.actions" class="twofa-btn">
            <button mat-button (click)="onAction(act)">{{act.disp}}</button>
        </div>
	</div>

	`,
	styles: [
        `.twofa {text-align:center}`,
		`.twofa-btn {display: inline-block; margin-right: 1em;}`, 
        `.twofa-footer {text-align:center;padding: 2em 0em 0em 0em;}`,
		`twofa:focus {outline:0; letter-spacing: normal; transition: .5s}`
	]
})
export class TwofaComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<TwofaComponent>) {
            
    }
    async onAction(action: any) {
        this.dialogRef.close(action);
    }
}
