import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IField, __set_data } from '../../form.interface';
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { Policy, is_num, insapi, IProfile, IPolicy, __eval_func } from 'insapi';
import { Subscription } from 'rxjs';
import { MatCheckbox } from '@angular/material/checkbox';

import { DatePipe } from '../../../lib/pipes/idate.pipe';
import { CurrencyPipe } from '@angular/common';

let pipes: {[key: string]: any} = {
    date: DatePipe,
    currency: CurrencyPipe
}

@Component({
    selector: 'multi-choice',
    templateUrl: './multi-choice.component.html',
    styleUrls: ['./multi-choice.component.css']
})
export class MultiChoiceComponent implements OnInit {

    @Output() onChange = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    policy!: Policy;
    readonly: boolean = false;
    data: any = {};
    multi: any = null;
    form: FormGroup | null = null;
    premium_field: string = 'premium_value';
    profile: IProfile | null = null;

    debounce: number|null = null;
    psubscription: Subscription | undefined = undefined;
    yesNo: any = {'Yes': 'done', 'No': 'close'};
    subscription: Subscription | undefined = undefined;

    parameters: any = [];
    aonamemap: any = {};
    visible: any[] = [];
    constructor(private fb: FormBuilder) { }

    ngOnInit(): void {
        this.subscription = insapi.profileSubject.subscribe((profile) => {this.profile = profile});
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._prepare_choices());
        // this.psubscription = this.policy?.policySubject.subscribe(() => {
        //     this._prepare_choices();
        // });
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = undefined;
    }

    __update_param_vsibility() {
        if (!this.data.choices) return;
        for (let c=0; c<this.data.choices.length; c++) {
            if (c >= this.visible.length) this.visible.push({});
            let choice = this.data.choices[c];
            let data = structuredClone(this.data);
            for (let key in choice) if (key !== 'selected' && choice[key]) __set_data(data, key, choice[key]);
            for (let param of this.parameters) {
                this.visible[c][param.param_name] = param.altiffunc(data);
            }
        }
    }

    __update_param_values() {
        for (let param of this.parameters) {
            if (this.policy.overrides?.[param.param_name]) param.param_values = this.policy.overrides[param.param_name].values;
            if (!(param.param_values instanceof Array)) param.param_values = param.param_values.split(',');
            if (is_num(param.param_values[0])) param.type = 'number';
            else if (param.param_values.length ==2 && (
                param.param_values[0].toLowerCase() == 'yes' || 
                param.param_values[0].toLowerCase() == 'y' || 
                param.param_values[1].toLowerCase() == 'yes')) param.type = "checkbox";
            else if (param.type != 'number') param.type = "options";
        }
    }

    __valids(policy: Policy) {
        let type = 'premium_calc';
        let valids = [];
        if (policy.endorsement && policy.endProduct) {
            valids.push(policy.endProduct.premium_calc_validations || {});
            valids.push(policy.endProduct.premium_calc_output_validations|| {});
        } else if (policy.product) {
            valids.push(policy.product[type+'_validations'] || {});
            valids.push(policy.product[type+'_output_validations'] || {});
        }
        return valids;
    }

    async __add_addon_conditional(param: any, aodef: any, pidx: number) {
        if (pidx < 0) {param.altiffunc = () => true; return;}
        param.altiffunc = (data: any, profile: any, policy: any) => {
            return data[aodef.array_name][pidx]?.[aodef.opted_name] == 'Yes';
        };
    }

    __add_param_conditional(param: any, valids: any[]) {
        // check if we have any validation set on this param
        let name = param.param_name;
        let valid = null;
        for (let v of valids) if (v[name]) {valid = v[name]; break;}
        param.altiffunc = (!valid?.altif) ? () => true : __eval_func(valid.altif, name);
    }

    __parent_addon_idx(aodef: any, aname: string, adata: any) {
        let parent = aodef.aomap[aname]?.parent;
        if (!parent) return -1;
        for (let ao of aodef.addons) {
            if (ao.ukey == parent) {    // locate this ao in data array
                for (let i=0; i<adata.length; i++) if (adata[i].addon_name == ao.name) return i;
            }
        }
        return -1;
    }

    async __prepare_parameters() {
        if (this.parameters.length > 0 || !this.policy.policy || !this.policy.product) return;

        if (this.policy?.product.data.premium_value) {
            this.premium_field = this.policy?.product.data.premium_value;
        }

        let addons: any = {};
        for (let ao of this.policy.product.addons||[]) {
            addons[ao.array_name] = ao;
            if (!ao.aomap) ao.aomap = ao.addons.reduce((a: any, x: any) => {a[x.name]=x; return a;}, {});
        }
        let valids = this.__valids(this.policy);
        let parameters = [];
        let multi = JSON.parse(JSON.stringify(this.policy?.product?.multi_quote));
        for (let param of multi.parameters) {
            let aodef = addons[param.param_name];
            if (aodef) {
                if (!(this.data[param.param_name] instanceof Array) || this.data[param.param_name].length <= 0) {
                    console.log('addon array not filled in yet ...', param.param_name, this.data[param.param_name])
                    return;    // prem-calc API has not been called yet, addon array not filled in
                }
                for (let i=0; i<this.data[param.param_name].length; i++) {
                    let ao = this.data[param.param_name][i];
                    let aoname = ao.addon_name || ao[aodef.addon_name];
                    let p = {
                        param_name: aodef.array_name + '.' + i + '.' + aodef.opted_name,
                        param_values: ['Yes', 'No'],
                        type: "checkbox",
                        param_label: aodef.aomap[aoname]?.desc || aoname
                    };
                    this.aonamemap[aoname] = ao;
                    parameters.push(p);

                    let pidx = this.__parent_addon_idx(aodef, aoname, this.data[param.param_name]);
                    this.__add_addon_conditional(p, aodef, pidx);
                }
            } else {
                if (this.policy.overrides?.[param.param_name]) param.param_values = this.policy.overrides[param.param_name].values;
                if (!(param.param_values instanceof Array)) param.param_values = param.param_values.split(',');
                if (is_num(param.param_values[0])) param.type = 'number';
                else if (param.param_values.length ==2 && (
                    param.param_values[0].toLowerCase() == 'yes' || 
                    param.param_values[0].toLowerCase() == 'y' || 
                    param.param_values[1].toLowerCase() == 'yes')) param.type = "checkbox";
                else if (param.type != 'number') param.type = "options";
                this.__add_param_conditional(param, valids);
                parameters.push(param);
            }
        }
        this.parameters = parameters;
        
        for (let output of multi.outputs||[]) {
            output.pipe = pipes[output.formatter] || '';
            if (multi.highlite == output.param_name) multi.highlite = output;
        }
        this.multi = multi;
        
        this.data.choices = this.data.choices || [];
        if (this.data.choices.length == 0) {
            await this.addChoice();
            if (this.data.choices.length>0) this.data.choices[0].selected = true;
        }
        

        let fdata: any = {};
        for (let i=0; i<this.data.choices.length; i++) {
            let choice = this.data.choices[i];
            let form = this.fb.group({});
            for (let param of this.multi.parameters) {
                const control = this.fb.control({value: choice[param.param_name]||''});
                form.addControl(param.param_name, control);
            }
            fdata['fg'+i] = form;
        }
        this.form = this.fb.group(fdata);

    }

    async _prepare_choices() {
        if (!this.policy?.product?.multi_quote) {
            console.log('**Error: missing multi quote definition in product');
            return;
        }


        this.__prepare_parameters();
        this.__update_param_values();
        this.__update_param_vsibility();
    }

    async addChoice() {
        if (!this.multi) return;
        if (this.data.choices.length >= this.multi.max) return;
        let choice: any = {selected: false};
        for (let param of this.parameters) {
            choice[param.param_name] = param.param_values[0];
        }
        this.data.choices.push(choice);
        this.onChange.emit([]);
    }

    __update_selected(choice: any) {
        let changed: any[] = [];
        for (let param of this.parameters) {
            __set_data(this.data, param.param_name, choice[param.param_name])
            changed.push({field_name: param.param_name, value: choice[param.param_name]});
        }
        // console.log('this.data:', this.data)
        return changed;
    }

    setSelected(choice: any) {
        if (this.readonly) return;
        for (let c of this.data.choices) c.selected = false;
        choice.selected = true;
        this.onChange.emit(this.__update_selected(choice));
    }

    changeValue(choice: any, param: any, value: any) {
        if (this.readonly) return;
        choice[param.param_name] = value;
        console.log('changeValue:', param.param_name, value, choice[param.param_name]);
        if (this.debounce) clearTimeout(this.debounce);

        this.debounce = null;
        if (choice.selected) {
            this.onChange.emit(this.__update_selected(choice));
            return;
        }
        this.debounce = window.setTimeout(() => {
            this.onChange.emit([]);
        }, 1000);
    }

}
