import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';
import { Policy } from 'insapi';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-outputs',
    templateUrl: './outputs.component.html',
    styleUrls: ['./outputs.component.scss']
})
export class OutputsComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    data: any = {};
    outputs: any[] = [];
    policy!: Policy;
    outHtml: SafeHtml = "";

    psubscription: any;
    constructor(private preferences: PreferencesService) { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._init());
        this._init();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    async _init() {
        if (this.field.template_name) {
            this.outHtml = await this.preferences.render(this.field.template_name, this.policy.product?.product_name||'', this.policy);
        } else {
            this.outputs = [];
            for (let fname in this.field.props) {
                // this.outputs.push({name: fname, disp: this.field.props[fname], value: this.data[fname]||this.policy?.policy?.proposal?.data[fname] || this.policy?.policy?.quote?.data[fname]});
                let formatter = null;
                let disp = this.field.props[fname];
                let tip = disp;
                if (typeof disp !== 'string') {
                    tip = disp.tip || disp.disp;
                    formatter = disp.formatter ?? null;
                    disp = disp.disp || fname;
                }

                this.outputs.push({name: fname, tip, formatter, disp,
                    value: this.data[fname]||this.policy?.policy?.proposal?.data[fname] || this.policy?.policy?.quote?.data[fname]
                });
            }
        }
    }

}
