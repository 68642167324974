import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';

@Component({
    selector: 'app-radio',
    template: `
    <div class="margin-top" [formGroup]="group">
        <label class="radio-label-padding">{{field.label}}:</label>
        <mat-radio-group [formControlName]="field.field_name" [attr.data-iid]="field.field_name">
            <mat-radio-button *ngFor="let item of field.options" [value]="$any(item).value">{{$any(item).name}}</mat-radio-button>
        </mat-radio-group>
    </div>
    `,
    styles: [
    ]
})
export class RadioComponent implements OnInit {

    field!: IField;
    group!: FormGroup;
    constructor() { }

    ngOnInit(): void {
    }

}
