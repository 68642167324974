import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'tandc',
	template: `
	<div class="iagree">
        <div class="igree-tandc" *ngIf="data.tandc" [innerHTML]="data.tandc"></div>
    </div>
	<div class="iagree-footer">
    <div><mat-checkbox [(ngModel)]="data.agreed" [disabled]="data.agreedOn!=''">{{data.prompt}}</mat-checkbox></div>
        <div *ngIf="!data.agreedOn"><button mat-button (click)="onAction('agree')">{{data.label}}</button></div>
        <div *ngIf="data.agreedOn" style="padding: .5em 0em;">Agreed on <span class="agreed">{{data.agreedOn}}</span> by <span class="agreed">{{data.agreed_by}}</span></div>
	</div>
	`,
	styles: [
        `.iagree-footer {padding: 2em 0em 0em 0em;}`
	]
})
export class TAndCComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
        public dialogRef: MatDialogRef<TAndCComponent>) {
    }
    async onAction(action: any) {
        this.dialogRef.close(action);
    }
}
