class tracer {
    proxied: {[key: string]: any} = {};
    ignore: string[] = [];
    calls: {[key: string]: any} = {};
    constructor() {

    }

    trace(cls: any) {
        let cname = cls.prototype.constructor.name;
        let proto = cls.prototype;
        let meths: any = Object.getOwnPropertyNames(proto);
        if (this.proxied[cname]) return; // already wrapped
        this.proxied[cname] = true;

        for(var meth of meths) {
            if( typeof proto[meth] != 'function' )continue;
            this.calls[cname+'.'+meth] = {total: 0, count: 0, calls: []};
            this._wrap_method(proto, meth, cname+'.'+meth);
        }
        
    }

    _wrap_method(proto: any, meth: string, fname: string) {
        if (this.ignore.indexOf(meth) >= 0)return;
        let ometh = proto[meth];
        let self = this;
        if (ometh.constructor.name === 'AsyncFunction') {
            proto[meth] = async function(...args: any) {
                let start = performance.now();
                let ret = await ometh.apply( this, args);
                start = performance.now() - start;
                self.calls[fname].total += start;
                self.calls[fname].count += 1;
                self.calls[fname].calls.push(start.toFixed(2));
                return ret;
            };
        } else {
            proto[meth] = function(...args: any) {
                let start = performance.now();
                let ret = ometh.apply( this, args);
                start = performance.now() - start;
                self.calls[fname].total += start;
                self.calls[fname].count += 1;
                self.calls[fname].calls.push(start.toFixed(2));
                return ret;
            }
        }
    }

    _print() {
        for (let meth in this.calls) {
            //for (let call of this.calls[meth]) {
                console.log(meth, this.calls[meth].total, this.calls[meth].count, this.calls[meth].calls);
            //}
            this.calls[meth].calls = [];
        }
    }
}

export const trace = new tracer();