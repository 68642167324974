import { Pipe, PipeTransform } from '@angular/core';
import { numberFormat } from 'insapi';

@Pipe({
    name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

    transform(number: number, args?: any): any {
        return numberFormat(number);
    }
}