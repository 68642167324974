import { Component, OnInit, EventEmitter } from '@angular/core';
import { IField } from '../../form.interface';
import { FormGroup, FormControl } from "@angular/forms";
import { Policy, IPolicy } from 'insapi';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-price',
    templateUrl: './price.component.html',
    styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

    onChange = new EventEmitter();
    data!: any;
    field!: IField;
    group!: FormGroup;
    policy!: Policy;

    charges: any = {};
    details: any = {};
    psubscription: Subscription | undefined = undefined;
    payment: any = null;
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        // if (this.policy) {
        //     this.psubscription = this.policy.policySubject.subscribe((policy: IPolicy|null) => this._policy_changed());
        // }
    }
    
    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    _policy_changed() {
        if (!this.policy) return;
        let data: any = {};
        if (this.policy.endorsement?.prd_endorsement_id) {
            data = this.policy.endorsement?.eproposal?.data || {};
            this.payment = this.policy.endorsement?.payment || null;
        } else {
            data = this.policy.policy?.proposal?.data || this.policy.policy?.quote?.data || {};
            this.payment = this.policy?.policy?.payment || null;
        }
        this.charges = data.charges || {total: 0, charge_parts: []};
        this.details = data ? data.tax_details || {} : {};
        if (!this.details.tax_parts) this.details.tax_parts = [];
        for (let part of this.details.tax_parts) {
            if (part.absolute) part.desc = ''; // '@' + part.rate;
            else if (part.rate > 0) part.desc = '@' + part.rate + '%';
            else part.desc = '';
        }
        console.log('charges:', this.charges)
    }

}
