import { excelDateToDate } from "insapi";

const isWindow = (obj: any) => obj && obj.window === obj;
const isScope = (obj: any) => obj && obj.$evalAsync && obj.$watch;
const isRegExp = (value: any) => Object.prototype.toString.call(value) === '[object RegExp]';
const simpleCompare = (a: any, b: any) => a === b || (a !== a && b !== b);
const isDate = (value: any) => Object.prototype.toString.call(value) === '[object Date]';
const isArray = (arr: any) => Array.isArray(arr) || arr instanceof Array;


function isDefined(value: any) {return typeof value !== 'undefined';}
function isFunction(value: any) {return typeof value === 'function';}

function createMap() {
    return Object.create(null);
}


export  function equals(o1: any, o2: any) {
    if (o1 === o2) return true;
    if (o1 === null || o2 === null) return false;
    // eslint-disable-next-line no-self-compare
    if (o1 !== o1 && o2 !== o2) return true; // NaN === NaN
    var t1 = typeof o1, t2 = typeof o2, length, key, keySet;
    if (t1 === t2 && t1 === 'object') {
        if (isArray(o1)) {
            if (!isArray(o2)) return false;
            if ((length = o1.length) === o2.length) {
                for (key = 0; key < length; key++) {
                    if (!equals(o1[key], o2[key])) return false;
                }
                return true;
            }
        } else if (isDate(o1)) {
            if (!isDate(o2)) return false;
            return simpleCompare(o1.getTime(), o2.getTime());
        } else if (isRegExp(o1)) {
            if (!isRegExp(o2)) return false;
            return o1.toString() === o2.toString();
        } else {
            if (isScope(o1) || isScope(o2) || isWindow(o1) || isWindow(o2) ||
                isArray(o2) || isDate(o2) || isRegExp(o2)) return false;
            keySet = createMap();
            for (key in o1) {
                if (key.charAt(0) === '$' || isFunction(o1[key])) continue;
                if (!equals(o1[key], o2[key])) return false;
                keySet[key] = true;
            }


            for (key in o2) {
                if (!(key in keySet) &&
                    key.charAt(0) !== '$' &&
                    isDefined(o2[key]) &&
                    !isFunction(o2[key])) return false;
            }
            return true;
        }
    }
    return false;
}

export function jdiff(o1: any, o2: any) {
    if (o1 == o2) {
        // console.log('o1==o2', o1, o2)
        return null;
    }
    if (o1 === null || o1 === undefined) {
        if (o2 === '') return null;
        if (o2 !== null && o2 !== undefined) return {added: o2};
    }
    if (o2 === null || o2 === undefined) {
        if (o1 === '') return null;
        return {removed: o1};
    }
    if (o1 !== o1 && o2 !== o2) return null;  // NaN
    let t1 = typeof o1, t2 = typeof o2, length, key, keySet;
    if (t1 !== t2) {
        if (t1 === 'number' && t2 == 'string') {o1 = ''+o1; t1 = 'string'}
        else if (t2 === 'number' && t1 == 'string') {o2 = ''+o2; t2 = 'string'}
        else if (o1 instanceof Date && t2 === 'number') {o2 = excelDateToDate(o2); t2 = typeof o2}
        else if (o2 instanceof Date && t1 === 'number') {o1 = excelDateToDate(o1); t1 = typeof o1}
        else return {type: [t1, t2]};
    }
    if (t1 !== 'object') return {changed: [o1, o2]};         // primitive match
    if (isArray(o1)) {
        if (!isArray(o2)) return {changed: [o1, o2]};
        let changed: any = {};
        for (let i=0; i<o1.length; i++) {
            if (i < o2.length) {
                let ret = jdiff(o1[i], o2[i]);
                if (ret) changed[i] = ret;
            } else {
                changed[i] = {removed: o1[i]};
            }
        }
        if (o2.length > o1.length) {
            for (let i=o1.length; i<o2.length; i++) {
                changed[i] = {added: o1[i]};
            }
        }
        return changed;
    } else if (isDate(o1)) {
        if (!isDate(o2)) return {changed: [o1, o2]};
        if (!simpleCompare(o1.getTime(), o2.getTime())) return {changed: [o1, o2]}
        return null;
    } else if (isRegExp(o1)) {
        if (!isRegExp(o2) || o1.toString() !== o2.toString()) return {changed: [o1, o2]};
        return null;
    } else {
        if (isScope(o1) || isScope(o2) || isWindow(o1) || isWindow(o2) ||
        isArray(o2) || isDate(o2) || isRegExp(o2)) return null;
        keySet = createMap();
        let changed: any = {};
        for (key in o1) {
            if (key.charAt(0) === '$' || isFunction(o1[key])) continue;
            let ret = jdiff(o1[key], o2[key]);
            // console.log('key:', key, ret, o1[key], o2[key])
            if (ret) changed[key] = ret;
            keySet[key] = true;
        }
        for (key in o2) {
            if (!(key in keySet) &&
                key.charAt(0) !== '$' &&
                isDefined(o2[key]) &&
                !isFunction(o2[key])) changed[key] = {added: o2[key]};
        }
        if (Object.keys(changed).length == 0) return null;
        return changed;
    }
}

function __diff(flat: any[], path: string, op: string|undefined = undefined, o1: any = null, o2: any = null) {
    if (op === undefined) return flat;
    if (o1 && typeof o1 === 'object') o1 = JSON.stringify(o1);
    if (o2 && typeof o2 === 'object') o2 = JSON.stringify(o2);
    if (o2) flat.push({path, op, o1, o2});
    else flat.push({path, op, o1});
    return flat;
}

export function pdiff(o1: any, o2: any, flat: any[]=[], path: string='') {
    if (o1 == o2) return flat;
    if (o1 === null) {
        if (o2 !== null && o2 !== undefined) return __diff(flat, path, 'added', o2);
    }
    if (o2 === null || o2 === undefined) return __diff(flat, path, 'removed', o1); // return flat.push({[path]: {removed: o1}});
    if (o1 !== o1 && o2 !== o2) return flat;  // NaN
    let t1 = typeof o1, t2 = typeof o2, length, key, keySet;
    if (t1 !== t2) return __diff(flat, path, 'type', t1, t2);
    if (t1 !== 'object') return __diff(flat, path, 'changed', o1, o2); // return flat.push({[path]: {changed: [o1, o2]}});         // primitive match
    if (isArray(o1)) {
        if (!isArray(o2)) return __diff(flat, path, 'changed', o1, o2); // return flat.push({[path]: {changed: [o1, o2]}});
        for (let i=0; i<o1.length; i++) {
            if (i < o2.length) {
                pdiff(o1[i], o2[i], flat, path+'['+i+']');
            } else {
                __diff(flat, path+'['+i+']', 'removed', o1[i]);
            }
        }
        if (o2.length > o1.length) {
            for (let i=o1.length; i<o2.length; i++) {
                __diff(flat, path+'['+i+']', 'added', o1[i]);
            }
        }
        return flat;
    } else if (isDate(o1)) {
        if (!isDate(o2)) return __diff(flat, path, 'changed', o1, o2); // return {changed: [o1, o2]};
        if (!simpleCompare(o1.getTime(), o2.getTime())) return __diff(flat, path, 'changed', o1, o2); // return flat.push({[path]: {changed: [o1, o2]}});
        return flat;
    } else if (isRegExp(o1)) {
        if (!isRegExp(o2) || o1.toString() !== o2.toString()) return __diff(flat, path, 'changed', o1, o2); // return flat.push({[path]: {changed: [o1, o2]}});
        return flat;
    } else {
        if (isScope(o1) || isScope(o2) || isWindow(o1) || isWindow(o2) ||
        isArray(o2) || isDate(o2) || isRegExp(o2)) return flat;
        keySet = createMap();
        for (key in o1) {
            if (key.charAt(0) === '$' || isFunction(o1[key])) continue;
            pdiff(o1[key], o2[key], flat, path+'.'+key);
            keySet[key] = true;
        }
        for (key in o2) {
            if (!(key in keySet) &&
                key.charAt(0) !== '$' &&
                isDefined(o2[key]) &&
                !isFunction(o2[key])) {
                    __diff(flat, path+'.'+key, 'added', o2[key]);
                }
        }
        return flat;
    }
}