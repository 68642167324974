import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Route, NavigationStart } from "@angular/router";
import { MatTableDataSource} from '@angular/material/table';
import { insapi } from 'insapi';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0' })),
          state('expanded', style({ height: '*' })),
          transition('expanded <=> collapsed', animate('1225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
      ],

})
export class OrderComponent implements OnInit {
    dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);

    status: {[key: string]: string} = {0: 'Unpaid', 1: 'Paid'};
    columns: string[] = ['u_ts', 'status', 'count', 'amount', 'premium', 'tax'];
    edit = {index: -1};
    orderId: string | null = null;
    orders: any[] = [];
    start: number = 0;

    from: Date = new Date();
    till: Date = new Date();
    name: string = '';
    count: number = 5;
    isMobile: boolean = false;
    rsubscription: Subscription | null = null;
    bpsubscription: Subscription | null = null;
    constructor(private activatedRoute: ActivatedRoute, 
            private router: Router,
            private breakpointObserver: BreakpointObserver) {
        this.rsubscription = this.activatedRoute.queryParams.subscribe(params => {
            this.orderId = params.order_id || null;
            this.loadOrder();
        });
    }

    ngOnDestroy(): void {
        if (this.rsubscription) this.rsubscription.unsubscribe();
        this.rsubscription = null;
        if (this.bpsubscription) this.bpsubscription.unsubscribe();
        this.bpsubscription = null;
    }

    ngOnInit(): void {
        this.bpsubscription = this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
            // this.isMobile = true;
            // this.columns = ['u_ts', 'status', 'count', 'premium'];
            // console.log('isMobile:', this.isMobile);
        });
    }
    ngAfterViewInit() {
    }

    async loadOrder() {
        let filters = {
            order_id: this.orderId||undefined, 
            from: moment(this.from).format('YYYY-MM-DD'),
            till: moment(this.till).format('YYYY-MM-DD 11:59:00'),
            item_name: this.name
        };
        this.orders = await insapi._orders(filters, this.start, this.count);
        console.log('orders:', filters, this.orders);
        let i=0;
        for (let order of this.orders) {
            order.index = i ++;
            order.amount = 0;
            order.premium = 0;
            order.tax = 0;
            order.count = order.items.length;
            order.taxdetails = {};
            order.process = false;
            for (let item of order.items) {
                order.amount += item.amount;
                order.premium += item.premium;
                order.tax += item.tax;
                let td = JSON.parse(item.tax_details);
                for (let tp of td.tax_parts) {
                    let name = tp.name + ' - ' + tp.rate + '%';
                    if (!order.taxdetails[name]) order.taxdetails[name] = 0;
                    order.taxdetails[name] += +tp.value;
                }
                if (!item.policy_no) order.process = true;
            }
        }
        this.dataSource.data = this.orders;
        if (this.orderId) this.edit.index = 0;
    }

    async process(orderId: string) {
        await insapi._cart_process(orderId);
        await this.loadOrder();
    }

    toggle(item: any) {
        this.edit.index = this.edit.index == item.index ? -1 : item.index;
    }

    goto(policyId: string) {
        this.router.navigate(['/policy'], { state: {asd:1}, queryParams: {policy_id: policyId}});
    }
    applyFilters() {
        this.start = 0;
        this.loadOrder();
    }
    next() {
        this.start += this.count;
        this.loadOrder();
    }
    prev() {
        this.start -= this.count;
        this.loadOrder();
    }
}
