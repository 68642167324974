import { Component, OnInit, Output, EventEmitter, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { IField } from '../../form.interface';
import { Policy } from 'insapi';
import { Subscription, Observable } from 'rxjs';


@Component({
    selector: 'lib-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
    data!: any;
    field!: IField;
    policy!: Policy;
    readonly: boolean = false;
    control!: FormControl;
    @Output() onChange = new EventEmitter();
    psubscription: Subscription | undefined = undefined;

    addr_span: string = 'span 12';
    
    stateName: string = '';
    states: {name: string, value: string}[] = [{name: 'Tamil Nadu', value: 'TN'}];
    fltStates: {name: string, value: string}[] = [];
    addr1List: string[] = [];
    addr2List: string[] = [];
    addr3List: string[] = [];
    cityList: string[] = [];


    addrOptions: string[] = ['line 1', 'line 2', 'line 3'];
    cityOptions: string[] = ['Chennai', 'Mumbai', 'Delhi'];

    constructor() { }

    ngOnInit(): void {
        this.__init();
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    async _policy_changed() {

    }
    
    async __init() {
        // console.log('address-init:', this.field.field_name, this.field.address);
        if (!this.field.address) this.field.address = {
            same_as: '',
            address_lines: 1,
            zipcode: true,
            use_map: false,
            reverse_order: false
        };
        if (this.field.address.address_lines === undefined) this.field.address.address_lines = 2;
        
        if (this.field.address.address_lines) this.addr_span = "span 4"
        else this.addr_span = "span " + (12/+this.field.address.address_lines);
        
        if (!this.data[this.field.field_name]) {
            this.data[this.field.field_name] = {};
            this.control.setValue(this.data[this.field.field_name]);
        }
        if (!this.data[this.field.field_name].country)
            this.data[this.field.field_name].country = this.field.address.def_country || '';

        // console.log('address:', this.field.address, 'data', this.data[this.field.field_name]);
        this.filterList(null, 'state');
    }

    filterList(ev: any, type: string) {
        if (!ev?.target?.value) {
            if (type == 'state') this.fltStates = this.states;
            else if (type == 'address_1') this.addr1List = this.addrOptions;
            else if (type == 'address_2') this.addr2List = this.addrOptions;
            else if (type == 'address_3') this.addr3List = this.addrOptions;
            else if (type == 'city') this.cityList = this.cityOptions;
        } else {
            if (type == 'state') this.fltStates = this.states.filter(x => x.name.toLowerCase().indexOf(ev?.target?.value)>=0);
            else if (type == 'address_1') this.addr1List = this.addrOptions.filter(x => x.toLowerCase().indexOf(ev?.target?.value)>=0);
            else if (type == 'address_2') this.addr2List = this.addrOptions.filter(x => x.toLowerCase().indexOf(ev?.target?.value)>=0);
            else if (type == 'address_3') this.addr3List = this.addrOptions.filter(x => x.toLowerCase().indexOf(ev?.target?.value)>=0);
            else if (type == 'city') this.cityList = this.cityOptions.filter(x => x.toLowerCase().indexOf(ev?.target?.value)>=0);
        }
        // console.log('addr1List:', type, this.addr1List, this.addrOptions);
    }
    setSelected(value: string, type: string) {
        // console.log(this.field.field_name, this.data[this.field.field_name])
        this.data[this.field.field_name][type] = value;
    }
    nameOf(item: any) {
        return item?.name || '';
    }

    toggleSameAs() {
        if (this.data[this.field.field_name].same_as) {
            // copy all from other field
        } else {
            // reset all ?
        }
    }
}
