import { Country, PreferencesService } from '../../../lib/services/preferences.service';

export interface MaskFormat {
    [key: string]: {code: string, prefix: string, masks: string[], min?: number, max?: number} | MaskFormat
}

export const formats : MaskFormat = {
    '1': {code: 'US', prefix: '+1', masks:['+d (ddd) - ddd - dddd']},
    '91': {code: 'IN', prefix: '+91', masks: ['+dd ddddd ddddd']},
    '968': {
        '7': {code: 'OM', prefix: '+968', masks: ['+ddd dddd dddd']},
        '9': {code: 'OM', prefix: '+968', masks: ['+ddd dddd dddd']}
    },
    '971': {
        '5': {code: 'AE', prefix: '+971', masks: ['+ddd ddd dddd']}
    },
    '974': {code: 'QA', prefix: '+974', masks: ['+dd ddd ddd']},
    '44': {code: 'UK', prefix: '+44', masks: ['+dd dd dddddd']},
    '33': {code: 'FR', prefix: '+33', masks: ['+dd d dd dd dd']},
    '49': {code: 'DE', prefix: '+49', masks: ['+dd ddd ddddddd']},
}

export function __update_phone_code(countries: Country[]) {
    if (formats['994']) return; // already done
    for (let c of countries) {
        let phone = c.phone;
        if (phone[0] == '+') phone = phone.substring(1);
        let mask = '+' + '99999'.substring(0, phone.length) + ' 9999999999';
        if (!formats[phone]) formats[phone] = {code: c.iso2.toUpperCase(), masks: [mask], prefix: '+' + phone};
    }
}