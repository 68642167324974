<div style="padding-bottom: 1em;">
    <div class="field-group-caption" style="padding-left: 1em;">{{field.label}}</div>

    <div class="grid-4" style="padding-left: 1em;">
        <div>
            <mat-form-field style="width: 100%;">
                <mat-label>{{label}}</mat-label>
                <input type="text" matInput  [placeholder]="label" [(ngModel)]="id" (input)=onKeyup($event)>
            </mat-form-field>
        </div>
        <ng-template ngFor let-param [ngForOf]="params">
            <ng-template [ngIf]="param.visible">
                <ng-container [ngSwitch]="param.type">
                    <div *ngSwitchCase="'date'">
                        <mat-form-field style="width: 100%;">
                            <mat-label>{{param.label | titlecase}}</mat-label>
                            <input matInput [matDatepicker]="dpicker" [placeholder]="field.label||''" [(ngModel)]="param.value">
                            <mat-datepicker-toggle matSuffix [for]="dpicker"></mat-datepicker-toggle>
                            <mat-datepicker #dpicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div *ngSwitchCase="'lookup'">
                        <mat-form-field style="width: 100%;">
                            <mat-label>{{param.label | titlecase}}</mat-label>
                            <mat-select [placeholder]="field.label||''" [(ngModel)]="param.value">
                                <mat-option *ngFor="let opt of param.options" [value]="opt.value">{{opt.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngSwitchDefault>
                        <mat-form-field style="width: 100%;">
                            <mat-label>{{param.label | titlecase}}</mat-label>
                            <input type="text" matInput  [placeholder]="param.name" [(ngModel)]="param.value">
                        </mat-form-field>
                    </div>
                </ng-container>
            </ng-template>

            <!-- <div *ngIf="param.visible && param.type=='date'">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{param.name | titlecase}}</mat-label>
                    <input matInput [matDatepicker]="dpicker" [placeholder]="field.label||''" [(ngModel)]="param.value">
                    <mat-datepicker-toggle matSuffix [for]="dpicker"></mat-datepicker-toggle>
                    <mat-datepicker #dpicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div *ngIf="param.visible && param.type!='date'">
                <mat-form-field style="width: 100%;">
                    <mat-label>{{param.name | titlecase}}</mat-label>
                    <input type="text" matInput  [placeholder]="param.name" [(ngModel)]="param.value">
                </mat-form-field>
            </div> -->
        </ng-template>
        <div>
            <button mat-button ibutton (click)="fetchCustomer()" *ngIf="idtype">Fetch</button>
        </div>
    </div>

    <div *ngIf="data[field.field_name] && data[field.field_name].customerx_id" class="customer">
        <div class="grid-12">
            <div *ngFor="let fld of fields; let i=index;" class="grid-cols-3"  style="padding-bottom: 0.5em;">
                <div style="color: #888; font-size: 0.8em; line-height: 0.8em;">{{fld.label || fld.field_name}}</div>
                {{data[field.field_name][fld.field_name+'_dt'] || data[field.field_name][fld.field_name]}}
            </div>
        </div>
    </div>


    <div *ngIf="validId" class="cust-list">
        <table class="gtable">
            <tr><th *ngFor="let fld of fields; let i=index;">{{fld.label || fld.field_name}}</th><th></th></tr>
            <tr *ngFor="let match of matched">
                <td *ngFor="let fld of fields">{{match[fld.field_name+'_dt'] || match[fld.field_name]}}</td>
                <td style="cursor: pointer;" (click)="selectCustomer(match)">Select</td>
            </tr>
            <tr *ngIf="matched.length==0">
                <td style="text-align: center;" [attr.colspan]="(fields.length+1)">No matching customer found</td>
            </tr>
        </table>
    </div>

    <div *ngIf="validId" class="cust-edit">
        <div *ngIf="matched.length==0">Add new customer</div>
        <div *ngFor="let grp of fgroups">
            <field-group #fgs
                [fieldGroup]="grp" 
                [data]="customer"
                [policy]="policy"
                [readonly]="readonly"
                [layout]="grp.layout"
                (onChange)="changed($event)">
            </field-group>
        </div>
        <button mat-button ibutton (click)="addNew(false)" *ngIf="matched.length==0 || customer.customerx_id">Add</button>
        <button mat-button ibutton (click)="addNew(true)" *ngIf="customer.customerx_id">Update</button>
        <button mat-button ibutton (click)="_reset_inputs()">Close</button>
    </div>

</div>
