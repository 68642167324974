<div class="app-container" [class.orangy]="theme">
  <mat-toolbar class="toolbar">
    <button mat-icon-button class="app-icon" (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <img *ngIf="vendor.logo" src="assets/img/logo_header.png" routerLink="home" height="28">
    <span routerLink="home" class="title">{{company}} <!-- <small class="sub-title">{{subTitle}}</small> --> </span>
    <span class="amount-span">
      <i-number style="display: inline-block;" *ngIf="profile && premiumDisp && premiumData.premium_value>0" [field]="premiumDisp" [data]="premiumData" decimals="2"></i-number>
      <span style="pointer-events: auto;" *ngIf="premiumData.total_amount>0"><mat-icon style="pointer-events: auto;" #picon (click)="showPriceDetails(ovlay, picon._elementRef)">expand_more</mat-icon></span>
      {{premiumData.message}}
    </span>
    <span class="spacer"></span>

    <ng-template *ngIf="profile" ngFor let-mnu let-i="index" [ngForOf]="vendor.home.menu" >
      <!-- new added  -->
      <button mat-icon-button class="toolbar-button" *ngIf="mnu.routerLink && mnu.name!='BGR Plans'" [routerLink]="mnu.routerLink">{{mnu.name}}</button>
      <button mat-icon-button class="toolbar-button" *ngIf="mnu.routerLink && mnu.name=='BGR Plans' && profile.is_underwriter && planCreation" [routerLink]="mnu.routerLink">{{mnu.name}}</button>
      <!-- end added  -->
        <button mat-icon-button class="toolbar-button" *ngIf="mnu.submenu" [matMenuTriggerFor]="menuRef[mnu.submenu]">
            <!-- new code for sale user added condition   -->
            <span *ngIf="(profile.data.gist_email === undefined || profile.data.gist_email=='') && (profile.data.gist_cust_id === undefined || profile.data.gist_cust_id=='')">
              <span *ngIf="!mnu.icon">{{mnu.name}}</span>
              <span *ngIf="mnu.icon">
                <mat-icon fontSet="material-icons-outlined">{{mnu.icon}}</mat-icon>
              </span>
            </span>
              <!-- new code for sale user  -->
              <span *ngIf="(profile.data.gist_email !== undefined && profile.data.gist_email!='') || (profile.data.gist_cust_id !== undefined && profile.data.gist_cust_id!='')">
              <span *ngIf="!mnu.icon">
                <span *ngIf="mnu.name=='Quotes'">Certificates </span>
                <span *ngIf="mnu.name!='Quotes'">{{mnu.name}}</span>
              </span>
              <span *ngIf="mnu.icon">
                <mat-icon fontSet="material-icons-outlined">{{mnu.icon}}</mat-icon>
              </span>
            </span>
              <!-- end  -->

          <!-- <span *ngIf="!mnu.icon">{{mnu.name}}</span>
          <span *ngIf="mnu.icon">
            <mat-icon fontSet="material-icons-outlined">{{mnu.icon}}</mat-icon>
          </span> -->
        </button>
    </ng-template>

    <button mat-icon-button *ngIf="profile && profile.cart"
      [ngClass]="cartCount?'cart-count-enable':'cart-count-disable' " (click)="toggleCart()">
      <mat-icon fontSet="material-icons-outlined">shopping_cart</mat-icon>
      <div class="cart-count">{{this.cartCount}}</div>
    </button>
  
    <mat-menu #quotesmenu (closed)="clearSearch()">
      <mat-form-field *ngIf="products.length>10" style="margin-left: 1em; margin-right: 1em;">
        <input [(ngModel)]="searchValue" type="text" autocomplete="off" matInput placeholder="Search" (keyup)="onKey($event);$event.stopPropagation();" (click)="$event.stopPropagation();" (keydown)="$event.stopPropagation();">
      </mat-form-field>
      <ng-template [ngIf]="searchValue">
        <button mat-menu-item (keydown)="$event.stopPropagation();"  tabindex="-1" *ngFor="let p of sel_products">
            <mat-icon fontSet="material-icons-outlined" [routerLink]="['/policy-quote', p.id]" title="Quotes">request_quote</mat-icon>
            <mat-icon fontSet="material-icons-outlined" [routerLink]="['/policy-end', p.id]" title="Endorsements">edit_road</mat-icon>
            <span [routerLink]="['/policy-quote', p.id]"> {{p.name}} </span>
        </button>
        <button mat-menu-item *ngIf="products.length==0" >No product assigned</button>
      </ng-template>

      <ng-template [ngIf]="!searchValue">
        <ng-container *ngFor="let mi of nestedMenu">
          <button mat-menu-item [matMenuTriggerFor]="sub_menu">{{ mi.name }}</button>
          <mat-menu #sub_menu>
		  <span *ngIf="(profile.data.gist_email === undefined || profile.data.gist_email=='') && (profile.data.gist_cust_id === undefined || profile.data.gist_cust_id=='')">
            <button mat-menu-item (keydown)="$event.stopPropagation();" tabindex="-1" *ngFor="let smi of mi.children">
              <mat-icon fontSet="material-icons-outlined" [routerLink]="['/policy-quote', smi.id]" title="Quotes">request_quote</mat-icon>
              <mat-icon fontSet="material-icons-outlined" [routerLink]="['/policy-end', smi.id]" title="Endorsements">edit_road</mat-icon>
              <span [routerLink]="['/policy-quote', smi.id]" >{{ smi.name}}</span>
            </button>
		  </span>
		  <span *ngIf="(profile.data.gist_email !== undefined && profile.data.gist_email!='') || (profile.data.gist_cust_id !== undefined && profile.data.gist_cust_id!='')">
            <button mat-menu-item (keydown)="$event.stopPropagation();" tabindex="-1" *ngFor="let smi of mi.children">
              <mat-icon fontSet="material-icons-outlined" [routerLink]="['/policy-quote', smi.id]" title="Certificates">request_quote</mat-icon>
              <mat-icon fontSet="material-icons-outlined" [routerLink]="['/policy-end', smi.id]" title="Endorsements">edit_road</mat-icon>
              <span [routerLink]="['/policy-quote', smi.id]"  >{{ smi.name }}</span>
            </button>
		  </span>
         </mat-menu>
        </ng-container>
      </ng-template>
    </mat-menu>
    <mat-menu #profilemenu >
      <button mat-menu-item style="pointer-events: none" *ngIf="profile" (click)="theme=!theme">{{profile.email}}</button>
      <button mat-menu-item *ngIf="profile && profile.cart" routerLink="order">My Orders</button>
      <button mat-menu-item *ngIf="!profile">Guest</button>
      <!-- <button mat-menu-item *ngIf="profile" routerLink="settings">Settings</button> -->
      <button mat-menu-item *ngIf="profile" routerLink="org">Users </button>
      <button mat-menu-item *ngIf="profile" (click)="logout()">Logout</button>
      <button mat-menu-item style="pointer-events: none" >{{version}}</button>
    </mat-menu>
  	<mat-menu #reportsubmenu >
	  <button mat-menu-item *ngIf="profile && (profile.data.gist_cust_id || profile.data.gist_email || profile.is_admin)"  routerLink="cancelpolicy">MOP Policies</button>
   <!--  <button mat-menu-item *ngIf="profile && (profile.data.gist_cust_id || profile.data.gist_email || profile.is_admin)"  routerLink="soareport">SOA Report</button> -->
   <button mat-menu-item   *ngIf="showsoareport"  routerLink="soareport">SOA Report</button> 
    <button mat-menu-item *ngIf="showQuoteStatusreport" (click)='report("index.html#form-view?form=quote_status_report")'>Quote Status Report</button>
	 <button mat-menu-item   *ngIf="showNonraterreport"  (click)="report('index.html#form-view?form=quote_data_dump')">Non Rater - Quote Data Dump</button>
	   <button mat-menu-item  *ngIf="showFirereport" (click)="report('index.html#form-view?form=fire_dump')">Fire - Quote Data Dump</button>
	    <button mat-menu-item   *ngIf="showEcreport" (click)="report('index.html#form-view?form=WC_quote_data_dumb')">EC - Quote Data Dump</button> 
  </mat-menu> 
  </mat-toolbar>
  


<!-- removed 56 from style.marginTop.px -->
<mat-sidenav-container class="sidenav-container"
  [style.marginTop.px]="mobileQuery.matches ? 0 : 0">

  <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches" fixedTopGap="40" class="sidenav">

    <div style="display: grid; grid-template-rows: 1fr auto; height: 97%; padding-top: 1em;">
      <mat-tree #menuTree [dataSource]="dataSource" [treeControl]="treeControl" class="itree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="tree-node" (click)="menuClicked(node)">
              <mat-icon fontSet="material-icons-outlined" class="tree-node-icon" *ngIf="node.icon" >{{node.icon}}</mat-icon>
              {{node.name}}
          </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <li *ngIf="node.name != 'Ops'">
            <div class="tree-node" matTreeNodeToggle>
                <mat-icon fontSet="material-icons-outlined" class="tree-node-icon" *ngIf="node.icon" >{{node.icon}}</mat-icon>
                <span *ngIf="node.name != 'Quotes'">{{node.name}}</span>
                <span *ngIf="node.name == 'Quotes' && (profile && (profile.data.gist_email === undefined || profile.data.gist_email =='') && (profile.data.gist_cust_id === undefined || profile.data.gist_cust_id==''))">{{node.name}}</span>
                <span *ngIf="node.name == 'Quotes' && profile && ((profile.data.gist_email !== undefined && profile.data.gist_email !='') || (profile.data.gist_cust_id !== undefined && profile.data.gist_cust_id!=''))">Certificates</span>
                <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
              
            </div>
            <ul [class.tree-invisible]="!treeControl.isExpanded(node)" class="sub-tree-node">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>

      <button mat-menu-item *ngIf="profile" (click)="logout()">Logout</button>
      <!-- <div style="max-height: 100px;">
        <mat-icon fontSet="material-icons-outlined" class="tree-node-icon" >trending_up_outline</mat-icon>Reports
        <ul style="list-style-type: none;">
          <li (click)="gotoReport('/ddview/snapshot')"><mat-icon fontSet="material-icons-outlined" class="tree-node-icon" >space_dashboard</mat-icon> Snapshot</li>
          <li (click)="gotoReport('/ddview/usage')"><mat-icon fontSet="material-icons-outlined" class="tree-node-icon" >timeline</mat-icon> Usage</li>
        </ul>
      </div> -->
    </div>
  </mat-sidenav>

  <mat-sidenav-content id='center-content' style="scrollbar-width: thin;">
    <router-outlet></router-outlet>
    <div class="icart" *ngIf="showCart"><icart (close)="showCart=false"></icart></div>
  </mat-sidenav-content>
  
  </mat-sidenav-container>
  <div class="footer" *ngIf="vendor.footer.show">{{vendor.footer.content}}</div>
</div>

<ng-template #ovlay>
  <div class="price-details">
      <div style="padding-bottom: 1em;">{{vendor?.policy?.premium?.labels?.netPremium||'Net Premium'}}</div><div style="text-align: right;">{{premiumData.premium_value | generic2: premiumData.premium_pipe}}</div>
      <ng-template [ngIf]="premiumData.charges">
        <div>{{vendor?.policy?.premium?.labels?.charges||'Charges'}}</div><div></div>
        <ng-template ngFor let-tp [ngForOf]="premiumData.charges.charge_parts||[]">
          <div style="padding-left: 1em; font-size: 0.9em; color: #666;">{{tp.name}} {{tp.desc}}</div><div style="text-align: right;">{{tp.value | generic2: premiumData.tax_pipe}}</div>
        </ng-template>
      </ng-template>
      
      <div>{{vendor?.policy?.premium?.labels?.tax||'Tax'}}</div><div></div>
      <ng-template ngFor let-tp [ngForOf]="premiumData.tax.tax_parts||[]">
        <div style="padding-left: 1em; font-size: 0.9em; color: #666;">{{tp.name}} {{tp.desc}}</div><div style="text-align: right;">{{tp.value | generic2: premiumData.tax_pipe}}</div>
      </ng-template>
      <div style="margin-top: 1em;"></div><div style="margin-top: 1em; margin-bottom: .5em; border-bottom: 1px solid #999;"></div>
      <div >{{vendor?.policy?.premium?.labels?.totalPremium||'Total Amount'}}</div><div style="text-align: right;">{{premiumData.total_amount | generic2: premiumData.total_pipe}}</div>
  </div>
</ng-template>