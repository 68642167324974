import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupsComponent } from './components/admin/groups/groups.component';
import { RolesComponent } from './components/admin/roles/roles.component';
import { HomeComponent } from './components/home/home.component';
import { UsersComponent } from './components/admin/users/users.component';
import { PolicyComponent } from './components/policy/policy.component';
import { PaymentLedgerComponent } from './components/admin/payment-ledger/payment-ledger.component';
import { PolicyListComponent } from './components/policy-list/policy-list.component';
import { BulkComponent } from './components/bulk/bulk.component';
import { OrderComponent } from './components/order/order.component';
import { EndorsementComponent } from './components/endorsement/endorsement.component';
import { RenewableListComponent } from './components/renewable-list/renewable-list.component';
import { PlanCreationComponent } from './components/plan-creation/plan-creation.component';
import { SoaReportComponent } from './components/soa-report/soa-report.component';
import { CancelPolicyComponent } from './components/cancel-policy/cancel-policy.component';
// import { NstpComponent } from './components/nstp/nstp.component';

const routes: Routes = [
    { path: 'group', component: GroupsComponent },
    { path: 'group/:group_name', component: GroupsComponent },
    { path: 'role', component: RolesComponent },
    { path: 'role/:role_name', component: RolesComponent },
    { path: 'org', component: UsersComponent },
    { path: 'policy', component: PolicyComponent },
    { path: 'endorsement', component: EndorsementComponent},
    { path: 'deposit', component: PaymentLedgerComponent},
    { path: 'policy-quote/:product_id', component: PolicyListComponent},
    { path: 'policy-end/:product_id', component: PolicyListComponent},
    { path: 'order/:order_id', component: OrderComponent},
    { path: 'order', component: OrderComponent},
    { path: 'policy-list', component: PolicyListComponent},
    { path: 'renew-list', component: RenewableListComponent},
    { path: 'bulk/:product', component: BulkComponent},
    { path: 'plan-creation', component:PlanCreationComponent},
    // { path: 'referral', component: NstpComponent},
    { path: 'home', component: HomeComponent },
    { path: '', component: HomeComponent },
	{ path: 'soareport', component: SoaReportComponent },
	{ path: 'cancelpolicy', component: CancelPolicyComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
