import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ViewChild, QueryList, TemplateRef } from '@angular/core';
import { IField } from '../../../form.interface';
import { Subscription } from 'rxjs';
import { insapi, luid, Policy, __fix_getter_setter } from 'insapi';


@Component({
    selector: 'addon-grid',
    templateUrl: './addon-grid.component.html',
    styleUrls: ['./addon-grid.component.scss']
})
export class AddonGridComponent implements OnInit {
    @ViewChildren('fgs') fgs!: QueryList<any>;
    @Output() onChange = new EventEmitter();
    @Input() field: IField = {field_name: '', type: '', addon_groups: []};
    @Input() parent: any = null;
    @Input() pkeys: any = null;
    @Input() policy!: Policy;
    @Input() data!: any;
    @Input() readonly: boolean = false;

    alignleft: boolean = true;

    // grp: any = {fields: [], layout: {cls: 'addon-formgroup'}};
    params: any = {};
    opted: any = {};
    namemap: {[key: string]: any} = {};

    psubscription: Subscription | undefined = undefined;
    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
        // this._policy_changed();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    trackAg(index: number, item: any) {
        return 'ag-' + item.group_name;
    }
    trackAo(index: number, item: any) {
        return item.ukey || item.name;
    }
    trackParam(index: number, item: any) {
        return item.field_name;
    }

    __fix_conditionals(nm: any) {
        if (!nm.ao.iffunc) {
            if (nm.ao.if) nm.ao.iffunc = new Function('data', 'with(data){return  '+nm.ao.if+'}');
            else nm.ao.iffunc = () => true;
        }

        if (!nm.ao.mfunc) {
            if (nm.ao.mandatory) nm.ao.mfunc = new Function('data', 'with(data){return  '+nm.ao.mandatory+'}');
            else nm.ao.mfunc = () => false;
        }

    }

    // __detect_field_change_at(idx: number, field: any) {
    //     if (this.grp.fields.length <= idx) return true;
    //     if (this.grp.fields[idx].field_name != field.field_name) return true;
    //     return false;
    // }

    __fix_param_names(nm: any, index: number, fields: any[]) {
        let changed = false;
        nm.params = [];
        nm.ao.pfields = [];
        for (let p of nm.ao.params) {
            let fldname = this.field.field_name + '.' + index + '.' + p.orig_name;
            
            if (!this.field.fldmap[fldname]) {
                let np = JSON.parse(JSON.stringify(p));
                if (!np.span) np.span = 4;
                if (np.visible === undefined) np.visible = true;
                np.field_name = this.field.field_name + '.' + index + '.' + p.orig_name;
                /*if(!p.getdata)*/ __fix_getter_setter(np);
                // else console.log('setter exists:', p.field_name,p.getdata)
                nm.params.push(np);
                nm.ao.pfields.push(np);
                // fields.push(p);
                // changed = changed || this.__detect_field_change_at(fields.length-1, p);
                this.field.fldlist.push(np);
                this.field.fldmap[fldname] = np;
            } else {
                nm.params.push(this.field.fldmap[fldname]);
                nm.ao.pfields.push(this.field.fldmap[fldname]);
            }

        }
        let opted: any = { field_name: this.field.field_name + '.' + index + '.' + this.field.addon_def.opted_name,
            type: 'lookup', subtype: 'slider', label: '', visible: true, options: [{name: 'Yes', value: 'Yes'},{name: "No", value: 'No'}], span: 4};
        if (this.alignleft) opted.label = nm.ao.desc;

        let valids = this.policy.addons?.[this.field.field_name] || null;
        if (valids && valids.indexOf(nm.ao.name) < 0) opted.readonly = true;

        __fix_getter_setter(opted);
        
        if (!this.field.fldmap[opted.field_name]) {
            this.field.fldlist.push(opted);
            this.field.fldmap[opted.field_name] = opted;
        }
        nm.opted = this.field.fldmap[opted.field_name];
        nm.ao.fopted = this.field.fldmap[opted.field_name];
        // fields.push(opted);

        // nm.opted = opted;
        // changed = changed || this.__detect_field_change_at(fields.length-1, opted);
        return changed;
    }

    __prefill_ao_array() {
        let aarr = this.data[this.field.field_name];
        console.log('pf:', this.field.field_name, structuredClone(aarr));
        let namefld = this.field.addon_def.addon_name;
        let optdfld = this.field.addon_def.opted_name;
        // let namemap: any = {}; // this.field.addon_names;

        for (let nm in this.field.addon_names) {
            if (!this.namemap[nm]) this.namemap[nm] = {row: null, ao: this.field.addon_names[nm], rfixed: false}
            // else this.namemap[nm].row = null;
            this.__fix_conditionals(this.namemap[nm]);
        }
        
        for (let i=0; i<aarr.length; i++) {
            let row = aarr[i];
            if (row.puid === undefined) row.puid = '';
            console.log('row-namefld:', namefld, row[namefld], this.namemap[row[namefld]], 'pid:', row.puid, this.parent.uid);
            if (this.parent && row.puid != this.parent.uid) continue;
           
            if (!row[namefld]) continue;


            // if ((!row.puid && !this.parent) || (row.puid == this.parent.uid)) continue;
            if (!this.namemap[row[namefld]]) {
                row.is_addon_valid = false;
                console.log('invalid addon:', row);
            } else {
                this.namemap[row[namefld]].row = row;
                this.namemap[row[namefld]].rfixed = true;
    
                this.namemap[row[namefld]].ao.row = row;
                this.namemap[row[namefld]].ao.rfixed = true;
            }
        }

        // if an entry in namemap is not found in data array, let add it (prefilling)
        for (let nm in this.namemap) {
            if (this.namemap[nm].rfixed) continue;
            
            let row: any = {[namefld]: this.namemap[nm].ao.name, [optdfld]: 'No', uid: luid(6), puid: this.parent?.uid || ''};
            for (let c of this.field.names) row[c] = row[c] || this.policy.defaults[c] || this.policy.defaults[c+'_tmpl'] || '';
            if (row.puid && this.pkeys) {
                for (let key in this.pkeys) row[this.pkeys[key]] = this.parent[key];
            }
            this.namemap[nm].row = row;
            this.namemap[nm].ao.row = row;
            this.namemap[nm].rfixed = true;
            this.namemap[nm].ao.rfixed = true;
            aarr.push(row);
        }

        let changed = false;
        let fields: any[] = [];
        for (let i=0; i<aarr.length; i++) {
            let row = aarr[i];
            let nm = this.namemap[row[namefld]];
            if (!nm?.ao) continue;
            let tdata = {...this.data, ...(this.parent||{}), ...row};

            // mandatory field, force to Yes
            if (nm.ao.mfunc(tdata)) row[optdfld] = 'Yes';

            // invisible fields gets set to No automatically
            nm.visible = nm.ao.iffunc(tdata);
            if (!nm.visible) row[optdfld] = 'No';
            nm.ao.visible = nm.ao.iffunc(tdata);


            // this is a linked addon and this entry belongs to another parent row
            if (this.parent && row.puid != this.parent.uid) continue;

            row.index = i;
            changed = this.__fix_param_names(this.namemap[row[namefld]], i, fields) || changed; // we need the function called even if change detected in prevs
            console.log('--', row)
        }

        // console.log('changed:', changed, this.field.field_name, this.parent?.uid, fields);
        // if (changed) this.grp.fields = fields;
        // this.grp.name = this.field.field_name;

        
        // else console.log('no change detected ...', this.field.field_name, this.parent?.location_name)
        // this.grp.name = this.field.field_name;
    }


    async _policy_changed() {
        // if (this.grp.fields.length != 0) return;
        if (this.field.link_parent && !this.parent) {
            console.log('--- skipping linked addons', this.field.field_name)
            return;
        }
        
        if (!this.policy) return;
        
        if (!this.parent) this.parent = '';
        if (this.parent && this.field?.fldgrp?.layout) this.field.fldgrp.layout.cls = "addon-formgroup-linked";

        console.log('addon-grid: changed',this.field.field_name)
        this.__prefill_ao_array();
        if (this.fgs) this.fgs.forEach(x => x._changed({}));
    }

}
