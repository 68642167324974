/**
 * @ignore
 */
class Locker {
    acquired: {[key: string]: boolean} = {};
    resolves: {[key: string]: Function[]} = {};

    is_locked(name: string) {
        return this.acquired[name] ? true : false;
    }

    async lock(name: string) {
        // if nobody else have aquired this lock, acquire and return
        //
        if (!this.acquired[name]) {
            this.acquired[name] = true;
            return null;
        }

        if (!this.resolves[name]) this.resolves[name] = [];
        return new Promise((resolve, reject) => this.resolves[name].push(resolve));
    }

    unlock(name: string) {
        if (!this.resolves[name]) {
            this.acquired[name] = false;
            return;
        }

        const resolve = this.resolves[name].shift();
        if (this.resolves[name].length === 0) this.acquired[name] = false;
        if (resolve) resolve();
    }
}

export const ilock: Locker = new Locker();