import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { IField } from '../../form.interface';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { PreferencesService } from '../../../lib/services/preferences.service';
import { IPolicy, IEndorsement } from 'insapi';

@Component({
    selector: 'i-input',
    template: `
    <ng-template [ngIf]="field.multi==1">
        <mat-form-field [formGroup]="group">
            <mat-chip-list #chipList *ngIf="control">
                <mat-chip *ngFor="let val of control.value"
                    [selectable]="true" [removable]="true" (removed)="remove(val)" [attr.data-iid]="field.field_name+'-val'">
                    {{val}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input [placeholder]="field.label||''" #multiInput [formControl]="multiCtrl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)" [attr.data-iid]="field.field_name">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let val of filteredValues | async" [value]="val">
                {{val}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </ng-template>

    <ng-template [ngIf]="field.multi!=1 && field.area==1">
        <mat-form-field [formGroup]="group">
            <mat-label [ngClass]="field.labelclass">{{field.label}}</mat-label>
            <textarea matInput [formControlName]="field.field_name" [title]="field.title"
                [placeholder]="field.label||''" (change)="onChange.emit(field)" [attr.data-iid]="field.field_name">
            </textarea>
        </mat-form-field>
    </ng-template>

    <ng-template [ngIf]="field.multi!=1 && field.area!=1">
        <mat-form-field [formGroup]="group">
            <mat-label [ngClass]="field.labelclass">{{field.label}}</mat-label>
            <ng-template [ngIf]="field.mask=='currency'">
                <masked-input matInput [formControlName]="field.field_name" [title]="field.title ?? data[field.field_name] ?? '' | generic2: field.title_format" 
                    [placeholder]="field.label||''" 
                    [mask]="field.props.pattern"
                    [decimal]="field.props.decimal"
                    [symbol]="symbol(data)"
                    (change)="onChange.emit(field)" 
                    [attr.data-iid]="field.field_name"></masked-input>
            </ng-template>
            <ng-template [ngIf]="field.mask=='mobile-no'">
                <intl-phone-number matInput [formControlName]="field.field_name" [title]="field.title ?? data[field.field_name] ?? '' | generic2: field.title_format" 
                    [placeholder]="field.label||''" 
                    [default_code]="field.default_code"
                    [mandatory]="field.validate=='mandatory'"
                    [showFlag]="preferences?.vendor?.widgets?.['mobile-no']?.showFlag"
                    (change)="changed()" 
                    [attr.data-iid]="field.field_name"></intl-phone-number>
            </ng-template>
            <ng-template [ngIf]="field.mask=='country'">
                <country matInput [formControlName]="field.field_name" [title]="field.title ?? data[field.field_name] ?? '' | generic2: field.title_format" 
                    [placeholder]="field.label||''" 
                    [showFlag]="preferences?.vendor?.widgets?.['country']?.showFlag"
                    (change)="changed()" 
                    [attr.data-iid]="field.field_name"></country>
            </ng-template>
            <ng-template [ngIf]="!field.mask">
                <input matInput [formControlName]="field.field_name" [title]="field.title ?? data[field.field_name] ?? '' | generic2: field.title_format" 
                    [placeholder]="field.label||''" 
                    type="text" 
                    (change)="onChange.emit(field)" 
                    [attr.data-iid]="field.field_name">
            </ng-template>

            <mat-icon class="i-icon" *ngIf="field.icon" matPrefix fontSet="material-icons-outlined" style="padding-right: 8px;">{{field.icon}}</mat-icon>
            <mat-icon class="i-icon" *ngIf="field.icon_suffix" matSuffix fontSet="material-icons-outlined">{{field.icon_suffix}}</mat-icon>
            <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
                <mat-error *ngIf="control && validation.validator && control.hasError(validation.validator.name)">{{field.errors || (field.field_name | capitalize) + validation.message}}</mat-error>
            </ng-container>
            <mat-error *ngIf="control && control.hasError('ssValidator')">
                {{control?.errors?.ssValidator?.msg}}
            </mat-error>
            <div class="input-sub-text" *ngIf="field.subtext && field.subtext.varname">{{field.subtext.desc}} {{data[field.subtext.varname] | generic2: field.subtext.pipe}}</div>
        </mat-form-field>
    </ng-template>
    
  `,
    styles: [
        `.i-icon {vertical-align: middle;  color: #88C; font-size: 17px;}`
    ]
})
export class InputComponent implements OnInit {

    @Output() onChange = new EventEmitter();
    field!: IField;
    group!: FormGroup;
    data!: any;
    @Input() policy: any;
    control!: FormControl;

    multiCtrl = new FormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredValues!: Observable<string[]>;
    allValues: string[] = [];

    symbol: Function = (data: any) => '';

    @ViewChild('multiInput') multiInput!: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete!: MatAutocomplete;
    constructor(public preferences: PreferencesService) { }

    ngOnInit(): void {
        if (this.field?.mask == 'currency') {
            this.field.props.pattern = this.field.props.pattern || this.preferences.vendor?.currency.mask;
            this.field.props.decimal = this.field.props.decimal ?? this.preferences.vendor?.currency.decimal;

            let symb = this.field.props.symbol || this.preferences.vendor?.currency.symbol || '';
            if (symb.startsWith('data.')) this.symbol = new Function('data', 'return ' + symb);
            else this.symbol = new Function('data', 'return "' + symb + '"');
        }
        
        if (this.field?.mask == 'mobile-no') {
            this.field.default_code = this.preferences.vendor?.currency.calling_code;
        }
    }

    _add(value: string) {
        let ca = this.group.controls[this.field.field_name].value;
        if (!ca) ca = [];
        if (!(ca instanceof Array)) ca = [ca];
        ca.push(value);
        this.group.controls[this.field.field_name].setValue(ca);
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) this._add(value);
        event.input!.value = '';
        this.multiCtrl.setValue(null);
    }

    remove(value: string): void {
        let ca = this.group.controls[this.field.field_name].value;
        if (!ca) ca = [];
        const index = ca.indexOf(value);
        if (index >= 0) ca.splice(index, 1);
        this.group.controls[this.field.field_name].setValue(ca);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this._add(event.option.viewValue);
        this.multiInput.nativeElement.value = '';
        this.multiCtrl.setValue(null);
    }

    changed() {
        this.onChange.emit(this.field);
    }
}
