import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { insapi } from 'insapi';

@Component({
  selector: 'app-plan-creation',
  templateUrl: './plan-creation.component.html',
  styleUrls: ['./plan-creation.component.scss']
})
export class PlanCreationComponent implements OnInit {

  baseURL:any;
  constructor(public router:Router) { }

  async ngOnInit(): Promise<void> {

    var url = document.getElementsByTagName('base')[0].href;
    // var url ="https://partnerqmskgidev.cloware.in"
    var arr = url.split("/");
    this.baseURL=arr[0]+"//"+arr[2]+"/";
    let product_id = await insapi.__xget('/api/v1/BGR/getproductid');
    console.log("productid",product_id);
    console.log(this.baseURL);
        // window.open(this.baseURL+"adm/plan/M000000000012", "_blank");  // QC
        // window.open(this.baseURL+"adm/plan/M000000000010", "_blank");  // DEV
        window.open(this.baseURL+"adm/plan/"+product_id, "_blank");
        this.router.navigateByUrl('/home');
  }

}
