import { Component, OnInit, Input, Inject } from '@angular/core';
import { Policy, excelDateToDate, insapi } from 'insapi';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';

@Component({
    selector: 'app-policy-calendar',
    templateUrl: './policy-calendar.component.html',
    styleUrls: ['./policy-calendar.component.scss']
})
export class PolicyCalendarComponent implements OnInit {

    @Input() policy!: Policy;
    mode: number = 0;
    entities: any[] = [];
    min: moment.Moment | null = null;
    max: moment.Moment | null = null;
    more: any = null;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
        if (this.data?.policy) this.policy = this.data.policy;
        this.__init();
    }

    __add_event_to_bucket(event: any) {
        for (let p of this.entities) {
            if (event.mom.isSameOrAfter(p.pstart) && event.mom.isBefore(p.pend)) {
                p.events.push(event);
                break;
            }
        }
    }


    async __init() {
        console.log('cal:', this.policy?.policy?.policy_id)
        if (!this.policy?.policy?.policy_id) return;
        let events = await insapi.__xget('/api/v1/policy/calendar/' + encodeURIComponent(this.policy.policy.policy_id)) || [];
        console.log('events:', events);
        let data = this.policy.policy.proposal_id ? this.policy.policy.proposal.data : this.policy.policy.quote.data;
        let min = excelDateToDate(this.policy.policy.policy_start_date || data.policy_start_date)
        let max = excelDateToDate(this.policy.policy.policy_end_date || data.policy_end_date);
        events.push({subject: 'Policy start', note_ts: min?.format('YYYY-MM-DD 00:00:00')});
        events.push({subject: 'Policy end', note_ts: max?.format('YYYY-MM-DD 11:59:59')});
        for (let ev of events) {
            ev.mom = moment(ev.note_ts);
            if (ev.mom.isBefore(min)) min = ev.mom;
            if (ev.mom.isAfter(max)) max = ev.mom;
        }

        if (min?.isValid()) this.min = min;
        if (max?.isValid()) this.max = max;

        this.entities = [];
        let pstart = min;
        while (pstart?.isBefore(max)) {
            let pend = null;
            if (this.mode == 0) {
                pend = pstart.clone().endOf('month');
            } else {
                pend = pstart.clone().endOf('day');
            }
            // console.log('ps:', pstart.format('YYYY-MM-DD HH:mm:ss'), pend.format('YYYY-MM-DD HH:mm:ss'))
            
            let ent: any = {events: []};
            ent.disp = this.mode == 0 ? pstart.format('MMM-YYYY') : pstart.format('D');
            ent.pstart = pstart.clone();
            ent.pend = pend.clone();
            this.entities.push(ent);
            
            pstart = pend.add(1, 'second');
            if (this.entities.length>365) break;
        }

        for (let ev of events) {
            ev.ts = moment(ev.note_ts).format(this.mode == 0 ? 'Do' : 'HH:mm');
            console.log('ev:', ev);
            this.__add_event_to_bucket(ev);
        }
    }
}
