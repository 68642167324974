import { Directive, Input, OnInit, HostBinding} from '@angular/core';
import { PreferencesService } from '../../lib/services/preferences.service';

@Directive({
    selector: '[ibutton]'
})
export class IButtonDirective implements OnInit {

    @HostBinding('class.insbutton') insbutton = true;
    @HostBinding('class.mat-raised-button') raised = false;
    @HostBinding('class.mat-flat-button') flat = false;
    @HostBinding('class.mat-stroked-button') stroked = false;
    constructor(private preferences: PreferencesService) { }

    ngOnInit() {
        this.stroked = this.preferences.buttonStyle == 'stroked';
        this.flat = this.preferences.buttonStyle == 'flat';
        this.raised = this.preferences.buttonStyle == 'raised';
    }
}
