import { Component, OnInit, Input } from '@angular/core';
import { PreferencesService } from 'ins-form';
import { insapi, Policy } from 'insapi';
import { Subscription } from 'rxjs';

@Component({
    selector: 'submissions',
    templateUrl: './submissions.component.html',
    styleUrls: ['./submissions.component.scss']
})
export class SubmissionsComponent implements OnInit {

    @Input() policy!: Policy;
    psubscription: Subscription | null = null;
    submissionKey: string = '';
    submissions: any[] = [];
    columns: any[] = [];
    constructor(protected preferences: PreferencesService) { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._policy_changed());
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = null;
    }

    async _policy_changed() {
        await this.__init();
    }

    async __init() {
        let product = this.policy?.product;
        if (!this.policy?.policy?.quote_id || !product?.data.submissions) {
            this.submissionKey = '';
            this.submissions = [];
            return;
        }
        let submissions = this.preferences.vendor?.submissions?.[product.product_group_name] ?? 
            this.preferences.vendor?.submissions?.['all-groups'];
        this.columns = submissions.columns;

        let qdata = this.policy?.policy?.quote?.data;
        let skey = (product.data.submissions.key_1 ? qdata[product.data.submissions.key_1] : '') ?? '';
        skey += '~' + (product.data.submissions.key_2 ? qdata[product.data.submissions.key_2] : '') ?? '';
        skey += '~' + (product.data.submissions.key_3 ? qdata[product.data.submissions.key_3] : '') ?? '';

        if (this.submissionKey != skey) {
            let fields = this.columns.map(x => x.name);
            let url = '/api/v1/quote/submissions?quote_id=' + encodeURIComponent(this.policy.policy.quote_id);
            url += '&fields=' + encodeURIComponent(fields.join(','));
            let subm = await insapi.__xget(url) || [];
            this.submissions = subm.filter((x: any) => x.policy_id != this.policy.policy?.policy_id);
            console.log('submissions:', this.submissions);
            this.submissionKey = skey;
        }
    }

}
