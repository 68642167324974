<div class="mat-dialog-override">
    <mat-dialog-content>
        <div class="csv-header" >Import {{data.caption}}</div>
        <div style="padding: 1em 1em;">
            <button mat-button id="import-file" (click)="csvfile.click()">{{selcaption}}</button>
            <input hidden type="file" #csvfile  (change)="onFileSelected(csvfile.files)" [accept]="accept">{{selmessage}}
        </div>
    
        <div style="font-size: 1.5em; padding: 1em 0em 0em 0em;">Preview 
            <small *ngIf="parsed.length>0" style="font-size: 0.8em;"> 
                <span style="cursor: pointer; font-style: italic; text-decoration: underline;" (click)="showErrors=false">{{parsed.length}} </span> found with 
                <span style="cursor: pointer; font-style: italic; text-decoration: underline;" (click)="showErrors=true"> {{errCount}} errors </span>
            </small>
        </div>
    
        <ng-template [ngIf]="!jdata">
            <div class="csv-preview">
                <div>
                    <table class="csv-table">
                        <tr><th *ngFor="let col of headers">{{col}}</th></tr>
                        <tr *ngFor="let row of parsed; let r=index;">
                            <ng-template [ngIf]="!showErrors || status[r]==true">
                                <td *ngFor="let col of row; let c=index;" [title]="errors[r][c]">
                                    <mat-icon fontSet="material-icons-outlined" class="csv-err-icon" *ngIf="errors[r][c]" >close</mat-icon>
                                    <ng-container *ngIf="data.validations[c] && data.validations[c].date; else nonDate">
                                        {{col | idate}}
                                    </ng-container>
                                    <ng-template #nonDate>
                                        {{col}}
                                    </ng-template>
                                </td>
                            </ng-template>
                        </tr>
                    </table>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="jdata">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab *ngFor="let itm of jdata|keyvalue" [label]="itm.key">
                    <ng-template [ngIf]="itm.key=='common'" [ngIfElse]="jarr">
                        <div class="grid-12" style="padding-top: 1em;">
                            <div *ngFor="let nitm of itm.value|keyvalue">
                                <div>{{nitm.key}}</div>
                                <div>{{nitm.value}}</div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #jarr>
                        <table class="csv-table"  style="margin-top: 1em;">
                            <tr><th *ngFor="let col of jcolumns[itm.key]">{{col}}</th></tr>
                            <tr *ngFor="let row of itm.value;">
                                <td *ngFor="let col of jcolumns[itm.key]">{{row[col]}}</td>
                            </tr>
                        </table>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions>
        <ng-template [ngIf]="!jdata">
            <button mat-button ibutton (click)="onAction('append')" *ngIf="parsed.length>0">Append</button>
            <button mat-button ibutton (click)="onAction('replace')" *ngIf="parsed.length>0">Replace</button>
        </ng-template>
        <ng-template [ngIf]="jdata">
            <button mat-button ibutton *ngFor="let axn of actions" (click)="onAction(axn)">{{axn | capitalize}}</button>
        </ng-template>
        <button mat-button ibutton (click)="onClose()" >Close</button>
    </mat-dialog-actions>
</div>

<!-- <div class="csv-import">
    <div class="csv-header" >Import {{data.caption}}</div>
    <mat-dialog-content>
        <div style="padding: 1em 1em;">
            <button mat-button id="import-file" (click)="csvfile.click()">{{preferences.vendor?.strings?.csv_select_label || 'Select CSV file' }}</button>
            <input hidden type="file" #csvfile  (change)="onFileSelected(csvfile.files)" [accept]="accept">
            {{preferences.vendor?.strings?.csv_select_message || 'First line of file must have headers'}}
        </div>
    
        <div style="font-size: 1.5em; padding: 1em 0em 0em 0em;">Preview 
            <small *ngIf="parsed.length>0" style="font-size: 0.8em;"> 
                <span style="cursor: pointer; font-style: italic; text-decoration: underline;" (click)="showErrors=false">{{parsed.length}} </span> found with 
                <span style="cursor: pointer; font-style: italic; text-decoration: underline;" (click)="showErrors=true"> {{errCount}} errors </span>
            </small>
        </div>
    
        <ng-template [ngIf]="!jdata">
            <div class="csv-preview">
                <div>
                    <table class="csv-table">
                        <tr><th *ngFor="let col of headers">{{col}}</th></tr>
                        <tr *ngFor="let row of parsed; let r=index;">
                            <ng-template [ngIf]="!showErrors || status[r]==true">
                                <td *ngFor="let col of row; let c=index;" [title]="errors[r][c]">
                                    <mat-icon fontSet="material-icons-outlined" class="csv-err-icon" *ngIf="errors[r][c]" >close</mat-icon>
                                    <ng-container *ngIf="data.validations[c] && data.validations[c].date; else nonDate">
                                        {{col | idate}}
                                    </ng-container>
                                    <ng-template #nonDate>
                                        {{col}}
                                    </ng-template>
                                </td>
                            </ng-template>
                        </tr>
                    </table>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="jdata">
            <div class="csv-preview">
                <div>
                    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                        <mat-tab *ngFor="let itm of jdata|keyvalue" [label]="itm.key">
                            <ng-template [ngIf]="itm.key=='common'" [ngIfElse]="jarr">
                                <div class="grid-12" style="padding: 1em;">
                                    <div *ngFor="let nitm of itm.value|keyvalue">
                                        <div>{{nitm.key}}</div>
                                        <div>{{nitm.value}}</div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #jarr>
                                {{itm.key}}
                                <table class="csv-table">
                                    <tr><th *ngFor="let col of jcolumns[itm.key]">{{col}}</th></tr>
                                    <tr *ngFor="let row of itm.value;">
                                        <td *ngFor="let col of jcolumns[itm.key]">{{row[col]}}</td>
                                    </tr>
                                </table>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div [ngIf]="!jdata">
            <button mat-button ibutton (click)="onAction('append')" *ngIf="parsed.length>0">Append</button>
            <button mat-button ibutton (click)="onAction('replace')" *ngIf="parsed.length>0">Replace</button>
            <button mat-button ibutton (click)="onClose()" >Close</button>
        </div>

        <div [ngIf]="jdata">
            <button mat-button ibutton *ngFor="let axn of actions" (click)="onAction(axn)">{{axn | capitalize}}</button>
            <button mat-button ibutton (click)="onClose()" >Close</button>
        </div>
    </mat-dialog-actions>
</div> -->
