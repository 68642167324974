<div class="cart-container" >
    <div class="caption" >{{cartSubIds[idx]?.name || 'YOUR CART'}}
        <mat-icon class="small-icon" fontSet="material-icons-outlined"  (click)="prevCart()" *ngIf="cartSubIds.length>1">chevron_left</mat-icon>
        <mat-icon class="small-icon" fontSet="material-icons-outlined"  (click)="nextCart()" *ngIf="cartSubIds.length>1">chevron_right</mat-icon>
    </div>
    <div *ngIf="cart && cart.items.length>0">
        <div class="cart-item"  *ngFor="let item of cart.items" >
            <div class="cart-product-name" (click)="goto(item)">
                {{item.item_name | uppercase}}
                <div class="cart-product-desc">{{item.item_name}}</div>
            </div>
            <div style="text-align: right;">{{item.premium | currency}}</div>
            <div style="text-align: right; color: #999;">
                <mat-icon class="small-icon" fontSet="material-icons-outlined" title="Add to saved list" (click)="remove(item)">remove_circle</mat-icon>
            </div>
        </div>
        <div class="cart-total">
            <div>TOTAL</div>
            <div class="cart-item-amount">{{cart.total | currency}}</div>
            <div></div>
            <ng-template ngFor let-key let-i="index" [ngForOf]="taxKeys" >
                <div class="cart-item-tax">{{key}}</div>
                <div class="cart-item-amount">{{cart.tax[key] | currency}}</div>
                <div></div>
            </ng-template>
        </div>
        <div class="cart-net">
            {{cart.net | currency}}
        </div>
    </div>
    <div class="cart-footer" *ngIf="cart && cart.items.length>0">
        <div class="form-field">
            <mat-form-field style="width: 100%;">
                <input matInput [(ngModel)]="promoCode" placeholder="Promo Code">
                <button mat-button matSuffix >Apply</button>
            </mat-form-field>
        </div>
        
        <button mat-button ibutton (click)="checkout()">BUY</button>
        <button mat-button ibutton (click)="checkoutOnline()" *ngIf="cart">Pay Online</button>
        <button mat-button ibutton (click)="close.emit()">Close</button>
    </div>
</div>
<div class="cart-container">
    <div class="caption" style="text-align: center; padding-bottom: 2em;" *ngIf="cart && cart.items.length==0">YOUR CART IS EMPTY</div>
    <div *ngIf="saved.length>0">
        <div class="caption">SAVED PROPOSALS</div>
        <div class="cart-item"  *ngFor="let item of saved" >
            <div class="cart-product-name" (click)="goto(item)">
                {{item.item_name | uppercase}}
                <div class="cart-product-desc">{{item.item_name}}</div>
            </div>
            <div style="text-align: right;">{{item.premium | currency}}</div>
            <div style="text-align: right; color: #999;">
                <mat-icon class="small-icon" fontSet="material-icons-outlined" title="Add back to cart" (click)="moveToCart(item)">add_shopping_cart</mat-icon>
                <mat-icon class="small-icon" fontSet="material-icons-outlined" title="Remove from saved list" (click)="removeFromSaved(item)">remove_circle</mat-icon>
            </div>
        </div>
    
    </div>

</div>
