import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { IForm } from 'ins-form';
import { http, insapi, IProfile, InsapiService  } from 'insapi';
import { MatDialog } from '@angular/material/dialog';
import { TwofaComponent } from '../../../twofa.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    profile: IProfile | null = null;
    @Input() data: any = {};
    avatar: string = '';
    groups: any[] = [];
    roles: any[] = [];

    form: IForm = {groups: [], layout: {}};
    psubscription: Subscription | null = null;
    timeStamp: any = Math.round((new Date()).getTime()/(60*60*1000));   // to prevent voiding cache
    constructor(protected router: Router,
        public dialog: MatDialog) { }

    ngOnInit(): void {
        this.psubscription = insapi.profileSubject.subscribe((profile) => {
            this.profile=profile;
        });
        this.init();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = null;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        if (changes['data']) {
            this.form = JSON.parse(JSON.stringify(this.form));
            if (this.data?.email) {
                this.avatar = this.data.email.split('@').join('.') + '.jpg';
                this.loadOtherInfo();
            }
        }
    }

    async init() {
        try{
            if (this.profile)
                this.data = this.profile;
            if (this.data?.email) this.avatar = this.data.email.split('@').join('.') + '.jpg';
            let code = await insapi.form('profile');
            this.form = Function('"use strict";'+code)();
        } catch (e) {
            console.log(e);
        }
    }

    async onAction(ev: any) {
        console.log('profile: ', ev?.field_name);
        if (ev.field_name == 'saveProfile') {
            try{
                let data = Object.assign({}, this.data);
                delete data['old_password'];
                delete data['new_password'];
                delete data['confirm_password'];
                delete data['two_f_a'];
                delete data['two_f_a_img'];
                let res :any ={};
                res = await insapi.__xpost('/api/v1/profile',data);
                if(data.status == 0)
                    insapi.showMessage('Profile saved',0);
                }
            catch (e) {
                console.log(e);
            }
        }
        else if(ev.field_name == 'changePassword') {
            if (this.data.confirm_password != this.data.new_password) return insapi.showMessage("New password and confirm password are different", 0);
        	let res = await insapi.__xpost('/api/v1/profile/change_password', {new_password: this.data['new_password'], old_password: this.data['old_password']});
			if(res&&res.status == 0)
                insapi.showMessage('Your password has been changed...',0);
            else if (res)
                insapi.showMessage(res.txt || "Change Password failed...",1);
                    
        }
        else if (ev.field_name == 'webPushSubcribe'){
              
        }   
        else if (ev.field_name == 'two_f_a_gen'){
        	try{   
                let two_fa_gen ='';
                two_fa_gen = this.data.two_f_a;
                let res = await insapi.__xpost('/api/v1/profile/twofa',{'two_f_a':two_fa_gen});
                this.data.two_f_a_img= res.qrcode;
                if(res && res.qrcode) {
                    let data = {
                        two_f_a_img:res.qrcode,
                        actions: [{name: 'close', disp: 'Close'}]
                    };
                    
                    //console.log("two_f_a_img res", res);
                    this.dialog.open(TwofaComponent, {data});
                }
            }
            catch (e) {
                console.log(e);
            }     
       }
       else if (ev.field_name == 'two_f_a_remove'){
            try{
                let res :any ={};
                let email = '';
                email = this.data.email;
                await insapi.__xget('/api/v1/profile');        
                res = await http.xreq( insapi.server + '/api/v1/profile/twofa/reset/'+ encodeURIComponent( email ),'post');
                if(res&&res.status == 0)
                insapi.showMessage('QR code has been removed...',0); 
            }
            catch (e) {
                console.log(e);
            }
        }
        else
            console.log("Users Action not Implemented");
    }

    async onUpload(ev: any) {
        let evfile = ev.target.files[0];
        const formData = new FormData();
        formData.append('name', evfile.name);
        formData.append('file_type', 'profile');
        formData.append('field_name', 'profile');
        formData.append('args', '');
        formData.append('file', evfile, evfile.name);
        await insapi.__xpost('/api/v1/profile/attach', formData);
        this.timeStamp = (new Date()).getTime();
    }

    async loadOtherInfo() {
        if (!this.profile) return;
        this.groups = await insapi.xget("/api/v1/group/groups?email=" + encodeURIComponent(this.data.email)) || [];
        this.roles = await insapi.xget("/api/v1/role/roles?email=" + encodeURIComponent(this.data.email)) || [];
    }

}
