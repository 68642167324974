import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { insapi } from 'insapi';
import { environment } from './../environments/environment';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	
})
export class LoginComponent {
    email: string = '';
	password: string = '';
	totp: string = '';
	verifyOtp:string = '';
	type: string = 'password'; 
	old_type: string = 'password'; 
	new_type: string = 'password'; 
	bgImg: string = environment.vendor.login_bg_image || '';
	usericonImg: string = environment.vendor.user_icon_image || '';
	pwdiconImg: string = environment.vendor.pwd_icon_image || '';
	logoImg: string = environment.vendor.logo_bg || '';
	rightbgImg: string = environment.vendor.right_bg || '';
	leftbgImg: string = environment.vendor.left_bg || '';
	loginfullscreen: string = environment.vendor.login_fullscreen;

	otp_validity: number = environment.vendor.otp_validity || 300;
	showOTP: boolean = false;
	frgtPass: boolean = false;
	timeLeft: number = -1;
	timerFlag: boolean = false;
	interval: NodeJS.Timeout | undefined;
	showTOTP: boolean = false;
	showChangePwd: boolean = false;
	new_pwd: any;
	old_pwd: any;
	cnfrm_pwd: any;
	changePasswordKey:string = '';
	adlogin: boolean = false;

	constructor(public dialogRef: MatDialogRef<LoginComponent>, public router: Router) { 
		if (environment.vendor?.ad_hostname?.includes(window.location.hostname)){
			this.adlogin = true;
		}
	}
	
    async onLogin() {
		try{
			console.log(environment.vendor?.ad_hostname,window.location.hostname,environment.vendor?.ad_hostname?.includes(window.location.hostname));
			if (environment.vendor?.ad_hostname?.includes(window.location.hostname))
				await insapi.ad_auth(this.email, this.password, environment.vendor?.ad_success_api);
			else	
				await insapi.auth(this.email, this.password, '', '', this.totp);
			this.dialogRef.close();
		} catch (e: any) {
			if (e?.message && e.message.startsWith('Auth expired:')) {
				this.showChangePwd =true;
				this.changePasswordKey = e.message.substring(14);
			} else if (e?.message && e.message.startsWith('-121')) {
				console.log('emsg:', e.message)
				if (!this.showTOTP)
					this.showTOTP = true;
				else
					insapi.showMessage('Invalid Two Factor Token', 0);	
			} else if (e?.message && e.message.indexOf('xpired') > 0) {
				insapi.showMessage(e.message, 0);
			} else if(e?.message && e.message.startsWith('-120')){
			insapi.showMessage('Your account has been locked due to multiple failed attempts. Please contact the administrator to unlock',0);
			}else {
				console.log('login:', e.message);
				insapi.showMessage('Authentication failed', 0);
			}
		}
    }
    async onSendOTP() { 
		if(this.email != ""){
			this.showOTP = true
			this.verifyOtp = ""
			let ret = await insapi.login_otp(this.email);
			if (ret.status == 0) {
				insapi.showMessage(ret.data, 0);
				this.timeLeft= this.otp_validity;
				this.interval = setInterval(() => {
					if (this.timeLeft > 0) {
						
					this.timeLeft--;
					this.timerFlag = true;
					console.log('time up', this.timeLeft);
					}else{
						this.timerFlag = false;
						if (this.interval) clearInterval(this.interval);
						return;
					}
				}, 1000);
			} 
		}else{
			insapi.showMessage('Please enter email', 0);
		}
    }

	async navigateToBack(){
		this.showOTP = false
		this.frgtPass = false;
		this.showChangePwd =false;
		if (this.interval) clearInterval(this.interval);
	}

	async onVerifyOTP() {
		try{
			if (!this.verifyOtp) return; 
			if (this.interval) clearInterval(this.interval);
			await insapi.verify_otp(this.verifyOtp,this.email);
			this.dialogRef.close();
		} catch (e: any) {
			insapi.showMessage(e, 1);
		}
	}

	showPassword() {
        this.type = "text";
        setTimeout(() => this.type="password", 3000);
	}

	showOldPassword() {
		this.old_type = "text";
		setTimeout(() => this.old_type="password", 3000);
	}
	showNewPassword() {
		this.new_type = "text";
		setTimeout(() => this.new_type="password", 3000);
	}
	
	async forgetPassword() {
		if(this.email==''){
			insapi.showMessage('Email id is mandatory... ', 0);
			return;
		}
		await insapi.__xpost('/api/v1/auth/forgot', {email : this.email});
		this.frgtPass = true;
	}

	        
	async save_password(){
		try{
			if(this.cnfrm_pwd === this.new_pwd) {
				const res = await insapi.__xpost('/api/v1/auth/url',{old_password: this.old_pwd, new_password: this.new_pwd, key: this.changePasswordKey});
    			if(res&&res.status == 0) {
					insapi.showMessage('Your password has been changed...Login with new password.',0);
					this.showChangePwd =false;
					return;
				}
				else if (res)
					insapi.showMessage(res.txt || "Change Password failed...",0);  
			} else {
				insapi.showMessage("New and Confirm password should match",0);  	
			}
		} catch (e) {
			console.log(e);
		} 
	  }

}
