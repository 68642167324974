import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { IField } from '../../form.interface';

@Component({
    selector: 'i-checkbox',
    template: `
    <div class="icheckbox">
        <mat-checkbox [ngModel]="data[field.field_name] === field.checkbox?.truevalue ? true : false" [attr.data-iid]="field.field_name"
            (ngModelChange)="changed($event ? field.checkbox.truevalue : field.checkbox.falsevalue)"
            [title]="field.title ?? '' | generic2: field.title_format">{{field.label}}</mat-checkbox>
    </div>  `,
    styles: [
    ]
})
export class CheckboxComponent implements OnInit {
    @Output() onChange = new EventEmitter();
    data!: any;
    field!: IField;
    group!: FormGroup;
    constructor() { }

    ngOnInit(): void {
        if (!this.field.checkbox) this.field.checkbox = {truevalue: true, falsevalue: false};
        if (this.field.checkbox.truevalue === undefined) this.field.checkbox.truevalue = true;
        if (this.field.checkbox.falsevalue === undefined) this.field.checkbox.falsevalue = false;
    }
    changed(value: any) {
        if (this.field.setdata) this.field.setdata(this.data, value);
        this.onChange.emit(this.field);
    }
}
