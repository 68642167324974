<ng-template [ngIf]="field.template_name" [ngIfElse]="regular">
    <div [innerHTML]="outHtml"></div>
</ng-template>
<ng-template #regular>
<div class="outputs">
    <div [class]="['grid-'+(field.grid||2), 'outputs-wrap']">
        <div class="outputs-item" *ngFor="let op of outputs">
            <div class="outputs-item-caption" [attr.data-iid]="op.disp">{{op.disp}}</div>
            <div class="outputs-item-value" [attr.data-iid]="op.disp" [title]="op.tip">{{op.value | generic2: op.formatter}}</div>
        </div>
    </div>
</div>
</ng-template>