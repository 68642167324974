import { Component, OnInit, Output, EventEmitter, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IField, animRotate, IForm } from '../../form.interface';
import { Policy, IPolicy, insapi, IProduct } from 'insapi';
import { Subscription } from 'rxjs';
import { fn } from 'moment';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'lib-wordings',
    templateUrl: './wordings.component.html',
    styleUrls: ['./wordings.component.scss'],
    animations: [animRotate]
})
export class WordingsComponent implements OnInit {
    
    @ViewChild('dialogRef') dialogRef!: TemplateRef<any>;
    @Output() onChange = new EventEmitter();
    policy: any = {};
    field!: IField;
    group!: FormGroup;
    data: any = {};
    wordings: any[] = [];
    readonly!: boolean;
    _slider: boolean = false;
    __url: string = '';
    clauseName: string = '';
    description: string = '';
    customid: number = 1;
    psubscription: Subscription | undefined = undefined;
    selected: any = {disp:'',tooltip:'', value:''};

    constructor(public dialog: MatDialog) { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject?.subscribe(() => this._init());

        //this.psubscription = this.policy.policySubject.subscribe((policy: IPolicy|null) => this._init());
        //this._init();
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    openTempDialog(option: any) {
        let data = {
            caption: option.value + ' : ' + option.disp, 
            description: option.tooltip
        };
        this.dialog.open(this.dialogRef, {data});
    }
    

    async _init() {
        if (!this.data[this.field.field_name]) return;

        if (insapi.profile?.is_underwriter)
            this.field.editable = "true";
        this.wordings = [];
        this.customid = 1;
        for (let fname of this.data[this.field.field_name]) {
            if (fname[this.field.op_field_value]) {
                this.wordings.push(
                    {
                        disp: fname[this.field.op_field_name],
                        value: fname[this.field.op_field_value],
                        tooltip: fname[this.field.op_tooltip],
                        applicability: fname[this.field.op_applicability],
                        selected: fname['selected']
                    });
                if (fname[this.field.op_applicability] === 'custom') {
                    let maxcustvalue = Number(fname[this.field.op_field_value].replace(/[^0-9]/g,'')) || 0;
                    this.customid = maxcustvalue+1;
                }
                    

            }

        }

        //console.log("this.wordings Init",this.wordings);
        //}
    }

    _toggle(element: any, wordingsItem: any) {

        //console.log("this.wordings _toggle:",element, wordingsItem, this.wordings);
        for (let fname in this.data[this.field.field_name]) {
            if (this.data[this.field.field_name][fname][this.field.op_applicability] != "fixed" && this.data[this.field.field_name][fname][this.field.op_field_value] == wordingsItem.value) {
                //console.log("this.wordings _toggle:",this.data[this.field.field_name][fname]['selected']);

                if (this.data[this.field.field_name][fname]['selected'] === 'undefined')
                    this.data[this.field.field_name][fname]['selected'] = true;
                else
                    this.data[this.field.field_name][fname]['selected'] = !this.data[this.field.field_name][fname]['selected'];

                this.onChange.emit([{ field_name: this.field.field_name, value: this.data[this.field.field_name] }]);
                this.policy.dirty = true;
                break;
            }
        }
        this.wordings.map(x => { if (x.value == wordingsItem.value) { x['selected'] = (x['selected'] === 'undefned') ? true : !x['selected'] } return x; });

        //console.log("this.wordings _toggle:",this.data[this.field.field_name]);


    }

    save_wordings(_event: any, selected: any) {
        console.log(this.selected)
        if (!selected?.disp) return;
        var index = this.wordings.length.toString();
        if (selected?.value != '' && selected?.applicability == 'custom') {
            let found = this.data[this.field.field_name].findIndex((ele: { [x: string]: any; }) => ele[this.field.op_field_value] == selected?.value);
            
            console.log("found",found);
            if (found >= 0) {
                this.data[this.field.field_name][found][this.field.op_field_name] =  selected?.disp;
                this.data[this.field.field_name][found][this.field.op_tooltip] =  selected?.tooltip;
            }
            
        }
        else {
            let dtobj: any = {}
            dtobj[this.field.op_field_name] = selected?.disp;
            dtobj[this.field.op_field_value] = ((this.data[this.field.custom.prefix] || this.field.custom.prefix || 'C') + String(this.customid).padStart(3, '0') + (this.data[this.field.custom.suffix] || this.field.custom.suffix || ''));
            dtobj[this.field.op_tooltip] = selected?.tooltip;
            dtobj[this.field.op_applicability] = 'custom';
            dtobj['selected'] = 'true';
            if (!this.data.hasOwnProperty(this.field.field_name) || !Array.isArray(this.data[this.field.field_name]))
                this.data[this.field.field_name] = [];
            this.data[this.field.field_name].push(dtobj);
            console.log(this.field.field_name, this.data[this.field.field_name]);
        }
        this.clear();
        this.onChange.emit([{ field_name: this.field.field_name, value: this.data[this.field.field_name] }]);
        this.policy.dirty = true;
    }

    editwordings(wordingsItem: any) {
        console.log("wordingsItem", wordingsItem);
        if (wordingsItem && wordingsItem?.applicability == 'custom') {
            this.selected = wordingsItem;
        }
    }

    deletewordings(wordingsItem: any) {
        let found = this.data[this.field.field_name].findIndex((ele: { [x: string]: any; }) => ele[this.field.op_field_value] == wordingsItem?.value);
        if (found >= 0) {
            this.data[this.field.field_name].splice(found,1);
        }
        this.clear();
        this.onChange.emit([{ field_name: this.field.field_name, value: this.data[this.field.field_name] }]);

        this.policy.dirty = true;
    }

    clear(){
        this.selected = {disp:'',tooltip:'', value:''};
    }
}
