<div class="csv-import">
    <div class="csv-header" >Import {{data.caption}}</div>
    
    <div style="padding: 1em 1em;">
        <button mat-button ibutton id="import-file" (click)="csvfile.click()">{{preferences.vendor.strings?.csv_select_label || 'Select CSV file' }}</button>
        <input hidden type="file" #csvfile  (change)="onFileSelected(csvfile.files)">
        {{preferences.vendor.strings?.csv_select_message || 'First line of file must have headers'}}
    </div>

    <div style="font-size: 1.5em; padding: 1em 0em 0em 0em;">Preview 
        <small *ngIf="parsed.length>0" style="font-size: 0.8em;"> 
            <span style="cursor: pointer; font-style: italic; text-decoration: underline;" (click)="showErrors=false">{{parsed.length}} </span> found with 
            <span style="cursor: pointer; font-style: italic; text-decoration: underline;" (click)="showErrors=true"> {{errCount}} errors </span>
        </small>
    </div>
    <div class="csv-preview">
        <div>
            <table class="csv-table">
                <tr><th *ngFor="let col of headers">{{col}}</th></tr>
                <tr *ngFor="let row of parsed; let r=index;">
                    <ng-template [ngIf]="!showErrors || status[r]==true">
                        <td *ngFor="let col of row; let c=index;" [title]="errors[r][c]">
                            <mat-icon fontSet="material-icons-outlined" class="csv-err-icon" *ngIf="errors[r][c]" >close</mat-icon>
                            {{col}}
                        </td>
                    </ng-template>
                </tr>
            </table>
        </div>
    </div>
    <div>
        <button mat-button ibutton (click)="onAppend()" *ngIf="parsed.length>0">Append</button>
        <button mat-button ibutton(click)="onReplace()" *ngIf="parsed.length>0">Replace</button>
        <button mat-button ibutton (click)="onClose()" >Close</button>
    </div>
</div>
