import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Policy, insapi } from 'insapi';
import { IField } from '../../form.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'installments',
    templateUrl: './installments.component.html',
    styleUrls: ['./installments.component.scss']
})
export class InstallmentsComponent implements OnInit {
    @Output() onAction = new EventEmitter<any>();
    @Output() onChange = new EventEmitter();
    data!: any;
    field!: IField;
    policy!: Policy;
    enabled: boolean = false;
    psubscription: Subscription | undefined = undefined;
    emiCount: number = 0;
    emiOptions: string[] = [];

    constructor() { }

    ngOnInit(): void {
        this.psubscription = this.policy?.changeSubject.subscribe((policy: any) => {
            
            this.enabled = false;
            if (!this.policy?.product) return;
            if (this.policy.product.installments?.enable_installments == 1) this.enabled = true;
            if (!this.policy.policy?.payment_id) this.enabled = false;
            if (this.policy.policy?.policy_no && !this.policy.policy?.payment?.schedule) this.enabled = false;
            console.log('policy changed ....', this.enabled)
            if (this.policy.product.installments) {
                this.emiOptions = [];
                for (let i=2; i<this.policy.product.installments?.max_emi_count; i++) {
                    this.emiOptions.push(''+i);
                }
            }
            console.log('schedule:', this.policy.policy?.payment?.schedule);
        });
    }

    ngOnDestroy(): void {
        if (this.psubscription) this.psubscription.unsubscribe();
        this.psubscription = undefined;
    }

    async cancelInstallments() {
        if (!this.policy.policy?.payment_id) return;
        await insapi.__xdel('/api/v1/payment/installments?payment_id=' + encodeURIComponent(this.policy.policy?.payment_id));
        this.field.ignore_change = true;
        this.onAction.emit(this.field);
    }

    async addInstallments() {
        if (!this.policy.policy?.payment_id) return;
        if (this.emiCount < 2) return;
        let data = {payment_id: this.policy.policy?.payment_id, emi_count: this.emiCount};
        await insapi.__xpost('/api/v1/payment/installments', data);
        this.field.ignore_change = true;
        this.onAction.emit(this.field);
    }
}
