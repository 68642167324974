import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, 
    APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
// import { ScrollingModule } from '@angular/cdk/scrolling';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; 
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTreeModule } from '@angular/material/tree'; 
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips'; 
import { MatRippleModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion'; 

import { insapi, InsapiService } from 'insapi';
import { HttpClientModule } from '@angular/common/http';
import { ToastService } from 'ins-form';
import { ZulipService } from './services/zulip.service';
import { SettingsService } from './services/settings.service';
import { BusySpinnerComponent } from './busy.component';
import { GroupSelectorComponent } from './components/admin/group-selector.component';
import { UserSelectorComponent } from './components/admin/user-selector.component';
import { OptionSelectorComponent } from './components/admin/option-selector.component';
import { RoleSelectorComponent } from './components/admin/role-selector.component';
import { CalendarSelectorComponent } from './components/admin/calendar-selector.componet';

import { LoginComponent } from './login.component';
import { DatePipe, DateTimePipe, CapitalizePipe, GenericPipe, GenericPipe2, ShortNumberPipe, TimeElapsedPipe, NumberWordPipe, 
    CoinsuranceComponent, FieldGroupComponent, IntermediaryComponent, AddressComponent } from 'ins-form';
import { InsDatePickerAdapter } from './date.adapter';


import { MAT_DATE_FORMATS, MatDateFormats, DateAdapter } from '@angular/material/core';
import { GroupsComponent } from './components/admin/groups/groups.component';
import { HomeComponent } from './components/home/home.component';
import { RolesComponent } from './components/admin/roles/roles.component';
import { UsersComponent } from './components/admin/users/users.component';
import { UserProfileComponent } from './components/admin/user-profile/user-profile.component';
import { ActivityComponent } from './components/activity/activity.component';
import { PolicyComponent } from './components/policy/policy.component';
import { PaymentLedgerComponent } from './components/admin/payment-ledger/payment-ledger.component';
import { PolicyListComponent } from './components/policy-list/policy-list.component';
import { ReassignPolicyComponent } from './components/policy-list/reassign-policy.component';
import { ReferralMessageComponent } from './components/referral-message/referral-message.component';
// import { NstpComponent } from './components/nstp/nstp.component';
// import { RiskDistributionComponent } from './components/risk-distribution/risk-distribution.component';
import { CsvImportComponent } from './components/admin/csv-import/csv-import.component';

import { InsFormModule, GridComponent, CustomerComponent, IButtonDirective, 
    IstepperComponent, FlowComponent, PreferencesService } from 'ins-form';
import { TwofaComponent } from './twofa.component';

import { environment } from './../environments/environment';
import localeIn from '@angular/common/locales/en-IN';


export const dtformat: MatDateFormats = {
    parse: {
          dateInput: 'YYYY-MM-DD',
    },
    display: {
      // dateInput: 'YYYY-MM-DD',
      dateInput: 'input',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    },
};

const formFieldOptions: MatFormFieldDefaultOptions = {
    appearance: environment.vendor.appearance
};
if (!environment.vendor.appearance) delete formFieldOptions.appearance;


class GlobalErrorHandler implements ErrorHandler {
    handleError(error: string) {
        console.log('handleError: ', error, (new Error()).stack);
    }
}

import { components } from 'ins-form';
import { BulkComponent } from './components/bulk/bulk.component';
import { ProductSelectorComponent } from './components/home/product-selector/product-selector.component';
// import { CartComponent } from './components/cart/cart.component';
import { OrderComponent } from './components/order/order.component';
import { EndorsementComponent } from './components/endorsement/endorsement.component';

components['grid'] = GridComponent;
components['customer'] = CustomerComponent;
components['coinsurance'] = CoinsuranceComponent;
components['intermediary'] =  IntermediaryComponent;
components['address'] =  AddressComponent;

import { trace } from './services/tracer';
import { RenewableListComponent } from './components/renewable-list/renewable-list.component';
import { SubmissionsComponent } from './components/submissions/submissions.component';
import { PolicyMiniBarComponent } from './components/policy/policy-mini-bar/policy-mini-bar.component';
import { PolicyCalendarComponent } from './components/policy/policy-calendar/policy-calendar.component';
import { UwSummaryComponent } from './components/reports/uw-summary/uw-summary.component';
import { PolicyTimelineComponent } from './components/policy/policy-timeline/policy-timeline.component';
import { PlanCreationComponent } from './components/plan-creation/plan-creation.component';
import { SoaReportComponent } from './components/soa-report/soa-report.component';
import { CancelPolicyComponent } from './components/cancel-policy/cancel-policy.component';
function __trace() {
    // trace.trace(FieldGroupComponent);
}


@NgModule({
    declarations: [
        AppComponent,
        // ShortNumberPipe,
        // CapitalizePipe,
        // DateTimePipe,
        // DatePipe,
        // TimeElapsedPipe,
        // GenericPipe,
        // GenericPipe2,
        LoginComponent,
        BusySpinnerComponent,
        GroupsComponent,
        HomeComponent,
        RolesComponent,
        UsersComponent,
        GroupSelectorComponent,
        UserSelectorComponent,
        OptionSelectorComponent,
        RoleSelectorComponent,
        CalendarSelectorComponent,
        UserProfileComponent,
        ReassignPolicyComponent,
        // InputComponent,
        // IFieldDirective,
        // FieldGroupComponent,
        // LookupComponent,
        // DateComponent,
        // ButtonComponent,
        // ButtonsComponent,
        // RadioComponent,
        // CheckboxComponent,
        // PasswordComponent,
        // HtmlComponent,
        // FileComponent,
        // EmailComponent,
        // PriceComponent,
        // PolicyMetaDataComponent,
        // PeriodComponent,
        // ReadonlyComponent,
        // BenefitsComponent,
        // OutputsComponent,
        // PlanComponent,
        // GridComponent,
        // MasterPolicyComponent,
        // DummyComponent,
        // NumberComponent,
        ActivityComponent,
        PolicyComponent,
        PaymentLedgerComponent,
        PolicyListComponent,
        ReferralMessageComponent,
        // NstpComponent,
        // RiskDistributionComponent,
        CsvImportComponent,
        BulkComponent,
        ProductSelectorComponent,
        OrderComponent,
        EndorsementComponent,
        TwofaComponent,
        RenewableListComponent,
        SubmissionsComponent,
        PolicyMiniBarComponent,
        PolicyCalendarComponent,
        UwSummaryComponent,
        PolicyTimelineComponent,
		PlanCreationComponent,
		SoaReportComponent,
		CancelPolicyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
        CdkStepperModule,
        DragDropModule,
        // ScrollingModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatRadioModule,
        MatTreeModule,
        MatSliderModule,
        MatChipsModule,
        MatRippleModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSortModule,
        MatMenuModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
		HttpClientModule,
        InsFormModule.forRoot(environment.vendor),
    ],
    providers: [
        SettingsService,
        { provide: APP_INITIALIZER, useFactory: (config: any) => () => config.load(), deps: [SettingsService], multi: true },
        { provide: DateAdapter, useClass: InsDatePickerAdapter},
        { provide: MAT_DATE_FORMATS, useValue: dtformat },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: formFieldOptions},
        { provide: DEFAULT_CURRENCY_CODE, useValue: environment.vendor.currency.code || 'INR'},
        { provide: LOCALE_ID, useValue: environment.vendor.locale || 'en-US' },
        CurrencyPipe,
        DecimalPipe,
        CapitalizePipe,
        DateTimePipe,
        GenericPipe,
        GenericPipe2,
        DatePipe,
        ShortNumberPipe,
        TimeElapsedPipe,
        NumberWordPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    spinnerRef: any[] = [];
    constructor(private insapiService: InsapiService,
        public dialog: MatDialog,
        private toast: ToastService,
        private titleService: Title,
        private settings: SettingsService,
        protected preferences: PreferencesService
        ) {
        this.__init_insillion();
    }

    async __init_insillion() {
        insapi.guest_mode(false, false);
        registerLocaleData(localeIn);
        if (environment.vendor.encrypt) await this.insapiService.setEncryptionOn();
        this.insapiService.setStorage(environment.vendor.storage);
        __trace();

        insapi.messageFunc = (message: string, type: number) => this.toast.showMessage(message, type);
        insapi.authFunc = async () => {
            return new Promise((resolve, reject) => {
                const dialogRef = this.dialog.open(LoginComponent, {disableClose: true, panelClass: 'login-panel'});
                dialogRef.afterClosed().subscribe(result => {resolve('');});
            });
        }
        insapi.spinnerFunc = (show: boolean) => {
            if (show) {
                this.spinnerRef.push(this.dialog.open(BusySpinnerComponent, {disableClose: true}));
            }
            else {
                this.spinnerRef.pop()?.close();
            }
        }
        this.insapiService.loadProfile();
        this.titleService.setTitle(this.settings.title);
        this.preferences.__load_countries();
    }
}
