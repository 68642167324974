import { NativeDateAdapter } from '@angular/material/core';
import { LOCALE_ID, Inject, PLATFORM_ID, Injectable } from "@angular/core";
import { excelDateToDate } from 'insapi';
import moment from 'moment';
import { PreferencesService } from './services/preferences.service';

@Injectable({providedIn: 'root'})
export class InsDatePickerAdapter extends NativeDateAdapter {

    constructor(@Inject(LOCALE_ID) public locale: any,
        @Inject(PLATFORM_ID) platformId: any, private preferences: PreferencesService){super(locale, platformId);}

    parse(value: any): Date | null {
        let mdt = excelDateToDate(value, undefined, this.preferences.dateFormat);
        if (mdt) return mdt.toDate();
        return null;
    }

    format(date: Date, displayFormat: Object): string {
        return moment(date).format(this.preferences.dateFormat);
    }
}