<div *ngIf="submissions?.length > 0">
    <table class="small-table">
        <tr>
            <th *ngFor="let col of columns">{{col.disp}}</th>
        </tr>
        <tr *ngFor="let sub of submissions">
            <td *ngFor="let col of columns">{{sub[col.name] | generic2: col.type}}</td>
        </tr>
    </table>
</div>
<div *ngIf="submissions?.length == 0">No matching submissions found</div>