<div class="page-container-s">
    <group-selector [(ngModel)]="groupName" (optionSelected)="groupChanged()"></group-selector>
</div>
<div class="page-container-m"  *ngIf="groupName">
    <mat-tab-group dynamicHeight mat-align-tabs="center">
        <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">face</mat-icon>
              Users
            </ng-template>
            <div class="user-list grid-3">
                <div *ngFor="let user of groupUsers">
                    <div><mat-icon class="tab-icon-delete" (click)="removeUser(user)">close</mat-icon></div>
                    <div>{{user}}</div>
                </div>
                <div *ngIf="groupUsers.length==0">No user has been mapped yet!</div>
            </div>
            <div class="add-user-list">
                <div>
                    <user-selector [(ngModel)]="userName" (optionSelected)="userChanged()"></user-selector>
                </div>
                <div>
                    <button mat-button ibutton (click)="addUser()">Add</button>
                </div>
                <div>
                </div>
                <div>
                    <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="addUsers($event)" accept=".csv"/>
                    <button mat-button ibutton (click)="csvInput.click()">Bulk upload</button>
                </div>
            </div>
        </mat-tab>
        
        <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon">manage_accounts</mat-icon>
              Privileges
            </ng-template>
            <div class="sub-caption" style="color: #6758c9">View</div>
            <div class="user-list grid-3"  style="color: #6758c9">
                <div *ngFor="let p of groupPrivileges['GET']">
                    <div><mat-icon class="tab-icon-delete" (click)="removePriv(p.priv_name)">close</mat-icon></div>
                    <div>{{p.disp_name}}</div>
                </div>
                <div *ngIf="groupPrivileges['GET'].length==0">No privilege has been mapped yet!</div>
            </div>
            <div class="sub-caption" style="color: #c95867">Modify</div>
            <div class="user-list grid-3">
                <div *ngFor="let p of groupPrivileges['POST']" style="color: #c95867">
                    <div><mat-icon class="tab-icon-delete" (click)="removePriv(p.priv_name)">close</mat-icon></div>
                    <div>{{p.disp_name}}</div>
                </div>
                <div *ngIf="groupPrivileges['POST'].length==0">No privilege has been mapped yet!</div>
            </div>
            <div class="sub-caption">Remove</div>
            <div class="user-list grid-3">
                <div *ngFor="let p of groupPrivileges['DELETE']">
                    <div><mat-icon class="tab-icon-delete" (click)="removePriv(p.priv_name)">close</mat-icon></div>
                    <div>{{p.disp_name}}</div>
                </div>
                <div *ngIf="groupPrivileges['DELETE'].length==0">No privilege has been mapped yet!</div>
            </div>

            <div class="add-user-list">
                <div>
                    <option-selector [(ngModel)]="privName" [options]="privNames" (optionSelected)="privChanged()"></option-selector>
                </div>
                <div>
                    <button mat-button ibutton (click)="addPrivilege()" [disabled]='!privName || !privileges[privName]'>Add</button>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</div>
