export const  pastels_gradient: any[] = [
    {bg: '#EBE2FD', light: '#FBF2FD', dark: '#8B829D', deep: '#473E99', 
        style: {color: '#473E99', background: 'linear-gradient(to bottom, #FBF2FD 0%, #CBC2FD 100%)'}},
    {bg: '#E4FFE9', light: '#F8FFFA', dark: '#849F89', deep: '#40BF49',
        style: {color: '#40BF49', background: 'linear-gradient(to bottom, #F8FFFA 0%, #C8FFCA 100%)'}},
    {bg: '#FCF5D1', light: '#FFFFE3', dark: '#9C9571', deep: '#BCB031',
        style: {color: '#BCB031', background: 'linear-gradient(to bottom, #FFFFE3 0%, #FCF5D1 100%)'}},
    {bg: '#FDDFDF', light: '#FFEFEF', dark: '#9D7F7F', deep: '#CD4F4F',
        style: {color: '#CD4F4F', background: 'linear-gradient(to bottom, #FFEFEF 0%, #FFE0E0 100%)'}},
    {bg: '#D4F9F7', light: '#E4FFF9', dark: '#749997', deep: '#44C9C7',
        style: {color: '#44C9C7', background: 'linear-gradient(to bottom, #E4FFF9 0%, #CAF0ED 100%)'}},
    {bg: '#F4D9F6', light: '#F8E9F9', dark: '#947996', deep: '#C449C6',
        style: {color: '#C449C6', background: 'linear-gradient(to bottom, #F8E9F9 0%, #F0C9F0 100%)'}}
];


export const  pastels_bright: any[] = [
    {bg: '#E4FFE9', light: '#F8FFFA', dark: '#849F89', deep: '#40BF49', 
        style: {color: '#40BF49', 'background-color': '#7ee8fa', 'background-image': 'linear-gradient(315deg, #7ee8fa 0%, #80ff72 74%)'}},

    {bg: '#D4F9F7', light: '#E4FFF9', dark: '#749997', deep: '#44C9C7',
        style: {color: '#34CADA', 'background-color': '#b1ade2', 'background-image': 'linear-gradient(315deg, #b1ade2 0%, #7ddff8 74%)'}},

    {bg: '#F4D9F6', light: '#F8E9F9', dark: '#947996', deep: '#C449C6',
        style: {color: '#C449C6', 'background-color': '#cdedfd', 'background-image': 'linear-gradient(319deg, #cdedfd 0%, #ffec82 37%, #ffcfd2 100%)'}},

    {bg: '#FCF5D1', light: '#FFFFE3', dark: '#9C9571', deep: '#fce043',
        style: {color: '#8b3b72', 'background-color': '#fce043', 'background-image': 'linear-gradient(315deg, #fce043 0%, #fb7ba2 74%)'}},

    {bg: '#FDDFDF', light: '#FFEFEF', dark: '#9D7F7F', deep: '#CD4F4F',
        style: {color: '#604380', 'background-color': '#abe9cd', 'background-image': 'linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%)'}},

    {bg: '#F4D9F6', light: '#F8E9F9', dark: '#947996', deep: '#C449C6',
        style: {color: '#C449C6', 'background-color': '#ee5166', 'background-image': 'linear-gradient(315deg, #ee5166 0%, #f08efc 74%)'}},

    {bg: '#FDDFDF', light: '#FFEFEF', dark: '#9D7F7F', deep: '#CD4F4F',
        style: {color: '#CD4F4F', 'background-color': '#ffac81', 'background-image': 'linear-gradient(315deg, #ffac81 0%, #ff928b 74%)'}},
];

export const prodIcons = ['explore', 'view_in_ar', 'anchor', 'hotel_class', 'balance', 'webhook', 'maps_home_work', 'stream'];