<div class="summary" *ngIf="listDef.headers">
    <div [class]="['grid-'+(field.grid||2), 'summary-wrap']" style="display:grid" [style.grid-template-columns]="field.gridTemplateColumns">
        <div [ngStyle] = "listDef.columnlayout[head].header_style" class="summary-header" [attr.data-iid]="head"  *ngFor="let head of listDef.headers; let h=index" >
            {{head | capitalize}}
        </div>
        <ng-template class="summary-item" [attr.data-iid]="mp" ngFor let-mp let-i="index" [ngForOf]="listDef.fields">
            <div class="summary-item-cell" [ngStyle] = "mergeStyle(listDef.columnlayout[d].style,listDef.fields[i].layout.style)" [attr.data-iid]="d" *ngFor="let d of listDef.headers; let j=index">
                <div *ngIf="!mp.ifFunc || mp.ifFunc(data,profile, policy?.endorsement || policy?.policy)">
                    <div *ngIf="mp[d+'func']">
                        {{mp[d+'func'](data,profile,policy?.endorsement || policy?.policy) ?? '' | generic2: listDef.columnlayout[d].format}}
                    </div>
                    <div *ngIf="!mp[d+'func']">
                        {{ mp[d] ?? ''}}
                    </div>
                    <!-- {{ ((listDef.columnlayout[d].type == "string")?mp[d]:(data.hasOwnProperty(mp[d])?data[mp[d]]:'') | generic2 : listDef.columnlayout[d].format) }} -->
                </div>
            </div>
        </ng-template>
    </div>
    
</div>
